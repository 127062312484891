import { get } from 'helpers/axios/axiosInterceptorsV1';
import metadataExampleTest from './metadataExampleTest.json';

const fetchMetadata = async fchar => {
  const path = `/tax-entities/${fchar}/metadata`;
  // Copy the metadata you want to test into the metadataExampleTest file. After that, update the fcharToTest variable with the fchar to test.
  const fcharToTest = null;
  try {
    if (fchar === fcharToTest) {
      return metadataExampleTest;
    }
    const response = await get(path);
    return response.data;
  } catch (error) {
    throw error;
  };
};

export default fetchMetadata;