import actionTypes from './NotificationsConstants';

let latestRelease;

try {
  latestRelease = JSON.parse(sessionStorage.getItem('LATEST_RELEASE')) || { NewsDate: '' };
} catch (e) {
  latestRelease = { NewsDate: '' };
}

const initialStore = {
  notifications: [],
  fetchingNotifications: false,
  latestRelease: latestRelease,
  fetchingLatestNotification: false,
  fetchingNotificationFilters: false,
  notificationFilters: [],
  creatingNotification: false,
  newNotificationResponse: [],
  editingNotification: false,
  editedNotificationResponse: [],
  deletingNotification: false,
  deletedNotificationResponse: []
};

export default function NotificationsReducer(state = initialStore, action) {
  switch (action.type) {
    case actionTypes.FETCHING_NOTIFICATIONS:
      return { ...state, fetchingNotifications: true };

    case actionTypes.FETCH_NOTIFICATIONS_SUCCESS: {
      return { ...state,
        notifications: action.notifications.Data,
        fetchingNotifications: false
      };
    }

    case actionTypes.FETCH_NOTIFICATIONS_FAIL:
      return { ...state, fetchingNotifications: false };

    case actionTypes.FETCHING_LATEST_NOTIFICATION:
      return { ...state, fetchingLatestNotification: true };

    case actionTypes.FETCH_LATEST_NOTIFICATION_SUCCESS:
      sessionStorage.setItem('LATEST_RELEASE', JSON.stringify(action.latestRelease));
      return { ...state, latestRelease: action.latestRelease, fetchLatestNotification: false };

    case actionTypes.FETCH_LATEST_NOTIFICATION_FAIL:
      return { ...state, fetchLatestNotification: false };

    case actionTypes.FETCHING_NOTIFICATION_FILTERS:
      return { ...state, fetchingNotificationFilters: true };

    case actionTypes.FETCH_NOTIFICATION_FILTERS_SUCCESS:
      return { ...state, notificationFilters: action.notificationFilters.reverse(), fetchingNotificationFilters: false };

    case actionTypes.FETCH_NOTIFICATION_FILTERS_FAIL:
      return { ...state, fetchingNotificationFilters: false };

    case actionTypes.CREATING_NEW_NOTIFICATION:
      return { ...state, creatingNotification: true };

    case actionTypes.CREATE_NEW_NOTIFICATION_SUCCESS:
      return { ...state, creatingNotification: false, newNotificationResponse: action.payload };

    case actionTypes.CREATE_NEW_NOTIFICATION_FAIL:
      return { ...state, creatingNotification: false };

    case actionTypes.EDITING_NOTIFICATION:
      return { ...state, editingNotification: true };

    case actionTypes.EDIT_NOTIFICATION_SUCCESS:
      return { ...state, editingNotification: false, editedNotificationResponse: action.payload };

    case actionTypes.EDIT_NOTIFICATION_FAIL:
      return { ...state, editingNotification: false };

    case actionTypes.DELETING_NOTIFICATION:
      return { ...state, deletingNotification: true };

    case actionTypes.DELETE_NOTIFICATION_SUCCESS:
      return { ...state, deletingNotification: false, deletedNotificationResponse: action.payload };

    case actionTypes.DELETE_NOTIFICATION_FAIL:
      return { ...state, deletingNotification: false };

    case actionTypes.LOGOUT_SUCCESS:
      return { ...initialStore };

    default:
      return state;
  }
}
