import SovosTheme from 'mosaic-react/themes';

export default {
  ...SovosTheme,
  table: {
    ...SovosTheme.table,
    margin: 0,
    marginTop: 5
  },
  slidingPanel: {
    baseWidth: '87.5%'
  }
};
