import { Component } from 'react';
import PropTypes from 'prop-types';
import theme from 'mosaic-react/themes/sovos-theme';
import AuthorAvatar from 'shared/authorAvatar/AuthorAvatar';
import RefreshIndicatorLoading from 'shared/refreshIndicatorLoading/refreshIndicatorLoading';
import './commentList.scss';

const { palette } = theme;

class CommentList extends Component {

  componentDidMount() {
    this.props.commentsActions.fetchComments(this.props.formType, this.props.formId, 1, 50);
  }

  componentDidUpdate(prevProps) {
    if (this.props.formId !== prevProps.formId || this.props.formType !== prevProps.formType ) {
      this.props.commentsActions.fetchComments(this.props.formType, this.props.formId, 1, 50);
    }
    this.containerComments.scrollTop = this.containerComments.scrollHeight;
  }

  render() {
    const { fetchingComments, comments } = this.props;
    return (
      <div
        className="form-comment__wrapper"
        ref={ div => this.containerComments = div }
      >
        { (fetchingComments) ? <RefreshIndicatorLoading size={ 40 } containerStyle={ { position: 'relative' } } /> :
        ( !comments || comments.length === 0 ) ?
          <div
            style={ { borderColor: palette.divider, color: palette.grey[600] } }
            className="form-comment__comment"
          >
            There are no comments yet
          </div> :
          comments.map((comment, index) =>
            (
              <div
                style={ { borderColor: palette.divider } }
                className={ `form-comment__comment ${( index > 0) ? 'form-comment__non-first-comment' : ''}` }
                key={ comment.key }
              >
                <div className="form-comment__left-content" >
                  <AuthorAvatar userName={ comment.userName } />
                  <div className="form-comment__left-content_white-space">
                    { comment.message }
                  </div>
                </div>
                <div className="form-comment__right-content" style={ { color: palette.grey[600] } } >
                  <span>{ comment.timeStamp }</span>
                </div>
              </div>
            )
          )
        }
      </div>
    );
  }
}

CommentList.propTypes = {
  comments: PropTypes.array.isRequired,
  fetchingComments: PropTypes.bool.isRequired,
  commentsActions: PropTypes.shape({
    fetchComments: PropTypes.func
  }).isRequired,
  formId: PropTypes.number.isRequired,
  formType: PropTypes.string.isRequired
};

export default CommentList;
