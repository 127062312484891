import { string, bool, func, object, number, oneOfType } from 'prop-types';
import SovosButton from 'mosaic-react/sovos-button';
import { WithholdingFormStatus } from 'pages/withholdingForms/pages/withholdingFormsPage/WithholdingFormsColumns';
import filingStatusConstants from 'helpers/filingStatusConstants';
import './wfMarkAsCorrectionButton.scss';

const WFMarkAsCorrectionButton = ({ status, taxEntityCode, label, value, onChange, formActions, isClone }) => {

  const handleOnChange = () => {
    formActions.setFieldValue('ReturnStatus', filingStatusConstants.CORRECTION, isClone, taxEntityCode);
    onChange(null, !value);
  };

  if (status === WithholdingFormStatus.SUBMISSION_ACCEPTED || value) {
    return (
      <div className='button-field-form__container' key={ label }>
        <SovosButton
          id={ label }
          variant= 'text'
          disabled= { value }
          className={ 'button-field-form__padding' }
          onClick={ handleOnChange }
        >
          { label }
        </SovosButton>
      </div>
    );
  }
};

WFMarkAsCorrectionButton.propTypes = {
  status: number,
  fchar: string,
  label: string.isRequired,
  value: oneOfType([string, bool]),
  onChange: func.isRequired,
  formActions: object.isRequired
};

export default WFMarkAsCorrectionButton;