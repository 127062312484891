import { useState, useEffect } from 'react';
import { array } from 'prop-types';
import ValidationGridTextField from 'shared/standardInput/textField/validationGridTextField/ValidationGridTextFieldInterface';
import TinSuggestions from './tinSuggestions/TinSuggestionsConnector';
import './tin.scss';

const TIN = ({ recipientList, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (props.isInUse && recipientList.length) {
      setIsOpen(true);
    }
  }, [recipientList]);

  const handleOnFocus = () => setIsOpen(true);

  const handleOnBlur = () => setIsOpen(false);

  return (
    <div className="tin__container">
      <ValidationGridTextField
        { ...props }
        onFocus={ handleOnFocus }
        onBlur={ handleOnBlur }
      />
      {
        Boolean(isOpen && recipientList.length) &&
          <TinSuggestions
            recipientList={ recipientList }
          />
      }
    </div>
  );
};

TIN.propTypes = {
  recipientList: array.isRequired
};

export default TIN;
