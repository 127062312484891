import PropTypes from 'prop-types';
import './taxYear.scss';

const TaxYear = ({ taxYear }) => {
  const firstLetters = taxYear.substring(0, 2);
  const lastLetters = taxYear.substring(2, 4);
  return (
    taxYear &&
    <h3 className="tax-year">
      <label className="tax-year__fist_2_letters"> { firstLetters }</label>
      <label className="tax-year__last_2_letters">{ lastLetters }</label>
    </h3>
  );
};

TaxYear.propTypes = {
  taxYear: PropTypes.string
};

TaxYear.defaultProps = {
  taxYear: ''
};

export default TaxYear;