import AuthenticationHelper from '../authenticationHelper';
import { reportLogoutSuccess } from 'redux/auth/authActions';

const apiVersions = {
  V1: 'v1',
  V2: 'v2',
  NoVersion: ''
};

const ERR_CANCELED = 'ERR_CANCELED';

const triggerLogout = response => {
  const logoutUrl = `${window.location.protocol}//${window.location.host}/logout`;
  const errorCode = response.headers['x-error-code'];
  window.open(`${logoutUrl}${errorCode?'?errorcode='+errorCode:''}`, '_self');
};

const setAxiosInterceptors = (store, version, getAxiosInstance) => {
  getAxiosInstance().defaults.baseURL = `${window.location.protocol}//${window.location.host}/api/${version}`;

  getAxiosInstance().interceptors.request.use(config => {
    config.headers = AuthenticationHelper.getHeaders(config);
    return config;
  });

  getAxiosInstance().interceptors.response.use(resp => {
    if (resp.data.errMsg && resp.data.status === 401) {
      triggerLogout(resp);
      return Promise.reject(resp);
    }

    if (resp.data.errMsg) {
      return Promise.reject(resp);
    }

    if (resp.config.url.includes('/otp-login')){
      AuthenticationHelper.storeJwtToken(resp.headers['platform-token'] || resp.data);
      AuthenticationHelper.storeIdToken(resp.headers['platform-token']);
    }

    if (resp.data.status === 202 && resp.headers['platform-token'] === undefined ){
      store.dispatch(reportLogoutSuccess());
    }

    return resp;
  });

  getAxiosInstance().interceptors.response.use(undefined, err => {
    if (
      err.response && (
        (err.response.status === 400 && err.response.data.message === 'Authorization Error') ||
        (err.response.status === 401)
      )
    ) {
      triggerLogout(err.response);
      return Promise.reject(err.response);
    }

    if (err.code === ERR_CANCELED) {
      return Promise.reject(err);
    }

    if (err.response.data) {
      const responseData = Object.fromEntries(
        Object.entries(err.response.data).map(([k, v]) => [k.toLowerCase(), v])
      );

      return Promise.reject({ ...err.response, data: responseData });
    }

    return Promise.reject(err.response);
  });
};

export {
  ERR_CANCELED,
  setAxiosInterceptors,
  apiVersions
};
