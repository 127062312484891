import { get, post } from 'helpers/axios/axiosInterceptorsV1';
import actionTypes from './contextConstants';
import authenticationHelper from 'helpers/authenticationHelper';

const iamUsersPath = '/iam/users';

const fetchContextsSuccess = contexts => ({ type: actionTypes.FETCH_CONTEXTS_SUCCESS, contexts });
const fetchingContexts = () => ({ type: actionTypes.FETCHING_CONTEXTS });
const setOrganizationsTree = allOrgs => ({ type: actionTypes.SET_CONTEXT_TREE, contextTree: allOrgs });
const fetchingUser = () => ({ type: actionTypes.FETCHING_USER });
const fetchUserSuccess = userInfo => ({ type: actionTypes.FETCH_USER_SUCCESS, userInfo });
const fetchUserFailed = () => ({ type: actionTypes.FETCH_USER_FAIL });
const setContextSuccess = context => ({ type: actionTypes.SET_CONTEXT_SUCCESS, context });
const setContexts = contexts => ({ type: actionTypes.SET_CONTEXTS, contexts });

const setContext = context => dispatch => {
  dispatch(setContextSuccess(context));
  dispatch(saveUserContext(context));
};

const migrateContextsToAccounts = allOrgs => {
  let allOrgsString = JSON.stringify(allOrgs);
  allOrgsString = allOrgsString.replaceAll('nestedContexts', 'nestedAccounts');
  return JSON.parse(allOrgsString);
};

const fetchContexts = isInternalAdmin => async dispatch => {
  dispatch(fetchingContexts());
  const path = isInternalAdmin
    ? `${iamUsersPath}/clients/orgs?parent-org-id=${authenticationHelper.getOrgId()}`
    : `${iamUsersPath}/clients/orgs`;
  get(path)
    .then(async response => {
      let allOrgs = response.data.data;
      allOrgs = migrateContextsToAccounts(allOrgs);
      const savedContext = !isInternalAdmin ? await getSavedUserContext() : {};
      const isContextSaved = Boolean(Object.keys(savedContext).length);
      const userContexts = getUserContexts(allOrgs);
      const selectedContext = isContextSaved ? savedContext : getMaxYearProdContext(userContexts);
      !isContextSaved && !isInternalAdmin && dispatch(saveUserContext(selectedContext));
      dispatch(setOrganizationsTree(allOrgs));
      dispatch(fetchContextsSuccess({ allOrgs, userContexts, selectedContext }));
    })
    .catch(error => { throw error; });
};

const getMaxYearProdContext = contexts =>
  contexts
    .filter(context => context.name === 'PROD')
    .reduce((prev, current) => prev.taxYear > current.taxYear ? prev : current);

const getSavedUserContext = async () => {
  try {
    const savedContext = await get('/users/configurations/USER_CONTEXT');
    return savedContext.data.data.Value;
  } catch (e) {
    return Promise.resolve({});
  }
};

const getUserContexts = contexts => {
  let userContexts = [];
  const findContext = childContext => {
    if ('nestedAccounts' in childContext) {
      childContext.nestedAccounts.forEach(context => {
        findContext(context);
      });
    } else {
      const customAttributes = JSON.parse(childContext.customAttributes);
      userContexts.push({
        ...childContext,
        name: customAttributes.parentName.toUpperCase(),
        taxYear: customAttributes.name,
        customAttributes
      });
    }
  };

  findContext(contexts[0]);
  return userContexts;
};

const saveUserContext = context => async dispatch => {
  const path = '/users/configurations';
  const payload = {
    name: 'USER_CONTEXT',
    value: context
  };
  try {
    await post(path, payload);
    dispatch({ type: actionTypes.SAVE_USER_CONTEXT_SUCCESS, context });
  } catch (e) {
    dispatch({ type: actionTypes.SAVE_USER_CONTEXT_FAILURE });
  }
};

const fetchUser = () => async dispatch => {
  dispatch(fetchingUser());
  const userId = authenticationHelper.getDecodedToken().sub;
  get(`${iamUsersPath}/${userId}`)
    .then(response => { dispatch(fetchUserSuccess(response.data.data)); })
    .catch(dispatch(fetchUserFailed()));
};

const fetchClient = () => async dispatch => {
  dispatch({ type: actionTypes.FETCHING_CLIENT });
  try {
    const response = await get('/environment/client');
    dispatch({ type: actionTypes.FETCH_CLIENT_SUCCESS, clientInfo: response.data.data });
  } catch (err) {
    dispatch({ type: actionTypes.FETCH_CLIENT_FAIL });
    throw (err);
  }
};

export {
  fetchClient,
  fetchContexts,
  fetchUser,
  setContext,
  setOrganizationsTree,
  fetchContextsSuccess,
  setContexts
};
