import PropTypes from 'prop-types';
import theme from 'mosaic-react/themes/sovos-theme';
import ColumnListItem from './ColumnListItem';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';

const { palette } = theme;

const ColumnListRow = ({ column, isEnabled, onShowColumn, onHideColumn }) => (
  <div className="column-selector__column-row">
    <div>
      <ColumnListItem
        column={ column }
        visible={ isEnabled }
      />
    </div>
    <div className="column-selector__icon-container">
      {
        isEnabled ?
          <Visibility htmlColor={ palette.primary.main } onClick={ () => onHideColumn(column) } /> :
          <VisibilityOff color='disabled' onClick={ () => onShowColumn(column) } />
      }
    </div>
  </div>
);

ColumnListRow.propTypes = {
  onHideColumn: PropTypes.func.isRequired,
  onShowColumn: PropTypes.func.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  column: PropTypes.object.isRequired
};

export default ColumnListRow;
