const states = {
  NOT_SET: 'NotSet',
  AWATING_APPROVAL: 'AwaitingApproval',
  READY_TO_TRANSMIT: 'ReadyToTransmit',
  READY_TO_TRANSMIT_FEDERAL: 'ReadyToTransmitFederal',
  READY_TO_TRANSMIT_STATE: 'ReadyToTransmitState',
  PROCESSING: 'Processing',
  CORRECTED: 'CorrectedForms'
};

export default states;
