import sharedActions from '../constants/SharedConstants';

const actionTypes = {
  UPDATE_TABLE_OPTIONS_SUCCESS: 'UPDATE_TABLE_OPTIONS_SUCCESS',
  UPDATE_TABLE_OPTIONS_PENDING: 'UPDATE_TABLE_OPTIONS_PENDING',
  RESET_EULA_SETTING: 'RESET_EULA_SETTING',
  ...sharedActions
};

export default actionTypes;
