import { lazy, Suspense } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
const DNDDragDropContext = lazy(() => import(/* webpackChunkName: "DragDropContext" */ './DragDropContextInterface'));

const DragDropContext = ({ children, onDragEnd, onDragStart }) => {
  const handleOnDragEnd = result => result.destination ? onDragEnd(result) : null;

  const handleOnStart = initial => onDragStart(initial);

  return (
    <>
      <Suspense fallback={ <LinearProgress /> }>
        <DNDDragDropContext
          onDragEnd={ handleOnDragEnd }
          onDragStart={ handleOnStart }
        >
          { children }
        </DNDDragDropContext>
      </Suspense>
    </>
  );
};

DragDropContext.defaultProps = {
  onDragStart: () => {}
};

DragDropContext.propTypes = {
  children: PropTypes.element.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  onDragStart: PropTypes.func
};

export default DragDropContext;
