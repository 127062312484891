import sharedActions from 'redux/constants/SharedConstants';

const actionTypes = {
  ...sharedActions,
  SETTING_JOB_STATE: 'SETTING_JOB_STATE',
  SET_JOB_STATE_SUCCESS: 'SET_JOB_STATE_SUCCESS',
  SET_JOB_STATE_FAIL: 'SET_JOB_STATE_FAIL'
};

export default actionTypes;
