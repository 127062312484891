import { connect } from 'react-redux';
import DynamicFormValidations from './DynamicFormValidations';

const mapStateToProps = (state, ownProps) => ({
  formErrors: state.dynamicForm.formErrors[ownProps.taxEntityCode || state.dynamicForm.taxEntityCode] || [],
  cloneFormErrors: state.dynamicForm.cloneFormErrors,
  fetchingForm: state.dynamicForm.fetchingForm,
  isClone: state.dynamicForm.isClone
});

export default connect(mapStateToProps, null)(DynamicFormValidations);
