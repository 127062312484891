import { Component } from 'react';
import PropTypes from 'prop-types';
import SovosCheckbox from 'mosaic-react/sovos-checkbox';
import classNames from 'classnames';
import './checkBox.scss';

class CheckBoxFieldForm extends Component {
  state = {
    originalValue: this.props.value,
    checked: this.props.options[this.props.value] || this.props.value === '1',
    value: this.props.value
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      const checked = this.props.options[this.props.value] || false;
      this.setState({ checked, value: this.props.value });
    }
  }

  handleCheckboxChange = ev => {
    let value;
    this.setState(oldState => {
      value = Object.keys(this.props.options).find(option => this.props.options[option] === !oldState.checked);
      if (value === 'null') {
        value = '';
      }
      return ({ checked: !oldState.checked, value });
    }, () => {
      this.state.originalValue?.length === 0 && !this.state.checked
        ? this.props.onChange(ev, this.state.originalValue, this.props.onChangeCallback)
        : this.props.onChange(ev, value, this.props.onChangeCallback);
    });
  };
  render = () => {
    const { label, boxName, style, iconStyle, name, readOnly, readOnlyUser } = this.props;
    const customIconStyle = { ...style, ...iconStyle };
    return (
      <div className='checkbox-placeholder-form__wrapper'>
        <div className='label__container'>
          <div className='label__container-left'>
            { boxName &&
              <div>
                <label
                  className='checkbox__label-box'
                  htmlFor={ name }
                >
                  { boxName }
                </label>
              </div>
            }
            <div>
              <label
                className='checkbox__label'
                htmlFor={ name }
              >
                { label }
              </label>
            </div>
          </div>
          <div>
            <label
              className='checkbox__label-box'
              htmlFor={ name }
            >
              { boxName }
            </label>
          </div>
        </div>
        <div className='checkbox__container'>
          <SovosCheckbox
            name={ name }
            checked={ this.state.checked }
            onChange={ this.handleCheckboxChange }
            className={ classNames(
              'checkbox__wrapper__checkbox', {
                'checkbox__wrapper_checked': this.state.checked,
                'checkbox__wrapper--disabled': readOnlyUser || readOnly
              }
            ) }
            style={ customIconStyle }
            disabled={ readOnlyUser || readOnly }
          />
        </div>
      </div>
    );
  };
}

CheckBoxFieldForm.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  boxName: PropTypes.string,
  label: PropTypes.node,
  labelValidation: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onChangeCallback: PropTypes.func,
  options: PropTypes.object,
  style: PropTypes.object,
  iconStyle: PropTypes.object,
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  readOnlyUser: PropTypes.bool.isRequired
};

CheckBoxFieldForm.defaultProps = {
  name: '',
  boxName: '',
  label: '',
  options: { 'null': false, '1': true },
  value: '0',
  onChangeCallback: () => {},
  style: {},
  iconStyle: {}
};

export default CheckBoxFieldForm;
