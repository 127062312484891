import actionTypes from './AdminPanelConstants';
import { get, post, patch, remove } from 'helpers/axios/axiosInterceptorsV1';
import { saveBlob } from 'helpers/fileTransferHelper';
import { buildUrlsParams } from 'helpers/buildUrlParams';

const migratingClient = () => ({ type: actionTypes.MIGRATING_CLIENT });

const clientMigrationSuccess = migrationResult => ({ type: actionTypes.CLIENT_MIGRATION_SUCCESS, migrationResult });

const exportStarted = () => dispatch => { dispatch({ type: actionTypes.EXPORT_STARTED }); };

const exportFinished = () => dispatch => { dispatch({ type: actionTypes.EXPORT_FINISHED }); };

const createTaxYear = (taxYear, allClients) => async dispatch => {
  dispatch({ type: actionTypes.CREATING_TAX_YEAR });

  if (allClients) {
    const path = `/environment/provision/client/taxyear/${taxYear}`;

    try {
      await post(path);
      dispatch({
        type: actionTypes.CREATE_TAX_YEAR_SUCCESS,
        testResponse: 1,
        prodResponse: 1
      });
    } catch (error) {
      dispatch({ type: actionTypes.CREATE_TAX_YEAR_FAIL });
      throw (error);
    }
  }
  else {
    const clientId = JSON.parse(window.sessionStorage.context).clientId;
    const pathTEST = `/environment/provision/client/${clientId}/context/Test/taxyear`;
    const pathPROD = `/environment/provision/client/${clientId}/context/Prod/taxyear`;

    try {
      const response = await Promise.all([
        post(pathTEST, { TaxYear: taxYear, IsTest: true }),
        post(pathPROD, { TaxYear: taxYear, IsTest: false })
      ]);
      dispatch({
        type: actionTypes.CREATE_TAX_YEAR_SUCCESS,
        testResponse: response[0].data.data,
        prodResponse: response[1].data.data
      });
    } catch (error) {
      dispatch({ type: actionTypes.CREATE_TAX_YEAR_FAIL });
      throw (error);
    }
  }
};

const migrateClient = (seghash, formVolume, printServicesDisabled) => async dispatch => {
  dispatch(migratingClient());

  try {
    const response = await post(`/environment/migration/client/${seghash}`, { formVolume, printServicesDisabled, isTestClient: false });
    dispatch(clientMigrationSuccess(response.data.data));
  } catch (error) {
    dispatch({ type: actionTypes.CLIENT_MIGRATION_FAILURE });
    throw (error);
  }
};

const fetchResetDataConfig = () => async dispatch => {
  dispatch({ type: actionTypes.FETCHING_RESET_DATA_CONFIG });

  try {
    const response = await get('tax-entities/resets');
    dispatch({ type: actionTypes.RESET_DATA_CONFIG_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: actionTypes.RESET_DATA_CONFIG_FAILURE });
    throw (error);
  }
};

const resetData = recordsSelected => async dispatch => {
  dispatch({ type: actionTypes.RESETING_DATA });

  try {
    const query = buildUrlsParams({ entities: recordsSelected });
    await remove(`tax-entities/resets?${query}`, recordsSelected );
    dispatch({ type: actionTypes.RESET_DATA_SUCCESS });
  } catch (error) {
    dispatch({ type: actionTypes.RESET_DATA_FAILURE, message: 'We could not delete the records. Please, try again.' });
    throw (error);
  }
};

const fetchServices = () => async dispatch => {
  dispatch({ type: actionTypes.FETCHING_SERVICES });

  const app1Path = '/microservices/app1';
  const app2Path = '/microservices/app2';

  try {
    const result = await Promise.all([get(app1Path), get(app2Path)]);
    dispatch({
      type: actionTypes.FETCH_SERVICES_SUCCESS,
      app1Payload: result[0].data.data.sort((a, b) => a.RunningName.localeCompare(b.RunningName)),
      app2Payload: result[1].data.data.sort((a, b) => a.RunningName.localeCompare(b.RunningName))
    });
  } catch (error) {
    dispatch({ type: actionTypes.FETCH_SERVICES_FAIL });
    throw (error);
  }
};

const fetchVersions = () => async dispatch => {
  dispatch({ type: actionTypes.FETCHING_VERSIONS });

  const app1Path = '/microservices/app1/versions';
  const app2Path = '/microservices/app2/versions';

  try {
    const result = await Promise.all([get(app1Path), get(app2Path)]);
    dispatch({ type: actionTypes.FETCH_VERSIONS_SUCCESS, app1Payload: result[0].data.data, app2Payload: result[1].data.data });
  } catch (error) {
    dispatch({ type: actionTypes.FETCH_VERSIONS_FAIL });
    throw (error);
  }
};

const fetchAllLogLevels = () => async dispatch => {
  dispatch({ type: actionTypes.FETCHING_LOG_LEVELS });

  const app1Path = '/microservices/app1/logger';
  const app2Path = '/microservices/app2/logger';

  try {
    const result = await Promise.all([get(app1Path), get(app2Path)]);
    dispatch({ type: actionTypes.FETCH_LOG_LEVELS_SUCCESS, app1Payload: result[0].data.data, app2Payload: result[1].data.data });
  } catch (error) {
    dispatch({ type: actionTypes.FETCH_LOG_LEVELS_FAIL });
    throw (error);
  }
};

const setLogLevel = (targetServer, serviceName, level) => async dispatch => {
  const path = `/microservices/${targetServer}/${serviceName}/logger`;

  dispatch({ type: actionTypes.SETTING_LOG_LEVEL });

  try {
    const response = await patch(path, { LoggerName: "logfile", level });
    dispatch({ type: actionTypes.SET_LOG_LEVEL_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: actionTypes.SET_LOG_LEVEL_FAIL, error: error.data });
    throw (error);
  }
};

const usageReport = () => async dispatch => {
  const query = buildUrlsParams({ download: true });
  const payload = {
    FileName: 'TIR_CORPCLIENTUSAGE',
    DataRequests: [
      {
        name: 'TIR_CORPCLIENTUSAGE',
        url: '/api/v1/transmits/admin/overview',
        method: 'GET'
      }
    ],
    storeInFileManager: false
  };
  dispatch(exportStarted());
  try {
    const res = await post(`/reports?${query}`, payload, { responseType: 'blob' });
    saveBlob(res.data, 'TIR_CORPCLIENTUSAGE.xlsx');
    dispatch(exportFinished());
  } catch (error) {
    error.data = JSON.parse(await new Response(error.data).text());
    dispatch({ type: actionTypes.EXPORT_FAILED, exportError: error.data });
  }
};

const recalculate = () => async dispatch => {
  const path = '/tax-identity/aliases/recalculateStatus';

  dispatch({ type: actionTypes.RECALCULATING_STATUSES_STARTED });
  try {
    await post(path);
    dispatch({ type: actionTypes.RECALCULATING_STATUSES_SUCCESS });
  } catch (error) {
    dispatch({ type: actionTypes.RECALCULATING_STATUSES_FAIL });
    throw (error);
  }
};

const calculate = () => async dispatch => {
  const path = '/tax-identity/aliases/calculate-backup-withholding';

  dispatch({ type: actionTypes.CALCULATING_BW_STATUSES_STARTED });
  try {
    await post(path);
    dispatch({ type: actionTypes.CALCULATING_BW_STATUSES_SUCCESS });
  } catch (error) {
    dispatch({ type: actionTypes.CALCULATING_BW_STATUSES_FAIL });
    throw (error);
  }
};

const clearServices = () => dispatch => dispatch({ type: actionTypes.CLEAR_SERVICES });

export {
  migrateClient,
  fetchResetDataConfig,
  resetData,
  fetchServices,
  fetchVersions,
  fetchAllLogLevels,
  setLogLevel,
  clearServices,
  usageReport,
  recalculate,
  calculate,
  createTaxYear
};
