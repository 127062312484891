import sharedActions from 'redux/constants/SharedConstants';

const actionTypes = {
  ...sharedActions,
  SET_TRANSMITTAL_DATA: 'SET_TRANSMITTAL_DATA',
  UPDATING_CONFIRMATION_CODE: 'UPDATING_CONFIRMATION_CODE',
  CONFIRMATION_CODE_UPDATED: 'CONFIRMATION_CODE_UPDATED',
  REFRESHING_TRANSMITTAL_DATA: 'REFRESHING_TRANSMITTAL_DATA',
  REFRESH_TRANSMITTAL_DATA_SUCCESS: 'REFRESH_TRANSMITTAL_DATA_SUCCESS',
  REFRESH_TRANSMITTAL_DATA_FAIL: 'REFRESH_TRANSMITTAL_DATA_FAIL',
  FETCHING_CONTROL_TOTALS_SUMMARY: 'FETCHING_CONTROL_TOTALS_SUMMARY',
  FETCHING_CONTROL_TOTALS_SUMMARY_FAIL: 'FETCHING_CONTROL_TOTALS_SUMMARY_FAIL',
  FETCHING_CONTROL_TOTALS_SUMMARY_SUCCESS: 'FETCHING_CONTROL_TOTALS_SUMMARY_SUCCESS',
  CLEAR_TRANSMITTAL_DATA: 'CLEAR_TRANSMITTAL_DATA'
};

export default actionTypes;
