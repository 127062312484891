const calculateValue = (name, form) => {
  switch (name) {
    case 'C02' :
      if (parseFloat(form.A09) === parseFloat(form.A06)) {
        return 0;
      }
      if (parseFloat(form.A09) > parseFloat(form.A06)) {
        return 1;
      }
      if (parseFloat(form.A09) < parseFloat(form.A06)) {
        return 2;
      } else {
        return '';
      }

    default:
      return '';
  }
};

export {
  calculateValue
};
