import { post } from 'helpers/axios/axiosInterceptorsV1';
import actionsTypes from './authConstants';
const genericErrorMessage ='Some error occurred...';

const logingIn = () => ({ type: actionsTypes.PERFORMING_LOGIN });

const loginFailure = errorMessage => ({ type: actionsTypes.LOGIN_FAILURE, errorMessage });

const logingOut = () => ({ type: actionsTypes.PERFORMING_LOGOUT });
const logoutSuccess = () => ({ type: actionsTypes.LOGOUT_SUCCESS });

const logoutFailure = message => ({ type: actionsTypes.LOGOUT_FAILURE, message });

const reportLogoutSuccess = () => dispatch => dispatch(logoutSuccess());

const login = (user, url) => dispatch => {
  dispatch(logingIn());
  return post(url, user).then(() => {
    dispatch({ type: actionsTypes.LOGIN_SUCCESS });
  }, error => {
    let message;
    try {
      message = error.data.errMsg || genericErrorMessage;
    } catch (e) {
      message = genericErrorMessage;
    }
    dispatch(loginFailure(message));
    throw (error);
  }).catch(() => {});
};

const logout = () => async dispatch => {
  dispatch(logingOut());

  try {
    await post('/iam/logout', {});
  } catch (error) {
    dispatch(logoutFailure(error.data.error || genericErrorMessage));
    throw (error);
  }
};

const otpLogin = () => async dispatch => {
  const path = '/iam/otp-login';

  dispatch({ type: actionsTypes.PERFORMING_LOGIN });

  try {
    const response = await post(path, {});
    dispatch(otpLoginSuccess(response.data.data.userProfile.userId));
  } catch (e) {
    dispatch({ type: actionsTypes.LOGIN_FAILURE });
  }
};

const otpLoginSuccess = s1User => async dispatch=> {
  dispatch({ type: actionsTypes.LOGIN_SUCCESS, s1User });
};

export {
  login,
  reportLogoutSuccess,
  logout,
  otpLogin,
  otpLoginSuccess
};
