import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withSnackbar } from 'enhancers';
import OrderStatusTable from 'shared/orderStatusTable/OrderStatusTable';
import { columns } from '../../pages/printCorp/helpers/configOrderStatus';
import { getNoticeName, isNoticeType } from 'pages/printCorp/helpers/notice/NoticeConstants';
import usePrintHistory from 'shared/print/usePrintHistory';
import { getErrorMessage } from 'helpers/errorMessageHelper';
import { FE_PRINT_001 } from 'helpers/errorMessageConstants';
import useColumnSort from 'helpers/useColumnSort';
import usePaginationForFetch from 'helpers/usePaginationForFetch';

const PrintHistoryTable = ({
  configViewAllButton,
  initialItemsPerPage,
  showPaginationFooter,
  showMoreButton,
  showMoreUrl,
  noResultsMessage,
  className,
  enableSorting,
  title,
  breadcrumbs,
  showErrorSnackbar
}) => {
  const [total, setTotal] = useState(0);
  const [orderStatus, setOrderStatus] = useState([]);
  const [fetchingOrderStatus, setFetchingOrderStatus] = useState(false);

  const { fetchPrintHistory } = usePrintHistory();

  const {
    currentPage,
    itemsPerPage,
    handlePageChanged
  } = usePaginationForFetch(total, initialItemsPerPage);

  const {
    sortOrderIndicator,
    sortColumnIndicator,
    sortColumnId,
    onColumnSortChanged
  } = useColumnSort();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setFetchingOrderStatus(true);
        const { Total, Data } = await fetchPrintHistory(itemsPerPage, currentPage, sortOrderIndicator, sortColumnIndicator);
        setTotal(Total);
        setOrderStatus(Data);
      }
      catch (error) {
        showErrorSnackbar({ message: getErrorMessage(error, FE_PRINT_001) });
      }
      finally {
        setFetchingOrderStatus(false);
      }
    };

    fetchData();
  }, [itemsPerPage, currentPage, sortOrderIndicator, sortColumnId]);

  const handleRowClick = row => {
    const isRowNotice = isNoticeType(row.FormType);

    if (row.FORMS !== 0) {
      if (isRowNotice && row.PrintStatus === 'Awaiting Approval') {
        showErrorSnackbar({ message: `The ${ getNoticeName(row.FormType) } print file is still in progress, please try again later.` });
      } else {
        navigate(`/print-history/detail/${row.FormType}/${row.PrintNo}`, { state: { breadcrumbs: breadcrumbs } });
      }
    }
  };

  const getSortingProps = () => enableSorting
    ? {
      onColumnSortChanged,
      sortColumnId
    }
    : null;

  const getPaginationProps = () => showPaginationFooter
    ? {
      onPageChange: handlePageChanged,
      itemsPerPage,
      currentPage,
      totalItems: total
    }
    : null;

  return (
    <OrderStatusTable
      title={ title }
      actionButtonTestID='historyPrint'
      columns={ columns }
      data={ orderStatus }
      { ...getSortingProps() }
      isUpdating={ fetchingOrderStatus }
      showMoreButton={ showMoreButton }
      configViewAllButton={ configViewAllButton }
      onRowClick={ handleRowClick }
      showMoreUrl={ showMoreUrl }
      noResultsMessage={ noResultsMessage || 'No print found' }
      className={ className }
      breadcrumbs={ breadcrumbs }
      paginationFooterProps={ getPaginationProps() }
    />
  );
};

PrintHistoryTable.propTypes = {
  configViewAllButton: PropTypes.object,
  initialItemsPerPage: PropTypes.number,
  showPaginationFooter: PropTypes.bool,
  showErrorSnackbar: PropTypes.func,
  showMoreButton: PropTypes.bool,
  showMoreUrl: PropTypes.string,
  noResultsMessage: PropTypes.string,
  className: PropTypes.string,
  enableSorting: PropTypes.bool,
  title: PropTypes.string,
  breadcrumbs: PropTypes.array
};

PrintHistoryTable.defaultProps = {
  initialItemsPerPage: 20,
  configViewAllButton: null,
  showPaginationFooter: true,
  showMoreButton: false,
  enableSorting: true,
  breadcrumbs: []
};

export default withSnackbar(PrintHistoryTable);
