import actionTypes from './EntityConstants';
import { post } from 'helpers/axios/axiosInterceptorsV1';
import { buildUrlsParams } from 'helpers/buildUrlParams';

const fetchEntitiesByPayeeSuccess = entities => ({ type: actionTypes.FETCH_ENTITIES_BY_PAYEE_SUCCESS, entities });
const fetchEntitiesByPayeeFailure = () => ({ type: actionTypes.FETCH_ENTITIES_BY_PAYEE_FAILURE });
const resetEntitiesByPayee = () => ({ type: actionTypes.RESET_ENTITIES_BY_PAYEE });

const fetchEntitiesByPayee = modRecNo => async dispatch => {
  if (modRecNo > 0 ) {
    const qsParsed = buildUrlsParams({ id: modRecNo });
    const path = `/tax-entities/search?${qsParsed}`;
    dispatch({ type: actionTypes.FETCHING_ENTITIES_BY_PAYEE });

    try {
      const response = await post(path, {});
      dispatch(fetchEntitiesByPayeeSuccess(response.data));
    } catch (error) {
      dispatch(fetchEntitiesByPayeeFailure(error.data.errMsg || 'Some error occurred...'));
      throw (error);
    }
  }
  else {
    dispatch(resetEntitiesByPayee());
  }
};

export {
  fetchEntitiesByPayee,
  resetEntitiesByPayee
};
