import ValidationGridTextField from './ValidationGridTextField';

// This file exists only for the special cases like the FormField that sends dynamic props, and we want to
// pass down the properties to textfield, so later if the implmentator wants to add a textfield prop, can add it
// whitout having to mantain a list.
const ValidationTextFieldInterface = ({
  boxName,
  dbColumn,
  fchar,
  isDynamic,
  isInUse,
  floatingLabelText,
  headerModifier,
  fullMatching,
  dataModifier,
  dispatch,
  map,
  previousValue,
  showPreviousDifferentFields,
  isCustomCorrection,
  ...restProps
}) => (
  <>
    <ValidationGridTextField { ...restProps } />
  </>
);

export default ValidationTextFieldInterface;