import types from './PayerConstants';
import { get, post } from 'helpers/axios/axiosInterceptorsV1';
import { buildUrlsParams } from 'helpers/buildUrlParams';

const fetchPayerSuccess = payer => ({ type: types.FETCH_PAYER_SUCCESS, payer });
const fetchByRegexSuccess = payers => ({ type: types.FETCH_PAYER_BY_REGEX_SUCCESS, payers });

const fetchPayer = id => async dispatch => {
  const path = `/payers/${id}`;
  dispatch({ type: types.FETCHING_PAYER });

  try {
    const response = await get(path);
    dispatch(fetchPayerSuccess(response.data.Entity));
  } catch (error) {
    dispatch({ type: types.FETCH_PAYER_FAILURE });
    throw (error);
  }
};

const payerExists = payerId => async dispatch => {
  if (payerId) {
    const path = `/payers/search?${buildUrlsParams({ pageOffset: 1, pageSize: 10000, auto: true })}`;

    dispatch({ type: types.CHECKING_FOR_DUPLICATE_FIELD });

    try
    {
      const response = await post(path, { SearchText: payerId });

      dispatch({
        type: types.CHECK_FOR_DUPLICATE_FIELD_SUCCESS,
        field: 'PTIN',
        isDuplicate: Boolean(response.data.Data.find(payer => payer.Ptin === payerId.toUpperCase()))
      });
    }

    catch (err) {
      dispatch({ type: types.CHECK_FOR_DUPLICATE_FIELD_FAILURE });
    }
  } else {
    dispatch({
      type: types.CHECK_FOR_DUPLICATE_FIELD_SUCCESS,
      field: 'PTIN',
      isDuplicate: false
    });
  }
};

const fetchPayerByRegex = searchText => async dispatch => {
  const query = { pageOffset: 1, pageSize: 15 };
  dispatch({ type: types.FETCHING_PAYER });

  try {
    const response = searchText === undefined
      ? await post(`/payers/search?auto=true`, {})
      : await post(`/payers/search?${buildUrlsParams(query)}`, { SearchText: searchText });
    dispatch(fetchByRegexSuccess(response.data.PageSize ? response.data.Data : [{ Name: 'notFound' }]));
  } catch (error) {
    dispatch({ type: types.FETCH_PAYER_BY_REGEX_FAILURE });
    throw (error);
  }
};

const setPayer = payer => dispatch => dispatch({ type: types.SET_PAYER, payer });

const clearPayer = () => dispatch => dispatch({ type: types.RESET_PAYER });

const setPayerSet = value => dispatch => dispatch({ type: types.SET_PAYER_SET, value });

export {
  setPayer,
  fetchPayerByRegex,
  fetchPayer,
  clearPayer,
  payerExists,
  setPayerSet
};
