import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import W2MultipleLocalWage from './W2MultipleLocalWage';
import { setFieldValue, setSaveDisableByValidation } from '../../../sovos-dynamic-form/redux/DynamicFormActions';

const mapStateToProps = (state, ownProps) => ({
  localities: (state.dynamicForm.isClone ? state.dynamicForm.cloneForm : state.dynamicForm.form[ownProps.taxEntityCode])?.LOCALITIES || [],
  previousValue: state.dynamicForm.previousValueForm['LOCALITIES'] || [],
  isClone: state.dynamicForm.isClone,
  isFormTransmitted: state.dynamicForm.formTransmittedToLocality
});

const mapDispatchToProps = dispatch => ({
  formActions: bindActionCreators({ setFieldValue, setSaveDisableByValidation }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(W2MultipleLocalWage);
