import actionTypes from './UserAdministrationConstants';
import { get, post } from 'helpers/axios/axiosInterceptorsV1';
import authenticationHelper from 'helpers/authenticationHelper';
import fakeUsersBackend from './fakeUsersBackend';

const EULA_ACCEPTED = 'eulaAccepted';
const pendoWelcomeGuideId = 'NY64NxipVi0NawSydR6eEs1MxkM';

const fetchUsersToShareSuccess = usersToShare => ({ type: actionTypes.FETCH_USERS_TO_SHARE_SUCCESS, usersToShare });

const cleanUsersToShareSuccess = () => ({ type: actionTypes.CLEAN_USERS_TO_SHARE_SUCCESS });

const shareInformationSuccess = () => ({ type: actionTypes.SHARE_INFORMATION_SUCCESS });

const fetchUsersToShare = () => dispatch => {
  setTimeout(() => {
    const users = fakeUsersBackend;
    dispatch(fetchUsersToShareSuccess(users));
  }, 1000);
};

const cleanUsers = () => dispatch => {
  dispatch(cleanUsersToShareSuccess());
};

const shareInformation = (options, users) => dispatch => { // eslint-disable-line no-unused-vars
  dispatch(shareInformationSuccess());
};

const fetchEulaSetting = ({ value }) => async dispatch => {
  const path = `/users/EULA`;

  try {
    const response = await get(path);
    let openEula = false;
    let eulaSettingVersion = null;
    const eulaSetting = response.data.data;
    const isTIRCorporate = authenticationHelper.isTIRCorporate();

    if (eulaSetting) {
      eulaSettingVersion = eulaSetting.Version;
    }

    if (isTIRCorporate && (!eulaSetting || eulaSettingVersion !== value.Version)) {
      openEula = true;
    }

    updateEulaSetting(eulaSettingVersion, openEula, dispatch);
  } catch (err) {
    throw (err);
  }
};

const acceptEula = ({ value }) => async dispatch => {
  const path = `/users/EULA`;
  try {
    await post(path, { Version: value.Version });
    updateEulaSetting(value.Version, false, dispatch);
    //eslint-disable-next-line no-undef
    pendo.showGuideById(pendoWelcomeGuideId);
  } catch (err) {
    throw (err);
  }
};

const updateEulaSetting = (version, openEula, dispatch) => {
  sessionStorage.setItem(EULA_ACCEPTED, (version) ? version : '');
  dispatch({ type: actionTypes.FETCH_SETTINGEULA_SUCCESS, openEula: openEula });
};

export {
  fetchUsersToShare,
  cleanUsers,
  shareInformation,
  fetchEulaSetting,
  acceptEula
};
