import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TotalForms from './TotalForms';
import { fetchRecordsByFormSummary } from 'redux/recordsSummary/RecordsSummaryActions';

const mapStateToProps = state => ({
  fetchingRecordsByFormSummary: state.recordsSummary.fetchingRecordsByFormSummary,
  recordsByFormSummary: state.recordsSummary.recordsByFormSummary
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchRecordsByFormSummary }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TotalForms);
