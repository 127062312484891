import PropTypes from 'prop-types';
import { recipientFchar } from 'helpers/formConstants';
import './tinSuggestions.scss';

const TinSuggestions = ({ recipientList, setFormId, setTaxEntityCode, setTaxEntityCodeBase }) => {
  const handleSelection = modRecno => () => {
    setFormId(modRecno);
    setTaxEntityCode(recipientFchar);
    setTaxEntityCodeBase(recipientFchar);
  };

  return (
    <div className="tin-suggestions-box">
      <p className="tin-suggestion-box__message">This TIN already exists. If you want to modify an existing record please select from below.</p>
      <hr/>
      {
        recipientList.map(({ ModRecno, Name, Tin, Gml, AccountNo }) => (
          <div
            key={ ModRecno }
            id={ ModRecno }
            onMouseDown={ handleSelection(ModRecno) }
          >
            <p>{ Name }</p>
            <p><span>TIN:</span> { Tin }</p>
            <p><span>Account:</span> { AccountNo }</p>
            <p><span>GML:</span> { Gml }</p>
          </div>
        ))
      }
    </div>
  );
};

TinSuggestions.propTypes = {
  recipientList: PropTypes.array.isRequired,
  setFormId: PropTypes.func.isRequired,
  setTaxEntityCode: PropTypes.func.isRequired,
  setTaxEntityCodeBase: PropTypes.func.isRequired
};

export default TinSuggestions;