import { bool, oneOf, oneOfType, string, number } from 'prop-types';
import Pending from '@mui/icons-material/Timelapse';
import Failed from '@mui/icons-material/HighlightOff';
import AwaitingIdentity from '@mui/icons-material/NotInterested';
import CheckCircle from '@mui/icons-material/CheckCircle';
import SovosTypography from 'mosaic-react/sovos-typography';
import theme from 'mosaic-react/themes/sovos-theme';
import './verificationStatusIcon.scss';

const { palette } = theme;

const VerificationStatusIcon = ({ type, fetching, acceptedValue, value, irsStatus, isTiaTheTinStatusSource }) => {
  let statusSection;

  const getMessageResult = () => {
    const responsePrefixTiaStore = 'Stored result:';
    const responsePrefixIRS = 'IRS response:';

    if (isTiaTheTinStatusSource){
      switch (value) {
        case 1:
          return `${responsePrefixTiaStore} Awaiting identity`;
        case 2:
          return `${responsePrefixTiaStore} Invalid TIN Matching request`;
        case 3:
          return `${responsePrefixTiaStore} TIN and Name combination matches IRS records`;
        case 4:
          return `${responsePrefixTiaStore} TIN and Name combination does not match IRS records`;
        case 5:
          return `${responsePrefixTiaStore} TIN entered is not currently issued`;
        default:
          return "Awaiting identity";
      }
    } else if (value === 'U'){
      return `${responsePrefixIRS} IRS TIN matching service is currently down or unavailable`;
    } else if (irsStatus) {
      return `${responsePrefixIRS} ${irsStatus?.replace(/^\d+-/, '')}`;
    } else {
      return "Awaiting identity";
    }
  };

  const isAwaitingIdentity = () => (
    value === undefined ||
    value === '' ||
    value === '_' ||
    value === 'U' ||
    value === 'S' ||
    value === 1 ||
    (typeof value === 'object' && value.Status === '')
  );

  if (fetching) {
    statusSection = (
      <>
        <Pending id='PendingIcon' style={ { fill: palette.text.disabled } } />
        <SovosTypography variant='h6'>
          <span>{ type }:</span> Pending
        </SovosTypography>
      </>
    );
  } else if (isAwaitingIdentity()) {
    statusSection = (
      <>
        <AwaitingIdentity id='AwaitingIdentityIcon' style={ { fill: palette.text.disabled } } />
        <SovosTypography variant='h6'>
          <span>{ type }:</span>  { type === 'TIN' ? getMessageResult() : 'Awaiting identity' }
        </SovosTypography>
      </>
    );
  } else if (acceptedValue === value) {
    statusSection = (
      <>
        <CheckCircle htmlColor={ palette.success.main } />
        <SovosTypography variant='h6'>
          <span>{ type }:</span> { type === 'TIN' ? getMessageResult() : 'Passed' }
        </SovosTypography>
      </>
    );
  } else {
    statusSection = (
      <>
        <Failed id='FailedIcon' style={ { fill: palette.text.disabled } } />
        <SovosTypography variant='h6'>
          <span>{ type }:</span> { type === 'TIN' ? getMessageResult() : 'Failed' }
        </SovosTypography>
      </>
    );
  }

  return (
    <div className='verification-status-icon_container'>
      { statusSection }
    </div>
  );
};

VerificationStatusIcon.propTypes = {
  type: oneOf(['TIN', 'OFAC', 'DMF']).isRequired,
  fetching: bool.isRequired,
  acceptedValue: oneOfType([string, number]).isRequired,
  value: oneOfType([string, number]).isRequired
};

export default VerificationStatusIcon;
