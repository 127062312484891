import AuthenticationHelper from './authenticationHelper';
import { reportLogoutSuccess } from 'redux/auth/authActions';
import { buildUrlsParams } from 'helpers/buildUrlParams';

export const saveBlob = (() => {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";

  return (data, fileName) => {
    const blob = new Blob([data], { type: 'octet/stream' });
    const url = window.URL.createObjectURL(blob);

    a.setAttribute('href', url);
    a.setAttribute('download', fileName);
    a.click();
    window.URL.revokeObjectURL(url);
  };
})();

const loadHeaders = xhr => {
  const headers = AuthenticationHelper.getHeaders();
  for (let key in headers) {
    if (headers.hasOwnProperty(key)) {
      xhr.setRequestHeader(key, headers[key]);
    }
  }
};

export const downloadFileByKey = (key, name, onComplete, onFail, fchar, dispatch) => {
  console.warn('downloadFileByKey function is deprecated, please use downloadFileByKeyNext.');
  downloadFile(`/api/v1/file-manager/files/${key}/download`, name, onComplete, onFail, fchar, dispatch);
};

export const downloadFileByKeyNext = ({ key, fileName, onComplete, onFail, fChar }) => {
  if (!key) {
    return console.error('key parameter is required');
  }

  if (!fileName) {
    return console.error('fileName parameter is required');
  }

  downloadFileNext({
    url: `/api/v1/file-manager/files/${key}/download`,
    fileName,
    onComplete,
    onFail,
    fChar
  });
};

export const downloadFileByPathNext = ({ file, onComplete, onFail, fChar }) => {
  if (!file.path) {
    return console.error('path parameter is required');
  }

  if (!file.name) {
    return console.error('file name parameter is required');
  }

  downloadFileNext({
    url: `/api/v1/file-manager/files/download?${buildUrlsParams({ path: file.path })}`,
    fileName: file.name,
    onComplete,
    onFail,
    fChar
  });
};

export const bulkDownloadByKey = (fileIds, fileName, onComplete, onFail, fchar, dispatch) => {
  console.warn('bulkDownloadByKey function is deprecated, please use bulkDownloadByKeyNext.');
  const queryString = fileIds.reduce((acc, cur) => `${acc}&ids=${cur}`, '?');
  downloadFile(`/api/v1/file-manager/files/download${queryString}`, fileName, onComplete, onFail, fchar, dispatch);
};

export const bulkDownloadByKeyNext = ({ fileIds, fileName, onComplete, onFail, fchar }) => {
  if (!fileIds || fileIds.length === 0) {
    return console.error('fileIds parameter is required and must contain at least one element');
  }

  if (!fileName) {
    return console.error('fileName parameter is required');
  }

  const queryString = fileIds.reduce((acc, cur) => `${acc}&ids=${cur}`, '?');
  downloadFileNext({
    url: `/api/v1/file-manager/files/download${queryString}`,
    fileName,
    onComplete,
    onFail,
    fchar
  });
};

export const downloadFilePreviewByBatchId = (batchId, onComplete, onFail) => {
  downloadFile(`/api/v1/prints/advanced/batches/${batchId}/preview`, `preview${batchId}.pdf`, onComplete, onFail);
};

export const downloadFileByPath = (path, name, onComplete, onFail, fChar, dispatch, resolve, reject) => {
  downloadFile(`/api/v1/file-manager/files/download?${buildUrlsParams({ path })}`, name, onComplete, onFail, fChar, dispatch, resolve, reject);
};

function downloadFile(
  url,
  fileName,
  onComplete,
  onFail,
  fChar,
  dispatch = () => null,
  resolve,
  reject
) {
  const Xhr = new XMLHttpRequest();
  Xhr.open('GET', url, true);
  Xhr.responseType = 'arraybuffer';
  Xhr.onload = () => {
    if (Xhr.status === 200) {
      saveBlob(Xhr.response, fileName);
      if (onComplete) {
        dispatch(onComplete(fChar));
      }
      if (resolve) {
        resolve();
      }
    } else {
      if (onFail) {
        dispatch(onFail(fChar));
      }
      if (reject) {
        reject(Xhr.statusText);
      }
    }
  };
  loadHeaders(Xhr);

  Xhr.send();
}

const downloadFileNext = ({
  url,
  fileName,
  onComplete,
  onFail,
  fChar
}) => {
  const Xhr = new XMLHttpRequest();
  Xhr.open('GET', url, true);
  Xhr.responseType = 'arraybuffer';
  Xhr.onload = () => {
    if (Xhr.status === 200) {
      saveBlob(Xhr.response, fileName);
      if (onComplete) {
        onComplete(fChar);
      }

    } else if (onFail) {
      onFail(fChar);
    }
  };
  loadHeaders(Xhr);

  Xhr.send();
};

export function uploadFile({ formData, queryString, folderId, onProgressCallback, onCompleteCallback, onErrorCallback = () => null }, dispatch) {
  const Xhr = new XMLHttpRequest();
  let url = '/api/v1/file-manager/';
  url += folderId > 0 ? `folders/${folderId}/` : '';
  url += `files${queryString}`;

  Xhr.onreadystatechange = e => {
    try {
      if (Xhr.readyState === XMLHttpRequest.DONE && Xhr.status === 200) {
        onCompleteCallback(e);
        return;
      }

      //This logic is because backend response is a status code 200 and then internally handles the response as blob.
      const error = JSON.parse(Xhr.response);
      //So if the response is not able to be parsed as a JSON then we can continue checking the internal attr to check if the file is ready.
      if (error.status === 401) {
        dispatch(reportLogoutSuccess());
      }

      if (Xhr.readyState === XMLHttpRequest.DONE && Xhr.status === 400) {
        onErrorCallback({ data: error });
      }
    } catch (error) {
      if (Xhr.status === 500){
        console.error(Xhr.statusText);
      }
    }
  };
  Xhr.upload.onprogress = onProgressCallback;
  Xhr.open('POST', url);
  loadHeaders(Xhr);
  Xhr.send(formData);
};
