import axios from 'axios';
import { setAxiosInterceptors, apiVersions } from './axiosInterceptors';

let tirAxiosInstance = null;

const getAxiosInstance = () => {
  if (!tirAxiosInstance) {
    tirAxiosInstance = axios.create();
  }
  return tirAxiosInstance;
};

const setAxiosInterceptorsNoVersion = store => setAxiosInterceptors(store, apiVersions.NoVersion, getAxiosInstance);

const get = getAxiosInstance().get;
const post = getAxiosInstance().post;
const patch = getAxiosInstance().patch;
const put = getAxiosInstance().put;
const remove = getAxiosInstance().delete;

export {
  setAxiosInterceptorsNoVersion,
  get,
  post,
  patch,
  put,
  remove
};
