import { string } from 'prop-types';
import classnames from 'classnames';
import './requiredMessage.scss';

const RequiredMessage = ({ className }) => (
  <div className={ classnames('required-message', className) }>
    <p><span className="required-message__asterisk">*</span> <span className="required-message__text">indicates required field</span></p>
  </div>
);

RequiredMessage.propTypes = {
  className: string
};

export default RequiredMessage;