import sharedActions from 'redux/constants/SharedConstants';

const actionTypes = {
  ...sharedActions,
  FETCH_CARTDATA_SUCCESS_PRINT: 'FETCH_CARTDATA_SUCCESS_PRINT',
  FETCH_CARTDATA_FAILURE_PRINT: 'FETCH_CARTDATA_FAILURE_PRINT',
  SET_FETCHING_CARTDATA_PRINT: 'SET_FETCHING_CARTDATA_PRINT'
};

export default actionTypes;
