import PropTypes from 'prop-types';
import Droppable from '../../dragAndDropBeautiful/droppable/Droppable';
import Draggable from '../../dragAndDropBeautiful/draggable/Draggable';
import ColumnListRow from './ColumnListRow';
import theme from 'mosaic-react/themes/sovos-theme';

const { palette } = theme;

const droppableStyle = {
  width: 320
};

const ColumnList = props => {
  const getColumnItem = (column, index, isEnabled, elementProps) => ( // eslint-disable-line react/no-multi-comp
    <ColumnListRow
      key={ index }
      onHideColumn={ elementProps.onHideColumn }
      onShowColumn={ elementProps.onShowColumn }
      isEnabled={ isEnabled }
      column={ column }
    />
  );

  return (
    <div className="column-selector__columns-container">
      <Droppable
        droppableId="VISIBLECOLUMNS"
        draggingColor={ {
          withDragging: palette.background.default
        } }
        style={ droppableStyle }
      >
        { props.visibleColumns.map(
          (column, index) => (
            <Draggable
              key={ column.key }
              dragKey={ column.key }
              index={ index }
              isDragDisabled={ props.visibleColumns.length <= 1 }
              style={ {
                userSelect: 'none'
              } }
            >
              { getColumnItem(column, index, true, props) }
            </Draggable>
          ))
        }
      </Droppable>
      <hr />
      <Droppable
        droppableId="HIDDENCOLUMNS"
        draggingColor={ {
          withDragging: palette.background.default
        } }
        style={ droppableStyle }
      >
        {
          props.hiddenColumns.map(
            (column, index) => (
              <Draggable
                key={ column.key }
                dragKey={ column.key }
                index={ index }
                style={ {
                  userSelect: 'none'
                } }
              >
                { getColumnItem(column, index, false, props) }
              </Draggable>
            )
          )
        }
      </Droppable>
    </div>
  );
};

ColumnList.propTypes = {
  visibleColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
  hiddenColumns: PropTypes.arrayOf(PropTypes.object),
  onHideColumn: PropTypes.func.isRequired,
  onShowColumn: PropTypes.func.isRequired
};

ColumnList.defaultProps = {
  hiddenColumns: []
};

export default ColumnList;
