import { createElement } from 'react';
import { number, shape, string, func } from 'prop-types';
import { isLeapYear } from 'tir-frontend-common/helpers/dateHelper';
import AmountDifferenceFieldForm from 'shared/standardInput/textField/AmountDifferenceFieldForm';
import authenticationHelper from 'helpers/authenticationHelper';
import './semiWeeklyAmount.scss';

const SemiWeeklyAmount = props => {
  const handleOnChange = (field, value) => {
    props.formActions.setFieldValue(field, value, false, props.taxEntityCode);
  };

  return createElement(AmountDifferenceFieldForm,
    {
      ...props,
      mask: 'CURRENCY',
      hideLeftLabel: true,
      key: props.name,
      onChange: (_, value) => handleOnChange(props.name, value),
      readOnly: (props.boxName === 'February 29' && !isLeapYear(authenticationHelper.getTaxYear())) || props.readOnly,
      value: props.fieldValue || null,
      customClasses: {
        tirTextfieldFormWrapper: 'semi-weekly-amount__row',
        amountDifferentFields: 'semi-weekly-amount__input'
      }
    });
};

SemiWeeklyAmount.propTypes = {
  name: string.isRequired,
  fieldValue: number.isRequired,
  formActions: shape({
    setFieldValue: func.isRequired
  }).isRequired,
  taxEntityCode: string.isRequired
};

SemiWeeklyAmount.defaultProps = {
  name: '',
  fieldValue: 0,
  formActions: {
    setFieldValue: () => {}
  }
};

export default SemiWeeklyAmount;