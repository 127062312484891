export const areEquals = (original, arrayToCompare) => {
  if (!arrayToCompare) {
    return false;
  }

  if (original.length !== arrayToCompare.length) {
    return false;
  }

  for (let i = 0, l = original.length; i < l; i++) {
    if (original[i] instanceof Array && arrayToCompare[i] instanceof Array) {
      if (!areEquals(original[i], arrayToCompare[i])) {
        return false;
      }
    } else if (original[i] !== arrayToCompare[i]) {
      return false;
    }
  }
  return true;
};