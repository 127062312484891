import PropTypes from 'prop-types';
import SovosSelect from 'mosaic-react/sovos-select/SovosSelect';
import MenuItem from '@mui/material/MenuItem';
import { getRecordName, getAllFCharsSorted } from 'helpers/formConstants';

const FormsSelect = ({ onFormChange }) => (
  <div className='form-editor__container'>
    <SovosSelect
      className='form-editor__select-field'
      id='selectFormType'
      onChange={ event => onFormChange(event.target.value) }
      value=''
    >
      <MenuItem value='' disabled>Select a record type</MenuItem>
      { getAllFCharsSorted().map(fChar => (
        <MenuItem value={ fChar } key={ fChar }>
          { getRecordName(fChar) }
        </MenuItem>
      )) }
    </SovosSelect>
  </div>
);

FormsSelect.propTypes = {
  onFormChange: PropTypes.func.isRequired
};

export default FormsSelect;
