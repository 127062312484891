import sharedConstants from 'redux/constants/SharedConstants';

const actionTypes = {
  ...sharedConstants,
  FETCHING_PRINT_PREVIEW: 'FETCHING_PRINT_PREVIEW',
  FETCH_PRINT_PREVIEW_SUCCESS: 'FETCH_PRINT_PREVIEW_SUCCESS',
  FETCH_PRINT_PREVIEW_FAILURE: 'FETCH_PRINT_PREVIEW_FAILURE'
};

export default actionTypes;
