import actionTypes from './RecipientConstants';
import formActionTypes from 'shared/dataEntryForms/sovos-dynamic-form/redux/DynamicFormConstants';

const initialStore = {
  data: {},
  recipients: [],
  fetching: false,
  recipientSet: false
};

export default function RecipientReducer(state = initialStore, action) {
  switch (action.type) {
    case actionTypes.FETCHING_RECIPIENT:
      return { ...state, fetching: true };

    case actionTypes.FETCH_RECIPIENT_SUCCESS:
      return { ...state, data: action.recipient, fetching: false };

    case actionTypes.FETCH_RECIPIENT_FAILURE:
      return { ...state, fetching: false, data: {} };

    case actionTypes.RESET_RECIPIENT:
      return { ...state, data: {} };

    case actionTypes.FETCH_RECIPIENT_BY_REGEX_SUCCESS:
      return { ...state, recipients: action.recipients, fetching: false };

    case actionTypes.FETCH_RECIPIENT_BY_REGEX_FAILURE:
      return { ...state, fetching: false, recipients: [] };

    case actionTypes.SET_RECIPIENT:
      return { ...state, data: action.recipient, recipients: [], recipientSet: true };

    case actionTypes.SET_RECIPIENT_BOOLEAN:
      return { ...state, recipientSet: true };

    case actionTypes.CHECK_FOR_DUPLICATE_FIELD_SUCCESS:
      return action.recipients
        ? { ...state, recipients: action.recipients }
        : state;

    case formActionTypes.RESET_FORM:
    case actionTypes.LOGOUT_SUCCESS:
      return { ...initialStore };

    default:
      return state;
  }
}
