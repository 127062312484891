import PropTypes from 'prop-types';
import SovosAvatar from 'mosaic-react/sovos-avatar/SovosAvatar';
import { splitFullName } from 'helpers/textFormatHelper';
import theme from 'mosaic-react/themes/sovos-theme';

const { palette } = theme;

const avatarStyle = { margin: '1em' };

const AuthorAvatar = ({
  userName,
  color,
  backgroundColor,
  size
}) => {

  const [firstAndMiddle, lastName] = splitFullName(userName);

  return (
    <SovosAvatar
      color={ color }
      backgroundColor={ backgroundColor }
      size={ size }
      name={ firstAndMiddle }
      surname={ lastName }
      noNameMessage='?'
      style={ avatarStyle }
    />
  );
};

AuthorAvatar.propTypes = {
  userName: PropTypes.string.isRequired,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  size: PropTypes.string
};

AuthorAvatar.defaultProps = {
  color: palette.text.contrast,
  backgroundColor: palette.grey[600],
  size: 'medium'
};

export default AuthorAvatar;
