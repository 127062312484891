const NO_PRINT_SERVICES = 'noPrintServices';
const TEST_CLIENT = 'testClient';
const REAL_TIME_TIN = 'realTimeTin';
const BULK_TIN = 'bulkTin';
const E_STATEMENTS = 'eStatements';
const MANAGED_SERVICES = 'managedServices';
const TAX_IDENTITY_MANAGEMENT_SOLICITATION = 'taxIdentityManagementSolicitation';
const TAX_IDENTITY_MANAGEMENT_RESPONSE = 'taxIdentityManagementResponse';
const STATE_REPORTING = 'stateReporting';
const USE_SOVOS_TCC = 'useSovosTCC';
const USE_CLIENT_TCC = 'useClientTCC';
const W2 = 'w2';
const W2M = 'w2m';
const WITHHOLDING_FORMS = 'withholdingForms';
const COMBINED_STATEMENT = 'combinedStatement';
const SELECT_ALL = 'SELECT_ALL';
const SELECT_ALL_WITH_FILTERS = 'SELECT_ALL_WITH_FILTERS';
const DESELECT_ALL = 'DESELECT_ALL';
const DESELECT_ALL_ALL_WITH_FILTERS = 'DESELECT_ALL_ALL_WITH_FILTERS';
const ENT_PRINT_GENERAL = 'entPrintGeneral';
const ENT_PRINT_VISUAL = 'entPrintVisual';
const ENT_PRINT_FILTER_SETTINGS = 'entPrintFilterSettings';
const CHECKBOX_LIST_FILTER_MIN_LENGTH = 3;
const CHECKBOX_LIST_FILTER_MAX_LENGTH = 20;

export {
  NO_PRINT_SERVICES,
  TEST_CLIENT,
  REAL_TIME_TIN,
  BULK_TIN,
  E_STATEMENTS,
  MANAGED_SERVICES,
  TAX_IDENTITY_MANAGEMENT_SOLICITATION,
  TAX_IDENTITY_MANAGEMENT_RESPONSE,
  STATE_REPORTING,
  USE_SOVOS_TCC,
  USE_CLIENT_TCC,
  W2,
  W2M,
  WITHHOLDING_FORMS,
  COMBINED_STATEMENT,
  SELECT_ALL,
  DESELECT_ALL,
  ENT_PRINT_GENERAL,
  ENT_PRINT_VISUAL,
  ENT_PRINT_FILTER_SETTINGS,
  SELECT_ALL_WITH_FILTERS,
  DESELECT_ALL_ALL_WITH_FILTERS,
  CHECKBOX_LIST_FILTER_MIN_LENGTH,
  CHECKBOX_LIST_FILTER_MAX_LENGTH
};
