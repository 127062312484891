import sharedActions from 'redux/constants/SharedConstants';

const actionTypes = {
  ...sharedActions,
  FETCHING_RECIPIENT: 'FETCHING_RECIPIENT',
  FETCH_RECIPIENT_SUCCESS: 'FETCH_RECIPIENT_SUCCESS',
  FETCH_RECIPIENT_FAILURE: 'FETCH_RECIPIENT_FAILURE',
  RESET_RECIPIENT: 'RESET_RECIPIENT',
  FETCH_RECIPIENT_BY_REGEX_SUCCESS: 'FETCH_RECIPIENT_BY_REGEX_SUCCESS',
  FETCH_RECIPIENT_BY_REGEX_FAILURE: 'FETCH_RECIPIENT_BY_REGEX_FAILURE',
  SET_RECIPIENT: 'SET_RECIPIENT',
  SET_RECIPIENT_BOOLEAN: 'SET_RECIPIENT_BOOLEAN'
};

export default actionTypes;
