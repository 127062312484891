import actionTypes from './PrintStepsConstants';

const initialStore = {
  isPrintSlidingPanelOpen: false,
  isCancelPrintDialogOpen: false,
  isApprovePrintDialogOpen: false,
  isReviewButtonEnabled: false,
  cancellingPrintJob: false,
  approvingPrintJob: false,
  cancelPrintError: false,
  approvePrintError: false
};

export default function PrintCartReducer(state = initialStore, action) {
  switch (action.type) {

    case actionTypes.OPEN_CANCEL_PRINT_DIALOG:
      return { ...state, isCancelPrintDialogOpen: true };

    case actionTypes.CLOSE_CANCEL_PRINT_DIALOG:
      return { ...state, isCancelPrintDialogOpen: false };

    case actionTypes.OPEN_APPROVE_PRINT_DIALOG:
      return { ...state, isApprovePrintDialogOpen: true };

    case actionTypes.CLOSE_APPROVE_PRINT_DIALOG:
      return { ...state, isApprovePrintDialogOpen: false };

    case actionTypes.CANCELLING_PRINT_JOB:
      return { ...state, cancellingPrintJob: true, cancelPrintError: false };

    case actionTypes.CANCEL_PRINT_JOB_SUCCESS:
      return { ...initialStore };

    case actionTypes.CANCEL_PRINT_JOB_FAILURE:
      return { ...state, cancellingPrintJob: false, cancelPrintError: true };

    case actionTypes.APPROVING_PRINT_JOB:
      return { ...state, approvingPrintJob: true, approvePrintError: false };

    case actionTypes.APPROVE_PRINT_JOB_SUCCESS:
      return { ...initialStore };

    case actionTypes.APPROVE_PRINT_JOB_FAILURE:
      return { ...state, approvingPrintJob: false, approvePrintError: true };

    case actionTypes.LOGOUT_SUCCESS:
      return { ...initialStore };

    default:
      return { ...state };
  }
}
