import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchAllFailed, exportRecords } from '../redux/RecordActions';
import Failed from './Failed';

const mapStateToProps = state => ({
  records: state.recordReducer.failed,
  fetchingFailed: state.recordReducer.fetchingFailed
});

const mapDispatchToProps = dispatch => ({
  recordActions: bindActionCreators({ fetchAllFailed, exportRecords }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Failed);
