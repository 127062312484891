import { string, array } from 'prop-types';
import SovosTypography from 'mosaic-react/sovos-typography';
import { getReturnStatusType } from 'shared/dataEntryForms/sovos-dynamic-form/DynamicFormHelper';
import { theW2MFchar } from 'helpers/formConstants';
import './recordStatus.scss';

const RecordStatus = ({ trantype, taxEntityCode, fcharToPreview, states, localities, value }) => {

  const status = (name, returnStatus, index) => (
    <div className='record-status-information__text-container' key={ `${name}-${index}` }>
      <SovosTypography variant='body1' className='record-status-information__name'>{ name }&nbsp;|&nbsp;</SovosTypography>
      <SovosTypography variant='body1' noWrap>{ `Return status: ${getReturnStatusType(returnStatus)}` }</SovosTypography>
    </div>
  );

  return (
    <>
      { status('Federal', trantype || value) }
      { (taxEntityCode === theW2MFchar || fcharToPreview === theW2MFchar) &&
          states?.map((state, index) => status(state.StateAbbreviation, state.ReturnStatus, index))
      }
      { (taxEntityCode === theW2MFchar || fcharToPreview === theW2MFchar) &&
        localities?.map((locality, index) => status(locality.LocalityName, locality.ReturnStatus, index))
      }
    </>
  );
};

RecordStatus.propTypes = {
  trantype: string,
  taxEntityCode: string,
  fcharToPreview: string,
  states: array,
  localities: array,
  value: string
};

RecordStatus.defaultValues = {
  trantype: '_'
};

export default RecordStatus;