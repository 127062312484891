export const isChrome = () => /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

export const addFormHelper = (id, idSaveButton) => {
  const form = document.getElementById(id);
  const isIE11 = !(window.ActiveXObject) && "ActiveXObject" in window;
  if (form && window.HTMLFormElement && form instanceof window.HTMLFormElement && isIE11) {
    const inputSubmit = document.createElement('input');
    inputSubmit.setAttribute('id', 'internalSubmit');
    inputSubmit.setAttribute('type', 'submit');
    inputSubmit.setAttribute('form', id);
    inputSubmit.style.visibility= 'hidden';

    document.getElementById(idSaveButton).addEventListener('click', () => inputSubmit.click());

    form.appendChild(inputSubmit);
  }
};

export const hideAutocompleteForChrome = element => {
  if (isChrome()) {
    const input = document.getElementsByClassName(element)[0];

    input.setAttribute("autocomplete", "disabled");
  }
};