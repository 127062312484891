import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Loader, withSnackbar } from 'enhancers';
import IconButton from '@mui/material/IconButton';
import ConfirmationDialog from 'shared/confirmationDialog/ConfirmationDialog';
import { getRecords, getRecordName, recipientFchar, payerFchar } from 'helpers/formConstants';
import { TIR_C_REPRINT } from 'helpers/permissionConstants';
import SovosSlidingPanel from 'mosaic-react/sovos-sliding-panel';
import SovosSlidingPanelHeader from 'mosaic-react/sovos-sliding-panel-header';
import SovosSlidingPanelHeaderActionButtons from 'mosaic-react/sovos-sliding-panel-header-action-buttons';
import SovosButton from 'mosaic-react/sovos-button';
import SovosIconButton from 'mosaic-react/sovos-icon-button/SovosIconButton';
import CloseIcon from '@mui/icons-material/Close';
import FormInTabs from '../formInTabs/FormInTabsConnector';
import FormContainer from '../formContainer/FormContainerConnector';
import FormsSelect from './formsSelect/FormsSelect';
import ReprintOptionsDialog from './ReprintOptionsDialog';
import FormOptions from './FormOptions';
import FormTitle from './formTitle/FormTitleConnector';
import AuthenticationHelper from 'helpers/authenticationHelper';
import { get, post } from 'helpers/axios/axiosInterceptorsV1';
import { getErrorMessage } from 'helpers/errorMessageHelper';
import { getConditionToDisableSave } from '../helper/dataEntryHelper';
import { downloadFile } from 'redux/printPreview/PrintPreviewActions';
import { getLocalTimestampForFileName } from 'tir-frontend-common/helpers/dateHelper';
import './slidingFormEditor.scss';

// TODO: waiting for some fix from mosaic team, SovosSlidingPanel not propagate classes object
// const sliderStyle = { paper: 'form-editor__slider-paper', content: 'form-editor__slider-content' };
const sliderStyle = { root: 'form-editor__slider-root' };

const SlidingFormEditor = ({
  openFormSlidingPanel,
  taxEntityCode,
  formId,
  formModified,
  canBeDeleted,
  countFormLinked,
  formEditorTitle,
  saveDisableByValidation,
  savingForm,
  areUniqueFieldsDuplicated,
  fetchingForm,
  taxEntityCodeBase,
  newForm,
  enableReprint,
  mountDialog,
  checkingDuplicatedValuesInFields,
  fetchingIdentityManagement,
  formActions,
  onCloseSlidingPanel,
  entityActions,
  printActions,
  formHistoryActions,
  formIdToPreview,
  fcharToPreview,
  fcharToDelete,
  deleted,
  formPrinted,
  formTransmittedToIrs,
  payerSelected,
  recipientSelected,
  creatingClone,
  selectedContext,
  formMetadata,
  fetchFormSuccess,
  modRecNo,
  metadataActions,
  checkingDuplicatedFailed,
  showSnackbar,
  showErrorSnackbar
}) => {
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openFormSlidingPanelClone, setOpenFormSlidingPanelClone] = useState(false);
  const [openReprintDialog, setOpenReprintDialog] = useState(false);
  const [reprintStatus, setReprintStatus] = useState({});
  const [payerRecipientSelected, setPayerRecipientSelected] = useState(false);

  const formConstants = getRecords();

  useEffect(() => {
    if (taxEntityCode && !formMetadata.hasOwnProperty(taxEntityCode)) {
      metadataActions.fetchMetadata(taxEntityCode);
    }
  }, [taxEntityCode]);

  useEffect(() => {
    if (!newForm && formMetadata.hasOwnProperty(taxEntityCode) && !fetchingForm) {
      onFetchForm();
    }
  }, [formId, formMetadata]);

  useEffect(() => {
    if (deleted) {
      setOpenDeleteDialog(false);
      handleCloseSlidingPanel();
      showSnackbar({
        message: 'Record has been successfully deleted.'
      });
      formActions.setDeleted(false);
    }
  }, [deleted]);

  useEffect(() => {
    if (!payerRecipientSelected && (payerSelected && recipientSelected)) {
      setPayerRecipientSelected(true);
    }

    if (payerRecipientSelected && (!payerSelected || !recipientSelected)) {
      setPayerRecipientSelected(false);
    }
  }, [payerSelected, recipientSelected]);

  useEffect(() => {
    if (checkingDuplicatedFailed) {
      showErrorSnackbar({
        message: 'Could not check unique value. This record can not be saved.'
      });
    }
  }, [checkingDuplicatedFailed]);

  useEffect(() => {
    if (areUniqueFieldsDuplicated && taxEntityCode !== recipientFchar) {
      showErrorSnackbar({
        message: `${taxEntityCode === payerFchar ? 'Payer Id' : 'Account ID'} is already in use and it must be unique.`
      });
    }
  }, [areUniqueFieldsDuplicated]);

  useEffect(() => {
    if (fetchFormSuccess) {
      isFormQuededForReprint();
      formActions.setFetchFormSuccess(false);
    }
  }, [fetchFormSuccess]);

  const onFetchForm = () => {
    formActions.fetchForm(formId, taxEntityCode);
    formActions.checkConditionToDelete(taxEntityCode, formId);
    isFormQuededForReprint();
  };

  const handleFormChange = targetName => {
    formActions.setTaxEntityCode(targetName);
    formActions.setTaxEntityCodeBase(targetName);
    formActions.resetForm();
  };

  const handleOnSaveFormSuccess = () =>
    showSnackbar({
      message: 'Changes saved successfully.'
    });

  const handleOnOkConfirmation = () => {
    handleCloseSlidingPanel();
    setOpenConfirmationDialog(false);
  };

  const deleteForm = () => setOpenDeleteDialog(true);

  const previewForm = () =>
    printActions.fetchPreview(formIdToPreview, `${fcharToPreview}_${selectedContext.name}_${getLocalTimestampForFileName()}.pdf`);

  const handleOnDelete = () =>
    formActions.deleteForm(formId, showErrorSnackbar);

  const cloneForm = () => {
    formActions.createClone();
    setOpenFormSlidingPanelClone(true);
  };

  const handleAddNewForm = newTaxEntityCode => {
    formActions.setTaxEntityCode(newTaxEntityCode);
    if (!formMetadata.hasOwnProperty(newTaxEntityCode)) {
      metadataActions.fetchMetadata(newTaxEntityCode);
    }
    formActions.resetExtraForm(newTaxEntityCode);
    formActions.setFormEditorTitle(`Add ${getRecordName(newTaxEntityCode)}`);
    setOpenFormSlidingPanelClone(true);
  };

  const handleCloseFormSlidingPanelClone = () => {
    entityActions.fetchEntitiesByPayee(modRecNo);
    formActions.resetCloneForm();
    setOpenFormSlidingPanelClone(false);
  };

  const getMessage = (fchar, canBeDeleted, countFormLinked) => {
    let title= '';
    let message= '';
    let actionLabel= '';

    if (fchar && canBeDeleted) {

      title = formConstants[fchar].delete.can.title;

      if (countFormLinked) {
        message = formConstants[fchar].delete.can.hasForm.message.replace('countForm', countFormLinked);
      } else {
        message = formConstants[fchar].delete.can.hasNoForm
          ? formConstants[fchar].delete.can.hasNoForm.message
          : formConstants[fchar].delete.can.message;
      }

      actionLabel = formConstants[fchar].delete.can.actionLabel;
    }

    return { title, message, actionLabel };
  };

  const handleSubmit = () => formActions.saveForm(showErrorSnackbar);

  const isFormQuededForReprint = async () => {
    try {
      if (AuthenticationHelper.checkSomeUserPermissions(TIR_C_REPRINT) && formPrinted) {
        const response = await get(`prints/forms/${formId}/reprint`);
        setReprintStatus(response.data);
      }
    } catch (error) {
      showErrorSnackbar({
        message: getErrorMessage(error)
      });
    }
  };

  const handleReprintDialog = () => setOpenReprintDialog(!openReprintDialog);

  const setSelectedOption = selectedReprintOption => setReprintStatus({ ...reprintStatus, selectedReprintOption });

  const handleFormSave = () => {
    formPrinted && AuthenticationHelper.checkSomeUserPermissions(TIR_C_REPRINT) && !formTransmittedToIrs
      ? handleReprintDialog()
      : handleSubmit();
  };

  const handleReprint = async selectedReprintOption => {
    let message = '';

    try {
      switch (selectedReprintOption) {
        case 'availableReprint':
          message = await getReprintAction(selectedReprintOption);
          break;
        case 'printLocally':
          message = await getPrintLocally();
          break;
        case 'updateAndIgnore':
          handleSubmit();
          break;
        default:
          message = await getReprintAction(selectedReprintOption);
      }
      selectedReprintOption !== 'printLocally' &&
        showSnackbar({ message });
    } catch (error) {
      showErrorSnackbar({
        message: getErrorMessage(error)
      });
    }
    setSelectedOption(selectedReprintOption);
    handleReprintDialog();
  };

  const getReprintAction = async selectedReprintOption => {
    const isAvailableReprint = selectedReprintOption === 'availableReprint';
    const message = isAvailableReprint
      ? 'Form was successfully marked as reprinted.'
      : 'Form was successfully unmarked as reprinted.';
    const reprintURL = `prints/forms/${formId}/reprint/${isAvailableReprint ? 'request' : 'cancelRequest'}`;
    await post(`${reprintURL}`);
    if (formModified && isAvailableReprint) {
      handleSubmit();
    }
    if (!formModified) {
      formHistoryActions.fetchRecordHistory(taxEntityCode, formId);
      isFormQuededForReprint();
    }
    return message;
  };

  const getPrintLocally = async () => {
    if (formModified) {
      handleSubmit();
    } else {
      await handleLocalDownload();
      formHistoryActions.fetchRecordHistory(taxEntityCode, formId);
      isFormQuededForReprint();
    }
  };

  const handleLocalDownload = async () => {
    const message = 'Form was successfully marked as printed locally.';
    const localPrintURL = `prints/forms/${formId}/reprint/localReprint`;
    showSnackbar({
      message: 'File download started'
    });
    const fileData = (await post(`${localPrintURL}`, {}, { responseType: 'arraybuffer' })).data;
    const fileName = `${getRecordName(taxEntityCode)}-${getLocalTimestampForFileName()}.pdf`;
    downloadFile(fileData, fileName);
    showSnackbar({ message });
  };

  let messageToDelete = getMessage(fcharToDelete, canBeDeleted, countFormLinked);

  const conditionToDisableSave = getConditionToDisableSave({
    fetchingIdentityManagement,
    saveDisableByValidation,
    checkingDuplicatedValuesInFields,
    areUniqueFieldsDuplicated,
    newForm,
    formModified,
    payerRecipientSelected,
    taxEntityCode,
    savingForm
  });

  const handleCloseSlidingPanel = () => {
    formActions.setTaxEntityCode('');
    formActions.setTaxEntityCodeBase('');
    formActions.resetForm();
    entityActions.resetEntitiesByPayee();
    onCloseSlidingPanel();
  };

  const handleClose = formModified
    ? () => setOpenConfirmationDialog(true)
    : handleCloseSlidingPanel;

  return (
    <span>
      <SovosSlidingPanel
        open={ openFormSlidingPanel }
        onClose={ handleClose }
        classes={ sliderStyle }
      >
        <SovosSlidingPanelHeader
          title={
            !fetchingForm &&
              <FormTitle currentTab={ taxEntityCode } newForm={ newForm } />
          }
        >
          <SovosSlidingPanelHeaderActionButtons>
            {
              Boolean(!newForm) &&
                <FormOptions
                  canBeDeleted={ canBeDeleted }
                  deleteForm={ deleteForm }
                  fcharToDelete={ fcharToDelete }
                  cloneForm={ cloneForm }
                  previewForm={ previewForm }
                  currentTab={ taxEntityCode }
                  reprintStatus={ reprintStatus }
                  formModified={ formModified }
                  formPrinted={ formPrinted }
                  handleReprintDialog={ handleReprintDialog }
                  enableReprint={ enableReprint }
                />
            }
            {
              taxEntityCode
                ? <>
                  <SovosButton
                    data-for='formCancel'
                    variant='text'
                    onClick={ handleClose }
                  >
                    Cancel
                  </SovosButton>
                  <SovosButton
                    id='saveForm'
                    form='sovosDynamicForm'
                    disabled={ conditionToDisableSave }
                    onClick={ handleFormSave }
                  >
                    Save
                  </SovosButton>
                </>
                : <IconButton onClick={ handleCloseSlidingPanel } size='large'>
                  <CloseIcon />
                </IconButton>
            }
          </SovosSlidingPanelHeaderActionButtons>
        </SovosSlidingPanelHeader>
        {
          newForm && !taxEntityCode
            ? <FormsSelect onFormChange={ handleFormChange } />
            : <FormInTabs
              mainTab={ taxEntityCodeBase }
              handleAddNewForm={ handleAddNewForm }
              onSaveFormSuccess={ handleOnSaveFormSuccess }
              formModified={ formModified }
              newForm={ newForm }
              formId={ formId }
            />
        }
      </SovosSlidingPanel>
      {
        formModified && <ConfirmationDialog
          open={ openConfirmationDialog }
          onOKClick={ handleOnOkConfirmation }
          onCancelClick={ () => setOpenConfirmationDialog(false) }
          title='Discard changes'
          content='Are you sure you want to discard your changes?'
          actionLabel='Discard'
        />
      }
      {
        mountDialog && canBeDeleted &&
          <ConfirmationDialog
            open={ openDeleteDialog }
            onOKClick={ handleOnDelete }
            onCancelClick={ () => setOpenDeleteDialog(false) }
            title={ messageToDelete.title }
            content={ messageToDelete.message }
            actionLabel={ messageToDelete.actionLabel }
            disabledActionLabel={ !canBeDeleted }
          />
      }
      <SovosSlidingPanel
        open={ openFormSlidingPanelClone }
        onClose={ handleCloseFormSlidingPanelClone }
      >
        <SovosSlidingPanelHeader
          title={ !creatingClone && formEditorTitle }
        >
          <SovosSlidingPanelHeaderActionButtons>
            <SovosButton
              type='submit'
              onClick={ handleSubmit }
              color='primary'
              form='sovosDynamicFormClone'
              id='saveForm'
              disabled={ conditionToDisableSave }
            >
              Save
            </SovosButton>
            <SovosIconButton tooltipText='' onClick={ handleCloseFormSlidingPanelClone }>
              <CloseIcon />
            </SovosIconButton>
          </SovosSlidingPanelHeaderActionButtons>
        </SovosSlidingPanelHeader>
        <div className='sovosSlidingPanel__container'>
          <Loader isLoading={ creatingClone }>
            <FormContainer
              formId={ formId }
              taxEntityCode={ taxEntityCode }
            />
          </Loader>
        </div>
      </SovosSlidingPanel>
      {
        openReprintDialog &&
          <ReprintOptionsDialog
            open={ openReprintDialog }
            formPrinted={ formPrinted }
            formModified={ formModified }
            reprintStatus={ reprintStatus }
            handleReprintDialog={ handleReprintDialog }
            handleReprint={ handleReprint }
          />
      }
    </span>
  );
};

SlidingFormEditor.defaultProps = {
  mountDialog: false,
  newForm: false
};

SlidingFormEditor.propTypes = {
  openFormSlidingPanel: PropTypes.bool.isRequired,
  taxEntityCode: PropTypes.string.isRequired,
  formId: PropTypes.number.isRequired,
  formModified: PropTypes.bool.isRequired,
  canBeDeleted: PropTypes.bool.isRequired,
  countFormLinked: PropTypes.number,
  formEditorTitle: PropTypes.string.isRequired,
  saveDisableByValidation: PropTypes.bool.isRequired,
  savingForm: PropTypes.bool.isRequired,
  areUniqueFieldsDuplicated: PropTypes.bool.isRequired,
  fetchingForm: PropTypes.bool.isRequired,
  taxEntityCodeBase: PropTypes.string.isRequired,
  newForm: PropTypes.bool,
  enableReprint: PropTypes.bool.isRequired,
  mountDialog: PropTypes.bool,
  checkingDuplicatedValuesInFields: PropTypes.bool.isRequired,
  fetchingIdentityManagement: PropTypes.bool.isRequired,
  formActions: PropTypes.shape({
    setFormEditorTitle: PropTypes.func,
    resetForm: PropTypes.func,
    resetExtraForm: PropTypes.func,
    resetCloneForm: PropTypes.func,
    checkConditionToDelete: PropTypes.func,
    createClone: PropTypes.func,
    deleteForm: PropTypes.func,
    setDeleted: PropTypes.func,
    setTaxEntityCode: PropTypes.func,
    setTaxEntityCodeBase: PropTypes.func,
    saveForm: PropTypes.func,
    fetchForm: PropTypes.func,
    setFetchFormSuccess: PropTypes.func
  }).isRequired,
  onCloseSlidingPanel: PropTypes.func.isRequired,
  entityActions: PropTypes.shape({
    resetEntitiesByPayee: PropTypes.func,
    fetchEntitiesByPayee: PropTypes.func
  }),
  printActions: PropTypes.shape({
    fetchPreview: PropTypes.func
  }),
  formHistoryActions: PropTypes.shape({
    fetchRecordHistory: PropTypes.func
  }),
  formIdToPreview: PropTypes.number.isRequired,
  fcharToPreview: PropTypes.string.isRequired,
  fcharToDelete: PropTypes.string.isRequired,
  deleted: PropTypes.bool.isRequired,
  formPrinted: PropTypes.bool.isRequired,
  formTransmittedToIrs: PropTypes.bool.isRequired,
  payerSelected: PropTypes.bool.isRequired,
  recipientSelected: PropTypes.bool.isRequired,
  creatingClone: PropTypes.bool.isRequired,
  selectedContext: PropTypes.object.isRequired,
  formMetadata: PropTypes.object,
  fetchFormSuccess: PropTypes.bool.isRequired,
  modRecNo: PropTypes.number,
  metadataActions: PropTypes.shape({
    fetchMetadata: PropTypes.func.isRequired
  }),
  checkingDuplicatedFailed: PropTypes.bool.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  showErrorSnackbar: PropTypes.func.isRequired
};

export default withSnackbar(SlidingFormEditor);
