export const B_NOTICE = 'BNotice';
export const FIRST_B_NOTICE = 'FirstBNotice';
export const SECOND_B_NOTICE = 'SecondBNotice';
export const W9R = 'W9Notice';
export const NOTICE_TYPE_LIST = [B_NOTICE, FIRST_B_NOTICE, SECOND_B_NOTICE, W9R];

export const getNoticeName = noticeType => {
  switch (noticeType) {
    case B_NOTICE:
      return 'B-notice';

    case FIRST_B_NOTICE:
    case '1BN':
      return '1st B notice';

    case SECOND_B_NOTICE:
    case '2BN':
      return '2nd B notice';

    case W9R:
    case 'W9R':
      return 'W-9';

    case 'PNW':
      return 'P notice - backup withholding';

    case 'PNNW':
      return 'P notice - no backup withholding';

    case 'PS':
      return 'Proactive W-9';

    default:
      return null;
  }
};

export const isNoticeType = type => NOTICE_TYPE_LIST.includes(type);