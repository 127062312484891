import { bool } from 'prop-types';
import './differenceValuesHeader.scss';

const DifferenceValuesHeader = ({ showPreviousDifferentFields }) => (
  showPreviousDifferentFields
    ? <div className='difference-values-header'>
      <span>Previously reported</span>
      <span>Correct values</span>
      <span>Difference</span>
    </div>
    : null
);

DifferenceValuesHeader.propTypes = {
  showPreviousDifferentFields: bool.isRequired
};

DifferenceValuesHeader.defaultProps = {
  showPreviousDifferentFields: false
};

export default DifferenceValuesHeader;