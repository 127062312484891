import actionTypes from './PrintPreviewConstants';

const initialStore = {
  fetchingPreview: false
};

export default function PrintPreviewReducer(state = initialStore, action) {
  switch (action.type) {
    case actionTypes.FETCHING_PRINT_PREVIEW:
      return { ...state, fetchingPreview: true };

    case actionTypes.FETCH_PRINT_PREVIEW_SUCCESS:
      return { ...state, fetchingPreview: false };

    case actionTypes.FETCH_PRINT_PREVIEW_FAILURE:
      return { ...state, fetchingPreview: false };

    case actionTypes.LOGOUT_SUCCESS:
      return { ...initialStore };

    default:
      return state;
  }
}
