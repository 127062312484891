import { Navigate } from 'react-router-dom';
import { node, object, string } from 'prop-types';
import authenticationHelper from 'helpers/authenticationHelper';

const getLandingPage = () => authenticationHelper.getProductLandingPage();

const ProtectedRoute = ({ children, route, redirectPath }) => {
  if (!route) {
    throw Error('Route prop is required');
  }

  if (authenticationHelper.checkConfigurationsAndPermissions(route) && authenticationHelper.checkProduct(route.product)) {
    if (authenticationHelper.isTIRCorporateECommerce() && authenticationHelper.isSubscriptionCancelled()) {
      if (route.showSubscriptionCancelled) {
        return children;
      }
      else {
        return <Navigate to={ redirectPath } replace/>;
      }
    }
    return children;
  }

  return <Navigate to={ redirectPath } replace />;
};

ProtectedRoute.propTypes = {
  children: node.isRequired,
  route: object.isRequired,
  redirectPath: string
};

ProtectedRoute.defaultProps = {
  redirectPath: getLandingPage()
};

export default ProtectedRoute;
