import { memo } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import './radiosListGroup.scss';

const RadiosListGroup = ({ onChange, options, value, disableAll }) => (
  <FormControl className="radiosListGroup">
    <RadioGroup onChange={ onChange }>
      {
        options.map(option => (
          <FormControlLabel
            key={ option.value }
            className='radiosListGroup__label'
            value={ option.value }
            control={
              <Radio
                color="primary"
                classes={ {
                  root: 'radiosListGroup__radioRoot',
                  checked: 'radiosListGroup__radioChecked'
                } }
                checked={ option.value === value }
                disabled={ option.disabled || disableAll }
              /> }
            label={ option.label }
          />))
      }
    </RadioGroup>
  </FormControl>
);

RadiosListGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
    label: PropTypes.string,
    disabled: PropTypes.bool
  })),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]).isRequired,
  disableAll: PropTypes.bool,
  className: PropTypes.string
};

RadiosListGroup.defaultProps = {
  options: []
};

export default memo(RadiosListGroup);

