//Please kill this file after user migration, and sso users migrations, this is only for s1 old login.
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { otpLogin } from 'redux/auth/authActions';
import Login from './LoginOtp';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ otpLogin }, dispatch)
});

export default connect(null, mapDispatchToProps)(Login);
