import { useState } from 'react';
import { bool, func } from 'prop-types';
import SovosDialog from 'mosaic-react/sovos-dialog';
import SovosDialogActions from 'mosaic-react/sovos-dialog-actions';
import SovosDialogContent from 'mosaic-react/sovos-dialog-content';
import SovosDialogTitle from 'mosaic-react/sovos-dialog-title';
import SovosButton from 'mosaic-react/sovos-button';
import ValidationTextField from 'shared/standardInput/textField/validationTextField/ValidationTextField';
import { withSnackbar } from 'enhancers';
import { put } from 'helpers/axios/axiosInterceptorsV1';
import { getErrorMessage } from 'helpers/errorMessageHelper';

const inputValidation = {
  validateOn: 'keyup',
  required: true,
  pattern: '^.[0-9]*$',
  message: 'Entry must be a number.'
};

const RequeueBackupWithholdingDialog = ({ modalOpen, toggleDialog, showSnackbar, showErrorSnackbar }) => {
  const [inputValue, setInputValue] = useState('');
  const [inputValueValid, setInputValueValid] = useState(false);
  const [sending, setSending] = useState(false);

  const handleOnChange = event => setInputValue(event.target.value);

  const onSend = async () => {
    try {
      setSending(true);
      await put(`tax-identity/solicitations/${inputValue}/backup-withholding/enqueue`);

      showSnackbar({ message: 'Backup Withholding was requeue' });

      onReset();
    } catch (error) {
      showErrorSnackbar({ message: getErrorMessage(error) });
    }
    setSending(false);
  };

  const onReset = () => {
    setInputValue('');
    toggleDialog();
  };

  return (
    <SovosDialog open={ modalOpen }>
      <SovosDialogTitle>Requeue backup withholding</SovosDialogTitle>
      <SovosDialogContent>
        <ValidationTextField
          name='solicitation_id'
          value={ inputValue }
          onChange={ handleOnChange }
          onValidation={ value => setInputValueValid(value) }
          label='Solicitation ID'
          validation={ inputValidation }
          fullWidth
        />
      </SovosDialogContent>
      <SovosDialogActions>
        <SovosButton
          onClick={ onReset }
          variant='text'
        >
          Cancel
        </SovosButton>
        <SovosButton
          disabled={ !inputValueValid || sending }
          onClick={ onSend }
        >
          Send
        </SovosButton>
      </SovosDialogActions>
    </SovosDialog>
  );
};

RequeueBackupWithholdingDialog.propTypes = {
  modalOpen: bool.isRequired,
  toggleDialog: func.isRequired,
  showSnackbar: func.isRequired,
  showErrorSnackbar: func.isRequired
};

export default withSnackbar(RequeueBackupWithholdingDialog);
