import { useState, useEffect } from 'react';
import { determineMask } from './maskHelper';

const useMask = (mask, decimalLimit = 2) => {
  const [maskToApply, setMaskToApply] = useState({});

  useEffect(() => {
    setMaskToApply(determineMask(mask, decimalLimit));
  }, [mask, decimalLimit]);

  return [maskToApply];
};

export default useMask;