import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SovosTableGroup from 'mosaic-react/sovos-table-group';
import TotalFormsByTypeTable from './TotalFormsByTypeTableConnector';
import SovosReadOnlySlidingPanel from 'mosaic-react/sovos-readonly-sliding-panel';
import useColumnSort from 'helpers/useColumnSort';
import { getFcharByName } from 'helpers/formConstants';
import totalFormsTableColumns from './TotalFormsTableColumns';
import './totalForms.scss';

const TotalForms = ({ recordsByFormSummary, actions, fetchingRecordsByFormSummary }) => {
  const [openFormSlider, setOpenFormSlider] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const {
    sortOrderIndicator,
    sortColumnIndicator,
    sortColumnId,
    onColumnSortChanged
  } = useColumnSort({ sortIndicator: 'FormType', id: 1 }, '+');

  useEffect(() => {
    actions.fetchRecordsByFormSummary(sortColumnIndicator, sortOrderIndicator);
  }, [sortOrderIndicator, sortColumnIndicator]);

  const onFormTypeClick = row => {
    setOpenFormSlider(true);
    setSelectedRow(row);
  };

  const closeFormSlider = () => {
    setOpenFormSlider(false);
    actions.fetchRecordsByFormSummary(sortColumnIndicator, sortOrderIndicator);
  };

  return (
    <div>
      <div className="total-forms__container">
        <SovosTableGroup
          isLoading={ fetchingRecordsByFormSummary }
          TableProps={ {
            columns: totalFormsTableColumns,
            data: recordsByFormSummary,
            onRowClick: onFormTypeClick,
            onColumnSort: onColumnSortChanged,
            columnSortId: sortColumnId
          } }
        />
      </div>
      <SovosReadOnlySlidingPanel
        open={ openFormSlider }
        onClose={ closeFormSlider }
        title={
          <span>
            <span style={ { opacity: .5 } }>Total forms / </span>{ selectedRow.FormType || '' }
          </span>
        }
      >
        <TotalFormsByTypeTable
          fChar={ getFcharByName(selectedRow.FormType) }
        />
      </SovosReadOnlySlidingPanel>
    </div>
  );
};

TotalForms.propTypes = {
  recordsByFormSummary: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    fetchRecordsByFormSummary: PropTypes.func
  }).isRequired,
  fetchingRecordsByFormSummary: PropTypes.bool.isRequired
};

export default TotalForms;
