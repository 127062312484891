//TODO: use this page to see if we can create a button to re-login to the app using the sso providername

//Example of a relogin link (for another app):

//https://dev-sso.identity.sovos.com/oauth2/authorize?client_id=70paulb1bvu4lo9p9hjhhb1mr1&redirect_uri=http://localhost:21738/signin-oidc&response_type=code&scope=openid profile email&code_challenge=fA0aiTc2l11x4GpIa-aGGy-bb_8GU2CY1e8C05gUYfk&code_challenge_method=S256&identity_provider=intuit-oidc&state=CfDJ8BBx9TJYlVpMvMvE6vUGQIIhbMOCpFKBnY62JJocZR9wLYF0N5qkJ4dfgKPUX8Te8JayONwoli4tVrCtIJIadrYEEHdBXDea5RGead9GJiHH3cOQm5hyyX37TFAgUZFtHN5mRbGLlZwpXlDUXRd-Fp9TgL_v69E41ShuXk1QQMLYf3AArmKQITIK9hyR8a9o2Jd9kMur3TQV3rpcMQMR0gyMEtA6FkEhw6bIYjOq_f10TuV81-47sxkra2zWj4rT-7scHnPukGG3v1DrRHJGwtn7owrY644xswCdFdD25iICyKLyJNnhlTqeVJ7Zu_JfrPer_gY41BSmncHS7OFKDoCXQ_pDi5U_RXWNNUH9U69ccGOP-UtJx0lD6f0nzZ-e7A&x-client-SKU=ID_NETSTANDARD2_0&x-client-ver=6.10.0.0
import Logo from './Sovos-Logo.dark.svg';
import './logoutSSO.scss';

const LogoutSSO = () => (
  <div className='logout-sso'>
    <div className='logout-sso__left-side'>
      <img src={ Logo } alt='sovos-logo' width={ 112 }/>
      <p>You’ve successfully logged out! You’re good to exit this page</p>
      { /* <SovosButton className='logout-sso__login-button' disabled={ true }>Log back in</SovosButton> */ }
    </div>
    <div className='logout-sso__right-side'>
      <div className='logout-sso__right-side-content'>
        <h3>Global Solutions Fueled by Local Expertise</h3>
        <span className='logout-sso__right-side-text'>Whether you do business in 60 countries or in one, increase agility and stay ahead of rapidly changing tax and regulatory rules with tax compliance software and services from Sovos.</span>
      </div>
    </div>
  </div>
);

export default LogoutSSO;