import { oneOf, string } from 'prop-types';
import { Circle } from 'mosaic-react-icons';
import SovosTypography from 'mosaic-react/sovos-typography';
import './statusCircleIcon.scss';

const StatusCircleIcon = ({ children, status }) => (
  <div className='status-circle-icon__container'>
    <Circle className='status-circle-icon__circle' color={ status }/>
    <SovosTypography component='span'>{ children }</SovosTypography>
  </div>
);

StatusCircleIcon.propTypes = {
  children: string.isRequired,
  status: oneOf(['success', 'warning']).isRequired
};

export default StatusCircleIcon;
