import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FormContainer from './FormContainer';
import {
  setShowCorrectionDialog,
  saveWithMarkAsCorrection,
  saveWithoutMarkAsCorrection
} from '../sovos-dynamic-form/redux/DynamicFormActions';

const mapStateToProps = (state, ownProps) => ({
  showCorrectionDialog: state.dynamicForm.showMarkAsCorrectionDialog,
  modLevel: (state.dynamicForm.isClone ? state.dynamicForm.cloneForm : state.dynamicForm.form[ownProps.taxEntityCode])?.MODLEVEL
});

const mapDispatchToProps = dispatch => ({
  dynamicFormActions: bindActionCreators({
    setShowCorrectionDialog,
    saveWithMarkAsCorrection,
    saveWithoutMarkAsCorrection
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
