import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import './loader.scss';

const LoaderData = {
  'Linear': LinearProgress,
  'Circular': CircularProgress
};

const Loader = ({ children, isLoading, loaderType, loaderText, ...otherProps }) => {
  const LoaderMUIComponent = LoaderData[loaderType] || LinearProgress;
  return (
    isLoading
      ?
      <div className={ classnames({ 'container-loader': loaderType === 'Circular' }) }>
        <LoaderMUIComponent className={ classnames({ 'loader-circular': loaderType === 'Circular' }) } { ...otherProps } />
        { Boolean(loaderText) && <Typography className="loader-text" variant="subtitle2">{ loaderText }</Typography> }
      </div>
      : children
  );
};

Loader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node,
  loaderType: PropTypes.oneOf(['Linear', 'Circular']),
  loaderText: PropTypes.string
};

Loader.defaultProps = {
  children: null,
  loaderType: 'Linear'
};

export default Loader;