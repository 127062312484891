import { forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

const TextMaskCustom = forwardRef(({ value, onChange, className, name, maskToApply, disabled, onBlur }, _) => {
  const [mask, setMask] = useState([]);

  useEffect(() => {
    if (maskToApply.mask){
      setMask(maskToApply.mask);
    }
  }, [maskToApply]);

  return (
    <MaskedInput
      id={ name }
      name={ name }
      className={ className }
      value={ value }
      onChange={ onChange }
      onBlur={ onBlur }
      mask={ mask }
      placeholder={ maskToApply.placeholder }
      pipe={ maskToApply.pipe }
      disabled = { disabled }
    />
  );
});

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func,
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  className: PropTypes.string,
  maskToApply: PropTypes.shape({
    mask: PropTypes.array,
    placeholder: PropTypes.string,
    pipe: PropTypes.func
  })
};

export default TextMaskCustom;
