import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CommentList from './CommentList';
import { fetchComments } from 'redux/formComments/formCommentsActions';

const mapStateToProps = state => ({
  comments: state.formComments.comments,
  fetchingComments: state.formComments.fetchingComments
});

const mapDispatchToProps = dispatch => ({
  commentsActions: bindActionCreators({ fetchComments }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CommentList);
