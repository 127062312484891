// mapped BE error codes
export const BE_PRINT_SERVICE_150000 = 'PrintService-150000';
export const BE_PRINT_SERVICE_150001 = 'PrintService-150001';
export const BE_PRINT_SERVICE_150002 = 'PrintService-150002';
export const BE_PRINT_SERVICE_150003 = 'PrintService-150003';
export const BE_PRINT_SERVICE_150004 = 'PrintService-150004';
export const BE_PRINT_SERVICE_150005 = 'PrintService-150005';
export const BE_TRANSMIT_SERVICE_110000 = 'TransmitService-110000';
export const BE_TRANSMIT_SERVICE_110001 = 'TransmitService-110001';
export const BE_TRANSMIT_SERVICE_110003 = 'TransmitService-110003';
export const BE_TRANSMIT_SERVICE_120006 = 'TransmitService-120006';
export const BE_TRANSMIT_SERVICE_120007 = 'TransmitService-120007';
export const BE_TRANSMIT_SERVICE_120009 = 'TransmitService-120009';
export const BE_TRANSMIT_SERVICE_120010 = 'TransmitService-120010';
export const BE_TRANSMIT_SERVICE_120011 = 'TransmitService-120011';
export const BE_TRANSMIT_SERVICE_120012 = 'TransmitService-120012';
export const BE_TRANSMIT_SERVICE_120013 = 'TransmitService-120013';
export const BE_TRANSMIT_SERVICE_120014 = 'TransmitService-120014';
export const BE_TRANSMIT_SERVICE_130002 = 'TransmitService-130002';
export const BE_TRANSMIT_SERVICE_130003 = 'TransmitService-130003';
export const BE_TRANSMIT_SERVICE_130004 = 'TransmitService-130004';
export const BE_TRANSMIT_SERVICE_130005 = 'TransmitService-130005';
export const BE_TRANSMIT_SERVICE_130006 = 'TransmitService-130006';
export const BE_TRANSMIT_SERVICE_130007 = 'TransmitService-130007';
export const BE_TRANSMIT_SERVICE_130008 = 'TransmitService-130008';
export const BE_TRANSMIT_SERVICE_130009 = 'TransmitService-130009';
export const BE_TRANSMIT_SERVICE_130010 = 'TransmitService-130010';
export const BE_TRANSMIT_SERVICE_130011 = 'TransmitService-130011';
export const BE_TRANSMIT_SERVICE_130013 = 'TransmitService-130013';
export const BE_TAX_ENTITY_SERVICE_100000 = 'TaxEntityService-100000';
export const BE_TAX_ENTITY_SERVICE_100001 = 'TaxEntityService-100001';
export const BE_TAX_ENTITY_SERVICE_100002 = 'TaxEntityService-100002';
export const BE_TAX_ENTITY_SERVICE_100003 = 'TaxEntityService-100003';
export const BE_TAX_ENTITY_SERVICE_100004 = 'TaxEntityService-100004';
export const BE_TAX_ENTITY_SERVICE_120000 = 'TaxEntityService-120000';
export const BE_TAX_ENTITY_SERVICE_120001 = 'TaxEntityService-120001';
export const BE_TAX_ENTITY_SERVICE_120002 = 'TaxEntityService-120002';
export const BE_TAX_ENTITY_SERVICE_120003 = 'TaxEntityService-120003';
export const BE_TAX_ENTITY_SERVICE_120004 = 'TaxEntityService-120004';
export const BE_TAX_ENTITY_SERVICE_120005 = 'TaxEntityService-120005';
export const BE_TAX_ENTITY_SERVICE_120006 = 'TaxEntityService-120006';
export const BE_TAX_ENTITY_SERVICE_120007 = 'TaxEntityService-120007';
export const BE_TAX_ENTITY_SERVICE_120008 = 'TaxEntityService-120008';
export const BE_TAX_ENTITY_SERVICE_120009 = 'TaxEntityService-120009';
export const BE_TAX_ENTITY_SERVICE_120010 = 'TaxEntityService-120010';
export const BE_TAX_ENTITY_SERVICE_120011 = 'TaxEntityService-120011';
export const BE_TAX_ENTITY_SERVICE_120012 = 'TaxEntityService-120012';
export const BE_TAX_ENTITY_SERVICE_120013 = 'TaxEntityService-120013';
export const BE_TAX_ENTITY_SERVICE_120014 = 'TaxEntityService-120014';
export const BE_TAX_ENTITY_SERVICE_120015 = 'TaxEntityService-120015';
export const BE_TAX_ENTITY_SERVICE_120016 = 'TaxEntityService-120016';
export const BE_TAX_ENTITY_SERVICE_120017 = 'TaxEntityService-120017';
export const BE_TAX_ENTITY_SERVICE_120018 = 'TaxEntityService-120018';
export const BE_TAX_ENTITY_SERVICE_120019 = 'TaxEntityService-120019';
export const BE_TAX_ENTITY_SERVICE_120020 = 'TaxEntityService-120020';
export const BE_TAX_ENTITY_SERVICE_120021 = 'TaxEntityService-120021';
export const BE_TAX_ENTITY_SERVICE_120022 = 'TaxEntityService-120022';
export const BE_TAX_ENTITY_SERVICE_120023 = 'TaxEntityService-120023';
export const BE_TAX_ENTITY_SERVICE_120024 = 'TaxEntityService-120024';
export const BE_TAX_ENTITY_SERVICE_120025 = 'TaxEntityService-120025';
export const BE_TAX_ENTITY_SERVICE_120026 = 'TaxEntityService-120026';
export const BE_TAX_ENTITY_SERVICE_130000 = 'TaxEntityService-130000';
export const BE_TAX_ENTITY_SERVICE_130001 = 'TaxEntityService-130001';
export const BE_TAX_ENTITY_SERVICE_130004 = 'TaxEntityService-130004';
export const BE_TAX_ENTITY_SERVICE_130006 = 'TaxEntityService-130006';
export const BE_TAX_ENTITY_SERVICE_130007 = 'TaxEntityService-130007';
export const BE_TAX_ENTITY_SERVICE_130008 = 'TaxEntityService-130008';
export const BE_TAX_ENTITY_SERVICE_140000 = 'TaxEntityService-140000';
export const BE_TAX_ENTITY_SERVICE_140001 = 'TaxEntityService-140001';
export const BE_TAX_ENTITY_SERVICE_140002 = 'TaxEntityService-140002';
export const BE_TAX_ENTITY_SERVICE_140003 = 'TaxEntityService-140003';
export const BE_TAX_ENTITY_SERVICE_150000 = 'TaxEntityService-150000';
export const BE_TAX_ENTITY_SERVICE_150001 = 'TaxEntityService-150001';
export const BE_TAX_ENTITY_SERVICE_150002 = 'TaxEntityService-150002';
export const BE_TAX_ENTITY_SERVICE_V2_100106 = 'TaxEntityServiceV2-100106';
export const BE_TAX_ENTITY_SERVICE_V2_100109 = 'TaxEntityServiceV2-100109';
export const BE_TAX_ENTITY_SERVICE_V2_100121 = 'TaxEntityServiceV2-100121';
export const BE_TAX_ENTITY_SERVICE_V2_100503 = 'TaxEntityServiceV2-100503';
export const BE_TAX_IDENTITY_100002 = 'TaxIdentityService-100002';
export const BE_TAX_IDENTITY_100004 = 'TaxIdentityService-100004';
export const BE_TAX_IDENTITY_100005 = 'TaxIdentityService-100005';
export const BE_TAX_IDENTITY_100006 = 'TaxIdentityService-100006';
export const BE_TAX_IDENTITY_100009 = 'TaxIdentityService-100009';
export const BE_TAX_IDENTITY_100010 = 'TaxIdentityService-100010';
export const BE_TAX_IDENTITY_100011 = 'TaxIdentityService-100011';
export const BE_TAX_IDENTITY_100012 = 'TaxIdentityService-100012';
export const BE_TAX_IDENTITY_100013 = 'TaxIdentityService-100013';
export const BE_TAX_IDENTITY_100014 = 'TaxIdentityService-100014';
export const BE_TAX_IDENTITY_100017 = 'TaxIdentityService-100017';
export const BE_TAX_IDENTITY_100018 = 'TaxIdentityService-100018';
export const BE_TAX_IDENTITY_100019 = 'TaxIdentityService-100019';
export const BE_TAX_IDENTITY_100022 = 'TaxIdentityService-100022';
export const BE_TAX_IDENTITY_100023 = 'TaxIdentityService-100023';
export const BE_TAX_IDENTITY_100024 = 'TaxIdentityService-100024';
export const BE_TAX_IDENTITY_100025 = 'TaxIdentityService-100025';
export const BE_TAX_IDENTITY_100026 = 'TaxIdentityService-100026';
export const BE_TAX_IDENTITY_100027 = 'TaxIdentityService-100027';
export const BE_TAX_IDENTITY_100028 = 'TaxIdentityService-100028';
export const BE_TAX_IDENTITY_100029 = 'TaxIdentityService-100029';
export const BE_TAX_IDENTITY_100030 = 'TaxIdentityService-100030';
export const BE_TAX_IDENTITY_100031 = 'TaxIdentityService-100031';
export const BE_TAX_IDENTITY_100034 = 'TaxIdentityService-100034';
export const BE_TAX_IDENTITY_100038 = 'TaxIdentityService-100038';
export const BE_TAX_IDENTITY_100042 = 'TaxIdentityService-100042';
export const BE_TAX_IDENTITY_100043 = 'TaxIdentityService-100043';
export const BE_TAX_IDENTITY_100046 = 'TaxIdentityService-100046';
export const BE_TAX_IDENTITY_100055 = 'TaxIdentityService-100055';
export const BE_TAX_IDENTITY_100056 = 'TaxIdentityService-100056';
export const BE_TAX_IDENTITY_100057 = 'TaxIdentityService-100057';
export const BE_TAX_IDENTITY_100058 = 'TaxIdentityService-100058';
export const BE_TAX_IDENTITY_100062 = 'TaxIdentityService-100062';
export const BE_TAX_IDENTITY_100067 = 'TaxIdentityService-100067';
export const BE_TAX_IDENTITY_100068 = 'TaxIdentityService-100068';
export const BE_TAX_IDENTITY_100070 = 'TaxIdentityService-100070';
export const BE_TAX_IDENTITY_100071 = 'TaxIdentityService-100071';
export const BE_TAX_IDENTITY_100072 = 'TaxIdentityService-100072';
export const BE_TAX_IDENTITY_100073 = 'TaxIdentityService-100073';
export const BE_TAX_IDENTITY_100074 = 'TaxIdentityService-100074';
export const BE_TAX_IDENTITY_100075 = 'TaxIdentityService-100075';
export const BE_TAX_IDENTITY_100076 = 'TaxIdentityService-100076';
export const BE_TAX_IDENTITY_100077 = 'TaxIdentityService-100077';
export const BE_TAX_IDENTITY_100078 = 'TaxIdentityService-100078';
export const BE_TAX_IDENTITY_100079 = 'TaxIdentityService-100079';
export const BE_TAX_IDENTITY_100080 = 'TaxIdentityService-100080';
export const BE_TAX_IDENTITY_100088 = 'TaxIdentityService-100088';
export const BE_TAX_IDENTITY_100092 = 'TaxIdentityService-100092';
export const BE_TAX_IDENTITY_100093 = 'TaxIdentityService-100093';
export const BE_TAX_IDENTITY_100094 = 'TaxIdentityService-100094';
export const BE_TAX_IDENTITY_100096 = 'TaxIdentityService-100096';
export const BE_TAX_IDENTITY_100097 = 'TaxIdentityService-100097';
export const BE_TAX_IDENTITY_100098 = 'TaxIdentityService-100098';
export const BE_TAX_IDENTITY_100113 = 'TaxIdentityService-100113';
export const BE_TAX_IDENTITY_VERIFICATION_100007 = 'IdentityVerificationService-100007';
export const BE_IMPORTV2_130104 = 'ImportServiceV2-130104';
export const BE_IMPORT_SERVICE_100001 = 'ImportService-100001';
export const BE_IMPORT_SERVICE_100002 = 'ImportService-100002';
export const BE_IMPORT_SERVICE_100004 = 'ImportService-100004';
export const BE_IMPORT_SERVICE_100005 = 'ImportService-100005';
export const BE_REPORT_SERVICE_100000 = 'RS-100000';
export const BE_REPORT_SERVICE_100001 = 'RS-100001';
export const BE_REPORT_SERVICE_100002 = 'RS-100002';
export const BE_REPORT_SERVICE_101000 = 'ReportService-101000';
export const BE_REPORT_SERVICE_101100 = 'ReportService-101100';
export const BE_REPORT_SERVICE_101200 = 'ReportService-101200';
export const BE_REPORT_SERVICE_101201 = 'ReportService-101201';
export const BE_REPORT_SERVICE_101202 = 'ReportService-101202';
export const BE_REPORT_SERVICE_101300 = 'ReportService-101300';
export const BE_REPORT_SERVICE_101301 = 'ReportService-101301';
export const BE_REPORT_SERVICE_101302 = 'ReportService-101302';
export const BE_REPORT_SERVICE_101303 = 'ReportService-101303';
export const BE_REPORT_SERVICE_101304 = 'ReportService-101304';
export const BE_REPORT_SERVICE_101305 = 'ReportService-101305';
export const BE_REPORT_SERVICE_101306 = 'ReportService-101306';
export const BE_FILE_MANAGER_SERVICE_100012 = 'FileManagerService-100012';

// fallback FE error codes
export const FE_TRANSMIT_001 = 'FE_TRANSMIT_001';
export const FE_CORP_TRANSMIT_001 = 'FE_CORP_TRANSMIT_001';
export const FE_CORP_TRANSMIT_002 = 'FE_CORP_TRANSMIT_002';
export const FE_CORP_TRANSMIT_003 = 'FE_CORP_TRANSMIT_003';
export const FE_PRINT_001 = 'FE_PRINT_001';
export const FE_ENT_FILEMANAGER_001 = 'FE_ENT_FILEMANAGER_001';
export const FE_ENT_TRANSMIT_001 = 'FE_ENT_TRANSMIT_001';
export const FE_ENT_TRANSMIT_002 = 'FE_ENT_TRANSMIT_002';
export const FE_ENT_TRANSMIT_003 = 'FE_ENT_TRANSMIT_003';
export const FE_ENT_TRANSMIT_004 = 'FE_ENT_TRANSMIT_004';
export const FE_ENT_TRANSMIT_005 = 'FE_ENT_TRANSMIT_005';
export const FE_ENT_TRANSMIT_006 = 'FE_ENT_TRANSMIT_006';
export const FE_ENT_TRANSMIT_007 = 'FE_ENT_TRANSMIT_007';
export const FE_ENT_TRANSMIT_008 = 'FE_ENT_TRANSMIT_008';
export const FE_ENT_TRANSMIT_009 = 'FE_ENT_TRANSMIT_009';
export const FE_ENT_TRANSMIT_010 = 'FE_ENT_TRANSMIT_010';
export const FE_ENT_TRANSMIT_011 = 'FE_ENT_TRANSMIT_011';
export const FE_ENT_PRINT_001 = 'FE_ENT_PRINT_001';
export const FE_ENT_PRINT_002 = 'FE_ENT_PRINT_002';
export const FE_ENT_PRINT_003 = 'FE_ENT_PRINT_003';
export const FE_ENT_PRINT_004 = 'FE_ENT_PRINT_004';
export const FE_ENT_PRINT_005 = 'FE_ENT_PRINT_005';
export const FE_ENT_TAXIDENT_001 = 'FE_ENT_TAXIDENT_001';
export const FE_ENT_TAXIDENT_002 = 'FE_ENT_TAXIDENT_002';
export const FE_ENT_TAXIDENT_003 = 'FE_ENT_TAXIDENT_003';
export const FE_ENT_TAXIDENT_004 = 'FE_ENT_TAXIDENT_004';
export const FE_ENT_TAXIDENT_005 = 'FE_ENT_TAXIDENT_005';
export const FE_ENT_TAXIDENT_006 = 'FE_ENT_TAXIDENT_006';
export const FE_ENT_TAXIDENT_007 = 'FE_ENT_TAXIDENT_007';
export const FE_ENT_TAXIDENT_008 = 'FE_ENT_TAXIDENT_008';
export const FE_ENT_PRINT_NOTE_001 = 'FE_ENT_PRINT_NOTE_001';
export const FE_FEATURES_001 = 'FE_FEATURES_001';
export const FE_FEATURES_002 = 'FE_FEATURES_002';
export const FE_IMPORT_001 = 'FE_IMPORT_001';
export const FE_IMPORT_002 = 'FE_IMPORT_002';
export const FE_IMPORT_003 = 'FE_IMPORT_003';
export const FE_IMPORT_004 = 'FE_IMPORT_004';
export const FE_IMPORT_005 = 'FE_IMPORT_005';
export const FE_IMPORT_006 = 'FE_IMPORT_006';
export const FE_REPORT_001 = 'FE_REPORT_001';
export const FE_REPORT_002 = 'FE_REPORT_002';
export const FE_FILEMANAGER_001 = 'FE_FILEMANAGER_001';
export const FE_BACKUP_WITHHOLDING_001 = 'FE_BACKUP_WITHHOLDING_001';
export const FE_FECHING_RECORDS_001 = 'FE_FECHING_RECORDS_001';
export const FE_NEW_TAX_YEAR_001 = 'FE_NEW_TAX_YEAR_001';
export const FE_NEW_TAX_YEAR_002 = 'FE_NEW_TAX_YEAR_002';
