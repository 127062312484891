import { get } from 'helpers/axios/axiosInterceptorsV1';
import actionTypes from './formHistoryConstants';

const fetchingHistory = () => ({ type: actionTypes.FETCHING_HISTORY });

const fetchRecordHistorySuccess = formHistory => ({ type: actionTypes.FETCH_HISTORY_SUCCESS, formHistory });

const fetchRecordHistoryFailure = () => ({ type: actionTypes.FETCH_HISTORY_FAILURE });

const fetchRecordHistory = (recordType, recordId) => async (dispatch, getState) => {
  if (getState().dynamicForm.taxEntityCode) {
    dispatch(fetchingHistory());

    try {
      const response = await get(`/record-activity/${recordType}-${recordId}/history`);
      dispatch(fetchRecordHistorySuccess(response.data.data));
    } catch (error) {
      dispatch(fetchRecordHistoryFailure());
      throw (error);
    }
  }
};

export {
  fetchRecordHistory
};
