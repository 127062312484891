import { eulaEcommerce } from 'helpers/eulaContent';
import './eulaPage.scss';

const Eula = () => (
  <div className='eula-page'>
    <h1>Terms and Conditions</h1>
    <div className='eula-content'>
      <div dangerouslySetInnerHTML={ { __html: eulaEcommerce.content } } />
    </div>
  </div>
);

export default Eula;
