import sharedActions from 'redux/constants/SharedConstants';

const actionTypes = {
  ...sharedActions,
  FETCHING_HISTORY: 'FETCHING_HISTORY',
  FETCH_HISTORY_SUCCESS: 'FETCH_HISTORY_SUCCESS',
  FETCH_HISTORY_FAILURE: 'FETCH_HISTORY_FAILURE'
};

export default actionTypes;
