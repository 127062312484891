import { useState, useEffect } from 'react';
import { getErrorMessage } from 'helpers/errorMessageHelper';
import { FE_TRANSMIT_001 } from 'helpers/errorMessageConstants';
import { fetchStateTransmit } from 'helpers/corpStateTransmitHelper';

const useRecentStateXmits = (hasCorporateStateReporting, isStateProcessing, showErrorSnackbar) => {
  const [fetchingStateTransmit, setFetchingStateTransmit] = useState(false);
  const [stateTransmitRecords, setStateTransmitRecords] = useState([]);

  useEffect(() => {
    let mounted = true;
    if (hasCorporateStateReporting && !isStateProcessing) {

      const fetchStateTransmitRecords = async () => {
        try {
          setFetchingStateTransmit(true);
          const result = await fetchStateTransmit();
          if (mounted) {
            setStateTransmitRecords([...result.Data]);
          }
        } catch (error) {
          console.log(error);
          showErrorSnackbar({ message: getErrorMessage(null, FE_TRANSMIT_001) });
        }
        setFetchingStateTransmit(false);
      };

      fetchStateTransmitRecords();
    }

    return () => {
      mounted = false;
    };
  }, [isStateProcessing]);

  return {
    fetchingStateTransmit,
    stateTransmitRecords
  };
};

export default useRecentStateXmits;