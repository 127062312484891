export const ActionType = {
  unspecified: 0,
  added: 1,
  updated: 2,
  print: 3,
  correction: 4,
  irsTransmit: 5,
  stateTransmit: 6,
  markForReprint: 7,
  reprintLocally: 8,
  cancelReprint: 9,
  updateTinMatchStatus: 10,
  localityTransmit: 11,
  extensionOfTime: 12,
  cancelExtensionOfTime: 13
};