import actionTypes from './FileManagerFoldersConstants';

const initialStore = {
  folders: [],
  errorCreateFolder: false,
  errorRenameFolder: false,
  errorMessage: '',
  fetchingFolders: false
};

const FileManagerFoldersReducer = (state = initialStore, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_FOLDERS:
      return { ...state, fetchingFolders: true };

    case actionTypes.FETCH_FOLDERS_SUCCESS:
      return {
        ...state,
        folders: action.foldersList,
        fetchingFolders: false,
        errorMessage: ''
      };

    case actionTypes.FETCH_FOLDERS_FAILURE:
      return { ...state, errorMessage: action.errorMessage };

    case actionTypes.CREATE_FOLDER_SUCCESS:
      return {
        ...state,
        errorCreateFolder: false,
        errorMessage: ''
      };

    case actionTypes.CREATE_FOLDER_FAILURE:
      return {
        ...state,
        errorCreateFolder: true,
        errorMessage: action.errorMessage
      };

    case actionTypes.RENAMING_FOLDER:
      return {
        ...state,
        errorRenameFolder: false,
        errorMessage: ''
      };

    case actionTypes.RENAME_FOLDER_SUCCESS:
      return {
        ...state,
        errorRenameFolder: false,
        errorMessage: ''
      };

    case actionTypes.RENAME_FOLDER_FAILURE:
      return {
        ...state,
        errorRenameFolder: true,
        errorMessage: action.errorMessage
      };

    case actionTypes.LOGOUT_SUCCESS:
      return { ...state, editedState: initialStore };

    default:
      return state;
  }
};

export default FileManagerFoldersReducer;
