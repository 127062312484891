import actionTypes from './PrintNotesConstants';

const initialStore = {
  isFetchingNotes: false,
  isSavingNote: false,
  isUpdatingNote: false,
  isDeletingNote: false,
  notes: []
};

export default function PrintNotesReducer(state = initialStore, action) {
  switch (action.type) {
    case actionTypes.FETCHING_PRINT_NOTES:
      return { ...state, isFetchingNotes: true };

    case actionTypes.FETCH_PRINT_NOTES_SUCCESS:
      return { ...state, isFetchingNotes: false, notes: action.data };

    case actionTypes.SAVING_PRINT_NOTE:
      return { ...state, isSavingNote: true };

    case actionTypes.SAVE_PRINT_NOTE_SUCCESS:
      return { ...state, isSavingNote: false };

    case actionTypes.UPDATING_PRINT_NOTE:
      return { ...state, isUpdatingNote: true };

    case actionTypes.UPDATE_PRINT_NOTE_SUCCESS:
      return { ...state, isUpdatingNote: false };

    case actionTypes.DELETING_PRINT_NOTE:
      return { ...state, isDeletingNote: true };

    case actionTypes.DELETE_PRINT_NOTE_SUCCESS:
      return { ...state, isDeletingNote: false };

    case actionTypes.LOGOUT_SUCCESS:
      return { ...initialStore };

    default:
      return { ...state };
  }
}
