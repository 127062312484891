import { createElement } from 'react';
import PropTypes from 'prop-types';
import TinMask from './tinMask/TinMask';
import TinType from './tinType/TinType';

const type = {
  TINMASK: TinMask,
  TINTYPE: TinType
};

const DataModifier = props => (
  createElement(type[props.modifierType.toUpperCase()],
    {
      records: props.records,
      is1042SForm: props.is1042SForm,
      fchar: props.fchar
    })
);

DataModifier.propTypes = {
  modifierType: PropTypes.string.isRequired,
  records: PropTypes.object.isRequired,
  is1042SForm: PropTypes.bool,
  fchar: PropTypes.string
};

export default DataModifier;
