import { useState } from 'react';
import PropTypes from 'prop-types';
import SovosTabGroup from 'mosaic-react/sovos-tab-group';
import SovosTabContent from 'mosaic-react/sovos-tab-content';
import CommentsTab from './commentsTab/CommentsTab';
import HistoryTab from './historyTab/HistoryTabConnector';
import AttachmentTab from './attachmentsTab/AttachmentTab';
import { recipientFchar } from 'helpers/formConstants';
import { TAX_IDENTITY_MANAGEMENT_RESPONSE } from 'helpers/configurationConstants';
import authenticationHelper from 'helpers/authenticationHelper';
import './dynamicFormInformation.scss';

const tabNames = {
  COMMENTS: "Comments",
  HISTORY: "History",
  ATTACHMENTS: "Attachments"
};

const hasAttachmentPermissions = authenticationHelper.checkConfiguration([TAX_IDENTITY_MANAGEMENT_RESPONSE])[0] && authenticationHelper.isTIREnterprise();

const DynamicFormInformation = ({ formId, taxEntityCode, fetchingForm }) => {
  const [currentTab, setCurrentTab] = useState(tabNames.COMMENTS);

  if (formId > -1 && !fetchingForm) {
    return (
      <div className="form-tabs__tabs-container">
        <SovosTabGroup>
          <SovosTabContent
            className='form-tabs__tab-container'
            TabProps={ {
              label: tabNames.COMMENTS,
              onClick: () => setCurrentTab(tabNames.COMMENTS)
            } }
          >
            {
              currentTab === tabNames.COMMENTS
              && <CommentsTab formId={ formId } formType={ taxEntityCode } />
            }
          </SovosTabContent>
          <SovosTabContent
            className='form-tabs__tab-container'
            TabProps={ {
              label: tabNames.HISTORY,
              onClick: () => setCurrentTab(tabNames.HISTORY)
            } }
          >
            {
              currentTab === tabNames.HISTORY
              && <HistoryTab formId={ formId } formType={ taxEntityCode } />
            }
          </SovosTabContent>
          {
            hasAttachmentPermissions &&
            taxEntityCode === recipientFchar &&
            <SovosTabContent
              className='form-tabs__tab-container'
              TabProps={ {
                label: tabNames.ATTACHMENTS,
                onClick: () => setCurrentTab(tabNames.ATTACHMENTS)
              } }
            >
              {
                currentTab === tabNames.ATTACHMENTS
                && <AttachmentTab formId={ formId } formType={ taxEntityCode } />
              }
            </SovosTabContent>
          }
        </SovosTabGroup>
      </div>
    );
  }

  return null;
};

DynamicFormInformation.propTypes = {
  formId: PropTypes.number.isRequired,
  taxEntityCode: PropTypes.string.isRequired,
  fetchingForm: PropTypes.bool.isRequired
};

export default DynamicFormInformation;
