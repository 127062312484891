//Please kill this route and the component after sso clients are migrated to sovos identity
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import auth from 'helpers/authenticationHelper';
import { post } from 'helpers/axios/axiosInterceptorsV1';
import RefreshIndicatorLoading from 'shared/refreshIndicatorLoading/refreshIndicatorLoading';

const LoginOtp = ({ actions }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const getReferenceToken = async () => {
      const getOtp = async () => {
        await actions.otpLogin();
      };
      const params = new URL(document.location).searchParams;
      const otp = params.get('otp');

      if (otp) {
        sessionStorage.clear();
        auth.storeOtpToken(otp);
        await getOtp();
      }
      trackUser();
      navigate('/post-login');
    };
    getReferenceToken();
  }, []);

  const trackUser = async () => {
    try {
      await post('/authorization/user/login', { authenticationIssuer: auth.getValueFromJwtPayload('iss') });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <RefreshIndicatorLoading style={ { backgroundColor: 'transparent' } } />
    </div>
  );
};

LoginOtp.propTypes = {
  actions: PropTypes.shape({
    otpLogin: PropTypes.func
  }).isRequired
};

export default LoginOtp;
