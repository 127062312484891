import { alphabetizeForms, the499R2Fchar, theW2Fchar, theW2VIFchar, theW2MFchar } from 'helpers/formConstants';
import { batchFileMetadataType, batchType, transmittalType, transmittalPage } from './enums';
import theme from 'mosaic-react/themes/sovos-theme';

const { palette } = theme;

const SOLICITATION_CARD_INFO = {
  PREPARE_DATA: {
    header: 'Prepare data',
    path: 'prepare-data'
  },
  REVIEW: {
    header: 'Review',
    path: 'review'
  },
  APPROVE: {
    header: 'Approve',
    path: 'approve'
  },
  IN_PROGRESS: {
    header: 'In progress',
    path: 'in-progress'
  },
  COMPLETE: {
    header: 'Complete',
    path: 'complete'
  }
};

const BATCH_STATE = {
  PREPARE_DATA: 1,
  PREVIEW: 2,
  QUEUED: 3,
  INITIALIZING: 4,
  ERROR: 5,
  CANCELLED: 6,
  COMPLETE: 7
};

const JOB_STATE = {
  READY_TO_ENQUEUE: 1,
  FAILED_TO_ENQUEUE: 2,
  QUEUED: 3,
  IN_PROGRESS: 4,
  GENERATED: 5,
  COMPLETE: 6,
  CANCELLING: 7,
  CANCELLED: 8,
  FAILED: 9,
  RUNNING: 10
};

const NOTICE_PROCESSOR_JOB_STATE = {
  NOT_STARTED: 0,
  RUNNING: 1,
  IMPORTING: 10,
  VALIDATING_IMPORT_DATA: 20,
  DEDUPLICATING_DATA: 30,
  NORMALIZING_DATA: 40,
  MATCHING_DATA_WITH_ALIAS: 50,
  RECALCULATING_TIN_STATUS: 60,
  CANCELLING: 99,
  FINISHED: 100
};

const FILE_STATE = {
  READY_TO_APPROVE: 1,
  APPROVED: 2,
  COMPLETED: 3,
  CANCELLED: 4,
  EXCEPTION: 5
};

const IRS_RESPONSE_CODE = {
  NOT_YET_PROCESSED: -1,
  GOOD_NON_RELEASED: 0,
  GOOD_RELEASED: 1,
  BAD: 2,
  CLOSED: 3,
  REPLACED: 4,
  GOOD: 5
};

const stateTransmittalStateSettingId = 17;
const federalTccSettingId = 38;
const IRS = 'IRS';
const SSA = 'SSA';

const getLocality = batch => {
  const localityFilterColumnId = 425;

  let locality = batch.Filters.find(filter => filter.ColumnId === localityFilterColumnId).Criteria;

  return locality;
};

const getEvent = (state, event) => state
  ? {
    key: event.name,
    color: event.color,
    user: state.UserId,
    date: state.CreatedOn
  }
  : { key: event.name };

const hasCustomTransmitter = batch =>
  batch.Settings.some(setting => setting.SettingId === federalTccSettingId && setting.Value !== 'SOVOSXMIT');

const isDocGenTransmitter = taxEntityType => [theW2Fchar, the499R2Fchar, theW2VIFchar, theW2MFchar].includes(taxEntityType);

const isSSATransmitter = batch => batch.TaxEntityTypes?.some(() => batch.BatchType === batchType.DocGenFederalTransmittal);

const isIRSOrSSA = regime => [IRS, SSA].includes(regime);

const isRegimeFederal = batch => (isIRSOrSSA(batch.Regime) && (batch.BatchType === batchType.FederalTransmittal || batch.BatchType === batchType.DocGenFederalTransmittal));

const getRecordsByBatchType = (batchTypeTransmittal, file, controlTotals) => batchTypeTransmittal === batchType.extensionOfTimeTransmittal ? controlTotals["0"] : file.Records;

const getRecordsTransmittedByBatchType = (batch, controlTotals) => batch.BatchType === batchType.extensionOfTimeTransmittal ? controlTotals["0"] : getNumberOfRecords(batch.Files);

const getNonDeletedFiles = solicitation => solicitation.Batch.Files.filter(file => !file.IsDeleted);

const stateOrFederal = batch => isRegimeFederal(batch)
  ? transmittalPage.FEDERAL
  : ((batch.BatchType === batchType.StateTransmittal)
    ? transmittalPage.STATE
    : transmittalPage.LOCALITY);

const getBatchType = type => {
  switch (type) {
    case batchType.StateTransmittal:
      return transmittalPage.STATE;
    case batchType.FederalTransmittal:
    case batchType.DocGenFederalTransmittal:
      return transmittalPage.FEDERAL;
    case batchType.localityTransmittal:
      return transmittalPage.LOCALITY;
    case batchType.extensionOfTimeTransmittal:
      return transmittalPage.EXTENSIONOFTIME;
    default:
      return '';
  }
};

const preprocessTransmittalBatches = batch => {
  const regimeOrState = `${batch.Name === transmittalType.STATE
    ? batch.Settings.find(setting => setting.SettingId === stateTransmittalStateSettingId)?.Value
    : (batch.Name === transmittalType.LOCALITY
      ? 'Locality'
      : (batch.Name === transmittalType.EOT
        ? 'Extension of time'
        : (isDocGenTransmitter(batch.TaxEntityTypes[0])
          ? SSA
          : IRS
        )))}`;

  const eventMeta = {
    created: { name: 'Transmittal Created', color: palette.primary.dark },
    approved: { name: 'Transmittal Approved', color: palette.grey[400] },
    completed: { name: 'Transmittal Completed', successColor: palette.success.main, rejectColor: palette.error.main }
  };

  const createdState = batch.States.find(state => state.StateType === BATCH_STATE.INITIALIZING || state.StateType === BATCH_STATE.PREPARE_DATA);
  const createdEvent = getEvent(createdState, eventMeta.created);

  const approvedState = batch.Files[0]?.States.find(state => state.StateType === FILE_STATE.APPROVED && !state.StatusDetail);
  const approvedEvent = getEvent(approvedState, eventMeta.approved);

  let completedEvent;
  if (regimeOrState === IRS || isSSATransmitter(batch)) {
    const allFilesCompleted = batch.Files.length && batch.Files.every(file => file.States?.some(state => state.StateType === FILE_STATE.COMPLETED));
    if (allFilesCompleted) {
      const completedState = batch.Files[0]?.States.find(state => state.StateType === FILE_STATE.COMPLETED);
      const irsStatusDetails = JSON.parse(completedState.StatusDetail);
      completedEvent = {
        key: eventMeta.completed.name,
        color: irsStatusDetails?.IRSResponse === IRS_RESPONSE_CODE.BAD ? eventMeta.completed.rejectColor : eventMeta.completed.successColor,
        user: hasCustomTransmitter(batch) || isSSATransmitter(batch) ? completedState.UserId : IRS,
        date: completedState.CreatedOn
      };
    } else {
      completedEvent = { key: eventMeta.completed.name };
    }
  } else {
    const completedState = batch.Files[0]?.States.find(state => state.StateType === FILE_STATE.COMPLETED);
    completedEvent = completedState
      ? {
        key: eventMeta.completed.name,
        color: eventMeta.completed.successColor,
        user: completedState.UserId,
        date: completedState.CreatedOn
      }
      : { key: eventMeta.completed.name };
  }

  const stateTransmittalFilingStatusSettingId = 18;
  const federalTransmittalFilingStatusSettingId = 26;
  const localityTransmittalFilingStatusSettingId = 101;

  const filingStatus = () => {
    switch (batch.Name) {
      case (transmittalType.STATE):
        return batch.Settings.find(setting => setting.SettingId === stateTransmittalFilingStatusSettingId)?.Value;
      case (transmittalType.FEDERAL):
        return batch.Settings.find(setting => setting.SettingId === federalTransmittalFilingStatusSettingId)?.Value;
      case (transmittalType.LOCALITY):
        return batch.Settings.find(setting => setting.SettingId === localityTransmittalFilingStatusSettingId)?.Value;
      default:
        return 'Original';
    }
  };

  const sortTaxEntityTypes = taxEntityTypes => [...taxEntityTypes].sort(alphabetizeForms);

  const StateAndLocality = regimeOrState === 'Locality'
    ? getLocality(batch)
    : '';

  return { ...batch, events: [createdEvent, approvedEvent, completedEvent], regimeOrState, filingStatus: filingStatus(), TaxEntityTypes: sortTaxEntityTypes(batch.TaxEntityTypes), StateAndLocality };
};

const preprocessPrintBatches = batch => {
  const eventMeta = {
    dataBatched: { name: 'Data batched', color: palette.primary.light },
    dataApproved: { name: 'Data approved', color: palette.primary.main },
    fileGenerated: { name: 'File generated', color: palette.primary.dark },
    fileApproved: { name: 'File approved', color: palette.grey[400] },
    completed: { name: 'Statements mailed', color: palette.success.main }
  };

  const dataBatchedState = batch.States.find(state => state.StateType === BATCH_STATE.PREPARE_DATA);
  const dataBatchedEvent = getEvent(dataBatchedState, eventMeta.dataBatched);

  const dataApprovedState = batch.States.find(state => state.StateType === BATCH_STATE.PREVIEW);
  const dataApprovedEvent = getEvent(dataApprovedState, eventMeta.dataApproved);

  const fileGeneratedState = batch.States.find(state => state.StateType === BATCH_STATE.QUEUED);
  const fileGeneratedEvent = getEvent(fileGeneratedState, eventMeta.fileGenerated);

  const fileApprovedState = batch.Files[0]?.States.find(state => state.StateType === FILE_STATE.APPROVED);
  const fileApprovedEvent = getEvent(fileApprovedState, eventMeta.fileApproved);

  const completedState = batch.Files[0]?.States.find(state => state.StateType === FILE_STATE.COMPLETED);
  const completedEvent = getEvent(completedState, eventMeta.completed);

  return { ...batch, events: [dataBatchedEvent, dataApprovedEvent, fileGeneratedEvent, fileApprovedEvent, completedEvent] };
};

const preprocessSolicitationBatches = solicitation => {
  const eventMeta = {
    started: { name: 'Solicitations Started', color: palette.primary.light },
    reviewed: { name: 'Solicitations Reviewed', color: palette.primary.main },
    created: { name: 'Solicitation Print File Created', color: palette.primary.dark },
    approved: { name: 'Solicitation Print File Approved', color: palette.grey.A700 },
    completed: { name: 'Solicitation Print File Completed', color: palette.success.main }
  };

  const currentBatchState = sortByDatePropertyDescending(solicitation.Batch.States, 'CreatedOn')[0].StateType;
  const nonDeletedFiles = getNonDeletedFiles(solicitation);

  const dataBatchedState = solicitation.Batch.States.find(state => state.StateType === BATCH_STATE.PREPARE_DATA);
  const dataBatchedEvent = getEvent(dataBatchedState, eventMeta.started);

  const dataReviewedState = BATCH_STATE.PREVIEW <= currentBatchState && solicitation.Batch.States.find(state => state.StateType === BATCH_STATE.PREVIEW);
  const dataReviewedEvent = getEvent(dataReviewedState, eventMeta.reviewed);

  const fileGeneratedState = BATCH_STATE.QUEUED <= currentBatchState && solicitation.Batch.States.find(state => state.StateType === BATCH_STATE.QUEUED);
  const fileGeneratedEvent = getEvent(fileGeneratedState, eventMeta.created);

  const fileApprovedState = nonDeletedFiles[0]?.States.find(state => state.StateType === FILE_STATE.APPROVED);
  const fileApprovedEvent = getEvent(fileApprovedState, eventMeta.approved);

  const completedState = nonDeletedFiles[0]?.States.find(state => state.StateType === FILE_STATE.COMPLETED) ||
    solicitation.Batch.States.find(state => state.StateType === BATCH_STATE.COMPLETE);
  const completedEvent = getEvent(completedState, eventMeta.completed);

  return { ...solicitation, events: [dataBatchedEvent, dataReviewedEvent, fileGeneratedEvent, fileApprovedEvent, completedEvent] };
};

const FAILED_BATCH_STATES = [BATCH_STATE.ERROR, BATCH_STATE.CANCELLED];

const FAILED_JOB_STATES = [JOB_STATE.FAILED_TO_ENQUEUE, JOB_STATE.CANCELLING, JOB_STATE.CANCELLED, JOB_STATE.FAILED];

const FAILED_FILE_STATES = [FILE_STATE.CANCELLED, FILE_STATE.EXCEPTION];

const stateTestTransmitSettingId = 19;

const federalTestTransmitSettingId = 27;

const batchFailed = batch => (
  batch.States?.some(state => FAILED_BATCH_STATES.includes(state.StateType)) ||
  batch.Jobs?.some(job => FAILED_JOB_STATES.includes(job.StateType)) ||
  batch.Files?.some(files => files.States.some(state => FAILED_FILE_STATES.includes(state.StateType))) ||
  batch.Settings?.find(setting => setting.SettingId === stateTestTransmitSettingId || setting.SettingId === federalTestTransmitSettingId)?.Value === 'true'
);

const ALL_FORMS = 'All forms';
const ALL_EXCEPTIONS = 'All exceptions';
const EXTENSION = 'Extension';

const ALL_REGIMES = 'All regimes';
const STATE = 'State';
const LOCALITY = 'Locality';
const regimes = [ALL_REGIMES, IRS, SSA, STATE, LOCALITY];
const regimeConversion = {
  'IRS': 'Federal Transmittal',
  'State': 'State Transmittal'
};

const ALL_FILING_STATUSES = 'All filing statuses';
const ORIGINAL = 'Original';
const CORRECTION = 'Correction';
const filingStatuses = [ALL_FILING_STATUSES, ORIGINAL, CORRECTION];
const confirmationNumbers = [
  batchFileMetadataType.StateConfirmationCode,
  batchFileMetadataType.FederalConfirmationCode,
  batchFileMetadataType.LocalityConfirmationcode,
  batchFileMetadataType.ExtensionOfTimeConfirmationCode
];

const filterByForms = (formFilter, transmittalBatches) => {
  switch (formFilter) {
    case ALL_FORMS:
      return transmittalBatches;
    case EXTENSION:
      return transmittalBatches.filter(batch => batch.BatchType === batchType.extensionOfTimeTransmittal);
    default:
      return transmittalBatches.filter(batch => batch.TaxEntityTypes.includes(formFilter));
  }
};

const filterTransmittalBatches = (formFilter, regimeFilter, filingStatusFilter, searchText, transmittalBatches) => {
  const filteredByForms = filterByForms(formFilter, transmittalBatches);
  const filteredByRegime = regimeFilter === ALL_REGIMES
    ? filteredByForms
    : filteredByForms.filter(batch => regimeFilter === STATE ? batch.Name === regimeConversion[regimeFilter] : batch.regimeOrState === regimeFilter);
  const filteredByFilingStatus = filingStatusFilter === ALL_FILING_STATUSES
    ? filteredByRegime
    : filteredByRegime.filter(batch => batch.filingStatus === filingStatusFilter);

  const filterBySearch = (batches, text) => {
    if (!text) {
      return batches;
    }
    const textRegex = new RegExp(text, 'i');
    return batches.filter(batch => (
      textRegex.test(batch.Settings.find(setting => setting.SettingId === stateTransmittalStateSettingId)?.Value) ||
      textRegex.test(batch.BatchNumber.toString().padStart(4, '0'))
    ));
  };

  return filterBySearch(filteredByFilingStatus, searchText);
};

const sortByDatePropertyDescending = (array, propertyName) =>
  array?.sort((current, next) => {
    current = new Date(current[propertyName]);
    next = new Date(next[propertyName]);
    return current > next ? -1 : current < next ? 1 : 0;
  }) || [];

const getNumberOfRecords = files => files.length ? files.reduce((total, { Records }) => ( total + Records ), 0) : 0;

const oneFileConfirmationStateOrSSA = file => file.Metadata?.find(metadatum => confirmationNumbers.includes(metadatum.MetadataTypeId))?.MetadataValue;

const getConfirmationNumber = metadata => metadata ? oneFileConfirmationStateOrSSA({ Metadata: metadata }) : '';

const getTransmittedTo = taxEntityType => `Transmitted to ${isDocGenTransmitter(taxEntityType)
  ? SSA
  : IRS}`;

const isBatchGeneratedByDocGen = type => type === batchType.DocGenFederalTransmittal;

const getRegimeName = type =>{
  switch (type) {
    case batchType.StateTransmittal:
      return transmittalPage.STATE;
    case batchType.DocGenFederalTransmittal:
      return 'ent-docgen-federal';
    case batchType.localityTransmittal:
      return transmittalPage.LOCALITY;
    case batchType.extensionOfTimeTransmittal:
      return transmittalPage.EXTENSIONOFTIME;
    default:
      return '';
  }
};

export {
  SOLICITATION_CARD_INFO,
  BATCH_STATE,
  JOB_STATE,
  NOTICE_PROCESSOR_JOB_STATE,
  FILE_STATE,
  IRS_RESPONSE_CODE,
  hasCustomTransmitter,
  preprocessTransmittalBatches,
  preprocessPrintBatches,
  preprocessSolicitationBatches,
  batchFailed,
  ALL_FORMS,
  ALL_EXCEPTIONS,
  EXTENSION,
  ALL_REGIMES,
  regimes,
  ALL_FILING_STATUSES,
  ORIGINAL,
  CORRECTION,
  filingStatuses,
  filterTransmittalBatches,
  sortByDatePropertyDescending,
  getNumberOfRecords,
  isIRSOrSSA,
  stateOrFederal,
  oneFileConfirmationStateOrSSA,
  getConfirmationNumber,
  getTransmittedTo,
  isBatchGeneratedByDocGen,
  getRegimeName,
  isDocGenTransmitter,
  getBatchType,
  getRecordsByBatchType,
  getRecordsTransmittedByBatchType,
  getNonDeletedFiles
};
