import { connect } from 'react-redux';
import RecordStatus from './RecordStatus';

const mapStateToProps = (state, ownProps) => ({
  trantype: state.dynamicForm.form[ownProps.taxEntityCode]?.TRANTYPE,
  fcharToPreview: state.dynamicForm.fcharToPreview,
  states: state.dynamicForm.form[ownProps.taxEntityCode]?.STATES,
  localities: state.dynamicForm.form[ownProps.taxEntityCode]?.LOCALITIES
});

export default connect(mapStateToProps, null)(RecordStatus);
