import actionTypes from './TransmittalENTConstants';

const initialState = {
  transmittalData: [],
  fileMetadataUpdating: [],
  refreshingTransmittalData: false,
  fetchingControlTotalsSummary: false,
  controlTotalsSummary: {}
};

export default function TransmittalENTReducer(state = initialState, action) {
  switch (action.type) {

    case actionTypes.UPDATING_CONFIRMATION_CODE:
      const newMetadataUpdatingArray = [...state.fileMetadataUpdating, action.fileId];
      return { ...state, fileMetadataUpdating: newMetadataUpdatingArray };

    case actionTypes.CONFIRMATION_CODE_UPDATED:
      const arrayToModify = [...state.fileMetadataUpdating];
      const modifiedArray = arrayToModify.filter(file => file !== action.fileId);
      return { ...state, fileMetadataUpdating: modifiedArray };

    case actionTypes.REFRESHING_TRANSMITTAL_DATA:
      return { ...state, refreshingTransmittalData: true };

    case actionTypes.SET_TRANSMITTAL_DATA:
      return { ...state, transmittalData: action.transmittalData };

    case actionTypes.REFRESH_TRANSMITTAL_DATA_SUCCESS:
      return { ...state, transmittalData: action.transmittalData, refreshingTransmittalData: false };

    case actionTypes.REFRESH_TRANSMITTAL_DATA_FAIL:
      return { ...state, refreshingTransmittalData: false };

    case actionTypes.FETCHING_CONTROL_TOTALS_SUMMARY:
      return { ...state, fetchingControlTotalsSummary: true };

    case actionTypes.FETCHING_CONTROL_TOTALS_SUMMARY_FAIL:
      return { ...state, fetchingControlTotalsSummary: false };

    case actionTypes.FETCHING_CONTROL_TOTALS_SUMMARY_SUCCESS:
      return { ...state, fetchingControlTotalsSummary: false, controlTotalsSummary: action.summary };

    case actionTypes.CLEAR_TRANSMITTAL_DATA:
      return { ...state, controlTotals: {}, controlTotalsSummary: {}, transmittalData: [] };

    default:
      return state;
  }
}
