import { useState } from 'react';

const defaultItemsPerPageOptions = [15, 25, 50, 100];

const usePaginationForFetch = (totalItems = 0, defaultItemsPerPage = 15) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage);

  const resetCurrentPage = () => setCurrentPage(1);

  const handlePerPageChanged = ({ target: { value } }) => {
    setItemsPerPage(value);
    setCurrentPage(1);
  };

  const handlePageChanged = (page, itemsPerPage) => {
    setItemsPerPage(itemsPerPage);
    setCurrentPage(page);
  };

  const handlePrevClicked = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClicked = () => {
    if (currentPage < totalItems / itemsPerPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDirectPageChange = pageNumber => {
    setCurrentPage(pageNumber);
  };

  return {
    defaultItemsPerPageOptions,
    currentPage,
    itemsPerPage,
    resetCurrentPage,
    handlePerPageChanged,
    handlePageChanged,
    handlePrevClicked,
    handleNextClicked,
    handleDirectPageChange
  };
};

export default usePaginationForFetch;