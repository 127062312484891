import {
  TIR_U_RECORD_PAYER,
  TIR_U_RECORD_PAYEE,
  TIR_U_RECORD_NON_WITHHOLDING,
  TIR_U_RECORD_NON_TAX_FIELDS
} from 'helpers/permissionConstants';
import { noFormsFchars } from 'helpers/formConstants';
import authenticationHelper from 'helpers/authenticationHelper';

export const getConditionToDisableSave = ({
  fetchingIdentityManagement = false,
  saveDisableByValidation = false,
  checkingDuplicatedValuesInFields = false,
  areUniqueFieldsDuplicated = false,
  newForm,
  formModified = true,
  payerRecipientSelected = true,
  taxEntityCode,
  savingForm
}) => savingForm ||
    fetchingIdentityManagement ||
    saveDisableByValidation ||
    !authenticationHelper.checkSomeUserPermissions([TIR_U_RECORD_PAYER, TIR_U_RECORD_PAYEE, TIR_U_RECORD_NON_WITHHOLDING, TIR_U_RECORD_NON_TAX_FIELDS]) ||
    checkingDuplicatedValuesInFields ||
    areUniqueFieldsDuplicated ||
    (
      noFormsFchars.includes(taxEntityCode) || !newForm
        ? !formModified
        : !payerRecipientSelected
    );

export const areUniqueFieldsDuplicated = fieldsInUseList => {
  switch (fieldsInUseList.length) {
    case 0: return false;
    case 1: return !fieldsInUseList.includes('TIN');
    default: return true;
  }
};
