import { useEffect, useState } from 'react';
import { arrayOf, bool, func, number, object, oneOfType, shape, string } from 'prop-types';
import AmountDifferenceFieldForm from 'shared/standardInput/textField/AmountDifferenceFieldForm';
import SelectDifferenceFieldForm from 'shared/standardInput/selectField/SelectDifferenceFieldForm';
import MultipleRows from '../shared/multipleRows/MultipleRows';
import './codedPayments.scss';

const PCODE = 'PCode';
const PAMOUNT = 'PAmount';
const CODE = 'Code';
const AMOUNT = 'Amount';
const limit = 10;

const cleanCodedPayments = cPayments => cPayments
  .filter(cPayments => cPayments[CODE])
  .map(cPayments => ({
    [CODE]: cPayments.Code,
    [AMOUNT]: cPayments.Amount
  }));

const addButtonLabelText = '+ Add additional value';
const errorAddButtonLabelText = 'The maximum number of values for Box 12 is 10';

const CodedPayments = ({
  codedPayments,
  previousValue,
  formActions,
  options,
  readOnlyUser,
  showPreviousDifferentFields,
  isClone,
  runUIValidationAutomatically,
  taxEntityCode
}) => {
  const [localCodedPayments, setLocalCodedPayments] = useState(() => codedPayments.length
    ? [...codedPayments
      .map((cPayments, id) => {
        const previousCodePayment = previousValue.find(previousCPayment => previousCPayment[CODE] === cPayments[CODE]) || {};

        return {
          Id: id + 1,
          [CODE]: cPayments[CODE],
          [AMOUNT]: cPayments[AMOUNT],
          [PCODE]: previousCodePayment[CODE],
          [PAMOUNT]: previousCodePayment[AMOUNT]
        };
      })
    ]
    : []);

  useEffect(() => {
    if (shouldSave(codedPayments, localCodedPayments)){
      const completedRows = cleanCodedPayments(localCodedPayments);
      formActions.setFieldValue('CODEDPAYMENTS', completedRows, isClone, taxEntityCode);
    }
  }, [localCodedPayments, formActions, isClone]);

  const shouldSave = (codedPayments, localCodedPayments) => {
    if (codedPayments.length !== localCodedPayments.length) {
      return true;
    }

    return !codedPayments.every(cPayment =>
      localCodedPayments.find(lCodedPayment =>
        cPayment[CODE] === lCodedPayment[CODE]
        && cPayment[AMOUNT] === lCodedPayment[AMOUNT]
      )
    );
  };

  const codedOptions = Object
    .values(options)
    .map(option => ({ text: option, value: option }));

  const handleAddCodedPayment = () => {
    const newCodedPayments = [
      ...localCodedPayments,
      {
        Id: (localCodedPayments.at(-1)?.Id || 0) + 1,
        [CODE]: '',
        [AMOUNT]: ''
      }
    ];

    setLocalCodedPayments(newCodedPayments);
  };

  const handleDeleteCodedPayment = id => setLocalCodedPayments(prevState => prevState.filter(record => record.Id !== id));

  const handleChange = (key, value, id) =>
    setLocalCodedPayments(prevState => prevState.map(
      codedPaymentInfo => codedPaymentInfo.Id === id
        ? { ...codedPaymentInfo, [key]: value }
        : codedPaymentInfo)
    );

  const handleDisabledOption = coded => localCodedPayments.some(cPayment => cPayment.Code === coded);

  const isAtLimit = localCodedPayments.length === limit;

  const hasCodeTransmitted = cPayment => Boolean(cPayment[PCODE]);

  return (
    <div className='coded-payments__container'>
      <MultipleRows
        onAddRow={ handleAddCodedPayment }
        showAddButton={ !readOnlyUser }
        addButtonProps={ {
          label: isAtLimit ? errorAddButtonLabelText : addButtonLabelText,
          disabled: isAtLimit
        } }
      >
        {
          localCodedPayments.map(cPayment => (
            <MultipleRows.Row
              key={ cPayment.Id }
              onDeleteRow={ handleDeleteCodedPayment }
              row={ cPayment }
              showDeleteButton={ !readOnlyUser && !hasCodeTransmitted(cPayment) }
            >
              <SelectDifferenceFieldForm
                boxName='12'
                label='Coded payments'
                name={ `${CODE}-${cPayment.Id}` }
                options={ codedOptions }
                value={ cPayment[CODE] }
                previousValue={ cPayment[PCODE] }
                onChange={ (_, value) => handleChange(CODE, value, cPayment.Id) }
                onDisabledOption={ handleDisabledOption }
                showPreviousDifferentFields={ showPreviousDifferentFields && hasCodeTransmitted(cPayment) }
                readOnlyUser={ readOnlyUser }
                runUIValidationAutomatically={ runUIValidationAutomatically }
                customClasses={ { container: 'coded-payments__select-difference-container' } }
                disabled={ hasCodeTransmitted(cPayment) }
              />
              <AmountDifferenceFieldForm
                name={ `${AMOUNT}-${cPayment.Id}` }
                hideLabel
                value={ cPayment[AMOUNT] }
                previousValue={ cPayment[PAMOUNT] }
                onChange={ (_, value) => handleChange(AMOUNT, value, cPayment.Id) }
                showPreviousDifferentFields= { showPreviousDifferentFields && hasCodeTransmitted(cPayment) }
                readOnlyUser={ readOnlyUser }
                runUIValidationAutomatically={ runUIValidationAutomatically }
                customClasses={ { amountDifferentFields: 'coded-payments__amount-different-fields' } }
                mask='CURRENCY'
              />
            </MultipleRows.Row>
          ))
        }
      </MultipleRows>
    </div>
  );
};

CodedPayments.propTypes = {
  codedPayments: arrayOf(shape({
    [CODE]: string,
    [AMOUNT]: oneOfType([string, number])
  })).isRequired,
  options: object,
  readOnlyUser: bool,
  showPreviousDifferentFields: bool,
  runUIValidationAutomatically: bool,
  isClone: bool.isRequired,
  formActions: shape({
    setFieldValue: func.isRequired
  }).isRequired,
  previousValue: arrayOf(shape({
    [CODE]: string,
    [AMOUNT]: oneOfType([string, number])
  })),
  taxEntityCode: string.isRequired
};

CodedPayments.defaultProps = {
  options: {},
  readOnlyUser: false,
  showPreviousDifferentFields: false,
  runUIValidationAutomatically: false,
  previousValue: []
};

export default CodedPayments;
