import { createElement } from 'react';
import PropTypes from 'prop-types';
import SovosReadOnlySlidingPanel from 'mosaic-react/sovos-readonly-sliding-panel';
import Failed from './failed/FailedConnector';
import Errors from './errors/ErrorsConnector';
import TotalForms from './totalForms/TotalFormsConnector';
import RecordFilterEnum from './RecordFilterEnum';
import RecordTable from './recordTable/RecordTableConnector';

const records = {
  Errors,
  Failed,
  TotalForms
};

const RecordSlidingPanel = ({
  type,
  recordType,
  openRecordSlidingPanel,
  closeRecordSlidingPanel,
  cardTitle,
  job,
  isTestImport,
  filterBy
}) => (
  <SovosReadOnlySlidingPanel
    onClose={ closeRecordSlidingPanel }
    open={ openRecordSlidingPanel }
    title={ cardTitle }
  >
    {
      type
        ? (
          createElement(records[type] || RecordTable, {
            job,
            recordType,
            type,
            closeRecordSlidingPanel,
            filterBy: RecordFilterEnum[filterBy],
            fchar: recordType,
            isTestImport
          })
        )
        : <div>{ cardTitle } sliding panel info goes here</div>
    }
  </SovosReadOnlySlidingPanel>
);

RecordSlidingPanel.propTypes = {
  type: PropTypes.string,
  recordType: PropTypes.string,
  isTestImport: PropTypes.bool,
  cardTitle: PropTypes.string,
  filterBy: PropTypes.oneOf(['', 'all', 'print', 'transmittal']),
  job: PropTypes.number,
  closeRecordSlidingPanel: PropTypes.func.isRequired,
  openRecordSlidingPanel: PropTypes.bool.isRequired
};

RecordSlidingPanel.defaultProps = {
  type: undefined,
  filterBy: 'all',
  cardTitle: '',
  recordType: '',
  isTestImport: false,
  job: 0
};

export default RecordSlidingPanel;
