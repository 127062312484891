import PropTypes from 'prop-types';
import { getFormName } from 'shared/dataEntryForms/sovos-dynamic-form/DynamicFormHelper';
import './sectionHeader.scss';
import classNames from 'classnames';

const sectionHeaderFieldIgnored = ['Box 12', 'Box 14', 'State', 'Locality'];

const SectionHeader = ({ title, subtitle, isCustomCorrection, classes }) => (
  <div className= { classNames( "section-header", classes.sectionHeader) } data-for={ title }>
    <h6 className="section-header__title">{ getFormName(title, isCustomCorrection && !sectionHeaderFieldIgnored.includes(title)) }</h6>
    <p className="section-header__subtitle">{ subtitle }</p>
  </div>
);

SectionHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isCustomCorrection: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string)
};

SectionHeader.defaultProps = {
  title: '',
  subtitle: '',
  isCustomCorrection: false,
  classes: {}
};

export default SectionHeader;