import { Component } from 'react';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import SovosTooltip from '../../sovos-tooltip/SovosTooltip';
import './selectField.scss';

const defaultSelectStyle = {
  width: '100%',
  height: 50,
  border: 'none',
  fontSize: 12
};

const getInitialValue = ({ value, multiple, options }) => {
  if (multiple) {
    return value || [''];
  }

  if (!multiple) {
    const optionValues = options.map(option => option.value);
    if (optionValues.includes(value)) {
      return value;
    }
    const defaultValue = options.find(option => option.default)?.value || '';
    return defaultValue;
  }
};

class SelectField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: getInitialValue(props),
      showError: !this.validateValue(props)
    };
  }

  componentDidUpdate = prevProps => {
    if (this.props.value !== prevProps.value) {
      this.validateValue(this.props) && this.setState({ value: this.props.value });
    }
  };

  validateValue = props => (props.value || props.value === 0) ? props.options.some(option => option.value == props.value) : true; //eslint-disable-line eqeqeq

  handleChange = event => {
    let value = event.target.value;
    if (Array.isArray(value)){
      if (value.length > 1 && value.includes('')) {
        value = value.filter(val => val);
      }
      if (Array.isArray(value) && value.length === 0){
        value.push('');
      }
    }
    this.props.onChange(event, value, this.props.onBlur);
    this.setState({ value, showError: false });
  };

  handleOnInvalid = event => {
    event.preventDefault();
    this.setState({ showError: true });
  };

  render() {
    const {
      label,
      labelValidation,
      name,
      options,
      validation,
      multiple,
      wrapperStyle,
      selectStyle,
      readOnly,
      readOnlyUser,
      customRender
    } = this.props;

    return (
      <div style={ wrapperStyle } className="select-field__wrapper">
        { label && <label className="select-field__label" htmlFor={ name }>{ label }</label> }
        <SovosTooltip
          id={ name }
          label={ !this.validateValue(this.props) ? `Value "${this.props.value}" is invalid` : `${labelValidation} is required.` }
          show={ this.state.showError }
          place="right"
          className="tir-texfield__error"
          effect="solid"
        >
          <Select
            name={ name }
            error={ this.state.showError }
            native
            required={ validation && validation.required }
            autoWidth={ true }
            multiple={ multiple }
            style={ Object.assign({}, defaultSelectStyle, selectStyle) }
            value={ this.state.value }
            onChange={ this.handleChange }
            className="select-field__input"
            classes={ { root: 'select-field__input-root' } }
            inputProps={ { className: 'select-field__select', 'data-for': name } }
            disabled={ readOnlyUser || readOnly }
            onInvalid={ this.handleOnInvalid }
            variant='filled'
          >
            {
              (validation && validation.required)
                ? !options.find(option => option.value === 'null' || option.value === '') && <option value="" disabled>Select...</option>
                : !options.find(option => option.value === 'null' || option.value === '') && <option value="">(blank)</option>
            }
            { options.map(option => (
              <option
                value={ option.value }
                key={ option.value }
              >
                { customRender ? option.value + ' - ' + option.text : option.text }
              </option>
            )) }
          </Select>
        </SovosTooltip>
      </div>
    );
  }
}

SelectField.propTypes = {
  label: PropTypes.node,
  labelValidation: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ])
    })
  ).isRequired,
  validation: PropTypes.shape({
    pattern: PropTypes.string,
    message: PropTypes.string,
    validateOn: PropTypes.string,
    required: PropTypes.bool,
    maxLength: PropTypes.number
  }),
  multiple: PropTypes.bool,
  wrapperStyle: PropTypes.object,
  selectStyle: PropTypes.object,
  readOnly: PropTypes.bool,
  readOnlyUser: PropTypes.bool.isRequired,
  customRender: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number
  ])
};

SelectField.defaultProps = {
  errorText: '',
  value: '',
  onBlur: () => {},
  multiple: false,
  wrapperStyle: {}
};

export default SelectField;
