// TODO: In the future this file will be moved to a library.
// Please do not modify, take consideration this file is duplicated in another project
import { useContext } from 'react';
import { AutomaticLogoutContext } from './AutomaticLogoutContext';

const useAutomaticLogout = () => {
  const context = useContext(AutomaticLogoutContext);

  if (!context) {
    console.warn(`Provider context is undefined,
    please verify you are calling useAutomaticLogout() as child of a some Provider component.`);
  }

  return context;
};

export default useAutomaticLogout;
