import { connect } from 'react-redux';
import NQI from './NQI';
import { bindActionCreators } from 'redux';
import { setFieldValue, setDisabledByEntityFetcher, unsetDisabledByEntityFetcher } from '../../../sovos-dynamic-form/redux/DynamicFormActions';

const mapDispatchToProps = dispatch => ({
  dynamicFormActions: bindActionCreators({
    setFieldValue,
    setDisabledByEntityFetcher,
    unsetDisabledByEntityFetcher
  }, dispatch)
});

const mapStateToProps = ({ dynamicForm }, ownProps) => ({
  isClone: dynamicForm.isClone,
  form: dynamicForm.form[ownProps.taxEntityCode] || {}
});

export default connect(mapStateToProps, mapDispatchToProps)(NQI);
