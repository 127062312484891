const getMessage = fchar => (
  ['1', 'N'].includes(fchar)
    ? `This recipient has had one or more forms transmitted.
      Do you want to mark those forms as needing correction?
      Clicking "Mark as correction" will re-stage the corrected forms as ready to print and/or transmit.`
    : `This form has already been transmitted.
      Would you like to mark it as a correction and stage the corrected forms to be printed and/or transmitted?`
);

export default getMessage;
