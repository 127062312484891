import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import W2MultipleStates from './W2MultipleStates';
import { setFieldValue, setSaveDisableByValidation } from '../../../sovos-dynamic-form/redux/DynamicFormActions';

const mapStateToProps = (state, ownProps) => ({
  states: (state.dynamicForm.isClone ? state.dynamicForm.cloneForm : state.dynamicForm.form[ownProps.taxEntityCode])?.STATES || [],
  previousValue: state.dynamicForm.previousValueForm['STATES'] || [],
  isClone: state.dynamicForm.isClone,
  isFormTransmitted: state.dynamicForm.formTransmittedToState
});

const mapDispatchToProps = dispatch => ({
  formActions: bindActionCreators({ setFieldValue, setSaveDisableByValidation }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(W2MultipleStates);
