import sharedActions from 'redux/constants/SharedConstants';
import importActions from 'redux/import/importMapping/ImportMappingConstants';

const actionTypes = {
  SET_RULES: 'SET_RULES',
  DELETE_RULE: 'DELETE_RULE',
  CLONE_RULE: 'CLONE_RULE',
  FETCH_MAP_BY_ID_SUCCESS: importActions.FETCH_MAP_BY_ID_SUCCESS,
  ...sharedActions
};

export default actionTypes;
