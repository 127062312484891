import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import SovosCheckbox from 'mosaic-react/sovos-checkbox';
import SovosDialog from 'mosaic-react/sovos-dialog';
import SovosDialogActions from 'mosaic-react/sovos-dialog-actions';
import SovosDialogTitle from 'mosaic-react/sovos-dialog-title';
import SovosButton from 'mosaic-react/sovos-button';
import SovosTextField from 'mosaic-react/sovos-text-field';
import './clientMigrationDialog.scss';

const ClientMigrationDialog = ({
  toggleDialog,
  modalOpen,
  onSubmit
}) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid }
  } = useForm({ defaultValues: { seghash: '', formCount: '', noPrintServicesChecked: false }, mode: 'onChange' });

  const closePanel = () => {
    reset();
    toggleDialog();
  };

  const onStartMigrationClick = ({ seghash, formCount, noPrintServicesChecked }) => {
    onSubmit(seghash, formCount, noPrintServicesChecked);
    closePanel();
  };

  const seghashProps = register(
    'seghash',
    {
      required: 'Seghash is required',
      pattern: {
        value: /^([a-zA-Z0-9]){1,50}$/,
        message: 'Seghash must be letters and numbers with a maximum length of 50 characters.'
      }
    }
  );

  const formCountProps = register(
    'formCount',
    {
      required: 'Form count is required',
      pattern: {
        value: /^.([0-9]){1,10}$/,
        message: 'Form count must be a number between 0-9999999999.'
      }
    }
  );

  return (
    <SovosDialog open={ modalOpen } width='small'>
      <form onSubmit={ handleSubmit(onStartMigrationClick) }>
        <SovosDialogTitle>
          Client migration
        </SovosDialogTitle>
          Move your existing product to TIR Corporate. You will need to enter your Seghash and number of forms in order to migrate. Contact support with any issues.
        <SovosTextField
          className='client-migration__text-field'
          fullWidth
          label='Seghash'
          data-testid='seghash'
          error={ Boolean(errors.seghash) }
          helperText={ errors.seghash?.message }
          { ...seghashProps }
        />
        <SovosTextField
          fullWidth
          label='Form count'
          data-testid='form-count'
          error={ Boolean(errors.formCount) }
          helperText={ errors.formCount?.message }
          { ...formCountProps }
        />
        <Controller
          control={ control }
          name='noPrintServicesChecked'
          render={ ({ field: { value, onChange } }) => (
            <FormControlLabel
              control={
                <SovosCheckbox
                  name='noPrintServicesChecked'
                  onChange={ onChange }
                  checked={ value }
                />
              }
              label='No print services'
              classes={ {
                root: 'client-migration__checkbox-label'
              } }
            />
          ) }
        />
        <SovosDialogActions>
          <SovosButton variant='text' onClick={ closePanel }>
            Cancel
          </SovosButton>
          <SovosButton type='submit' color='primary' disabled={ !isValid }>
            Start migration
          </SovosButton>
        </SovosDialogActions>
      </form>
    </SovosDialog>
  );
};

ClientMigrationDialog.propTypes = {
  toggleDialog: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ClientMigrationDialog;
