import { useState, useEffect } from 'react';
import { post, get } from 'helpers/axios/axiosInterceptorsV1';
import { buildUrlsParams } from 'helpers/buildUrlParams';
import { TIMStatus, matchTIAandTINStatus } from './TIAtoTINStatusHelper';

const defaultEmptyStatus = { Status: '' };

const useIdentityValidation = (tin, name, tinType, onResetTin = () => null, onResetName = () => null, onResetTinType = () => null) => {
  const [isTinStatusAwaiting, setIsTinStatusAwaiting] = useState(false);
  const [isTinValidating, setIsTinValidating] = useState(false);
  const [isDmfFetching, setIsDmfFetching] = useState(false);
  const [isOfacFetching, setIsOfacFetching] = useState(false);
  const [storedTinStatus, setStoredTinStatus] = useState(defaultEmptyStatus);
  const [tiaTinStatus, setTiaTinStatus] = useState(defaultEmptyStatus);
  const [legacyStatusAndTiaStatusMatch, setLegacyStatusAndTiaStatusMatch] = useState(false);
  const [dmfResult, setDmfResult] = useState('');
  const [ofacResult, setOfacResult] = useState(defaultEmptyStatus);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) {
      return;
    }
    setTiaTinStatus(defaultEmptyStatus);
    setOfacResult(defaultEmptyStatus);
    onResetName();
  }, [name]);

  useEffect(() => {
    if (!mounted) {
      return;
    }
    setDmfResult('');
    setTiaTinStatus(defaultEmptyStatus);
    onResetTin();
  }, [tin]);

  useEffect(() => {
    if (!mounted) {
      return;
    }
    setTiaTinStatus(defaultEmptyStatus);
    if (tin && name) {
      onResetTinType();
    }
  }, [tinType]);

  const convertTinType = type => {
    switch (type) {
      case '1':
        return 'E';
      case '2':
        return 'S';
      default:
        return 'U';
    }
  };

  const getTinStatus = async modrecno => {
    setIsTinStatusAwaiting(true);
    setStoredTinStatus(defaultEmptyStatus);
    try {
      const response = await get(
        `tax-identity/tin-matching/tin-status/${modrecno}`
      );
      setStoredTinStatus({ Status: response.data } );
      return TIMStatus[response.data];
    } catch (error) {
      throw (error);
    } finally {
      setIsTinStatusAwaiting(false);
    }
  };

  const runTinValidation = async values => {
    setIsTinValidating(true);
    setTiaTinStatus(defaultEmptyStatus);
    try {
      const response = await post(
        `identity/tin?${buildUrlsParams({ wait: true })}`,
        {
          Name: name ? name.toUpperCase().trim() : values.name.toUpperCase().trim(),
          Tin: tin ? tin : values.tin,
          TinType: convertTinType(tinType)
        }
      );
      setLegacyStatusAndTiaStatusMatch(matchTIAandTINStatus(storedTinStatus.Status, response.data.data.Status));
      setTiaTinStatus(response.data.data);
    } catch (error) {
      throw (error);
    } finally {
      setIsTinValidating(false);
    }
  };

  const runOfacCheck = async values => {
    setIsOfacFetching(true);
    setOfacResult(defaultEmptyStatus);
    try {
      const response = await post(`identity/ofac`,
        {
          name: name ? name.toUpperCase().trim() : values.name.toUpperCase().trim()
        }
      );
      setOfacResult(response.data.data);
    } catch (error) {
      throw (error);
    } finally {
      setIsOfacFetching(false);
    }
  };

  const runDmfCheck = async values => {
    setIsDmfFetching(true);
    setDmfResult('');
    try {
      const response = await post(`identity/dmf`,
        {
          tin: tin ? tin : values.tin
        });
      setDmfResult(response.data.data);
    } catch (error) {
      throw (error);
    } finally {
      setIsDmfFetching(false);
    }
  };

  return ({
    getTinStatus,
    runTinValidation,
    runOfacCheck,
    runDmfCheck,
    storedTinStatus,
    tiaTinStatus,
    ofacResult,
    dmfResult,
    isTinStatusAwaiting,
    isTinValidating,
    isDmfFetching,
    isOfacFetching,
    legacyStatusAndTiaStatusMatch
  });
};

export default useIdentityValidation;