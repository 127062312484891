export default {
  ObjectKeysToUppercase: obj => {
    const formattedObject = {};
    Object.keys(obj).forEach(key => {
      formattedObject[key.toUpperCase()] = obj[key];
    });
    return formattedObject;
  },
  ObjectValuesToUppercase: obj => {
    let formattedObject = {};
    Object.keys(obj).forEach(key => formattedObject[key] = obj[key].toUpperCase());
    return formattedObject;
  }
};
