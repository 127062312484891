import authenticationHelper from 'helpers/authenticationHelper';
import { WITHHOLDING_FORMS } from 'helpers/configurationConstants';

const forms1099 = [
  {
    fChar: '0',
    label: 'Payer data'
  },
  {
    fChar: '1',
    label: 'Recipient data'
  },
  {
    fChar: 'N',
    label: '1042-S recipients'
  },
  {
    fChar: 'F',
    label: '1042-S'
  },
  {
    fChar: '8',
    label: '1098'
  },
  {
    fChar: 'A',
    label: '1099-A'
  },
  {
    fChar: 'B',
    label: '1099-B'
  },
  {
    fChar: 'C',
    label: '1099-C'
  },
  {
    fChar: 'D',
    label: '1099-DIV'
  },
  {
    fChar: 'I',
    label: '1099-INT'
  },
  {
    fChar: 'X9K',
    label: '1099-K'
  },
  {
    fChar: 'M',
    label: '1099-MISC'
  },
  {
    fChar: 'X9N',
    label: '1099-NEC'
  },
  {
    fChar: 'O',
    label: '1099-OID'
  },
  {
    fChar: 'R',
    label: '1099-R'
  },
  {
    fChar: 'S',
    label: '1099-S'
  },
  {
    fChar: '321',
    label: '3921'
  },
  {
    fChar: '322',
    label: '3922'
  },
  {
    fChar: '6',
    label: '499R-2'
  },
  {
    fChar: '5',
    label: '5498'
  },
  {
    fChar: 'W',
    label: 'W-2'
  },
  {
    fChar: 'G',
    label: 'W-2G'
  },
  {
    fChar: 'WVI',
    label: 'W-2VI'
  },
  {
    fChar: 'W2M',
    label: 'W-2M'
  }
];

const withholdingForms = [
  {
    fChar: '945',
    label: '945'
  },
  {
    fChar: '1042',
    label: '1042'
  }
];

const [withholdingFormsEnabled] = authenticationHelper.checkConfiguration([WITHHOLDING_FORMS]);

export const forms = withholdingFormsEnabled
  ? forms1099.concat(withholdingForms)
  : forms1099;