import { useState, useEffect, Suspense, lazy } from 'react';
import { get } from 'helpers/axios/axiosInterceptorsV1';
import { withSnackbar } from 'enhancers';
import { getErrorMessage } from 'helpers/errorMessageHelper';
import RefreshIndicatorLoading from 'shared/refreshIndicatorLoading/refreshIndicatorLoading';
import PropTypes from 'prop-types';
import AttachmentList from './attachmentList/AttachmentList';
import SovosReadOnlySlidingPanel from 'mosaic-react/sovos-readonly-sliding-panel';
import LinearProgress from '@mui/material/LinearProgress';
import { mediaTypes } from 'shared/fileUpload/helper/helper';
import './attachmentsTab.scss';
const PdfPreview = lazy(() => import('shared/pdfPreview/PdfPreview'));

const AttachmentsTab = ({ formType, formId, showErrorSnackbar }) => {
  const [attachments, setAttachments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openSlidingPanel, setOpenSlidingPanel] = useState(false);
  const [fileSelected, setFileSelected] = useState({});

  useEffect(() => {
    let isMounted = true;

    const fetchAttachments = async () => {
      setIsLoading(true);
      try {
        const { data } = await get(`/record-activity/${formType}-${formId}/attachments`);

        if (isMounted) {
          setAttachments(data);
        }
      } catch (error) {
        showErrorSnackbar({ message: getErrorMessage(error) });
      }

      if (isMounted) {
        setIsLoading(false);
      }
    };

    fetchAttachments();

    return () => isMounted = false;
  }, []);

  const handleClick = file => {
    setFileSelected(file);
    setOpenSlidingPanel(true);
  };

  return (
    isLoading
      ? <div className="attachments-tab__loading">
        <RefreshIndicatorLoading size={ 40 } containerStyle={ { position: 'relative' } } />
      </div>
      : attachments.length
        ? <div className="attachments-tab__attachment-div">
          {
            attachments.map(attachment =>
              <AttachmentList
                key={ attachment.Id }
                fileName={ attachment.FileName }
                description={ attachment.Description }
                createdOn={ attachment.CreatedOn }
                userName={ attachment.UserName }
                fileId={ attachment.FileId }
                onClick={ handleClick }
              />
            )
          }
          <SovosReadOnlySlidingPanel
            open={ openSlidingPanel }
            onClose={ () => setOpenSlidingPanel(false) }
            title={ fileSelected.fileName }
          >
            <Suspense fallback={ <LinearProgress/> }>
              <PdfPreview
                fileId={ fileSelected.fileId }
                showTypeHead={ false }
                convertTo={ mediaTypes.pdf }
                allowRotate={ true }
              />
            </Suspense>
          </SovosReadOnlySlidingPanel>
        </div>
        : <div className="attachments-tab__empty">
          There are no attachments yet
        </div>
  );
};

AttachmentsTab.propTypes = {
  formId: PropTypes.number.isRequired,
  formType: PropTypes.string.isRequired,
  showErrorSnackbar: PropTypes.func.isRequired
};

export default withSnackbar(AttachmentsTab);
