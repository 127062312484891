import ErrorBoundary from './ErrorBoundary';
import SovosPageLayoutNext from 'mosaic-react/sovos-page-layout-next';
import SovosPageContentGeneric from 'mosaic-react/sovos-page-content-generic';

const TirPageLayout = ({ children, excludeContentLayer, slots, ...otherProps }) => {

  const renderActions = () => {
    if (slots?.actions || slots?.actions.length) {
      return (
        <div className='action-buttons-container'>
          {
            slots?.actions
          }
        </div>
      );
    }

    return null;
  };

  return (
    <SovosPageLayoutNext
      { ...otherProps }
      slots={ { ...slots, actions: () => renderActions() } }
    >
      {
        excludeContentLayer
          ? <ErrorBoundary>
            { children }
          </ErrorBoundary>
          : <SovosPageContentGeneric>
            <ErrorBoundary>
              { children }
            </ErrorBoundary>
          </SovosPageContentGeneric>
      }
    </SovosPageLayoutNext>
  );
};

export default TirPageLayout;
