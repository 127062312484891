import { string, array, object, bool, oneOfType, objectOf } from 'prop-types';
import SovosIconButton from 'mosaic-react/sovos-icon-button';
import SovosTypography from 'mosaic-react/sovos-typography';
import DeleteIcon from '@mui/icons-material/Delete';
import TextFieldForm from 'shared/standardInput/textField/TextFieldFormConnector';
import SummaryOfQDDHeader from './summaryOfQDDHeader/SummaryOfQDDHeader';
import SummaryOfQDDRow from './summaryOfQDDRow/SummaryOfQDDRow';
import ScheduleQDateRangeField from './scheduleQDateRangeField/ScheduleQDateRangeField';
import { the1042FormTaxEntityCode } from 'helpers/formConstants';
import './form1042ScheduleQs.scss';

const Form1042ScheduleQs = ({ formActions, value, paymentByQddFlag, readOnlyUser, readOnly, customClasses }) => {

  const handleDeleteScheduleQs = id => {
    const newForm1042ScheduleQs = value.filter(scheduleQ => id !== scheduleQ.id);
    formActions.setFieldValue('Form1042ScheduleQs', newForm1042ScheduleQs, false, the1042FormTaxEntityCode);
  };

  const handleChange = (key, filedValue, id) => {
    const newForm1042ScheduleQs = value.map(
      scheduleQ => id === scheduleQ.id
        ? { ...scheduleQ, [key]: filedValue }
        : scheduleQ);
    formActions.setFieldValue('Form1042ScheduleQs', newForm1042ScheduleQs, false, the1042FormTaxEntityCode);
  };

  const handleChangeDateRange = (startDatekey, startDatevalue, endDatekey, endDatevalue, id) => {
    const newForm1042ScheduleQs = value.map(
      scheduleQ => id === scheduleQ.id
        ? { ...scheduleQ, [startDatekey]: startDatevalue, [endDatekey]: endDatevalue }
        : scheduleQ);
    formActions.setFieldValue('Form1042ScheduleQs', newForm1042ScheduleQs, false, the1042FormTaxEntityCode);
  };

  return (
    <>
      { Boolean(value.length) && paymentByQddFlag === 'X' && value.map(scheduleQ => (
        <div className='form-1042-schedule-qs__container' key={ `scheduleQ-${scheduleQ.id}` }>
          <div className='form-1042-schedule-qs__title-container'>
            <div className='form-1042-schedule-qs__title-container__text'>
              <SovosTypography variant='body1' className='form-1042-schedule-qs__title-container__text__main'>Schedule Q (Form 1042):&nbsp;</SovosTypography>
              <SovosTypography variant='body1'>Tax liability of qualified derivatives dealer</SovosTypography>
            </div>
            <SovosIconButton
              tooltipText=''
              onClick={ () => handleDeleteScheduleQs(scheduleQ.id) }
              disabled={ readOnlyUser || readOnly }
            >
              <DeleteIcon />
            </SovosIconButton>
          </div>
          <TextFieldForm
            label='Name of taxpayer'
            labelValidation='Name of taxpayer'
            value={ scheduleQ.Name }
            name={ `Name-${scheduleQ.id}` }
            onChange={ (evt, fieldValue) => handleChange('Name', fieldValue, scheduleQ.id) }
            readOnlyUser={ readOnlyUser }
            readOnly={ readOnly }
            validation={ {
              pattern: "MAXLENGTH75",
              specialCharacters: "#\\-\\(\\)&'",
              message: "Must contain 75 valid characters or less.",
              validateOn: "keyup",
              required: true
            } }
            customClasses={ customClasses }
          />
          <TextFieldForm
            label='Enter the EIN (not the QI-EIN) of the QDD'
            labelValidation='Enter the EIN (not the QI-EIN) of the QDD'
            value={ scheduleQ.FEIN }
            name={ `Fein-${scheduleQ.id}` }
            onChange={ (evt, fieldValue) => handleChange('FEIN', fieldValue, scheduleQ.id) }
            readOnlyUser={ readOnlyUser }
            readOnly={ readOnly }
            validation={ {
              pattern: "^(|[0-9]{9})$",
              message: "Must contain 9 digits",
              validateOn: "keyup",
              required: true
            } }
            customClasses={ customClasses }
          />
          <TextFieldForm
            label='Name of QDD'
            labelValidation='Name of QDD'
            value={ scheduleQ.NameQdd }
            name={ `NameQdd-${scheduleQ.id}` }
            onChange={ (evt, fieldValue) => handleChange('NameQdd', fieldValue, scheduleQ.id) }
            readOnlyUser={ readOnlyUser }
            readOnly={ readOnly }
            validation={ {
              pattern: "MAXLENGTH75",
              specialCharacters: "#\\-\\(\\)&'",
              message: "Must contain 75 valid characters or less.",
              validateOn: "keyup",
              required: true
            } }
            customClasses={ customClasses }
          />
          <TextFieldForm
            label='QI-EIN'
            labelValidation='QI-EIN'
            name={ `Einqdd-${scheduleQ.id}` }
            value={ scheduleQ.EINQdd }
            onChange={ (evt, fieldValue) => handleChange('EINQdd', fieldValue, scheduleQ.id) }
            readOnlyUser={ readOnlyUser }
            readOnly={ readOnly }
            validation={ {
              pattern: "^(|[0-9]{9})$",
              message: "Must contain 9 digits",
              validateOn: "keyup",
              required: true
            } }
            customClasses={ customClasses }
          />
          <ScheduleQDateRangeField
            label='QDD Tax Year (enter month, day, and year for beginning and ending dates)'
            startDatekey= 'TaxYearQddStart'
            startDatevalue={ scheduleQ.TaxYearQddStart || null }
            endDatekey='TaxYearQddEnd'
            endDatevalue={ scheduleQ.TaxYearQddEnd || null }
            handleOnChange={ (startDatekey, startDatevalue, endDatekey, endDatevalue) => handleChangeDateRange(startDatekey, startDatevalue, endDatekey, endDatevalue, scheduleQ.id) }
            readOnly={ readOnly || readOnlyUser }
            scheduleQId={ scheduleQ.id }
          />
          <ScheduleQDateRangeField
            label='Indicate the year or portion of the year to which the schedule relates (enter month, day, and year beginning and ending dates)'
            startDatekey= 'ScheStart'
            startDatevalue={ scheduleQ.ScheStart || null }
            endDatekey='ScheEnd'
            endDatevalue={ scheduleQ.ScheEnd || null }
            handleOnChange={ (startDatekey, startDatevalue, endDatekey, endDatevalue) => handleChangeDateRange(startDatekey, startDatevalue, endDatekey, endDatevalue, scheduleQ.id) }
            readOnly={ readOnly || readOnlyUser }
            scheduleQId={ scheduleQ.id }
          />
          <SummaryOfQDDHeader/>
          <SummaryOfQDDRow
            label= 'Total section 871(m) amount'
            boxName='1'
            rightBoxName='1'
            columnAField='Tot871GrossAmt'
            columnAValue={ scheduleQ.Tot871GrossAmt }
            columnBField='Tot871TaxRate'
            columnBValue={ scheduleQ.Tot871TaxRate }
            columnCValue={ (scheduleQ.Tot871GrossAmt * scheduleQ.Tot871TaxRate) || 0 }
            columnCReadOnly={ true }
            handleOnChange={ (fieldName, fieldValue) => handleChange(fieldName, fieldValue, scheduleQ.id) }
            readOnly={ readOnly || readOnlyUser }
            scheduleQId={ scheduleQ.id }
          />
          <SummaryOfQDDRow
            label= 'Total dividends received in equity derivatives dealer capacity'
            boxName='2'
            rightBoxName='2'
            columnAField='TotDividendsGrossAmt'
            columnAValue={ scheduleQ.TotDividendsGrossAmt }
            columnBField='TotDividendsTaxRate'
            columnBValue={ scheduleQ.TotDividendsTaxRate }
            columnCValue={ (scheduleQ.TotDividendsGrossAmt * scheduleQ.TotDividendsTaxRate) || 0 }
            columnCReadOnly={ true }
            handleOnChange={ (fieldName, fieldValue) => handleChange(fieldName, fieldValue, scheduleQ.id) }
            readOnly={ readOnly || readOnlyUser }
            scheduleQId={ scheduleQ.id }
          />
          <SummaryOfQDDRow
            label= 'Total QDD tax liability pursuant to section 3.09(A) of the qualified intermediary agreement'
            boxName='3'
            rightBoxName='3'
            columnAReadOnly={ true }
            columnBField='Qdd309ATaxRate'
            columnBValue={ scheduleQ.Qdd309ATaxRate }
            columnCField='Qdd309ATax'
            columnCValue={ scheduleQ.Qdd309ATax }
            handleOnChange={ (fieldName, fieldValue) => handleChange(fieldName, fieldValue, scheduleQ.id) }
            readOnly={ readOnly || readOnlyUser }
            scheduleQId={ scheduleQ.id }
          />
          <SummaryOfQDDRow
            label= 'Total QDD tax liability pursuant to section 3.09(B) of the qualified intermediary agreement'
            boxName='4'
            rightBoxName='4'
            columnAField='Qdd309BGrossAmt'
            columnAValue={ scheduleQ.Qdd309BGrossAmt }
            columnBField='Qdd309BTaxRate'
            columnBValue={ scheduleQ.Qdd309BTaxRate }
            columnCValue={ (scheduleQ.Qdd309BGrossAmt * scheduleQ.Qdd309BTaxRate) || 0 }
            columnCReadOnly={ true }
            handleOnChange={ (fieldName, fieldValue) => handleChange(fieldName, fieldValue, scheduleQ.id) }
            readOnly={ readOnly || readOnlyUser }
            scheduleQId={ scheduleQ.id }
          />
          <SummaryOfQDDRow
            label= 'Total QDD tax liability pursuant to section 3.09(C) of the qualified intermediary agreement'
            boxName='5'
            rightBoxName='5'
            columnAReadOnly={ true }
            columnBReadOnly={ true }
            columnCReadOnly={ true }
            readOnly={ readOnly || readOnlyUser }
            scheduleQId={ scheduleQ.id }
          />
          <SummaryOfQDDRow
            label= 'Income type'
            boxName='a'
            rightBoxName='5a'
            inputField='IncomeTypeA'
            inputValue={ scheduleQ.IncomeTypeA }
            columnAField='IncomeTypeAGrossAmt'
            columnAValue={ scheduleQ.IncomeTypeAGrossAmt }
            columnBField='IncomeTypeATaxRate'
            columnBValue={ scheduleQ.IncomeTypeATaxRate }
            columnCValue={ (scheduleQ.IncomeTypeAGrossAmt * scheduleQ.IncomeTypeATaxRate) || 0 }
            columnCReadOnly={ true }
            handleOnChange={ (fieldName, fieldValue) => handleChange(fieldName, fieldValue, scheduleQ.id) }
            readOnly={ readOnly || readOnlyUser }
            scheduleQId={ scheduleQ.id }
          />
          <SummaryOfQDDRow
            label= 'Income type'
            boxName='b'
            rightBoxName='5b'
            inputField='IncomeTypeB'
            inputValue={ scheduleQ.IncomeTypeB }
            columnAField='IncomeTypeBGrossAmt'
            columnAValue={ scheduleQ.IncomeTypeBGrossAmt }
            columnBField='IncomeTypeBTaxRate'
            columnBValue={ scheduleQ.IncomeTypeBTaxRate }
            columnCValue={ (scheduleQ.IncomeTypeBGrossAmt * scheduleQ.IncomeTypeBTaxRate) || 0 }
            columnCReadOnly={ true }
            handleOnChange={ (fieldName, fieldValue) => handleChange(fieldName, fieldValue, scheduleQ.id) }
            readOnly={ readOnly || readOnlyUser }
            scheduleQId={ scheduleQ.id }
          />
          <SummaryOfQDDRow
            label= 'Income type'
            boxName='c'
            rightBoxName='5c'
            inputField='IncomeTypeC'
            inputValue={ scheduleQ.IncomeTypeC }
            columnAField='IncomeTypeCGrossAmt'
            columnAValue={ scheduleQ.IncomeTypeCGrossAmt }
            columnBField='IncomeTypeCTaxRate'
            columnBValue={ scheduleQ.IncomeTypeCTaxRate }
            columnCValue={ (scheduleQ.IncomeTypeCGrossAmt * scheduleQ.IncomeTypeCTaxRate) || 0 }
            columnCReadOnly={ true }
            handleOnChange={ (fieldName, fieldValue) => handleChange(fieldName, fieldValue, scheduleQ.id) }
            readOnly={ readOnly || readOnlyUser }
            scheduleQId={ scheduleQ.id }
          />
          <SummaryOfQDDRow
            label= 'Income type'
            boxName='d'
            rightBoxName='5d'
            inputField='IncomeTypeD'
            inputValue={ scheduleQ.IncomeTypeD }
            columnAField='IncomeTypeDGrossAmt'
            columnAValue={ scheduleQ.IncomeTypeDGrossAmt }
            columnBField='IncomeTypeDTaxRate'
            columnBValue={ scheduleQ.IncomeTypeDTaxRate }
            columnCValue={ (scheduleQ.IncomeTypeDGrossAmt * scheduleQ.IncomeTypeDTaxRate) || 0 }
            columnCReadOnly={ true }
            handleOnChange={ (fieldName, fieldValue) => handleChange(fieldName, fieldValue, scheduleQ.id) }
            readOnly={ readOnly || readOnlyUser }
            scheduleQId={ scheduleQ.id }
          />
          <SummaryOfQDDRow
            label= 'Total of line 5 amounts'
            boxName='6'
            rightBoxName='6'
            columnAValue={
              (scheduleQ.IncomeTypeAGrossAmt || 0)
              + (scheduleQ.IncomeTypeBGrossAmt || 0)
              + (scheduleQ.IncomeTypeCGrossAmt || 0)
              + (scheduleQ.IncomeTypeDGrossAmt || 0)
            }
            columnAReadOnly={ true }
            columnBReadOnly={ true }
            columnCValue={
              (((scheduleQ.IncomeTypeAGrossAmt * scheduleQ.IncomeTypeATaxRate) || 0)
              + ((scheduleQ.IncomeTypeBGrossAmt * scheduleQ.IncomeTypeBTaxRate) || 0)
              + ((scheduleQ.IncomeTypeCGrossAmt * scheduleQ.IncomeTypeCTaxRate) || 0)
              + ((scheduleQ.IncomeTypeDGrossAmt * scheduleQ.IncomeTypeDTaxRate) || 0))
            }
            columnCReadOnly={ true }
            handleOnChange={ (fieldName, fieldValue) => handleChange(fieldName, fieldValue, scheduleQ.id) }
            readOnly={ readOnly || readOnlyUser }
            scheduleQId={ scheduleQ.id }
          />
        </div>
      ))
      }
    </>
  );
};

Form1042ScheduleQs.propTypes = {
  formActions: object.isRequired,
  value: oneOfType([string, array]),
  paymentByQddFlag: string,
  readOnlyUser: bool.isRequired,
  readOnly: bool.isRequired,
  customClasses: objectOf(string)
};

Form1042ScheduleQs.defaultProps = {
  readOnlyUser: false,
  readOnly: false
};

export default Form1042ScheduleQs;