import sharedConstants from 'redux/constants/SharedConstants';

const actionTypes = {
  ...sharedConstants,
  CREATING_PT_JOB: 'CREATING_PT_JOB',
  CREATE_PT_JOB_SUCCESS: 'CREATE_PT_JOB_SUCCESS',
  CREATE_PT_JOB_FAILURE: 'CREATE_PT_JOB_FAILURE',
  SET_PT_CANCELLING_JOB: 'SET_PT_CANCELLING_JOB',
  CANCEL_PT_JOB_SUCCESS: 'CANCEL_PT_JOB_SUCCESS',
  CANCEL_PT_JOB_FAILURE: 'CANCEL_PT_JOB_FAILURE',
  TRANSMITING_TO_IRS: 'TRANSMITING_TO_IRS',
  TRANSMIT_TO_IRS_SUCCESS: 'TRANSMIT_TO_IRS_SUCCESS',
  TRANSMIT_TO_IRS_FAILURE: 'TRANSMIT_TO_IRS_FAILURE'
};

export default actionTypes;
