import PropTypes from 'prop-types';
import SelectFieldForm from 'shared/standardInput/selectField/SelectFieldFormConnector';
import { calculateValue } from './AutoCalculatedSelectHelper';

const AutoCalculatedField = ({ floatingLabelText, form, name, ...otherProps }) => (
  <SelectFieldForm
    { ...otherProps }
    value={ calculateValue(name, form) }
    readOnly={ true }
    labelForm={ floatingLabelText }
    name={ name }
    onChange={ () => {} }
  />
);

AutoCalculatedField.propTypes = {
  name: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  floatingLabelText: PropTypes.string
};

export default AutoCalculatedField;
