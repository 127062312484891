import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TirPageLayout, withSnackbar } from 'enhancers';
import SovosReadOnlySlidingPanel from 'mosaic-react/sovos-readonly-sliding-panel';
import RecordsSummaryCards from 'shared/recordCards/RecordCards';
import DataInformationSection from './dataInformationSection/DataInformationSection';
import RecordSlidingPanel from 'shared/recordSlidingPanel/RecordSlidingPanel';
import { NonPrintedNonTransmittedTable } from 'shared/recordSlidingPanel';
import { taxDetailStatus } from 'helpers/enums';
import { identityManagementCardTypes, identityManagementCards } from 'helpers/identityManagementHelper';
import Search from 'pages/search/searchBar/SearchBar';
import useDataOverview from 'helpers/useDataOverview';
import './overviewPage.scss';

const sliderStyle = { content: 'overview__non-print-non-transmit-slider' };

const OverviewPage = ({ showErrorSnackbar }) => {
  const [openRecordSlidingPanel, setOpenRecordSlidingPanel] = useState(false);
  const [openSlidingPanel, setOpenSlidingPanel] = useState(false);
  const [clickedCard, setClickedCard] = useState('');
  const [type, setType] = useState('');
  const { fetchRecordsSummary, overview, fetchingOverview } = useDataOverview(false, showErrorSnackbar, false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchRecordsSummary();
  }, [openRecordSlidingPanel, openSlidingPanel]);

  const closeRecordSlidingPanel = () => setOpenRecordSlidingPanel(false);

  const closeSlidingPanel = () => setOpenSlidingPanel(false);

  const onRecordCardClick = (text, type) => {
    if (type === 'Errors') {
      navigate('/errors', { state: { pageCrumbText: 'Overview' } });
      return;
    }
    if (identityManagementCardTypes.includes(type)) {
      navigate('/identity-management', { state: { selectedTab: type, pageCrumbs: 'Overview', pageCrumbsPath: '/overview' } });
    }
    if (taxDetailStatus[type]) {
      setOpenSlidingPanel(true);
    } else {
      setOpenRecordSlidingPanel(true);
    }
    setClickedCard(text);
    setType(type);
  };

  const titleBarActions= [<Search key="search" />];

  return (
    <TirPageLayout
      pageTitle='Overview'
      slots={ { actions: titleBarActions } }
    >
      <div className="overview__container">
        <RecordsSummaryCards
          cards={ ['Errors', identityManagementCards.MismatchesCount.type, 'NonPrinted', 'NonTransmitted'] }
          onRecordCardClick={ onRecordCardClick }
          totals={ overview.totals }
          fetchingTotals={ fetchingOverview }
        />
        <RecordSlidingPanel
          openRecordSlidingPanel={ openRecordSlidingPanel }
          closeRecordSlidingPanel={ closeRecordSlidingPanel }
          cardTitle={ clickedCard }
          type={ type }
        />
        <SovosReadOnlySlidingPanel
          onClose={ closeSlidingPanel }
          open={ openSlidingPanel }
          title={ clickedCard }
          classes={ sliderStyle }
        >
          <NonPrintedNonTransmittedTable taxDetailStatus={ type } />
        </SovosReadOnlySlidingPanel>
        <DataInformationSection />
      </div>
    </TirPageLayout>
  );
};

OverviewPage.propTypes = {
  showErrorSnackbar: PropTypes.func
};

export default withSnackbar(OverviewPage);
