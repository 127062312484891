import specialFields from "helpers/specialFields";
import AuthenticationHelper from 'helpers/authenticationHelper';

export const getFieldsNames = metadata => {
  const allFields = {};
  const getNames = haystack => {
    if (Array.isArray(haystack)) {
      haystack.forEach(children => {
        if (children.hasOwnProperty('fields')) {
          children.fields.forEach(field => {
            if (AuthenticationHelper.getIsMetadataFieldAllowed(field)) {
              allFields[field.dbColumn || field.entityField || specialFields[field.type.toUpperCase()].toUpperCase()] = Object.assign(
                {
                  name: field.name,
                  label: field.label,
                  labelForm: field.labelForm,
                  fieldType: field.type,
                  viewColumn: field.viewColumn,
                  map: field.map,
                  hide: field.hide,
                  mask: field.mask,
                  entityField: field.entityField?.toUpperCase(),
                  dataModifier: field.dataModifier
                },
                field.options
                  ? { options: field.options }
                  : {},
                field.validation
                  ? { validation: field.validation }
                  : {}
              );
            }
          });
        }
        else if (typeof children === 'object') {
          getNames(children);
        }
      });
    }
    for (const [key, children] of Object.entries(haystack)) {
      if (typeof children === 'object' && ['rows', 'panels', 'fields', 'tabs'].includes(key)) {
        getNames(children);
      }
    }
  };

  getNames(metadata);
  return allFields;
};