const structureTypes = {
  ROOT: 'ROOT',
  CONTEXT: 'CONTEXT',
  TAXYEAR: 'TAXYEAR'
};

const TIR_ENT_SFTP = 'TIR_ENT_SFTP';

const removeEntSftpRole = roles => roles.filter(role => role.name !== TIR_ENT_SFTP);

const getContextsAndTaxyears = (structures, roles = null) => {
  let root = structures.find(structure => structure.type === structureTypes.ROOT);

  const contexts = structures
    .filter(structure => structure.type === structureTypes.CONTEXT && structure.parentId === root.id)
    .map(context => {
      const taxyears = structures
        .filter(structure => structure.type === structureTypes.TAXYEAR && structure.parentId === context.id)
        .map(taxyear => {
          const customAttributesJSON = taxyear.customAttributes ? JSON.parse(taxyear.customAttributes) : {};
          const taxYear = customAttributesJSON.taxYear || 0;
          const context = customAttributesJSON.contextType || '';

          return {
            ...taxyear,
            roles,
            taxYear,
            context
          };
        });

      taxyears.sort((a, b) => b.taxYear - a.taxYear);

      return {
        ...context,
        taxyears
      };
    });

  return contexts;
};

export {
  structureTypes,
  getContextsAndTaxyears,
  removeEntSftpRole
};