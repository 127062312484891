import sharedActions from 'redux/constants/SharedConstants';

const folderlistActions = {
  FETCHING_FOLDERS: 'FETCHING_FOLDERS',
  FETCH_FOLDERS_SUCCESS: 'FETCH_FOLDERS_SUCCESS',
  FETCH_FOLDERS_FAILURE: 'FETCH_FOLDERS_FAILURE',
  CREATING_FOLDER: 'CREATING_FOLDER',
  CREATE_FOLDER_SUCCESS: 'CREATE_FOLDER_SUCCESS',
  CREATE_FOLDER_FAILURE: 'CREATE_FOLDER_FAILURE',
  RENAMING_FOLDER: 'RENAMING_FOLDER',
  RENAME_FOLDER_SUCCESS: 'RENAME_FOLDER_SUCCESS',
  RENAME_FOLDER_FAILURE: 'RENAME_FOLDER_FAILURE',
  ...sharedActions
};

export default folderlistActions;
