import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setFieldValue } from '../../../sovos-dynamic-form/redux/DynamicFormActions';
import WFMarkAsCorrectionButton from './WFMarkAsCorrectionButton';

const mapStateToProps = (state, ownProps) => ({
  status: state.dynamicForm.form[ownProps.taxEntityCode]?.Status,
  taxEntityCode: state.dynamicForm.taxEntityCode,
  isClone: state.dynamicForm.isClone
});

const mapDispatchToProps = dispatch => ({
  formActions: bindActionCreators({ setFieldValue }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(WFMarkAsCorrectionButton);