import actionTypes from './formCommentsConstants';
import { processComments } from './formCommentsHelper';

const initialStore = {
  fetchingComments: false,
  addingComment: false,
  comments: []
};

export default function FormCommentsReducer(state = initialStore, action) {
  switch (action.type) {
    case actionTypes.FETCHING_COMMENTS:
      return { ...state,
        fetchingComments: true,
        comments: [] };

    case actionTypes.FETCH_COMMENTS_SUCCESS:
      return (action.commentList)? {
        ...state,
        comments: processComments(action.commentList.Data),
        fetchingComments: false,
        currentPage: action.commentList.CurrentPage,
        from: action.commentList.From,
        lastPage: action.commentList.LastPage,
        to: action.commentList.To,
        totalItems: action.commentList.Total,
        pageSize: action.commentList.PageSize
      } : {
        ...state,
        fetchingComments: false,
        comments: [],
        currentPage: 1,
        from: 0,
        lastPage: 0,
        to: 0,
        totalItems: 0,
        pageSize: 0
      };

    case actionTypes.FETCH_COMMENTS_FAILURE:
      return { ...state, fetchingComments: false };

    case actionTypes.ADDING_COMMENT:
      return { ...state,
        addingComment: true };

    case actionTypes.ADD_COMMENT_SUCCESS:
      return { ...state,
        addingComment: false };

    case actionTypes.ADD_COMMENT_FAILURE:
      return { ...state,
        addingComment: false };

    case actionTypes.LOGOUT_SUCCESS:
      return { ...state, editedState: initialStore };

    default:
      return state;
  }
}
