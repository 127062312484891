const ValidationRegex = {
  'NUMBER': '[0-9]',
  'TIN': '(^$|^[0-9]{9}$)',
  'EIN': '[0-9]{9}',
  'EMAIL': '^$|^(?=.{1,50}$)[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9]+(?:\\.[a-zA-Z0-9]{2,})$'
};

const getMaxLengthRegex = (number, specialCharacters = '') => `^[${specialCharacters}a-zA-Z0-9\\s]{0,${number}}$`;

const getMaxLengthNumberRegex = number => `^[0-9\\s]{0,${number}}$`;

export {
  ValidationRegex,
  getMaxLengthRegex,
  getMaxLengthNumberRegex
};
