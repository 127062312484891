import PropTypes from 'prop-types';
import EntityFetcher from '../entityFetcher/EntityFetcher';

const NQI = ({ dynamicFormActions, isClone, form, value, readOnlyUser, taxEntityCode }) => {
  const fieldsMap = {
    'S03': 'AccountNo',
    'S04': 'Tin',
    'C03': 'TinType',
    'N09': 'RecipCode',
    'N10': 'Rch4StCode',
    'S06': 'Name',
    'S21': 'Name2',
    'S29': 'Name3',
    'S15': 'Rgiin',
    'S25': 'Country',
    'S11': 'CountryCode',
    'S34': 'Frgnid',
    'S27': 'Address',
    'S28': 'Address2',
    'S22': 'City',
    'S07': 'State',
    'S08': 'Zip',
    'S26': 'ProvinceCode',
    'GML': 'gml'
  };

  const handlePayerSelect = payer => {
    Object.keys(fieldsMap).forEach(key => {
      if (payer) {
        dynamicFormActions.setFieldValue(key, payer[fieldsMap[key]] || '', isClone, taxEntityCode);
      }
      dynamicFormActions.setDisabledByEntityFetcher(fieldsMap);
    });
  };

  const handlePayeeRemove = () => {
    Object.keys(fieldsMap).forEach(key => {
      dynamicFormActions.setFieldValue(key, '', isClone, taxEntityCode);
      dynamicFormActions.unsetDisabledByEntityFetcher(fieldsMap);
    });
  };

  const checkForUserInput = () => {
    let containsUserInput = false;
    Object.keys(fieldsMap).forEach(key => {
      if (form[key]) {
        containsUserInput = true;
      }
    });
    return containsUserInput;
  };

  return (
    <EntityFetcher
      label={ 'Nonqualified intermediary' }
      onEntitySelect={ handlePayerSelect }
      fetchMultipleUrl={ `/npayees/search` }
      fetchSingleURL={ '/npayees/' }
      onEntityRemove={ handlePayeeRemove }
      entityLabel={ '1042-S recipient' }
      initialValueKey={ 'AccountNo' }
      idKeyName={ 'ModRecno' }
      containsUserInput={ checkForUserInput }
      value={ value }
      readOnlyUser={ readOnlyUser }
    />
  );
};

NQI.propTypes = {
  dynamicFormActions: PropTypes.shape({
    setFieldValue: PropTypes.func,
    setDisabledByEntityFetcher: PropTypes.func,
    unsetDisabledByEntityFetcher: PropTypes.func
  }).isRequired,
  form: PropTypes.object.isRequired,
  isClone: PropTypes.bool.isRequired,
  readOnlyUser: PropTypes.bool.isRequired,
  taxEntityCode: PropTypes.string.isRequired
};

export default NQI;
