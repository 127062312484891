import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Eula from './Eula';
import { fetchEulaSetting, acceptEula } from 'redux/userAdministration/UserAdministrationActions';

function mapStateToProps(state) {
  return {
    openEula: state.userAdministration.openEula,
    selectedContext: state.contextReducer.selectedContext
  };
}

function mapDispatchToProps(dispatch) {
  return {
    usersActions: bindActionCreators({ fetchEulaSetting, acceptEula }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Eula);
