import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import classNames from 'classnames';
import './checkBox.scss';

class CheckBox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      originalValue: props.value || props.defaultValue,
      checked: this.props.options[this.props.value] || props.value === '1' || props.value === 'Y',
      value: props.value
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      const checked = this.props.options[this.props.value] || false;
      this.setState({ checked, value: this.props.value });
    }
  }

  handleCheckboxChange = ev => {
    let value;
    this.setState(oldState => {
      value = Object.keys(this.props.options).find(option => this.props.options[option] === !oldState.checked);
      if (value === 'null') {
        value = '';
      }
      return ({ checked: !oldState.checked, value });
    }, () => {
      this.state.originalValue && this.state.originalValue.length === 0 && !this.state.checked
        ? this.props.onChange(ev, this.state.originalValue, this.props.onChangeCallback)
        : this.props.onChange(ev, value, this.props.onChangeCallback);
    });
  };

  render() {
    const { name, label, style, material, disabled, labelPlacement, readOnly, readOnlyUser, classes } = this.props;
    return (
      <div>
        {
          material ?
            <FormControlLabel
              control={
                <Checkbox
                  id={ name }
                  checked={ this.state.checked }
                  onChange={ this.handleCheckboxChange }
                  className={ classNames('checkbox__wrapper__checkbox', classes.checkbox) }
                  style={ style }
                  disabled={ disabled }
                  color='primary'
                />
              }
              label={ label }
              labelPlacement={ labelPlacement || "start" }
            />
            :
            <div className="checkbox__wrapper">
              <FormControlLabel
                control={
                  <Checkbox
                    id={ name }
                    checked={ this.state.checked }
                    onClick={ this.handleCheckboxChange }
                    className={ classNames(
                      'checkbox__wrapper__checkbox',
                      {
                        'checkbox__wrapper--disabled': readOnlyUser || readOnly || disabled
                      },
                      classes.checkbox
                    ) }
                    style={ style }
                    disabled={ readOnlyUser || readOnly || disabled }
                    color='primary'
                  />
                }
                label={ label }
                labelPlacement={ labelPlacement || "start" }
                classes={ { label: `checkbox__wrapper__label ${labelPlacement && 'checkbox__wrapper__label-black'}` } }
              />
            </div>
        }
      </div>
    );
  }
}

CheckBox.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  label: PropTypes.node,
  labelValidation: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onChangeCallback: PropTypes.func,
  options: PropTypes.object,
  style: PropTypes.object,
  material: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  readOnlyUser: PropTypes.bool,
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string)
};

CheckBox.defaultProps = {
  name: '',
  label: '',
  options: { 'true': true, 'false': false },
  value: '0',
  material: false,
  onChangeCallback: () => {},
  style: {},
  disabled: false,
  classes: { }
};

export default CheckBox;
