import types from './NotificationsConstants';
import { get, post, put, remove } from 'helpers/axios/axiosInterceptorsV1';
import { buildUrlsParams } from 'helpers/buildUrlParams';

const fetchNotifications = (pageOffset, pageSize, year, month) => async dispatch => {
  dispatch({ type: types.FETCHING_NOTIFICATIONS });
  const query = (year && month) ? { pageSize, pageOffset, year, month } : { pageSize, pageOffset };

  try {
    const response = await get(`environment/news?${buildUrlsParams(query)}`);
    dispatch({
      type: types.FETCH_NOTIFICATIONS_SUCCESS,
      notifications: response.data.data
    });
  } catch (err) {
    dispatch({ type: types.FETCH_NOTIFICATIONS_FAIL });
  }
};

const fetchNotificationFilters = () => async dispatch => {
  dispatch({ type: types.FETCHING_NOTIFICATION_FILTERS });

  try {
    const response = await get('environment/news/filters');
    dispatch({ type: types.FETCH_NOTIFICATION_FILTERS_SUCCESS, notificationFilters: response.data.data });
  } catch (err) {
    dispatch({ type: types.FETCH_NOTIFICATION_FILTERS_FAIL });
  }
};

const fetchLatestNotification = () => async dispatch => {
  dispatch({ type: types.FETCHING_LATEST_NOTIFICATION });

  try {
    const response = await get('environment/news/latest?type=release');
    dispatch({ type: types.FETCH_LATEST_NOTIFICATION_SUCCESS, latestRelease: response.data.data });
  } catch (err) {
    dispatch({ type: types.FETCH_LATEST_NOTIFICATION_FAIL });
  }
};

const createNewNotification = (newsDate, title, type, productCategory, note, detailsUrl, detailsLabel) => async dispatch => {
  dispatch({ type: types.CREATING_NEW_NOTIFICATION });

  try {
    const response = await post('environment/news', { newsDate, title, type, productCategory, note, detailsUrl, detailsLabel });
    dispatch({ type: types.CREATE_NEW_NOTIFICATION_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: types.CREATE_NEW_NOTIFICATION_FAIL });
  }
};

const editNotification = (newsDate, title, type, productCategory, note, detailsUrl, detailsLabel, idToEdit) => async dispatch => {
  dispatch({ type: types.EDITING_NOTIFICATION });

  try {
    const response = await put(`environment/news/${idToEdit}`, { newsDate, title, type, productCategory, note, detailsUrl, detailsLabel });
    dispatch({ type: types.EDIT_NOTIFICATION_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: types.EDIT_NOTIFICATION_FAIL });
  }
};

const deleteNotification = idToDelete => async dispatch => {
  dispatch({ type: types.DELETING_NOTIFICATION });

  try {
    const response = await remove(`environment/news/${idToDelete}`);
    dispatch({ type: types.DELETE_NOTIFICATION_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: types.DELETE_NOTIFICATION_FAIL });
  }
};

export {
  fetchNotifications,
  fetchLatestNotification,
  fetchNotificationFilters,
  createNewNotification,
  editNotification,
  deleteNotification
};
