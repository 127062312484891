import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SlidingFormEditor from './SlidingFormEditor';
import {
  resetForm,
  resetExtraForm,
  resetCloneForm,
  setFormEditorTitle,
  checkConditionToDelete,
  createClone,
  deleteForm,
  setDeleted,
  setTaxEntityCode,
  setTaxEntityCodeBase,
  saveForm,
  fetchForm,
  setFetchFormSuccess
} from '../sovos-dynamic-form/redux/DynamicFormActions';
import { fetchPreview } from 'redux/printPreview/PrintPreviewActions';
import { fetchRecordHistory } from 'redux/formHistory/formHistoryActions';
import { resetEntitiesByPayee, fetchEntitiesByPayee } from 'redux/entities/EntityActions';
import { fetchMetadata } from '../sovos-dynamic-form/redux/FormMetaInformationActions';
import { areUniqueFieldsDuplicated } from '../helper/dataEntryHelper';

const mapStateToProps = state => ({
  formEditorTitle: state.dynamicForm.formEditorTitle,
  form: state.dynamicForm.isClone ? state.dynamicForm.cloneForm : (state.dynamicForm.form[state.dynamicForm.taxEntityCode] || {}),
  formModified: state.dynamicForm.isClone ? state.dynamicForm.cloneFormModified : (state.dynamicForm.formsModified[state.dynamicForm.taxEntityCode] || false),
  fcharToDelete: state.dynamicForm.fcharToDelete,
  fcharToPreview: state.dynamicForm.fcharToPreview,
  saveFormSuccess: state.dynamicForm.saveFormSuccess,
  fetchFormSuccess: state.dynamicForm.fetchFormSuccess,
  formId: state.dynamicForm.formId,
  canBeDeleted: state.dynamicForm.canBeDeleted,
  isReadyToClone: state.dynamicForm.isReadyToClone,
  countFormLinked: state.dynamicForm.countFormLinked,
  checkingConditionToDelete: state.dynamicForm.checkingConditionToDelete,
  creatingClone: state.dynamicForm.creatingClone,
  deleting: state.dynamicForm.deleting,
  deleted: state.dynamicForm.deleted,
  cloning: state.dynamicForm.cloning,
  payerSelected: state.payer.payerSet,
  isClone: state.dynamicForm.isClone,
  recipientSelected: state.recipient.recipientSet,
  formIdToDelete: state.dynamicForm.formIdToDelete,
  formIdToClone: state.dynamicForm.formIdToClone,
  formIdToPreview: state.dynamicForm.formIdToPreview,
  currentFormId: state.dynamicForm.currentFormId,
  formPrinted: state.dynamicForm.formPrinted,
  formTransmittedToIrs: state.dynamicForm.formTransmittedToIrs,
  fetchingForm: state.dynamicForm.fetchingForm,
  saveDisableByValidation: state.dynamicForm.saveDisableByValidation,
  taxEntityCode: state.dynamicForm.taxEntityCode,
  taxEntityCodeBase: state.dynamicForm.taxEntityCodeBase,
  fetchingPrintPreview: state.printPreview.fetchingPreview,
  selectedContext: state.contextReducer.selectedContext,
  fetchingIdentityManagement: state.dynamicForm.fetchingIdentityManagement,
  checkingDuplicatedValuesInFields: state.dynamicForm.checkingDuplicatedValuesInFields,
  areUniqueFieldsDuplicated: areUniqueFieldsDuplicated(state.dynamicForm.fieldValueIsInUseList),
  checkingDuplicatedFailed: state.dynamicForm.checkingDuplicatedFailed,
  enableReprint: state.dynamicForm.enableReprint,
  newForm: state.dynamicForm.formId === -1,
  formMetadata: state.formMetaInformation.formsMetadata || {},
  allEntities: state.entity.entities,
  modRecNo: state.dynamicForm.form[state.dynamicForm.taxEntityCodeBase]?.MODRECNO,
  savingForm: state.dynamicForm.savingForm
});

const mapDispatchToProps = dispatch => ({
  formActions: bindActionCreators({
    resetForm,
    resetExtraForm,
    resetCloneForm,
    setFormEditorTitle,
    checkConditionToDelete,
    createClone,
    deleteForm,
    setDeleted,
    setTaxEntityCode,
    setTaxEntityCodeBase,
    saveForm,
    fetchForm,
    setFetchFormSuccess
  }, dispatch),
  printActions: bindActionCreators({ fetchPreview }, dispatch),
  formHistoryActions: bindActionCreators({ fetchRecordHistory }, dispatch),
  entityActions: bindActionCreators({ resetEntitiesByPayee, fetchEntitiesByPayee }, dispatch),
  metadataActions: bindActionCreators({ fetchMetadata }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SlidingFormEditor);
