import PropTypes from 'prop-types';
import { getRecordName } from 'helpers/formConstants';
import './formType.scss';

const FormType = ({ taxEntityCode }) => (
  <p className="form-type__information">Form: <b>{ getRecordName(taxEntityCode) }</b></p>
);

FormType.propTypes = {
  taxEntityCode: PropTypes.string.isRequired
};

export default FormType;