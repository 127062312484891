import PropTypes from 'prop-types';

const constants = {
  tinType: 'TINTYPE',
  ptinType: 'PTINTYPE',
  payerTypes: {
    0: 'UNK',
    1: 'EIN',
    2: 'SSN',
    3: 'QI-EIN',
    4: 'NQI-EIN'
  },
  recipientTypes: {
    0: 'UNK',
    1: 'EIN',
    2: 'SSN'
  },
  recipient1042STypes: {
    0: 'UNK',
    1: 'EIN',
    2: 'SSN/ITIN',
    3: 'QI-EIN',
    4: 'NO TIN'
  }
};

const TinType = props => {
  const findTinTypeString = (tinType, fchar) => {
    switch (fchar) {
      case '0':
        return constants.payerTypes[tinType] ? constants.payerTypes[tinType] : constants.payerTypes['0'];
      case '1':
        return constants.recipientTypes[tinType] ? constants.recipientTypes[tinType] : constants.recipientTypes['0'];
      case 'N':
        return constants.recipient1042STypes[tinType] ? constants.recipient1042STypes[tinType] : constants.recipient1042STypes['0'];
      default:
        return tinType;
    }
  };

  const tinType = props.records[constants.tinType] || props.records[constants.ptinType];
  const tinTypeString = findTinTypeString(tinType, props.fchar);

  return (
    <span>{ tinTypeString }</span>
  );
};

TinType.propTypes = {
  records: PropTypes.object.isRequired,
  fchar: PropTypes.string.isRequired
};

export default TinType;
