import sharedActions from 'redux/constants/SharedConstants';

const actionTypes = {
  ...sharedActions,
  FETCHING_COMMENTS: 'FETCHING_COMMENTS',
  FETCH_COMMENTS_SUCCESS: 'FETCH_COMMENTS_SUCCESS',
  FETCH_COMMENTS_FAILURE: 'FETCH_COMMENTS_FAILURE',
  ADDING_COMMENT: 'ADDING_COMMENT',
  ADD_COMMENT_SUCCESS: 'ADD_COMMENT_SUCCESS',
  ADD_COMMENT_FAILURE: 'ADD_COMMENT_FAILURE'
};

export default actionTypes;
