import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PayerInformation from './PayerInformation';
import * as PayerActions from 'redux/payer/PayerActions';
import { setRecipientPayer, setOpenExtraFormSliding, setTaxEntityCode, setTaxEntityCodeBase, resetExtraForm, setFormEditorTitle } from 'shared/dataEntryForms/sovos-dynamic-form/redux/DynamicFormActions';
import { theW2MFchar, withholdingForms } from 'helpers/formConstants';

const mapStateToProps = (state, ownProps) => ({
  payerID: state.dynamicForm.isClone ? state.dynamicForm.cloneForm.PAYERRECNO : state.dynamicForm.form[ownProps.taxEntityCode]?.PAYERRECNO,
  payerInformation: state.payer.data,
  payers: state.payer.payers,
  hideChangeButton: Boolean(state.dynamicForm.taxEntityCode === theW2MFchar && (state.dynamicForm.isClone ? state.dynamicForm.cloneForm.RECNO : state.dynamicForm.form[ownProps.taxEntityCode]?.RECNO)),
  showAddNewPayerButton: withholdingForms.includes(ownProps.taxEntityCode)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(PayerActions, dispatch),
  formActions: bindActionCreators({ setRecipientPayer, setOpenExtraFormSliding, setTaxEntityCode, setTaxEntityCodeBase, resetExtraForm, setFormEditorTitle }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PayerInformation);
