import actionTypes from './TransmittalCardConstants';
import States from './States';
import { allRecords } from 'helpers/formConstants';

const initialStore = {
  cardData: {
    [States.READY_TO_TRANSMIT_FEDERAL]: [],
    [States.READY_TO_TRANSMIT_STATE]: [],
    [States.AWATING_APPROVAL]: [],
    [States.PROCESSING]: [],
    CorrectedForms: []
  },
  fetchingReadyToTransmitFederal: false,
  fetchingReadyToTransmitState: false,
  fetchingAwaitingApproval: false,
  fetchingProcessing: false,
  fetchingCorrectedForms: false,
  breakPoint: { breakPoint: 1250 },
  fetchCardsCompleted: {
    [States.READY_TO_TRANSMIT_FEDERAL]: false,
    [States.READY_TO_TRANSMIT_STATE]: false,
    [States.AWATING_APPROVAL]: false,
    [States.PROCESSING]: false,
    [States.CORRECTED]: false
  }
};

export default function TransmitCardReducer(state = initialStore, action) {
  switch (action.type) {
    case actionTypes.SET_FETCHING_CARTDATA_TRANSMIT:
      if (action.filingStatus) {
        return {
          ...state,
          fetchingCorrectedForms: true
        };
      }
      return {
        ...state,
        [`fetching${action.cardState}`]: true
      };

    case actionTypes.FETCH_CARTDATA_SUCCESS_TRANSMIT:
      if (action.filingStatus) {
        return {
          ...state,
          cardData: {
            ...state.cardData,
            [States.CORRECTED]: action.data.data
          },
          fetchCardsCompleted: { ...state.fetchCardsCompleted, [States.CORRECTED]: true },
          fetchingCorrectedForms: false
        };
      }
      if (action.cardState === States.READY_TO_TRANSMIT_STATE) {
        const data = {
          Payers: action.data[0].Payers,
          FChar: action.data[0].TaxEntity,
          FormType: allRecords()[action.data[0].TaxEntity].name,
          Errors: action.data[0].ErrorCount
        };
        return {
          ...state,
          cardData: {
            ...state.cardData,
            [States.READY_TO_TRANSMIT_STATE]: data.Payers.length ? [data] : []
          },
          fetchCardsCompleted: { ...state.fetchCardsCompleted, [action.cardState]: true },
          [`fetching${action.cardState}`]: false
        };
      }
      return {
        ...state,
        cardData: {
          ...state.cardData,
          [action.cardState]: action.data.data
        },
        fetchCardsCompleted: { ...state.fetchCardsCompleted, [action.cardState]: true },
        [`fetching${action.cardState}`]: false
      };

    case actionTypes.FETCH_CARTDATA_FAILURE_TRANSMIT:
      return { ...state, fetchingCartData: false };

    case actionTypes.LOGOUT_SUCCESS:
      return { ...initialStore };

    case actionTypes.CLEAR_FETCH_CARDS_COMPLETED:
      return {
        ...state,
        fetchCardsCompleted: {
          [States.READY_TO_TRANSMIT_FEDERAL]: false,
          [States.READY_TO_TRANSMIT_STATE]: false,
          [States.AWATING_APPROVAL]: false,
          [States.PROCESSING]: false,
          [States.CORRECTED]: false
        }
      };

    case actionTypes.CLEAR_CARTDATA_TRANSMIT:
      return {
        ...state,
        cardData: {
          [States.READY_TO_TRANSMIT_FEDERAL]: [],
          [States.READY_TO_TRANSMIT_STATE]: [],
          [States.AWATING_APPROVAL]: [],
          [States.PROCESSING]: [],
          CorrectedForms: []
        }
      };

    default:
      return state;
  }
}
