import { useState, useEffect, useRef } from 'react';
import { bool, string, func, objectOf, object, node, oneOfType, number, shape } from 'prop-types';
import { getLabel } from '../../dataEntryForms/sovos-dynamic-form/helper/labelHelper';
import CurrencyFormat from 'react-currency-format';
import WrapperMuiTextField from './helper/WrapperMuiTextField';
import SovosTooltip from '../../sovos-tooltip/SovosTooltip';
import Validation from './helper/Validation';
import { calculateValue } from 'shared/dataEntryForms/formContainer/formDetailsComponents/autoCalculatedField/autoCalculatedFieldHelper';
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';
import theme from 'mosaic-react/themes/sovos-theme';
import './validationGridTextField/validationGridTextField.scss';

const { palette } = theme;

const styles = {
  root: {
    boxSizing: 'border-box',
    '& .MuiInputBase-root': {
      '&:before': {
        content: 'none'
      },
      '&:after': {
        borderBottom: 'none'
      }
    }
  },
  input: {
    height: 50,
    boxSizing: 'border-box',
    padding: '0 10px',
    borderLeft: `1px solid ${palette.divider}`
  }
};

const getFixedValue = (value, mask) => {
  const fractionDigits = mask === 'SHARES' ? 4 : 2;
  return parseFloat(value).toFixed(fractionDigits);
};

const getDifference = (previous, current) => (current || 0) - (previous || 0);

let validationForm;

const TotalAmountDifferenceFieldForm = ({
  boxName,
  customClasses,
  taxEntityCode,
  form,
  hideLabel,
  label,
  labelValidation,
  mask,
  name,
  onChange,
  onValid,
  payerInformation,
  previousValue,
  readOnly,
  readOnlyUser,
  reset,
  showPreviousDifferentFields,
  validation,
  value
}) => {
  const [validationErrorMessage, setValidationErrorMessage] = useState('');
  const [currencyInternalValue, setCurrencyInternalValue] = useState(getFixedValue(value, mask));
  const [differenceValue, setDifferenceValue] = useState(getFixedValue(value, mask));
  const [previousValuesForm, setPreviousValuesForm] = useState(form);
  const [manualChange, setManualChange] = useState(false);
  const didMount = useRef(false);

  const containerId = `container-${name}`;

  const handleOnValidation = (valid, message) => {
    setValidationErrorMessage(message);
    onValid(valid);
  };

  useEffect(() => {
    validationForm = new Validation(
      name,
      labelValidation,
      validation,
      mask,
      handleOnValidation
    );

    const actualValue = calculateValue(name, form, payerInformation, taxEntityCode, previousValuesForm);
    setDifferenceValue(getDifference(previousValue, actualValue));

    validationForm.addValidation();

    if (showPreviousDifferentFields) {
      const element = document.getElementById(containerId);
      const parentElement = element.parentElement;
      if (parentElement.classList.contains('xs-2')) {
        parentElement.classList.remove('xs-2');
        parentElement.classList.add('xs-9');
        element.children[0].style.width = '100%';
      }
    }
  }, []);

  useEffect(() => {
    validationForm.validateComponent(null, reset, false, (valid, message) => {
      setValidationErrorMessage(message);
      onValid(valid);
    });
  }, [reset]);

  useEffect(() => {
    if (didMount.current || !value ) {
      const actualValue = calculateValue(name, form, payerInformation, taxEntityCode, previousValuesForm);
      setPreviousValuesForm(form);
      if (!manualChange) {
        if (actualValue !== currencyInternalValue) {
          setCurrencyInternalValue(getFixedValue(actualValue, mask));
          setDifferenceValue(getDifference(previousValue, actualValue));
          onChange(null, actualValue || 0);
        }
      } else {
        setDifferenceValue(getDifference(previousValue, actualValue));
        setManualChange(false);
      }
    } else {
      didMount.current = true;
    }
  }, [form]);

  const validateCurrency = values => {
    let splitDecimal = values.value.split('.');
    return splitDecimal[0].charAt(0) === '-'
      ? splitDecimal[0].length <= 11
      : splitDecimal[0].length <= 10;
  };

  const handleOnChange = (evt, val) => {
    setCurrencyInternalValue(val.value);
    setDifferenceValue(getDifference(previousValue, val.floatValue));
    setManualChange(true);
    onChange(null, val.floatValue || 0);
  };

  const getBoxNameLabel = (showError, className) => (
    boxName &&
      <label
        className={ classNames('tirTexfield__label-box', { 'tirTexfield__label-error': showError }, className) }
        htmlFor={ name }
      >
        { boxName }
      </label>
  );

  const formatValue = () => setCurrencyInternalValue(getFixedValue(currencyInternalValue, mask));

  const showError = Boolean(validationErrorMessage);

  return (
    <div id={ containerId } className={ classNames('tirTextfieldForm__wrapper', 'amountDifference__wrapper', customClasses.tirTextfieldFormWrapper) }>
      { !hideLabel &&
          <div className='label__container'>
            <div className='label__container-left'>
              { getBoxNameLabel(showError, 'label__left-box-name') }
              <label
                className={ classNames('tirTexfield__label', showError && 'tirTexfield__label-error', customClasses.tirTexfieldLabel) }
                htmlFor={ name }
              >
                { getLabel(validation?.required, label) }
              </label>
            </div>
            { getBoxNameLabel(showError) }
          </div>
      }

      <div className={ classNames({ 'amount-field__display': showPreviousDifferentFields }) }>
        {
          showPreviousDifferentFields &&
          <div className={ classNames('textField__container', customClasses.textFieldContainer) }>
            <CurrencyFormat
              value={ previousValue ? getFixedValue(previousValue, mask) : '0.00' }
              thousandSeparator
              prefix='$'
              decimalScale={ 2 }
              fixedDecimalScale={ readOnly }
              customInput={ WrapperMuiTextField }
              autoComplete='off'
              disabled={ true }
            />
          </div>
        }

        <div className={ classNames('textField__container', customClasses.textFieldContainer) }>
          <SovosTooltip
            id={ name }
            label={ validationErrorMessage }
            show={ showError }
            place='right'
            className='tir-texfield__error'
            effect='solid'
          >
            <CurrencyFormat
              id={ name }
              value={ readOnly ? currencyInternalValue || 0.00 : currencyInternalValue || '' }
              thousandSeparator
              prefix='$'
              onBlur={ formatValue }
              decimalScale={ 2 }
              fixedDecimalScale={ readOnly }
              isAllowed={ validateCurrency }
              customInput={ WrapperMuiTextField }
              onValueChange={ values => handleOnChange(null, values) }
              autoComplete='off'
              disabled={ readOnlyUser || readOnly }
            />
          </SovosTooltip>
        </div>

        {
          showPreviousDifferentFields &&
          <div className={ classNames('textField__container', customClasses.textFieldContainer) }>
            <CurrencyFormat
              value={ getFixedValue(differenceValue, mask) }
              thousandSeparator
              prefix='$'
              decimalScale={ 2 }
              fixedDecimalScale={ readOnly }
              customInput={ WrapperMuiTextField }
              autoComplete='off'
              disabled={ true }
            />
          </div>
        }
      </div>

    </div>
  );
};

TotalAmountDifferenceFieldForm.propTypes = {
  boxName: string,
  customClasses: objectOf(string),
  taxEntityCode: string.isRequired,
  form: object.isRequired,
  hideLabel: bool,
  label: node.isRequired,
  labelValidation: string.isRequired,
  mask: string,
  name: string.isRequired,
  onChange: func.isRequired,
  onValid: func,
  payerInformation: object,
  previousValue: oneOfType([
    number,
    string
  ]),
  readOnly: bool,
  readOnlyUser: bool.isRequired,
  reset: bool,
  showPreviousDifferentFields: bool,
  validation: shape({
    pattern: string,
    message: string,
    validateOn: string,
    required: bool,
    maxLength: number
  }),
  value: oneOfType([
    number,
    string
  ])
};

TotalAmountDifferenceFieldForm.defaultProps = {
  boxName: '',
  hideLabel: false,
  placeholder: '',
  previousValue: 0,
  value: 0,
  mask: '',
  multiLine: false,
  multiLineConfig: {
    rows: 1,
    rowsMax: 4
  },
  onBlur: () => {},
  onKeyDown: null,
  onKeyPress: null,
  validation: null,
  reset: false,
  onValid: () => {},
  customClasses: {},
  showPreviousDifferentFields: false
};

export default withStyles(styles)(TotalAmountDifferenceFieldForm);