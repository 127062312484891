import { memo } from 'react';
import { arrayOf, bool, func, node, number, oneOfType, shape, string } from 'prop-types';
import NavigationClose from '@mui/icons-material/Close';
import SovosIconButton from 'mosaic-react/sovos-icon-button';
import theme from 'mosaic-react/themes/sovos-theme';
import './multipleRows.scss';

const { palette } = theme;

const Row = ({ children, row, onDeleteRow, showDeleteButton }) => (
  <div
    className='multi-rows__row'
  >
    { children }
    {
      showDeleteButton &&
      <SovosIconButton
        tooltipText=''
        aria-label={ `delete-row-${row.Id}` }
        className='multi-rows__remove-row'
        onClick={ () => onDeleteRow(row.Id) }
        color={ palette.grey[500] }
      >
        <NavigationClose />
      </SovosIconButton>
    }
  </div>
);

Row.propTypes = {
  children: oneOfType([node, arrayOf(node)]).isRequired,
  row: shape({
    Id: oneOfType([number, string]).isRequired
  }).isRequired,
  onDeleteRow: func.isRequired,
  showDeleteButton: bool
};

Row.defaultProps = {
  showDeleteButton: true
};

export default memo(Row);
