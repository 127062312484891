import sharedActions from 'redux/constants/SharedConstants';

const actionTypes = {
  ...sharedActions,
  FETCHING_PAYER: 'FETCHING_PAYER',
  FETCH_PAYER_SUCCESS: 'FETCH_PAYER_SUCCESS',
  FETCH_PAYER_FAILURE: 'FETCH_PAYER_FAILURE',
  RESET_PAYER: 'RESET_PAYER',
  FETCH_PAYER_BY_REGEX_SUCCESS: 'FETCH_PAYER_BY_REGEX_SUCCESS',
  FETCH_PAYER_BY_REGEX_FAILURE: 'FETCH_PAYER_BY_REGEX_FAILURE',
  SET_PAYER: 'SET_PAYER',
  SET_PAYER_SET: 'SET_PAYER_SET'
};

export default actionTypes;
