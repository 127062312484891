import { useState, useEffect } from 'react';
import { bool, string, func, node, oneOfType, number, shape, arrayOf } from 'prop-types';
import classNames from 'classnames';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import WrapperMuiTextField from '../textField/helper/WrapperMuiTextField';
import './selectDifferenceFieldForm.scss';

const SelectDifferenceFieldForm = ({
  boxName,
  customRender,
  label,
  name,
  onChange,
  options,
  previousValue,
  showPreviousDifferentFields,
  validation,
  value,
  onDisabledOption,
  customClasses,
  disabled
}) => {
  const [internalValue, setInternalValue] = useState(value);

  const containerId = `container-${name}`;

  useEffect(() => {
    if (showPreviousDifferentFields) {
      const element = document.getElementById(containerId);
      const parentElement = element.parentElement;
      if (parentElement.classList.contains('xs-2')) {
        parentElement.classList.remove('xs-2');
        parentElement.classList.add('xs-9');
        element.children[0].style.width = '100%';
      }
    }
  }, []);

  const getBoxNameLabel = className => (
    boxName &&
    <label
      className={ classNames(className) }
      htmlFor={ name }
    >
      { boxName }
    </label>
  );

  const generateOptionMapText = option => {
    if (option.text === '') {
      return '(blank)';
    }
    if (!customRender) {
      return option.text;
    }
    const val = customRender === 'pad0' ? String(option.value).padStart(2, '0') : option.value;
    return `${val} - ${option.text}`;
  };

  const handleOnChange = event => {
    const value = event.target.value;
    setInternalValue(value);
    onChange(null, value);
  };

  return (
    <div id={ containerId } className={ classNames('difference-field-form__wrapper', customClasses.container) }>
      {
        <div className='label__container'>
          <div className={ 'label__container-left' }>
            { getBoxNameLabel('label__left-box-name, label__box-name') }
            <div>
              { label &&
              <label className={ 'select-field__label' }>
                { label }
              </label> }
            </div>
          </div>
          { getBoxNameLabel('label__box-name') }
        </div>
      }

      <div className={ classNames({ 'difference-field__display': showPreviousDifferentFields }) }>
        {
          showPreviousDifferentFields &&
            <div className='previously-value__container'>
              <WrapperMuiTextField
                value={ previousValue }
                disabled={ true }
              />
            </div>
        }

        <div className='actual-value__container'>
          <Select
            style={ { minWidth: showPreviousDifferentFields ? false : 120, width: showPreviousDifferentFields ? '100%' : false } }
            className={ 'select-field-form__mui-select-field' }
            value={ internalValue }
            onChange={ handleOnChange }
            inputProps={ {
              'data-for': name
            } }
            disabled={ disabled }
            variant='outlined'
          >
            {
              (validation && validation.required)
                ? !options.find(option => option.value === 'null' || option.value === '') && <MenuItem value='' disabled>Select...</MenuItem>
                : !options.find(option => option.value === 'null' || option.value === '') && <MenuItem value=''>(blank)</MenuItem>
            }
            { options.map(option => (
              <MenuItem
                value={ option.value === 'null' ? '' : customRender === 'int' ? parseInt(option.value) : option.value }
                key={ option.value }
                disabled={ onDisabledOption && onDisabledOption(option.value) }
              >
                { generateOptionMapText(option) }
              </MenuItem>
            )) }
          </Select>
        </div>
      </div>
    </div>
  );
};

SelectDifferenceFieldForm.propTypes = {
  boxName: string.isRequired,
  customRender: oneOfType([
    string,
    bool
  ]),
  label: node.isRequired,
  name: string.isRequired,
  onChange: func.isRequired,
  options: arrayOf(
    shape({
      text: string,
      value: oneOfType([
        string,
        number
      ])
    })
  ).isRequired,
  previousValue: oneOfType([
    number,
    string
  ]),
  showPreviousDifferentFields: bool.isRequired,
  validation: shape({
    pattern: string,
    message: string,
    validateOn: string,
    required: bool,
    maxLength: number
  }),
  value: oneOfType([
    number,
    string
  ]).isRequired,
  onDisabledOption: func,
  customClasses: shape({
    container: string
  }),
  disabled: bool
};

SelectDifferenceFieldForm.defaultProps = {
  showPreviousDifferentFields: false,
  onDisabledOption: null,
  customClasses: {},
  disabled: false
};

export default SelectDifferenceFieldForm;