import { ValidationRegex, getMaxLengthRegex, getMaxLengthNumberRegex } from './validationRegex';
import { cleanMask } from './maskHelper';

class Validation {
  constructor(name, label = '', validation, mask, onValid, multiLine = false) {
    this._validation = validation;
    this._mask = mask;
    this._name = name;
    this._label = label;
    this._onValid = onValid;
    this._multiLine = multiLine;
  }

  getInputRef = () => document.getElementById(this._name);

  addValidation = () => {
    if (this._validation) {
      if (!this._mask && this._validation.pattern) {
        this.getInputRef().pattern = this.getPattern();
      }
      if (this._validation.required) {
        this.getInputRef().required = true;
      }

      this.getInputRef().addEventListener('blur', this.validateComponent);
      this.getInputRef().addEventListener(this._validation.validateOn || this._validation.activateOn, this.validateComponent);
    }
  };

  removeValidation = () => {
    if (this.getInputRef().required || this.getInputRef().pattern) {
      this.getInputRef().removeAttribute('required');
      this.getInputRef().removeAttribute('pattern');
      this.getInputRef().removeEventListener('blur', this.validateComponent);
      this.getInputRef().removeEventListener(this._validation.activateOn, this.validateComponent);
      this.validateComponent(null, true, 0, this._onValid);
    }
  };

  validateComponent = (evt, reset, isMaxLengthInvalid, onValid = null, errorMessage = null ) => {
    const onValidCallback = onValid || this._onValid;
    if (reset) {
      this.getInputRef().setCustomValidity('');
      onValidCallback(false, '');
      return;
    }

    if (errorMessage) {
      this.getInputRef().setCustomValidity(errorMessage);
      onValidCallback(this.getInputRef().validity.valid, errorMessage);
      return;
    }

    if (this.getInputRef().required && Boolean(!evt.target.value)) {
      onValidCallback(!this.getInputRef().validity.valueMissing, `${this._label} is required.`);
      return;
    }

    if (this.getInputRef().validity.patternMismatch) {
      onValidCallback(!this.getInputRef().validity.patternMismatch, this._validation.message);
      return;
    }

    if (this._multiLine) {
      const textRegex = new RegExp(this._validation.pattern);

      if (textRegex.test(evt.target.value)) {
        onValidCallback(!this._validation.patter.test(evt.target.value), this._validation.message);
        return;
      }
    }

    if (!isMaxLengthInvalid && this._validation) {
      const value = this._mask ? cleanMask(evt.target.value, this._mask) : evt.target.value;
      const regex = new RegExp(this.getPattern());
      !regex.test(value) ? this.getInputRef().setCustomValidity(this._validation.message) : this.getInputRef().setCustomValidity('');
    }

    if (!this.getInputRef().validity.valid) {
      let message = '';
      if (isMaxLengthInvalid) {
        const max = this._validation.maxLength;

        message = `Only ${ max } character${ max > 1 ? 's' : '' } allowed.`;
      } else {
        message = this._validation.message;
      }

      onValidCallback(this.getInputRef().validity.valid, message);
      return;
    }

    onValidCallback(this.getInputRef().validity.valid, '');
  };

  getPattern = () => {
    if (this._validation.pattern) {
      if (this._validation.pattern.search('MAXLENGTHNUMBER') > -1) {
        return getMaxLengthNumberRegex(this._validation.pattern.split('MAXLENGTHNUMBER')[1]);
      } else if (this._validation.pattern.search('MAXLENGTH') > -1) {
        return getMaxLengthRegex(this._validation.pattern.split('MAXLENGTH')[1], this._validation.specialCharacters);
      } else {
        switch (this._validation.pattern) {
          case 'NUMBER':
          case 'TIN':
          case 'EIN':
            return ValidationRegex[this._validation.pattern];
          default:
            return this._validation.pattern;
        }
      }
    }
    return '';
  };
}

export default Validation;