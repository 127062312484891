import actionTypes from './AppSettingsConstants';
import { post } from 'helpers/axios/axiosInterceptorsV1';

const updateTableOptionsPending = () => ({ type: actionTypes.UPDATE_TABLE_OPTIONS_PENDING });
const updateTableOptionsSuccess = () => ({ type: actionTypes.UPDATE_TABLE_OPTIONS_SUCCESS });
const resetEulaSetting = () => ({ type: actionTypes.RESET_EULA_SETTING });

const addOrMergeUserConfiguration = (name, value, callback) => () => {
  const path = '/users/configurations';
  post(path, { name, value }).then(callback);
};

const addOrMergeColumnSelectorOptions = (fchar, value) => dispatch => {
  dispatch(updateTableOptionsPending());
  dispatch(
    addOrMergeUserConfiguration(
      `SHOW_ON_SEARCH_FIELDS_${fchar}`,
      value,
      () => dispatch(updateTableOptionsSuccess()))
  );
};

export {
  addOrMergeColumnSelectorOptions,
  resetEulaSetting
};
