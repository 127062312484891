import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PayerShortName from './PayerShortName';
import { setFieldValue } from 'shared/dataEntryForms/sovos-dynamic-form/redux/DynamicFormActions';

const mapStateToProps = (state, ownProps) => {
  const { PTINTYPE, PNAME } = state.dynamicForm.form[ownProps.taxEntityCode] || {};

  return {
    tinType: PTINTYPE,
    payerName: PNAME,
    isClone: state.dynamicForm.isClone
  };
};

const mapDispatchToProps = dispatch => ({
  formActions: bindActionCreators({ setFieldValue }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PayerShortName);
