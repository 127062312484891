import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setFieldValue } from 'shared/dataEntryForms/sovos-dynamic-form/redux/DynamicFormActions';
import TotalAmountDifferenceFieldForm from './TotalAmountDifferenceFieldForm';

const mapStateToProps = ({ dynamicForm, payer }, ownProps) => ({
  form: dynamicForm.isClone ? dynamicForm.cloneForm : (dynamicForm.form[ownProps.taxEntityCode] || {}),
  previousValueForm: dynamicForm.previousValueForm,
  payerInformation: payer.data
});

const mapDispatchToProps = dispatch => ({
  formActions: bindActionCreators({ setFieldValue }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TotalAmountDifferenceFieldForm);