import { Component } from 'react';
import { func } from 'prop-types';
import { getErrorMessage } from 'helpers/errorMessageHelper';
import withSnackbar from './withSnackbar';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch() {
    this.props.showErrorSnackbar({ message: getErrorMessage() });
  }

  render() {
    if (this.state.hasError) {
      return null;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  showErrorSnackbar: func.isRequired
};

export default withSnackbar(ErrorBoundary);