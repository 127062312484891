export default [
  {
    title: 'Record type',
    id: 1,
    sortIndicator: 'RecordType',
    dataKey: 'RecordType'
  },
  {
    title: 'Code',
    id: 2,
    sortIndicator: 'Code',
    dataKey: 'Code'
  },
  {
    title: 'Description',
    id: 3,
    width: 'greedy',
    sortIndicator: 'Message',
    dataKey: 'Message'
  },
  {
    title: 'Records',
    id: 4,
    align: 'right',
    sortIndicator: 'Records',
    dataKey: 'Records'
  }
];