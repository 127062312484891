import actionTypes from './JobOperationsConstants';

const initialStore = {
  cancellingJob: false,
  transmittingToIrs: false,
  isErrored: false
};

export default function JobConfigReducer(state = initialStore, action) {
  switch (action.type) {
    case actionTypes.SET_PT_CANCELLING_JOB:
      return { ...state, cancellingJob: true };

    case actionTypes.CANCEL_PT_JOB_SUCCESS:
      return { ...state, cancellingJob: false, isErrored: false };

    case actionTypes.CANCEL_PT_JOB_FAILURE:
      return { ...state, cancellingJob: false, isErrored: true };

    case actionTypes.TRANSMITING_TO_IRS:
      return { ...state, transmittingToIrs: true };

    case actionTypes.TRANSMIT_TO_IRS_FAILURE:
      return { ...state, transmittingToIrs: false, isErrored: true };

    case actionTypes.TRANSMIT_TO_IRS_SUCCESS:
      return { ...state, transmittingToIrs: false, isErrored: false };

    case actionTypes.CLOSE_PT_SLIDING_PANEL:
      return { ...initialStore };

    default:
      return { ...state };
  }
}
