import { Children, useEffect } from 'react';
import { arrayOf, bool, func, node, shape, string } from 'prop-types';
import SovosButton from 'mosaic-react/sovos-button';
import Row from './Row';
import './multipleRows.scss';

const MultipleRows = ({ children, onAddRow, addButtonProps, showAddButton }) => {
  useEffect(() => {
    if (children.length) {
      Children.forEach(children, child => {
        if (child.type !== Row) {
          throw new Error('Only MultipleRows.Row component allowed as children.');
        }
      });
    }
  }, [children]);

  return (
    <div className='multi-rows__container'>
      { children }
      { showAddButton &&
      <div className='multi-rows__button-container'>
        <SovosButton
          variant='text'
          className='card-flat-button'
          onClick={ onAddRow }
          disabled={ addButtonProps.disabled }
        >
          { addButtonProps.label }
        </SovosButton>
      </div>
      }
    </div>
  );
};

MultipleRows.propTypes = {
  children: arrayOf(node),
  showAddButton: bool,
  onAddRow: func.isRequired,
  addButtonProps: shape({
    label: string,
    disabled: bool
  })
};

MultipleRows.defaultProps = {
  children: [],
  showAddButton: true,
  addButtonProps: {
    label: 'Add additional value',
    disabled: false
  }
};

MultipleRows.Row = Row;

export default MultipleRows;
