import actionTypes from './RecordConstants';
import sovosFunctions from 'helpers/sovosFunctions';
import { getRecordName } from 'helpers/formConstants';

const initialState = {
  records: [],
  errors: [],
  failed: [],
  fetchingRecords: false,
  fetchingErrors: false,
  fetchingFailed: false,
  total: 0,
  errorsTotal: 0,
  exportStarted: false,
  exportFailed: false,
  exportError: {}
};

const objectKeysToUppercase = records => records.map(record => sovosFunctions.ObjectKeysToUppercase(record));

export default function RecordReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCHING_RECORDS:
      return {
        ...state,
        fetchingRecords: true,
        fetchingFailed: false
      };

    case actionTypes.FETCH_RECORDS_SUCCESS:
      return {
        ...state,
        fetchingRecords: false,
        records: objectKeysToUppercase(action.records.Data) || [],
        total: action.records.Total
      };

    case actionTypes.FETCH_RECORDS_FAILURE:
      return { ...state, fetchingRecords: false, fetchingFailed: true };

    case actionTypes.FETCHING_ERRORS_RECORDS:
      return {
        ...state,
        fetchingErrors: true,
        errors: [],
        total: 0
      };

    case actionTypes.FETCH_ERRORS_RECORDS_SUCCESS:
      return {
        ...state,
        fetchingErrors: false,
        errors: action.records
          ? action.records.Data.map(record => ({
            ...record,
            RecordType: getRecordName(record.RecordType),
            Fchar: record.RecordType
          }))
          : [],
        errorsTotal: action.records ? action.records.Total : 0
      };

    case actionTypes.FETCH_ERRORS_RECORDS_FAILURE:
      return { ...state, fetchingErrors: false };

    case actionTypes.FETCHING_FAILED_RECORDS:
      return {
        ...state,
        fetchingFailed: true,
        failed: []
      };

    case actionTypes.FETCH_FAILED_RECORDS_SUCCESS:
      return {
        ...state,
        fetchingFailed: false,
        failed: action.records.Data
      };

    case actionTypes.FETCH_FAILED_RECORDS_FAILURE:
      return { ...state, fetchingFailed: false };

    case actionTypes.RESET_RECORDS:
      return { ...state,
        fetchingRecords: false,
        records: [],
        total: 0
      };

    case actionTypes.EXPORT_RECORDS_STARTED:
      return { ...state, exportStarted: true, exportFailed: false };

    case actionTypes.EXPORT_RECORDS_FINISHED:
      return { ...state, exportStarted: false };

    case actionTypes.EXPORT_RECORDS_FAILED:
      return { ...state, exportFailed: true, exportStarted: false, exportError: action.exportError };

    case actionTypes.LOGOUT_SUCCESS:
      return { ...initialState };

    default:
      return { ...state };
  }
}
