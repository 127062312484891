import PropTypes from 'prop-types';
import EntityFetcher from '../entityFetcher/EntityFetcher';

const SPID = ({ dynamicFormActions, isClone, form, value, readOnlyUser, taxEntityCode }) => {
  //TODO: Refactor to a single fetchentity object and remove NQI SPID and PayerWH....
  // this mapping should go to the metadata, and we should save here the url and the fetch related info.
  const fieldsMap = {
    'S09': 'Ptin',
    'S23': 'Pname',
    'S24': 'Pname2',
    'S10': 'Pein',
    'C04': 'PtinType',
    'S14': 'Pgiin',
    'N11': 'Pch3StCode',
    'N12': 'Pch4StCode'
  };

  const handlePayerSelect = payer => {
    Object.keys(fieldsMap).forEach(key => {
      if (payer) {
        dynamicFormActions.setFieldValue(key, payer[fieldsMap[key]] || '', isClone, taxEntityCode);
      }
      dynamicFormActions.setDisabledByEntityFetcher(fieldsMap);
    });
  };

  const handlePayerRemove = () => {
    Object.keys(fieldsMap).forEach(key => {
      dynamicFormActions.setFieldValue(key, '', isClone, taxEntityCode);
      dynamicFormActions.unsetDisabledByEntityFetcher(fieldsMap);
    });
  };

  const checkForUserInput = () => {
    let containsUserInput = false;
    Object.keys(fieldsMap).forEach(key => {
      if (form[key]) {
        containsUserInput = true;
      }
    });
    return containsUserInput;
  };

  return (
    <EntityFetcher
      label={ 'Payer different from withholding agent' }
      onEntitySelect={ handlePayerSelect }
      onEntityRemove={ handlePayerRemove }
      fetchMultipleUrl={ `/payers/search` }
      fetchSingleURL={ '/payers/' }
      entityLabel={ 'payer' }
      initialValueKey={ 'Ptin' }
      containsUserInput={ checkForUserInput }
      value={ value }
      readOnlyUser={ readOnlyUser }
    />
  );
};

SPID.propTypes = {
  isClone: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  dynamicFormActions: PropTypes.shape({
    setFieldValue: PropTypes.func,
    setDisabledByEntityFetcher: PropTypes.func,
    unsetDisabledByEntityFetcher: PropTypes.func
  }).isRequired,
  readOnlyUser: PropTypes.bool.isRequired,
  taxEntityCode: PropTypes.string.isRequired
};

export default SPID;
