import { bool, string } from 'prop-types';
import SovosTypography from 'mosaic-react/sovos-typography';
import SovosChip from 'mosaic-react/sovos-chip-choice';
import { getReturnStatusType } from 'shared/dataEntryForms/sovos-dynamic-form/DynamicFormHelper';
import './w2TransmittalStatus.scss';

const W2TransmittalStatus = ({ name, returnStatus, transmitStatus }) =>
  (
    <div className='w2-transmittal-status__container' data-for='transmittalStatus'>
      <div className='w2-transmittal-status__text-container'>
        <SovosTypography variant='body1' className='w2-transmittal-status__name'>{ name }&nbsp;|&nbsp;</SovosTypography>
        <SovosTypography variant='body1'>{ `Return status: ${getReturnStatusType(returnStatus)}` }</SovosTypography>
      </div>
      { transmitStatus && <SovosChip label={ 'Transmitted' }/> }
    </div>
  );

W2TransmittalStatus.propTypes = {
  name: string.isRequired,
  returnStatus: string.isRequired,
  transmitStatus: bool.isRequired
};

export default W2TransmittalStatus;