import { string, func, object, array, bool } from 'prop-types';
import SovosButton from 'mosaic-react/sovos-button';
import { the1042FormTaxEntityCode } from 'helpers/formConstants';
import './form1042ScheduleQButton.scss';

const Form1042ScheduleQButton = ({ boxName, label, formActions, form1042ScheduleQs, paymentByQddFlag, readOnlyUser, readOnly }) => {

  const handleAddNewScheduleQ = () => {
    let lastId = 0;
    if (form1042ScheduleQs.length) {
      lastId = form1042ScheduleQs[form1042ScheduleQs.length - 1].id +1;
    }
    formActions.setFieldValue('Form1042ScheduleQs', [...form1042ScheduleQs, { id: lastId }], false, the1042FormTaxEntityCode);
  };

  return (
    <div className='tirTextfieldForm__wrapper form-1042-schedule-q-button__container'>
      <div className='label__container'>
        <div className='label__container-left'>
          { boxName &&
          <label
            className={ 'tirTexfield__label-box label__left-box-name' }
            htmlFor={ boxName }
          >
            { boxName }
          </label> }
          <label
            className='tirTexfield__label'
            htmlFor={ boxName }
          >
            { label }
          </label>
        </div>
      </div>
      <SovosButton
        id={ label }
        variant= 'text'
        onClick={ handleAddNewScheduleQ }
        disabled={ paymentByQddFlag !== 'X' || readOnly || readOnlyUser }
      >
        Attach Schedule Q
      </SovosButton>
    </div>
  );
};

Form1042ScheduleQButton.propTypes = {
  boxName: string.isRequired,
  label: string.isRequired,
  onChange: func.isRequired,
  formActions: object.isRequired,
  form1042ScheduleQs: array,
  paymentByQddFlag: string,
  readOnlyUser: bool.isRequired,
  readOnly: bool.isRequired
};

Form1042ScheduleQButton.defaultProps = {
  readOnlyUser: false,
  readOnly: false
};

export default Form1042ScheduleQButton;