import sharedActions from '../constants/SharedConstants';

const actionTypes = {
  FETCHING_CONTEXTS: 'FETCHING_CONTEXTS',
  FETCH_CONTEXTS_SUCCESS: 'FETCH_CONTEXTS_SUCCESS',
  FETCHING_USER: 'FETCHING_USER',
  FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
  FETCH_USER_FAIL: 'FETCH_USER_FAIL',
  FETCHING_CLIENT: 'FETCHING_CLIENT',
  FETCH_CLIENT_SUCCESS: 'FETCH_CLIENT_SUCCESS',
  FETCH_CLIENT_FAIL: 'FETCH_CLIENT_FAIL',
  SAVE_USER_CONTEXT_SUCCESS: 'SAVE_USER_CONTEXT_SUCCESS',
  SAVE_USER_CONTEXT_FAILURE: 'SAVE_USER_CONTEXT_FAILURE',
  SET_CONTEXT_SUCCESS: 'SET_CONTEXT_SUCCESS',
  FETCH_USER_CONTEXT_FAILURE: 'FETCH_USER_CONTEXT_FAILURE',
  SET_CONTEXT_ID: 'SET_CONTEXT_ID',
  SET_CONTEXT_TREE: 'SET_CONTEXT_TREE',
  SET_CONTEXTS: 'SET_CONTEXTS'
};

export default { ...actionTypes, ...sharedActions };
