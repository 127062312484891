import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SovosButton from 'mosaic-react/sovos-button';
import EntityFetchTypeAhead from './entityFetchTypeAhead/EntityFetchTypeAhead';
import { get, post } from 'helpers/axios/axiosInterceptorsV1';
import ConfirmationDialog from 'shared/confirmationDialog/ConfirmationDialog';
import './entityFetcher.scss';

const NOT_FOUND = 'notFound';

const EntityFetcher = ({
  boxName,
  label,
  onEntitySelect,
  onEntityRemove,
  fetchMultipleUrl,
  fetchSingleURL,
  entityLabel,
  initialValueKey,
  idKeyName,
  containsUserInput,
  value,
  readOnlyUser
}) => {
  const ADD_ENTITY = `Add ${ entityLabel }`;
  const EDIT_ENTITY = `Edit ${ entityLabel }`;
  const SAVE_ENTITY = `Save ${ entityLabel }`;

  const [displayTypeAhead, setDisplayTypeAhead] = useState(false);
  const [buttonLabel, setButtonLabel] = useState(ADD_ENTITY);
  const [entities, setEntities] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState();
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [confirmingRemove, setConfirmingRemove] = useState(false);
  const [selectedEntityInfo, setSelectedEntityInfo] = useState();

  useEffect(() => {
    if (value) {
      renderSavedValue();
    }
  }, []);

  let fetchCountDown;

  const renderSavedValue = () => {
    setButtonLabel(EDIT_ENTITY);
    onEntitySelect();
  };

  const handleButtonClick = () => {
    if (buttonLabel === SAVE_ENTITY) {
      setButtonLabel(EDIT_ENTITY);
      setDisplayTypeAhead(false);
    }
    else {
      if (buttonLabel === EDIT_ENTITY) {
        setButtonLabel(SAVE_ENTITY);
      }
      setDisplayTypeAhead(true);
      window.setTimeout(function () {
        document.getElementById(`react-autowhatever-${entityLabel}`).previousSibling.focus();
      }, 0);
    }
  };

  const fetchEntity = async entityInfo => {
    setLoading(true);
    const response = await get(`${ fetchSingleURL }${ entityInfo[idKeyName] }`);
    const entity = response.data.Entity;
    setSelectedEntity(entity);
    setEntities([entity]);
    setDisplayTypeAhead(false);
    setButtonLabel(EDIT_ENTITY);
    onEntitySelect(entity);
    setLoading(false);
  };

  const handleEntitySelect = entityInfo => {
    setSelectedEntityInfo(entityInfo);
    if (entityInfo.Name !== NOT_FOUND) {
      containsUserInput() ? setShowDialog(true) : fetchEntity(entityInfo);
    }
    else {
      resetComponent();
    }
  };

  const handleEntitySelectFromDialog = () => {
    fetchEntity(selectedEntityInfo);
    setShowDialog(false);
  };

  const handleFetchRequest = ({ value, reason }) => {
    if (value.length >= 3) {
      setLoading(true);
      clearTimeout(fetchCountDown);
      fetchCountDown = setTimeout(async () => {
        const result = await post(fetchMultipleUrl, { SearchText: value });
        const entities = result.data.Data;
        setEntities(entities.length > 0 ? entities : [{ Name: NOT_FOUND }]);
        setLoading(false);
      }, 350);
    }
    else {
      setEntities([]);
    }
  };

  const handleCloseTypeAhead = () => {
    setConfirmingRemove(true);
    setShowDialog(true);
  };

  const resetComponent = () => {
    setSelectedEntity(undefined);
    setSelectedEntityInfo(undefined);
    setEntities([]);
    setShowDialog(false);
    setDisplayTypeAhead(false);
    setButtonLabel(ADD_ENTITY);
  };

  const handleOkDialogClick = () => {
    resetComponent();
    onEntityRemove();
    setConfirmingRemove(false);
  };

  const handleCancelDialogClick = () => {
    setShowDialog(false);
    setConfirmingRemove(false);
  };

  return (
    <div className="entity-fetcher">
      <div className="entity-fetcher__label">
        { boxName && <div>{ boxName }</div> }
        <div className="entity-fetcher__label-padding">{ label }</div>
      </div>
      {
        <EntityFetchTypeAhead
          onFetchRequest={ handleFetchRequest }
          data={ entities }
          onSuggestionSelected={ handleEntitySelect }
          show={ displayTypeAhead }
          initialValue={ (selectedEntity && selectedEntity[initialValueKey]) || value || '' }
          loading={ loading }
          onClose={ handleCloseTypeAhead }
          entityLabel={ entityLabel }
        />
      }
      {
        !displayTypeAhead &&
        selectedEntity &&
        <div className="entity-fetcher__suggestion-preview" >
          { `${ entityLabel } ID:` } <span className="entity-fetcher__suggestion-preview_bold">{ selectedEntity[initialValueKey] }</span>
        </div>
      }
      {
        !displayTypeAhead &&
        !selectedEntity &&
        value &&
        <div className="entity-fetcher__suggestion-preview" >
          { `${ entityLabel } ID:` } <span className="entity-fetcher__suggestion-preview_bold">{ value }</span>
        </div>
      }
      { !readOnlyUser && <SovosButton variant='text' className='entity-fetcher__button' onClick={ handleButtonClick }>{ buttonLabel }</SovosButton> }
      <ConfirmationDialog
        open={ showDialog }
        onOKClick={ confirmingRemove ? handleOkDialogClick : handleEntitySelectFromDialog }
        onCancelClick={ handleCancelDialogClick }
        content={
          confirmingRemove
            ? `Are you sure you want to remove this ${ entityLabel } from this form?`
            : `Adding this ${ entityLabel } will replace all current values in the "${ label }" section`
        }
        title={ confirmingRemove ? `Remove ${ entityLabel }?` : `Add ${ entityLabel }` }
        actionLabel={ confirmingRemove ? `Remove ${ entityLabel }` : `Add ${ entityLabel }` }
      />
    </div>
  );
};

EntityFetcher.propTypes = {
  label: PropTypes.string.isRequired,
  boxName: PropTypes.string,
  onEntitySelect: PropTypes.func,
  onEntityRemove: PropTypes.func,
  fetchMultipleUrl: PropTypes.string.isRequired,
  fetchSingleURL: PropTypes.string.isRequired,
  entityLabel: PropTypes.oneOf(['recipient', '1042-S recipient', 'payer']).isRequired,
  initialValueKey: PropTypes.string,
  idKeyName: PropTypes.string,
  containsUserInput: PropTypes.func,
  value: PropTypes.string,
  readOnlyUser: PropTypes.bool.isRequired
};

EntityFetcher.defaultProps = {
  onEntitySelect: () => null,
  onEntityRemove: () => null,
  initialValueKey: '',
  idKeyName: 'Id',
  boxName: null
};

export default EntityFetcher;
