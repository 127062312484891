import { Component } from 'react';
import TinMask from '../../dataModifier/tinMask/TinMask';
import CheckCircle from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import Typography from '@mui/material/Typography';
import AuthenticationHelper from 'helpers/authenticationHelper';
import './withColumnsByProduct.scss';

const renderTinMask = ({ content }) => <TinMask records={ { TIN: content.PayeeTin, TINTYPE: content.PayeeTinType } } />;

const renderStatus = ({ content }) => (
  content.ErrorCount
    ? <div className='with-columns-by-product__status--error'>
      <ErrorIcon color='error' />
      <Typography display='inline'>{ `${content.ErrorCount} error${content.ErrorCount === 1 ? '' : 's'}` }</Typography>
    </div>
    : <CheckCircle className='with-columns-by-product__status--no-error' />
);
const withColumnsByProduct = WrappedComponent => (class extends Component {
  render() {
    const columns = AuthenticationHelper.isTIREnterprise() ? [
      {
        title: 'Name',
        id: 1,
        notSortable: true,
        sortIndicator: 'PayeeName',
        dataKey: 'PayeeName'
      },
      {
        title: 'TIN',
        id: 2,
        notSortable: true,
        sortIndicator: 'PayeeTin',
        component: renderTinMask
      },
      {
        title: 'Account',
        id: 3,
        notSortable: true,
        sortIndicator: 'Account',
        component: ({ content }) => (
          content.Account ? content.Account : null
        )
      },
      {
        title: 'GML',
        id: 4,
        notSortable: true,
        sortIndicator: 'GML',
        component: ({ content }) => (
          content.GML ? content.GML : null
        )
      },
      {
        title: 'Status',
        id: 5,
        width: 'greedy',
        sortIndicator: 'ErrorCount',
        component: renderStatus
      }
    ] : [
      {
        title: 'Name',
        id: 1,
        notSortable: true,
        sortIndicator: 'PayeeName',
        dataKey: 'PayeeName'
      },
      {
        title: 'TIN',
        id: 2,
        notSortable: true,
        sortIndicator: 'PayeeTin',
        component: renderTinMask
      },
      {
        title: 'Status',
        id: 3,
        width: 'greedy',
        sortIndicator: 'ErrorCount',
        component: renderStatus
      }
    ];
    return (
      <WrappedComponent columns={ columns } { ...this.props } />
    );
  }
});

export default withColumnsByProduct;