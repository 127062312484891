import sharedActions from 'redux/constants/SharedConstants';

const actionTypes = {
  ...sharedActions,
  FETCHING_RECORDS: 'FETCHING_RECORDS',
  FETCH_RECORDS_SUCCESS: 'FETCH_RECORDS_SUCCESS',
  FETCH_RECORDS_FAILURE: 'FETCH_RECORDS_FAILURE',
  FETCHING_ERRORS_RECORDS: 'FETCHING_ERRORS_RECORDS',
  FETCH_ERRORS_RECORDS_SUCCESS: 'FETCH_ERRORS_RECORDS_SUCCESS',
  FETCH_ERRORS_RECORDS_FAILURE: 'FETCH_ERRORS_RECORDS_FAILURE',
  FETCHING_FAILED_RECORDS: 'FETCHING_FAILED_RECORDS',
  FETCH_FAILED_RECORDS_SUCCESS: 'FETCH_FAILED_RECORDS_SUCCESS',
  FETCH_FAILED_RECORDS_FAILURE: 'FETCH_FAILED_RECORDS_FAILURE',
  RESET_RECORDS: 'RESET_RECORDS',
  EXPORT_RECORDS_STARTED: 'EXPORT_RECORDS_STARTED',
  EXPORT_RECORDS_FINISHED: 'EXPORT_RECORDS_FINISHED',
  EXPORT_RECORDS_FAILED: 'EXPORT_RECORDS_FAILED'
};

export default actionTypes;
