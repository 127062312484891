import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setFieldValue } from '../../../sovos-dynamic-form/redux/DynamicFormActions';
import Form1042ScheduleQButton from './Form1042ScheduleQButton';

const mapStateToProps = (state, ownProps) => ({
  form1042ScheduleQs: state.dynamicForm.form[ownProps.taxEntityCode]?.Form1042ScheduleQs || [],
  paymentByQddFlag: state.dynamicForm.form[ownProps.taxEntityCode]?.PaymentByQddFlag
});

const mapDispatchToProps = dispatch => ({
  formActions: bindActionCreators({ setFieldValue }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Form1042ScheduleQButton);