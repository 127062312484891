import sharedActions from 'redux/constants/SharedConstants';

const filesActions = {
  FETCHING_FILES: 'FETCHING_FILES',
  RESET_PAGE_SETTINGS: 'RESET_PAGE_SETTINGS',
  FETCH_FILES_SUCCESS: 'FETCH_FILES_SUCCESS',
  FETCH_FILES_FAILURE: 'FETCH_FILES_FAILURE',
  DELETING_FILE: 'DELETING_FILE',
  DELETE_FILE_SUCCESS: 'DELETE_FILE_SUCCESS',
  DELETE_FILE_FAILURE: 'DELETE_FILE_FAILURE',
  RENAMING_FILE: 'RENAMING_FILE',
  RENAME_FILE_SUCCESS: 'RENAME_FILE_SUCCESS',
  RENAME_FILE_FAILURE: 'RENAME_FILE_FAILURE',
  MOVING_FILE: 'MOVING_FILE',
  MOVE_FILE_SUCCESS: 'MOVE_FILE_SUCCESS',
  MOVE_FILE_FAILURE: 'MOVE_FILE_FAILURE',
  FETCHING_OVERVIEW: 'FETCHING_OVERVIEW',
  FETCH_OVERVIEW_SUCCESS: 'FETCH_OVERVIEW_SUCCESS',
  FETCH_OVERVIEW_FAILURE: 'FETCH_OVERVIEW_FAILURE',
  UPLOADING_FILE: 'UPLOADING_FILE',
  UPLOADING_FILE_SUCCESS: 'UPLOADING_FILE_SUCCESS',
  DOWNLOADING_FILE: 'DOWNLOADING_FILE',
  DOWNLOAD_FILE_SUCCESS: 'DOWNLOAD_FILE_SUCCESS',
  DOWNLOAD_FILE_FAIL: 'DOWNLOAD_FILE_FAIL',
  DOWNLOADING_ALL: 'DOWNLOADING_ALL',
  RESET_DOWNLOAD_FILE_STATUS: 'RESET_DOWNLOAD_FILE_STATUS',
  RESET_DOWNLOAD_ALL_STATUS: 'RESET_DOWNLOAD_ALL_STATUS',
  ...sharedActions
};

export default filesActions;
