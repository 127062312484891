import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchRecordHistory } from 'redux/formHistory/formHistoryActions';
import HistoryTab from './HistoryTab';

const mapStateToProps = (state, ownProps) => ({
  shouldFetchAgain: state.formHistory.shouldFetchAgain,
  formHistory: state.formHistory.history,
  fetchingHistory: state.formHistory.fetchingHistory,
  fetchingMetadata: state.formMetaInformation.fetchingMetadata,
  allFields: state.formMetaInformation.allFields[ownProps.formType]
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  historyActions: bindActionCreators({
    fetchRecordHistory: () => fetchRecordHistory(ownProps.formType, ownProps.formId)
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(HistoryTab);
