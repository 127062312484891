import { connect } from 'react-redux';
import ThirdPartyPayer from './ThirdPartyPayer';
import { bindActionCreators } from 'redux';
import { setFieldValue, unsetDisabledByEntityFetcher, setDisabledByEntityFetcher } from '../../../sovos-dynamic-form/redux/DynamicFormActions';

const mapDispatchToProps = dispatch => ({
  dynamicFormActions: bindActionCreators({
    setFieldValue,
    setDisabledByEntityFetcher,
    unsetDisabledByEntityFetcher
  }, dispatch)
});

const mapStateToProps = ({ dynamicForm }, ownProps) => ({
  isClone: dynamicForm.isClone,
  form: dynamicForm.form[ownProps.taxEntityCode] || {}
});

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPartyPayer);
