import types from './NRecipientConstants';
import { get, post } from 'helpers/axios/axiosInterceptorsV1';
import { buildUrlsParams } from 'helpers/buildUrlParams';

const fetchNRecipientSuccess = nRecipient => ({ type: types.FETCH_N_RECIPIENT_SUCCESS, nRecipient });
const fetchNRecipientByRegexSuccess = nRecipients => ({ type: types.FETCH_N_RECIPIENT_BY_REGEX_SUCCESS, nRecipients });

const fetchNRecipient = id => async dispatch => {
  const path = `/npayees/${id}`;
  dispatch({ type: types.FETCHING_N_RECIPIENT });

  try {
    const response = await get(path);
    dispatch(fetchNRecipientSuccess(response.data.Entity));
  } catch (error) {
    dispatch({ type: types.FETCH_N_RECIPIENT_FAILURE });
    throw (error);
  }
};

const nRecipientExists = accountno => async dispatch => {
  if (accountno) {
    const path = `/npayees/search?${buildUrlsParams({ pageOffset: 1, pageSize: 10000, auto: true })}`;
    const body = {
      SearchText: accountno
    };

    dispatch({ type: types.CHECKING_FOR_DUPLICATE_FIELD });

    try
    {
      const response = await post(path, body);

      dispatch({
        type: types.CHECK_FOR_DUPLICATE_FIELD_SUCCESS,
        field: 'ACCOUNTNO',
        isDuplicate: Boolean(response.data.Data.find(payee => payee.AccountNo === accountno.toUpperCase()))
      });
    }

    catch (err) {
      dispatch({ type: types.CHECK_FOR_DUPLICATE_FIELD_FAILURE });
    }
  } else {
    dispatch({
      type: types.CHECK_FOR_DUPLICATE_FIELD_SUCCESS,
      field: 'TIN',
      isDuplicate: false
    });
  }
};

const fetchNRecipientByRegex = searchText => async dispatch => {
  const query = { pageOffset: 1, pageSize: 15, auto: true };
  const path = `/npayees/search?${buildUrlsParams(query)}`;
  dispatch({ type: types.FETCHING_N_RECIPIENT });

  try {
    const response = await post(path, { SearchText: searchText });
    dispatch(fetchNRecipientByRegexSuccess(response.data.PageSize ? response.data.Data : [{ Name: 'notFound' }]));
  } catch (error) {
    dispatch({ type: types.FETCH_N_RECIPIENT_BY_REGEX_FAILURE });
    throw (error);
  }
};

const setNRecipient = nRecipient => dispatch => dispatch({ type: types.SET_N_RECIPIENT, nRecipient });

const setNRecipientBoolean = () => dispatch => dispatch({ type: types.SET_N_RECIPIENT_BOOLEAN });

const clearNRecipient = () => dispatch => dispatch({ type: types.RESET_N_RECIPIENT });

export {
  setNRecipient,
  setNRecipientBoolean,
  fetchNRecipient,
  fetchNRecipientByRegex,
  clearNRecipient,
  nRecipientExists
};
