import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ValidationGridTextField from 'shared/standardInput/textField/validationGridTextField/ValidationGridTextFieldInterface';
import MultipleRows from '../shared/multipleRows/MultipleRows';
import './payerLocalities.scss';

const STATE = 'State';
const CODE = 'Code';
const TAX_ID = 'TaxId';

const validations = {
  [STATE]: {
    pattern: '^[a-zA-Z]{2}$|^$',
    message: 'State must be 2 letters',
    validateOn: 'keyup'
  },
  [CODE]: {
    pattern: 'MAXLENGTH20',
    specialCharacters: '\\-.',
    message: 'Locality must not exceed 20 valid characters',
    validateOn: 'keyup'
  },
  [TAX_ID]: {
    pattern: 'MAXLENGTH40',
    specialCharacters: '\\-',
    message: 'Locality tax ID must not exceed 40 valid characters',
    validateOn: 'keyup'
  }
};

const newRow = { [STATE]: '', [CODE]: '', [TAX_ID]: '' };

const checkForDuplicateStateLocalities = localities => {
  let tracker = {};
  for (const locality of localities) {
    if (tracker[`${locality[STATE]}${locality[CODE]}`]) {
      return locality;
    }
    tracker[`${locality[STATE]}${locality[CODE]}`] = true;
  }
  return null;
};

const PayerLocalities = ({ localities, isClone, formActions, readOnlyUser, taxEntityCode }) => {
  const [localLocalities, setLocalLocalities] = useState(localities.length ? [...localities] : [newRow]);
  const [duplicateStateLocalities, setDuplicateStateLocalities] = useState();

  useEffect(() => {
    const completedRows = localLocalities.filter(locality => locality[STATE] && locality[CODE] && locality[TAX_ID]);
    if (completedRows?.length) {
      formActions.setFieldValue('LOCALITIES', completedRows, isClone, taxEntityCode);
    }
    const duplicate = checkForDuplicateStateLocalities(completedRows);
    setDuplicateStateLocalities(duplicate);
    formActions.setSaveDisableByValidation(Boolean(duplicate));
  }, [localLocalities]);

  const handleAddNewLocality = () => {
    setLocalLocalities(prevState => [...prevState, newRow]);
  };

  const handleDeleteLocality = index => (
    setLocalLocalities(prevState => {
      if (prevState.length === 1) {
        return [newRow];
      }
      return prevState.filter((item, idx) => idx !== index);
    })
  );

  const handleChange = (key, value, index) => {
    setLocalLocalities(prevLocality => prevLocality.map(
      (stateInfo, idx) => idx === index
        ? { ...stateInfo, [key]: value.toUpperCase() }
        : stateInfo));
  };

  return (
    <div className='payer-localities__container'>
      <MultipleRows
        onAddRow={ handleAddNewLocality }
        showAddButton={ !readOnlyUser }
        addButtonProps={ { label: '+ Add another locality' } }
      >
        {
          Boolean(localLocalities.length) &&
            localLocalities.map((mState, idx) => (
              <MultipleRows.Row
                key={ idx }
                onDeleteRow={ handleDeleteLocality }
                row={ { Id: idx } }
                showDeleteButton={ !readOnlyUser && localLocalities.length >= 2 }
              >
                <ValidationGridTextField
                  label='State'
                  name={ `Locality-${STATE}-${idx}` }
                  value={ mState[STATE] }
                  onChange={ ({ target: { value } }) => handleChange(STATE, value, idx) }
                  errorText={
                    duplicateStateLocalities?.[STATE] === mState[STATE] && duplicateStateLocalities?.[CODE] === mState[CODE]
                      ? 'State and locality combinations must be unique.'
                      : ''
                  }
                  validation= { validations[STATE] }
                  readOnlyUser={ readOnlyUser }
                />
                <ValidationGridTextField
                  label='Locality'
                  name={ `Locality-${CODE}-${idx}` }
                  value={ mState[CODE] }
                  onChange={ ({ target: { value } }) => handleChange(CODE, value, idx) }
                  errorText={
                    duplicateStateLocalities?.[STATE] === mState[STATE] && duplicateStateLocalities?.[CODE] === mState[CODE]
                      ? 'State and locality combinations must be unique.'
                      : ''
                  }
                  validation= { validations[CODE] }
                  readOnlyUser={ readOnlyUser }
                />
                <ValidationGridTextField
                  label='Locality tax ID'
                  name={ `Locality-${TAX_ID}-${idx}` }
                  value={ mState[TAX_ID] }
                  onChange={ ({ target: { value } }) => handleChange(TAX_ID, value, idx) }
                  validation= { validations[TAX_ID] }
                  readOnlyUser={ readOnlyUser }
                />
              </MultipleRows.Row>
            ))
        }
      </MultipleRows>
    </div>
  );
};

PayerLocalities.propTypes = {
  localities: PropTypes.array.isRequired,
  isClone: PropTypes.bool.isRequired,
  formActions: PropTypes.shape({
    setFieldValue: PropTypes.func,
    setSaveDisableByValidation: PropTypes.func
  }).isRequired,
  readOnlyUser: PropTypes.bool.isRequired,
  taxEntityCode: PropTypes.string.isRequired
};

export default PayerLocalities;
