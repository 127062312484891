import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CollapseIcon from '@mui/icons-material/ExpandLess';
import ExpandIcon from '@mui/icons-material/ExpandMore';
import SovosTypography from 'mosaic-react/sovos-typography';
import theme from 'mosaic-react/themes/sovos-theme';
import './dynamicFormValidations.scss';

const { palette } = theme;

const iconStyle = { fill: palette.text.contrast };

class DynamicFormValidations extends PureComponent {
  state = {
    isCollapsed: false
  };

  toggleExpanded = () => this.setState(oldState => ({ isCollapsed: !oldState.isCollapsed }));

  render() {
    const { isClone, formErrors, cloneFormErrors } = this.props;
    const { isCollapsed } = this.state;

    const validations = isClone ? cloneFormErrors : formErrors;

    return validations.length
      ? <div className="form-validations__container">
        {
          <SovosTypography variant='h5' onClick={ this.toggleExpanded }>{ validations.length } Errors
            <span>{
              isCollapsed
                ? <ExpandIcon style={ iconStyle } />
                : <CollapseIcon style={ iconStyle } />
            }</span>
          </SovosTypography>
        }
        <div className={
          `form-validations__validation-list form-validations__validation-list__${ isCollapsed ? 'collapsed' : 'expanded'}`
        }>
          {
            validations.map(validation => (
              <p key={ validation.Code } className="form-validations__validation">
                <span>{ validation.Code }</span> { validation.Message }
              </p>
            ))
          }
        </div>
      </div>
      : null;
  }
}

DynamicFormValidations.propTypes = {
  isClone: PropTypes.bool.isRequired,
  formErrors: PropTypes.array,
  cloneFormErrors: PropTypes.array,
  fetchingForm: PropTypes.bool.isRequired
};

DynamicFormValidations.defaultProps = {
  formErrors: [],
  cloneFormErrors: []
};

export default DynamicFormValidations;
