// TODO: In the future this file will be moved to a library.
// Please do not modify, take consideration this file is duplicated in another project
import { useEffect, useRef } from 'react';
import { bool, node } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import AcrossTabs from 'across-tabs';
import { AutomaticLogoutContext } from './AutomaticLogoutContext';
import useManageTimes from './useManageTimes';
import { ADMIN_PORTAL_URL } from 'infrastructure/appConfig';

/** AutomaticLogoutChildProvider is a provider to use only on TIR */
const AutomaticLogoutChildProvider = ({ children, isSupport }) => {
  const navigate = useNavigate();
  const childTabRef = useRef(null);

  const logout = () => {
    if (!isSupport) {
      navigate('/logout');
    }
  };

  const sendMessageToParent = () => {
    if (isSupport && childTabRef.current) {
      childTabRef.current.sendMessageToParent('resetIdleTimeout');
    }
  };

  const { idleAlert } = useManageTimes({ onLogout: logout, onReset: sendMessageToParent });

  var childTabConfig = {
    handshakeExpiryLimit: 4000,
    origin: ADMIN_PORTAL_URL
  };

  useEffect(() => {
    if (isSupport) {
      childTabRef.current = new AcrossTabs.Child(childTabConfig);
    }

    return () => {
      if (isSupport) {
        childTabRef.current = null;
      }

      return null;
    };
  }, []);

  return (
    <AutomaticLogoutContext.Provider
      value={ {
        idleAlert
      } }
    >
      { children }
    </AutomaticLogoutContext.Provider>
  );
};

AutomaticLogoutChildProvider.propTypes = {
  children: node.isRequired,
  /** isSupport should only use in TIR, and the value is determinated to isInternalRole() function */
  isSupport: bool
};

AutomaticLogoutChildProvider.defaultProps = {
  isSupport: false
};

export default AutomaticLogoutChildProvider;
