import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setFieldValue } from 'shared/dataEntryForms/sovos-dynamic-form/redux/DynamicFormActions';
import RadioButtonFieldForm from './RadioButtonFieldForm';

const mapStateToProps = (state, ownProps) => ({
  form: state.dynamicForm.form[ownProps.taxEntityCode] || {},
  isClone: state.dynamicForm.isClone
});

const mapDispatchToProps = dispatch => ({
  formActions: bindActionCreators({ setFieldValue }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(RadioButtonFieldForm);
