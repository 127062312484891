import { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import TextMaskCustom from './TextMaskCustom';
import { getLabel } from '../../dataEntryForms/sovos-dynamic-form/helper/labelHelper';
import CurrencyFormat from 'react-currency-format';
import WrapperMuiTextField from './helper/WrapperMuiTextField';
import SovosTooltip from '../../sovos-tooltip/SovosTooltip';
import { determineMask, cleanMask } from './helper/maskHelper';
import Validation from './helper/Validation';
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';
import theme from 'mosaic-react/themes/sovos-theme';
import './validationGridTextField/validationGridTextField.scss';

const { palette } = theme;

const styles = {
  root: {
    boxSizing: 'border-box',
    '& .MuiInputBase-root': {
      '&:before': {
        content: 'none'
      },
      '&:after': {
        borderBottom: 'none'
      }
    }
  },
  input: {
    height: 50,
    boxSizing: 'border-box',
    padding: '0 10px',
    borderLeft: `1px solid ${palette.divider}`
  }
};

class TextFieldForm extends Component {
  constructor(props) {
    super(props);
    this.objRef = {};
    this.isMaxLengthInvalid = false;
    this.validation = new Validation(this.props.name, this.props.labelValidation, this.props.validation, this.props.mask, this.handleOnValidation, Boolean(this.props.multiLine));
    this.maskToApply = this.maskIsCurrency() ? '' : determineMask(this.props.mask);
    this.state = {
      validationErrorMessage: '',
      currencyInternalValue: this.getFixedValue(props.value, props.mask)
    };
  }

  componentDidMount() {
    this.validation.addValidation(this.getInputRef());
    if (this.props.modLevel) {
      const { name, value } = this.props;
      this.validation.validateComponent({ target: { name, value } }, null, this.isMaxLengthInvalid);
    }
  }

  componentDidUpdate = prevProps => {
    if (!prevProps.reset && this.props.reset) {
      this.validation.validateComponent(null, this.props.reset, this.isMaxLengthInvalid, (valid, message) => {
        this.setState({ validationErrorMessage: message }, () => this.props.onValid(valid));
      });
    }
    if (prevProps.value !== this.props.value) {
      this.setState({ currencyInternalValue: parseFloat(this.props.value) });
    }
  };

  getInputRef = () => {
    if (this.objRef && this.objRef.input) {
      if (this.props.multiLine) {
        return this.objRef.input.refs.input;
      }
      return this.props.mask ? this.objRef.input.inputElement : this.objRef.input;
    }
    return document.getElementById(this.props.name);
  };

  handleOnValidation = (valid, message) => {
    this.setState({ validationErrorMessage: message }, () => this.props.onValid(valid));
  };

  handleOnInvalid = evt => {
    this.validation.validateComponent(evt, null, this.isMaxLengthInvalid, (valid, message) => {
      this.setState({ validationErrorMessage: message }, () => this.props.onValid(valid));
    });
    evt.preventDefault();
  };

  validateCurrency = values => {
    let splitDecimal = values.value.split('.');
    return splitDecimal[0].charAt(0) === '-'
      ? splitDecimal[0].length <= 11
      : splitDecimal[0].length <= 10;
  };

  validateShares = values => {
    let splitDecimal = values.value.split('.');
    return splitDecimal[0].length <= 8;
  };

  handleOnChange = (evt, val) => {
    if (this.maskIsCurrency() || this.maskIsShares()) {
      this.setState({ currencyInternalValue: val.value }, () => this.props.onChange(null, val.floatValue || 0));
    } else {
      const value = cleanMask(val, this.props.mask);
      if (this.props.validation && this.props.validation.maxLength) {
        this.isMaxLengthInvalid = value.length > this.props.validation.maxLength;
        this.getInputRef().setCustomValidity(this.isMaxLengthInvalid || '');
      }
      if (!this.isMaxLengthInvalid) {
        this.props.onChange(evt, this.props.toUpper ? value.toUpperCase() : value);
      }
    }
  };

  maskIsCurrency = () => this.props.mask && this.props.mask === 'CURRENCY';

  maskIsShares = () => this.props.mask && this.props.mask === 'SHARES';

  getFixedValue = (value, mask) => {
    const fractionDigits = mask === 'SHARES' ? 4 : 2;
    return parseFloat(value).toFixed(fractionDigits);
  };

  getBoxNameLabel = (boxName, showError, className) => {
    const { name } = this.props;
    return (
      boxName &&
      <label
        className={ classNames('tirTexfield__label-box', { 'tirTexfield__label-error': showError }, className) }
        htmlFor={ name }
      >
        { boxName }
      </label>
    );
  };

  getInputProps = () => {
    const inputProps = {
      'data-for': this.props.name
    };
    if (this.maskToApply.mask) {
      inputProps.maskToApply = this.maskToApply;
    }
    return inputProps;
  };

  formatValue = () => this.setState(oldState => ({ currencyInternalValue: this.getFixedValue(oldState.currencyInternalValue, this.props.mask) }));

  checkMaxLength = () => {
    const length = this.props.validation.pattern
      .match(/{([^}]*)}/g)
      ?.map(item => parseInt(item.replace(/[{}]/g, '')))
      .reduce((a, b) => a + b);
    return length <= 5 ? 'inputLength5' : 'inputLength20';
  };

  getInputWidth = () => {
    if (this.props.mask === 'DATE') {
      return 'inputLengthDate';
    }
    if (this.props.mask === 'SHARES') {
      return 'inputLengthShares';
    }
    if (this.props.validation && this.props.validation.pattern) {
      const length = this.props.validation.pattern.match(/\d+/)?.[0]
        || this.validation.getPattern().match(/\d+/)?.[0]
        || 0;
      if (length <= 5) {
        return this.checkMaxLength();
      }
      if (length > 5 && length <= 20) {
        return 'inputLength20';
      }
      if (length > 20 && length <= 40) {
        return 'inputLength40';
      }
      if (length > 40) {
        return 'inputLength100';
      }
      return 'inputLengthDefault';
    }
    return 'inputLengthDefault';
  };

  renderMask = (error, disabled) => {
    switch (this.props.mask) {
      case 'CURRENCY':
        return (
          <CurrencyFormat
            id={ this.props.name }
            value={ this.props.readOnly ? this.state.currencyInternalValue || 0.00 : this.state.currencyInternalValue || '' }
            thousandSeparator
            prefix={ '$' }
            onBlur={ this.formatValue }
            decimalScale={ 2 }
            fixedDecimalScale={ this.props.readOnly }
            isAllowed={ this.validateCurrency }
            customInput={ WrapperMuiTextField }
            onValueChange={ values => this.handleOnChange(null, values) }
            autoComplete='off'
            disabled={ this.props.readOnlyUser || disabled || this.props.readOnly }
          />
        );
      case 'SHARES':
        return (
          <CurrencyFormat
            id={ this.props.name }
            value={ this.state.currencyInternalValue || '' }
            onBlur={ this.formatValue }
            decimalScale={ 4 }
            isAllowed={ this.validateShares }
            customInput={ WrapperMuiTextField }
            onValueChange={ values => this.handleOnChange(null, values) }
            autoComplete='off'
            disabled={ this.props.readOnlyUser || disabled || this.props.readOnly }
          />
        );
      default:
        return (
          <TextField
            id={ this.props.name }
            name={ this.props.name }
            className={ 'tirTextfieldForm__input' }
            autoComplete='off'
            value={ this.props.value }
            disabled={ this.props.readOnlyUser || disabled || this.props.readOnly }
            onChange={ evt => this.handleOnChange(evt, evt.target.value) }
            onBlur={ this.props.onBlur }
            classes={ { root: this.props.classes.root } }
            multiline = { Boolean(this.props.multiLine) }
            rows={ this.props.multiLine.numberOfRows }
            InputProps={ {
              classes: { input: this.props.classes.input },
              inputComponent: this.maskToApply.mask ? TextMaskCustom : 'input',
              disableUnderline: true
            } }
            onInvalid={ this.handleOnInvalid }
            inputProps={ this.getInputProps() }
            variant='filled'
          />
        );
    }
  };

  render = () => {
    const {
      renderMask
    } = this;

    const {
      validationErrorMessage
    } = this.state;

    const {
      label,
      hideLabel,
      name,
      disabledByEntityFetcher,
      validation,
      customClasses,
      modLevel,
      boxName,
      rightBoxName
    } = this.props;

    const showError = Boolean(validationErrorMessage) || (modLevel >= 0 && this.props.validation?.required && !this.props.value);

    return (
      <div className={ classNames('tirTextfieldForm__wrapper', customClasses.tirTextfieldFormWrapper) }>
        { !hideLabel &&
          <div className='label__container'>
            <div className='label__container-left'>
              { this.getBoxNameLabel(boxName, showError, 'label__left-box-name') }
              <label
                className={ classNames('tirTexfield__label', showError && 'tirTexfield__label-error', customClasses.tirTexfieldLabel) }
                htmlFor={ name }
              >
                { getLabel(validation?.required, label) }
              </label>
            </div>
            { this.getBoxNameLabel(rightBoxName || boxName, showError) }
          </div>
        }
        <div className={ customClasses.textFieldContainer }>
          <div className={ classNames('textField__container', this.getInputWidth()) }>
            <SovosTooltip
              id={ name }
              label={ validationErrorMessage }
              show={ Boolean(validationErrorMessage) }
              place='right'
              className='tir-texfield__error'
              effect='solid'
            >
              { renderMask(showError, disabledByEntityFetcher.includes(name)) }
            </SovosTooltip>
          </div>
        </div>
      </div>
    );
  };
}

TextFieldForm.propTypes = {
  label: PropTypes.node,
  labelValidation: PropTypes.string,
  hideLabel: PropTypes.bool,
  boxName: PropTypes.string,
  rightBoxName: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  modLevel: PropTypes.number,
  mask: PropTypes.string,
  multiLine: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  multiLineConfig: PropTypes.shape({
    rows: PropTypes.number.isRequired,
    rowsMax: PropTypes.number.isRequired
  }),
  validation: PropTypes.shape({
    pattern: PropTypes.string,
    message: PropTypes.string,
    validateOn: PropTypes.string,
    required: PropTypes.bool,
    maxLength: PropTypes.number
  }),
  disabledByEntityFetcher: PropTypes.array,
  reset: PropTypes.bool,
  onValid: PropTypes.func,
  readOnly: PropTypes.bool,
  readOnlyUser: PropTypes.bool.isRequired,
  customClasses: PropTypes.objectOf(PropTypes.string)
};

TextFieldForm.defaultProps = {
  labelValidation: '',
  boxName: '',
  rightBoxName: '',
  hideLabel: false,
  placeholder: '',
  value: '',
  mask: '',
  multiLine: false,
  multiLineConfig: {
    rows: 1,
    rowsMax: 4
  },
  onBlur: () => {},
  onKeyDown: null,
  onKeyPress: null,
  validation: null,
  reset: false,
  onValid: () => {},
  customClasses: {}
};

export default withStyles(styles)(TextFieldForm);
