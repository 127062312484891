import actionTypes from './AdminPanelConstants';

const initialStore = {
  migratingClient: false,
  migrationResult: 0,
  fetchingResetData: false,
  resetDataConfig: {},
  resettingData: false,
  errorMessage: '',
  app1Services: [],
  app2Services: [],
  fetchingServices: false,
  app1Versions: {},
  app2Versions: {},
  fetchingVersions: false,
  app1LogLevels: {},
  app2LogLevels: {},
  fetchingLogLevels: false,
  settingLogLevel: false,
  setLogLevelResponse: {},
  exportStarted: false,
  exportFailed: false,
  exportError: {},
  creatingTaxYear: false,
  recalculatingStatusStarted: false,
  recalculatingStatusSuccess: false,
  recalculatingStatusFailed: false,
  calculatingBWStatusStarted: false,
  calculatingBWStatusSuccess: false,
  calculatingBWStatusFailed: false,
  testResponse: 0,
  prodResponse: 0
};

export default function adminPanelReducer(state = initialStore, action) {
  switch (action.type) {
    case actionTypes.MIGRATING_CLIENT:
      return {
        ...state,
        migratingClient: true
      };

    case actionTypes.CLIENT_MIGRATION_SUCCESS:
      return {
        ...state,
        migratingClient: false,
        migrationResult: action.migrationResult
      };

    case actionTypes.CLIENT_MIGRATION_FAILURE:
      return {
        ...state,
        migratingClient: false
      };

    case actionTypes.FETCHING_RESET_DATA_CONFIG:
      return { ...state, fetchingResetData: true, errorMessage: '' };

    case actionTypes.RESET_DATA_CONFIG_SUCCESS:
      return { ...state, resetDataConfig: action.payload, fetchingResetData: false, errorMessage: '' };

    case actionTypes.RESET_DATA_CONFIG_FAILURE:
      return { ...state, resetDataConfig: [], fetchingResetData: false, errorMessage: '' };

    case actionTypes.RESETING_DATA:
      return { ...state, resettingData: true, errorMessage: '' };

    case actionTypes.RESET_DATA_SUCCESS:
      return { ...state, resettingData: false, errorMessage: '' };

    case actionTypes.RESET_DATA_FAILURE:
      return { ...state, resettingData: false, errorMessage: action.message };

    case actionTypes.CREATING_TAX_YEAR:
      return { ...state, creatingTaxYear: true, testResponse: 0, prodResponse: 0 };

    case actionTypes.CREATE_TAX_YEAR_SUCCESS:
      return { ...state, creatingTaxYear: false, testResponse: action.testResponse, prodResponse: action.prodResponse };

    case actionTypes.FETCHING_SERVICES:
      return { ...state, fetchingServices: true };

    case actionTypes.FETCH_SERVICES_SUCCESS:
      return { ...state, fetchingServices: false, app1Services: action.app1Payload, app2Services: action.app2Payload };

    case actionTypes.FETCH_SERVICES_FAIL:
      return { ...state, fetchingServices: false };

    case actionTypes.FETCHING_VERSIONS:
      return { ...state, fetchingVersions: true };

    case actionTypes.FETCH_VERSIONS_SUCCESS:
      return { ...state, fetchingVersions: false, app1Versions: action.app1Payload, app2Versions: action.app2Payload };

    case actionTypes.FETCH_VERSIONS_FAIL:
      return { ...state, fetchingVersions: false };

    case actionTypes.FETCHING_LOG_LEVELS:
      return { ...state, fetchingLogLevels: true };

    case actionTypes.FETCH_LOG_LEVELS_SUCCESS:
      return { ...state, fetchingLogLevels: false, app1LogLevels: action.app1Payload, app2LogLevels: action.app2Payload };

    case actionTypes.FETCH_LOG_LEVELS_FAIL:
      return { ...state, fetchingLogLevels: false };

    case actionTypes.SETTING_LOG_LEVEL:
      return { ...state, settingLogLevel: true };

    case actionTypes.SET_LOG_LEVEL_SUCCESS:
      return { ...state, settingLogLevel: false, setLogLevelResponse: action.payload };

    case actionTypes.SET_LOG_LEVEL_FAIL:
      return { ...state, settingLogLevel: false, setLogLevelResponse: action.error };

    case actionTypes.CLEAR_SERVICES:
      return { ...state, app1Versions: {}, app2Versions: {}, app1Services: [], app2Services: [] };

    case actionTypes.LOGOUT_SUCCESS:
      return initialStore;

    case actionTypes.EXPORT_STARTED:
      return { ...state, exportStarted: true, exportFailed: false };

    case actionTypes.EXPORT_FINISHED:
      return { ...state, exportStarted: false };

    case actionTypes.EXPORT_FAILED:
      return { ...state, exportFailed: true, exportStarted: false, exportError: action.exportError };

    case actionTypes.RECALCULATING_STATUSES_STARTED:
      return { ...state, recalculatingStatusStarted: true, recalculatingStatusSuccess: false, recalculatingStatusFailed: false };

    case actionTypes.RECALCULATING_STATUSES_FAIL:
      return { ...state, recalculatingStatusStarted: false, recalculatingStatusSuccess: false, recalculatingStatusFailed: true };

    case actionTypes.RECALCULATING_STATUSES_SUCCESS:
      return { ...state, recalculatingStatusStarted: false, recalculatingStatusSuccess: true, recalculatingStatusFailed: false };

    case actionTypes.CALCULATING_BW_STATUSES_STARTED:
      return { ...state, calculatingBWStatusStarted: true, calculatingBWStatusSuccess: false, calculatingBWStatusFailed: false };

    case actionTypes.CALCULATING_BW_STATUSES_FAIL:
      return { ...state, calculatingBWStatusStarted: false, calculatingBWStatusSuccess: false, calculatingBWStatusFailed: true };

    case actionTypes.CALCULATING_BW_STATUSES_SUCCESS:
      return { ...state, calculatingBWStatusStarted: false, calculatingBWStatusSuccess: true, calculatingBWStatusFailed: false };

    default:
      return state;
  }
}
