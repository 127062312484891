import { string, bool, object } from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import classNames from 'classnames';
import './mailingAddressAutoCheckBox.scss';

const MailingAddressAutoCheckBox = ({ name, label, disabled, readOnly, readOnlyUser, checkBoxValue, formActions, isClone, taxEntityCode }) => (
  <div>
    <div className="auto-check-box-field__wrapper">
      <FormControlLabel
        control={
          <Checkbox
            id={ name }
            checked={ checkBoxValue }
            onClick={ () => formActions.setFieldValue('MAILINGADDRESSAUTOCHECKBOX', !checkBoxValue, isClone, taxEntityCode) }
            className={ classNames(
              'auto-check-box-field__wrapper__checkbox'
            ) }
            disabled={ readOnlyUser || readOnly || disabled }
            color='primary'
          />
        }
        label={ label }
        labelPlacement={ 'start' }
        classes={ { label: 'auto-check-box-field__wrapper__label' } }
      />
    </div>
  </div>
);

MailingAddressAutoCheckBox.propTypes = {
  name: string.isRequired,
  label: string.isRequired,
  disabled: bool,
  readOnly: bool,
  readOnlyUser: bool,
  checkBoxValue: bool,
  formActions: object.isRequired,
  isClone: bool.isRequired,
  taxEntityCode: string.isRequired
};

export default MailingAddressAutoCheckBox;