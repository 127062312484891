// TODO: In the future this file will be moved to a library.
// Please do not modify, take consideration this file is duplicated in another project

import { useEffect, useRef, useState } from 'react';

const eventsToAudit = ['click', 'keydown'];

const idleDurationSec = 900; // 15min
const idleAlertDurationSec = 600; // 10min

const useManageTimes = ({ onLogout = () => {}, onReset = () => {} }) => {
  const [idleAlert, setIdleAlert] = useState(false);

  const idleTimerRef = useRef(null);
  const idleAlertTimerRef = useRef(null);

  useEffect(() => {
    initTimers();

    attachEvents(document);

    return () => {
      detachEvents(document);
      return null;
    };
  }, []);

  const attachEvents = document => {
    const body = document.getElementsByTagName('body')[0];
    eventsToAudit.map(event =>
      body.addEventListener(event, resetIdleTimeout)
    );
  };

  const detachEvents = document => {
    const body = document.getElementsByTagName('body')[0];
    eventsToAudit.map(event =>
      body.addEventListener(event, resetIdleTimeout)
    );
  };

  const initTimers = () => {
    idleTimerRef.current = setTimeout(() => onLogout(), idleDurationSec * 1000);
    idleAlertTimerRef.current = setTimeout(() => setIdleAlert(true), idleAlertDurationSec * 1000);
  };

  const resetIdleTimeout = () => {
    if (idleTimerRef.current && idleAlertTimerRef.current) {
      onReset();
      clearTimeout(idleTimerRef.current);
      clearTimeout(idleAlertTimerRef.current);
      setIdleAlert(false);
    }

    initTimers();
  };

  return {
    idleAlert,
    resetIdleTimeout
  };
};

export default useManageTimes;
