import PropTypes from 'prop-types';
import IdentityVerificationUI from './IdentityVerificationUI';

const SECONDNOTICE = 'SECONDNOTICE';
const DMF = 'DMF';
const OFACRANK = 'OFACRANK';
const OFACSTATUS = 'OFACSTATUS';
const DEFAULT_SECONDNOTICE_VALUE = 'S';
const UNKNOWN_SECONDNOTICE_VALUE = 'U';
const DEFAULT_OFAC_VALUE = '_';

const IdentityVerification = ({ formData, formActions, isClone, taxEntityCode }) => {
  const handleValidateClick = () => {
    formActions.fetchingIdentityManagement();
    formActions.setFieldValue(SECONDNOTICE, DEFAULT_SECONDNOTICE_VALUE, isClone, taxEntityCode);
    formActions.setFieldValue(DMF, '', isClone, taxEntityCode);
    formActions.setFieldValue(OFACRANK, 0, isClone, taxEntityCode);
    formActions.setFieldValue(OFACSTATUS, DEFAULT_OFAC_VALUE, isClone, taxEntityCode);
  };

  const handleValidationComplete = (tiaTinStatus, ofacResult, dmfResult, shouldUpdate) => {
    formActions.fetchingIdentityManagementComplete();
    formActions.setFieldValue(SECONDNOTICE, tiaTinStatus.Status, isClone, taxEntityCode);
    formActions.setFieldValue(OFACRANK, ofacResult.Rank, isClone, taxEntityCode);
    formActions.setFieldValue(OFACSTATUS, ofacResult.Status, isClone, taxEntityCode);
    formActions.setFieldValue(DMF, dmfResult, isClone, taxEntityCode);
    formActions.setFormModifiedByTINValidation(shouldUpdate);
  };

  const handleResetTin = () => {
    formActions.setFieldValue(SECONDNOTICE, UNKNOWN_SECONDNOTICE_VALUE, isClone, taxEntityCode);
    formActions.setFieldValue(DMF, '', isClone, taxEntityCode);
  };

  const handleResetName = () => {
    formActions.setFieldValue(SECONDNOTICE, UNKNOWN_SECONDNOTICE_VALUE, isClone, taxEntityCode);
    formActions.setFieldValue(OFACRANK, 0, isClone, taxEntityCode);
    formActions.setFieldValue(OFACSTATUS, DEFAULT_OFAC_VALUE, isClone, taxEntityCode);
  };

  const handleResetTinType = () => {
    formActions.setFieldValue(SECONDNOTICE, UNKNOWN_SECONDNOTICE_VALUE, isClone, taxEntityCode);
  };

  const handleSetNewTinValidationStatus = value => {
    formActions.setFieldValue(SECONDNOTICE, value, isClone, taxEntityCode, true);
  };

  return (
    <IdentityVerificationUI
      onValidationClick={ handleValidateClick }
      onResetTin={ handleResetTin }
      onResetName={ handleResetName }
      onResetTinType={ handleResetTinType }
      tin={ formData.TIN }
      name={ formData.NAME }
      tinType={ formData.TINTYPE }
      tinValidationStatus={ formData.SECONDNOTICE }
      dmfStatus={ formData.DMF }
      ofacStatus={ formData.OFACSTATUS }
      onValidationComplete={ handleValidationComplete }
      onSetNewTinValidationStatus={ handleSetNewTinValidationStatus }
      isTiaTheTinStatusSource
      modrecno={ formData.MODRECNO }
    />
  );
};

IdentityVerification.propTypes = {
  formData: PropTypes.object.isRequired,
  formActions: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    fetchingIdentityManagement: PropTypes.func.isRequired,
    fetchingIdentityManagementComplete: PropTypes.func.isRequired,
    setFormModifiedByTINValidation: PropTypes.func.isRequired
  }).isRequired,
  isClone: PropTypes.bool.isRequired,
  taxEntityCode: PropTypes.string.isRequired
};

export default IdentityVerification;
