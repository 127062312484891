export const set = cookie => document.cookie = cookie;

export const remove = cookieName => document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;

export const exists = cookieName => Boolean(document.cookie?.includes(`${cookieName}=`));

export const getOrgId = () => document.cookie.split('; ').find(cookie => cookie.includes('orgId')).replace('orgId=', '');

export const getContextId = () => document.cookie.split('; ').find(cookie => cookie.includes('contextId')).replace('contextId=', '');

export const isSSOUser = () => Boolean(document.cookie?.includes('SI_SSOUser=true'));
