import authenticationHelper from 'helpers/authenticationHelper';
import { post } from 'helpers/axios/axiosInterceptorsV1';
import { CHARGEBEE_SITE } from 'infrastructure/appConfig';

let cbInstance = undefined;

const includeChargebee = () => {
  const script = document.createElement('script');
  script.src = 'https://js.chargebee.com/v2/chargebee.js';
  script.async = true;
  document.head.appendChild(script);
};

const getChargebeeInstance = () => {
  //eslint-disable-next-line no-undef
  if (typeof Chargebee === 'function') {
    if (!cbInstance) {
      //eslint-disable-next-line no-undef
      cbInstance = Chargebee.init({
        site: CHARGEBEE_SITE
      });
    } else {
      //eslint-disable-next-line no-undef
      cbInstance = Chargebee.getInstance();
    }

    cbInstance.setPortalSession(async () => {
      try {
        const response = await post(`/ecommerce/client/${authenticationHelper.getClientId()}/subscription/portal-sessions`, { });
        return {
          'id': response.data.PortalSession.Id,
          'token': response.data.PortalSession.Token,
          'access_url': response.data.PortalSession.AccessUrl,
          'status': response.data.PortalSession.Status,
          'created_at': response.data.PortalSession.CreatedAt,
          'expires_at': response.data.PortalSession.ExpiresAt,
          'object': response.data.PortalSession.Object,
          'customer_id': response.data.PortalSession.CustomerId
        };
      }
      catch (error) {
        throw error;
      }
    });
  }

  return cbInstance;
};

export {
  includeChargebee,
  getChargebeeInstance
};
