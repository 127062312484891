import { useEffect, useState } from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import MultipleRows from '../shared/multipleRows/MultipleRows';
import PreviousCurrentTextFieldForm from 'shared/standardInput/textField/PreviousCurrentTextFieldForm';
import AmountDifferenceFieldForm from 'shared/standardInput/textField/AmountDifferenceFieldForm';
import AutoCalculatedField from '../autoCalculatedField/AutoCalculatedFieldConnector';
import ConfirmationDialog from 'shared/confirmationDialog/ConfirmationDialog';
import W2TransmittalStatus from '../w2TransmittalStatus/W2TransmittalStatus';
import './w2MultipleStates.scss';

const addIfHasValue = (object, key) => object[key] && { [key]: object[key] };

const PSTATE_ABBR = 'PStateAbbreviation';
const PSTATE_WAGES = 'PStateWages';
const PSTATE_TAX = 'PStateTax';
const PSTATE_NUMOFEXEMP = 'PStateNumberOfExemptions';
const PSTATE_NJWCWITHHOLD = 'PWithheldWorkerContributions';
const PSTATE_NJDIWITHHOLD = 'PDisabilityInsuranceWithholding';
const PSTATE_NJFLIWITHHOLD = 'PStateFamilyLeaveInsuranceWithholding';
const PSTATE_ORTRANWAGES = 'PStatewideTransitTaxWages';
const PSTATE_ORTRANWITHHOLD = 'PStatewideTransitTaxWithheld';
const STATE_ABBR = 'StateAbbreviation';
const STATE_WAGES = 'StateWages';
const STATE_TAX = 'StateTax';
const STATE_ID = 'StateId';
const STATE_NUMOFEXEMP = 'StateW4NumberOfExemptions';
const STATE_NJWCWITHHOLD = 'WithheldWorkerContributions';
const STATE_NJDIWITHHOLD = 'DisabilityInsuranceWithholding';
const STATE_NJFLIWITHHOLD = 'FamilyLeaveInsuranceWithholding';
const STATE_ORTRANWAGES = 'StatewideTransitTaxWages';
const STATE_ORTRANWITHHOLD = 'StatewideTransitTaxWithheld';
const RETURN_STATUS = 'ReturnStatus';
const XMITRUN = 'Xmitrun';

const MARYLAND_STATE_ABBR = 'MD';
const NEWJERSEY_STATE_ABBR = 'NJ';
const OREGON_STATE_ABBR = 'OR';

const cleanMultipleStates = states => states
  .filter(state => state[STATE_ABBR])
  .map(state => ({
    [STATE_ABBR]: state[STATE_ABBR],
    ...(addIfHasValue(state, STATE_WAGES)),
    ...(addIfHasValue(state, STATE_TAX)),
    ...(addIfHasValue(state, RETURN_STATUS)),
    ...(addIfHasValue(state, XMITRUN)),
    CustomFieldsData: {
      ...(addIfHasValue(state, STATE_NUMOFEXEMP)),
      ...(addIfHasValue(state, STATE_NJWCWITHHOLD)),
      ...(addIfHasValue(state, STATE_NJDIWITHHOLD)),
      ...(addIfHasValue(state, STATE_NJFLIWITHHOLD)),
      ...(addIfHasValue(state, STATE_ORTRANWAGES)),
      ...(addIfHasValue(state, STATE_ORTRANWITHHOLD))
    }
  }));

const checkForDuplicateStates = states => {
  let tracker = {};
  for (const state of states) {
    if (tracker[state]) {
      return state;
    }
    tracker[state] = true;
  }
  return null;
};

const W2MultipleStates = ({
  states,
  previousValue,
  formActions,
  isClone,
  readOnlyUser,
  isFormTransmitted,
  taxEntityCode
}) => {
  const [showModal, setShowModal] = useState(false);
  const [confirmedModal, setConfirmedModal] = useState(false);
  const [rowToSetZeroed, setRowToSetZeroed] = useState(null);
  const [duplicateState, setDuplicateState] = useState();
  const [localMultipleStates, setLocalMultipleStates] = useState(() => states.length
    ? [...states
      .map((state, id) => {
        const previousState = previousValue.find(previousCState => previousCState[STATE_ABBR] === state[STATE_ABBR]) || {};

        return {
          Id: id + 1,
          [STATE_ABBR]: state[STATE_ABBR],
          [STATE_WAGES]: state[STATE_WAGES],
          [STATE_TAX]: state[STATE_TAX],
          [STATE_NUMOFEXEMP]: state.CustomFieldsData?.[STATE_NUMOFEXEMP],
          [STATE_NJWCWITHHOLD]: state.CustomFieldsData?.[STATE_NJWCWITHHOLD],
          [STATE_NJDIWITHHOLD]: state.CustomFieldsData?.[STATE_NJDIWITHHOLD],
          [STATE_NJFLIWITHHOLD]: state.CustomFieldsData?.[STATE_NJFLIWITHHOLD],
          [STATE_ORTRANWAGES]: state.CustomFieldsData?.[STATE_ORTRANWAGES],
          [STATE_ORTRANWITHHOLD]: state.CustomFieldsData?.[STATE_ORTRANWITHHOLD],
          [RETURN_STATUS]: state[RETURN_STATUS] || '',
          [XMITRUN]: state[XMITRUN] || '',
          [PSTATE_ABBR]: previousState[STATE_ABBR],
          [PSTATE_WAGES]: previousState[STATE_WAGES],
          [PSTATE_TAX]: previousState[STATE_TAX],
          [PSTATE_NUMOFEXEMP]: previousState.CustomFieldsData?.[STATE_NUMOFEXEMP],
          [PSTATE_NJWCWITHHOLD]: previousState.CustomFieldsData?.[STATE_NJWCWITHHOLD],
          [PSTATE_NJDIWITHHOLD]: previousState.CustomFieldsData?.[STATE_NJDIWITHHOLD],
          [PSTATE_NJFLIWITHHOLD]: previousState.CustomFieldData?.[STATE_NJFLIWITHHOLD],
          [PSTATE_ORTRANWAGES]: previousState.CustomFieldsData?.[STATE_ORTRANWAGES],
          [PSTATE_ORTRANWITHHOLD]: previousState.CustomFieldsData?.[STATE_ORTRANWITHHOLD],
          disabledState: true
        };
      })
    ]
    : []);

  useEffect(() => {
    const completedRows = cleanMultipleStates(localMultipleStates);

    formActions.setFieldValue('STATES', completedRows, isClone, taxEntityCode);

    const duplicate = checkForDuplicateStates(completedRows.map(item => item[STATE_ABBR]));
    setDuplicateState(duplicate);
    formActions.setSaveDisableByValidation(Boolean(duplicate));
  }, [localMultipleStates, formActions, isClone]);

  const handleAddState = () => {
    const newMultipleState = [
      ...localMultipleStates,
      {
        Id: (localMultipleStates.at(-1)?.Id || 0) + 1,
        [STATE_ABBR]: '',
        [STATE_WAGES]: null,
        [STATE_TAX]: null,
        [STATE_NUMOFEXEMP]: '',
        [STATE_NJWCWITHHOLD]: '',
        [STATE_NJDIWITHHOLD]: '',
        [STATE_NJFLIWITHHOLD]: '',
        [STATE_ORTRANWAGES]: '',
        [STATE_ORTRANWITHHOLD]: '',
        disabledState: false
      }
    ];

    setLocalMultipleStates(newMultipleState);
  };

  const setZeroedToAmountField = id => {
    handleChange(STATE_WAGES, null, id);
    handleChange(STATE_TAX, null, id);
  };

  const handleDeleteState = id => {
    if (isFormTransmitted) {
      if (confirmedModal) {
        setZeroedToAmountField(id);
      } else {
        setShowModal(true);
        setRowToSetZeroed(id);
      }
    } else {
      setLocalMultipleStates(prevState => prevState.filter(record => record.Id !== id));
    }
  };

  const handleChange = (key, value, id) =>
    setLocalMultipleStates(prevState => prevState.map(
      multipleStateInfo => multipleStateInfo.Id === id
        ? handleStateCustom(multipleStateInfo, key, value)
        : multipleStateInfo)
    );

  const handleStateCustom = (stateInfo, key, value) => {
    switch (stateInfo[STATE_ABBR])
    {
      case MARYLAND_STATE_ABBR:
        return { ...stateInfo, [key]: value, [STATE_NJWCWITHHOLD]: '', [STATE_NJDIWITHHOLD]: '', [STATE_NJFLIWITHHOLD]: '', [STATE_ORTRANWAGES]: '', [STATE_ORTRANWITHHOLD]: '' };
      case NEWJERSEY_STATE_ABBR:
        return { ...stateInfo, [key]: value, [STATE_NUMOFEXEMP]: '', [STATE_ORTRANWAGES]: '', [STATE_ORTRANWITHHOLD]: '' };
      case OREGON_STATE_ABBR:
        return { ...stateInfo, [key]: value, [STATE_NUMOFEXEMP]: '', [STATE_NJWCWITHHOLD]: '', [STATE_NJDIWITHHOLD]: '', [STATE_NJFLIWITHHOLD]: '' };
      default:
        return { ...stateInfo, [key]: value, [STATE_NUMOFEXEMP]: '', [STATE_NJWCWITHHOLD]: '', [STATE_NJDIWITHHOLD]: '', [STATE_NJFLIWITHHOLD]: '', [STATE_ORTRANWAGES]: '', [STATE_ORTRANWITHHOLD]: '' };
    }
  };

  const handleConfirmChangeStateTransmitted = () => {
    setShowModal(false);
    setConfirmedModal(true);
    if (Number(rowToSetZeroed)) {
      setZeroedToAmountField(rowToSetZeroed);
      setRowToSetZeroed(null);
    }
  };

  const hasStateTransmitted = state => Boolean(state[PSTATE_ABBR]);

  return (
    <>
      <div className='w2-multiple-states__container'>
        <MultipleRows
          onAddRow={ handleAddState }
          showAddButton={ !readOnlyUser }
          addButtonProps={ { label: '+ Add additional state' } }
        >
          {
            localMultipleStates.map(state => (
              <MultipleRows.Row
                key={ state.Id }
                onDeleteRow={ handleDeleteState }
                row={ state }
                showDeleteButton={ !readOnlyUser }
              >
                <div className='w2-multiple-states__row'>
                  <W2TransmittalStatus
                    name={ state[STATE_ABBR] || 'NEW STATE' }
                    returnStatus= { state[RETURN_STATUS] || 'O' }
                    transmitStatus= { Boolean(state[XMITRUN]) }
                  />
                  <PreviousCurrentTextFieldForm
                    boxName='15'
                    name={ `altstate-${state.Id}` }
                    label='State'
                    labelValidation='State'
                    value={ state[STATE_ABBR] }
                    previousValue={ state[PSTATE_ABBR] }
                    readOnlyUser={ readOnlyUser || state.disabledState }
                    errorText={ duplicateState === state[STATE_ABBR] ? 'State must be unique.' : '' }
                    validation={ {
                      pattern: 'MAXLENGTH2',
                      message: 'Must contain 2 valid characters or less',
                      validateOn: 'keyup',
                      required: true
                    } }
                    onChange={ (_, value) => handleChange(STATE_ABBR, value, state.Id) }
                    showPreviousDifferentFields={ hasStateTransmitted(state) }
                    toUpper
                  />
                  <AutoCalculatedField
                    name={ `stateId-${state.Id}` }
                    label='Payer state identifier'
                    readOnlyUser={ readOnlyUser }
                    value={ state[STATE_ID] }
                    onChange={ value => handleChange(STATE_ID, value, state.Id) }
                    dependentValue={ String(state[STATE_ABBR]) }
                    customClasses={ { textFieldContainer: 'w2-multiple-states__row-autocalculate' } }
                    taxEntityCode={ taxEntityCode }
                  />
                  <AmountDifferenceFieldForm
                    boxName='16'
                    name={ `a16-${state.Id}` }
                    label='State wages, tips, etc.'
                    value={ state[STATE_WAGES] }
                    previousValue={ state[PSTATE_WAGES] }
                    onChange={ (_, value) => handleChange(STATE_WAGES, value, state.Id) }
                    showPreviousDifferentFields={ hasStateTransmitted(state) }
                    readOnlyUser={ readOnlyUser }
                    mask='CURRENCY'
                  />
                  <AmountDifferenceFieldForm
                    boxName='17'
                    name={ `statewh-${state.Id}` }
                    label='State income tax'
                    value={ state[STATE_TAX] }
                    previousValue={ state[PSTATE_TAX] }
                    onChange={ (_, value) => handleChange(STATE_TAX, value, state.Id) }
                    showPreviousDifferentFields={ hasStateTransmitted(state) }
                    readOnlyUser={ readOnlyUser }
                    mask='CURRENCY'
                  />
                  { state[STATE_ABBR] === MARYLAND_STATE_ABBR &&
                    <PreviousCurrentTextFieldForm
                      name={ `statenumofexemp-${state.Id}` }
                      label='Number of exemptions'
                      labelValidation='Number of exemptions'
                      value={ state[STATE_NUMOFEXEMP] }
                      previousValue={ state[PSTATE_NUMOFEXEMP] }
                      readOnlyUser={ readOnlyUser }
                      validation={ {
                        pattern: 'MAXLENGTHNUMBER2',
                        message: 'Must contain a number of 2 digits or less',
                        validateOn: 'keyup',
                        maxLength: 2
                      } }
                      onChange={ (_, value) => handleChange(STATE_NUMOFEXEMP, value, state.Id) }
                      showPreviousDifferentFields={ hasStateTransmitted(state) }
                      toUpper
                    />
                  }
                  { state[STATE_ABBR] === NEWJERSEY_STATE_ABBR &&
                    <AmountDifferenceFieldForm
                      name={ `statenjwcwithhold-${state.Id}` }
                      label="Box 14 - Withheld worker's contributions"
                      labelValidation="Box 14 - Withheld worker's contributions"
                      value={ state[STATE_NJWCWITHHOLD] }
                      previousValue={ state[PSTATE_NJWCWITHHOLD] }
                      readOnlyUser={ readOnlyUser }
                      onChange={ (_, value) => handleChange(STATE_NJWCWITHHOLD, value, state.Id) }
                      showPreviousDifferentFields={ hasStateTransmitted(state) }
                      mask='CURRENCY'
                    />
                  }
                  { state[STATE_ABBR] === NEWJERSEY_STATE_ABBR &&
                    <AmountDifferenceFieldForm
                      name={ `statenjdiwithhold-${state.Id}` }
                      label='Box 14 - Disability insurance withholding'
                      labelValidation='Box 14 - Disability insurance withholding'
                      value={ state[STATE_NJDIWITHHOLD] }
                      previousValue={ state[PSTATE_NJDIWITHHOLD] }
                      readOnlyUser={ readOnlyUser }
                      onChange={ (_, value) => handleChange(STATE_NJDIWITHHOLD, value, state.Id) }
                      showPreviousDifferentFields={ hasStateTransmitted(state) }
                      mask='CURRENCY'
                    />
                  }
                  { state[STATE_ABBR] === NEWJERSEY_STATE_ABBR &&
                    <AmountDifferenceFieldForm
                      name={ `statenjfliwithhold-${state.Id}` }
                      label='Box 14 - Family leave insurance withholding'
                      labelValidation='Box 14 - Family leave insurance withholding'
                      value={ state[STATE_NJFLIWITHHOLD] }
                      previousValue={ state[PSTATE_NJFLIWITHHOLD] }
                      readOnlyUser={ readOnlyUser }
                      onChange={ (_, value) => handleChange(STATE_NJFLIWITHHOLD, value, state.Id) }
                      showPreviousDifferentFields={ hasStateTransmitted(state) }
                      mask='CURRENCY'
                    />
                  }
                  { state[STATE_ABBR] === OREGON_STATE_ABBR &&
                    <AmountDifferenceFieldForm
                      name={ `stateortranwages-${state.Id}` }
                      label='Box 14 - Statewide transit tax wages'
                      labelValidation='Box 14 - Statewide transit tax wages'
                      value={ state[STATE_ORTRANWAGES] }
                      previousValue={ state[PSTATE_ORTRANWAGES] }
                      readOnlyUser={ readOnlyUser }
                      onChange={ (_, value) => handleChange(STATE_ORTRANWAGES, value, state.Id) }
                      showPreviousDifferentFields={ hasStateTransmitted(state) }
                      mask='CURRENCY'
                    />
                  }
                  { state[STATE_ABBR] === OREGON_STATE_ABBR &&
                    <AmountDifferenceFieldForm
                      name={ `stateortranwithhold-${state.Id}` }
                      label='Box 14 - Statewide transit tax withheld'
                      labelValidation='Box 14 - Statewide transit tax withheld'
                      value={ state[STATE_ORTRANWITHHOLD] }
                      previousValue={ state[PSTATE_ORTRANWITHHOLD] }
                      readOnlyUser={ readOnlyUser }
                      onChange={ (_, value) => handleChange(STATE_ORTRANWITHHOLD, value, state.Id) }
                      showPreviousDifferentFields={ hasStateTransmitted(state) }
                      mask='CURRENCY'
                    />
                  }
                </div>
              </MultipleRows.Row>
            ))
          }
        </MultipleRows>
      </div>
      <ConfirmationDialog
        open={ showModal }
        content='Box 16 and 17 will be zeroed out, but Box 15 will remain because this record has been transmitted. Do you wish to proceed?'
        onCancelClick={ () => setShowModal(false) }
        onOKClick={ handleConfirmChangeStateTransmitted }
      />
    </>
  );
};

W2MultipleStates.propTypes = {
  states: arrayOf(shape({
    StateAbbreviation: string,
    StateWages: number,
    StateTax: number,
    ReturnStatus: string,
    Xmitrun: number
  })).isRequired,
  isClone: bool.isRequired,
  formActions: shape({
    setFieldValue: func.isRequired,
    setSaveDisableByValidation: func.isRequired
  }).isRequired,
  readOnlyUser: bool,
  isFormTransmitted: bool.isRequired,
  previousValue: arrayOf(shape({
    StateAbbreviation: string,
    StateWages: number,
    StateTax: number
  })),
  taxEntityCode: string.isRequired
};

W2MultipleStates.defaultProps = {
  readOnlyUser: false,
  previousValue: []
};

export default W2MultipleStates;
