import moment from 'moment';
import { getLocalTimestamp } from 'tir-frontend-common/helpers/dateHelper';

export function calcFileSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) {
    return '0 Bytes';
  }
  let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10); // this line calculates how much times can we divide the size in 1024 to check the unit to use
  i = i > sizes.length - 1 ? sizes.length - 1 : i;
  return `${Math.round(bytes / (1024 ** i), 2)} ${sizes[i]}`;
}

export function processFiles(filesList) {
  const result = {};
  result.files = filesList.map(file => {
    const expiresDate = file.ExpireDate.split('T')[0].split('-');
    const parsedFile = { };
    parsedFile.key = file.Id;
    parsedFile.name = file.FileName;
    parsedFile.folder = file.FolderName;
    parsedFile.modified = getLocalTimestamp(file.ModifyDate);
    parsedFile.status = 'sync'; // TBD
    parsedFile.size = calcFileSize(file.FileSize);
    parsedFile.expires = `${expiresDate[1]}/${expiresDate[2]}/${expiresDate[0]}`;
    parsedFile.createDate = moment.utc(file.CreateDate).local().format('MM/DD/YYYY h:mm A');
    return parsedFile;
  });

  return result;
}
