import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './clientInformation.scss';
import SovosTypography from 'mosaic-react/sovos-typography';

class ClientInformation extends PureComponent {
  render() {
    const { client } = this.props;

    return (
      <div className='client-information'>
        <SovosTypography className='client-information__text'>{ `CLIENT: ${ client.Name }` }</SovosTypography>
        <SovosTypography className='client-information__text'>{ `CID: ${ client.Cid }` }</SovosTypography>
      </div>
    );
  }
}

ClientInformation.propTypes = {
  client: PropTypes.object.isRequired
};

export default ClientInformation;
