import { the499R2Fchar, theW2MFchar } from 'helpers/formConstants';
import { monthlyScheduleDepositorFlagField, semiWeeklyFederalTaxLiabilityFields, semiweeklyScheduleDepositorFlagField } from 'pages/withholdingForms/helpers/withholdingFormsHelper';

const nonDbFields = [
  'stateId',
  ...semiWeeklyFederalTaxLiabilityFields
];

const fieldsToCalculateA06Field499R2 = ['A02', 'A03', 'A04', 'A05'];
const monthlyFederalTaxLiabilityFields = [
  'JanuaryTotalFederalTaxLiability',
  'FebruaryTotalFederalTaxLiability',
  'MarchTotalFederalTaxLiability',
  'AprilTotalFederalTaxLiability',
  'MayTotalFederalTaxLiability',
  'JuneTotalFederalTaxLiability',
  'JulyTotalFederalTaxLiability',
  'AugustTotalFederalTaxLiability',
  'SeptemberTotalFederalTaxLiability',
  'OctoberTotalFederalTaxLiability',
  'NovemberTotalFederalTaxLiability',
  'DecemberTotalFederalTaxLiability'
];

const calculateValue = (name, form, payerInformation, fchar, previousValuesForms, dependentValue = null) => {

  const totalAmountField = fieldsToCalculateA06Field499R2.some(field => form[field] !== previousValuesForms[field])
    ? fieldsToCalculateA06Field499R2.reduce((accumulator, field) => accumulator + parseFloat(form[field] || 0), 0)
    : form.A06;

  if (theW2MFchar === fchar) {
    if (payerInformation?.PayerStates && form.STATES) {
      const payerStateInfo = payerInformation.PayerStates.find( payerState => payerState.State.toUpperCase() === dependentValue?.toUpperCase());
      return payerStateInfo ? payerStateInfo.StateId : '';
    }
    return '';
  }

  switch (name) {
    case 'stateId':
      if (payerInformation?.PayerStates && form.ALTSTATE) {
        const payerStateInfo = payerInformation.PayerStates.find( payerState => payerState.State.toUpperCase() === form.ALTSTATE.toUpperCase());
        return payerStateInfo ? payerStateInfo.StateId : '';
      }
      return '';
    case 'f-sumBox6':
      return (form.A01 || form.A03) ? parseFloat((form.A01 || 0) - (form.A03 || 0)).toFixed(2) : '';

    case 'A06':
      return fchar === the499R2Fchar
        ? totalAmountField > 0
          ? totalAmountField
          : ''
        : (form.A01 || form.A03)
          ? parseFloat(((form.A01 || 0) - (form.A03 || 0)) * (form.N01 || 0)).toFixed(2)
          : '';

    case 'checkf8949':
      if (form.C04 === "1" && form.C05 === "1") {
        return 'A';
      }
      if (form.C04 === "1" && form.C05 !== "1") {
        return 'B';
      }
      if (form.C04 === "2" && form.C05 === "1") {
        return 'D';
      }
      if (form.C04 === "2" && form.C05 !== "1") {
        return 'E';
      }
      if (form.A03 || form.A04 || form.A05 || form.A06) {
        return '';
      } else {
        return 'X';
      }

    case 'BalanceDue':
      return form.TotalTaxes > form.TotalDeposits ? parseFloat((form.TotalTaxes || 0) - (form.TotalDeposits || 0).toFixed(2)) : '';

    case 'Overpayment':
      return form.TotalDeposits > form.TotalTaxes ? parseFloat((form.TotalDeposits || 0) - (form.TotalTaxes || 0).toFixed(2)) : '';

    case 'JanuarySemiWeeklyFederalTaxLiability':
      return parseFloat(Object.keys(form).filter(key => key.includes('JanuarySemiWeeklyFederalTaxLiability_')).reduce((sum, key) => sum + (form[key] || 0), 0).toFixed(2));

    case 'FebruarySemiWeeklyFederalTaxLiability':
      return parseFloat(Object.keys(form).filter(key => key.includes('FebruarySemiWeeklyFederalTaxLiability_')).reduce((sum, key) => sum + (form[key] || 0), 0).toFixed(2));

    case 'MarchSemiWeeklyFederalTaxLiability':
      return parseFloat(Object.keys(form).filter(key => key.includes('MarchSemiWeeklyFederalTaxLiability_')).reduce((sum, key) => sum + (form[key] || 0), 0).toFixed(2));

    case 'AprilSemiWeeklyFederalTaxLiability':
      return parseFloat(Object.keys(form).filter(key => key.includes('AprilSemiWeeklyFederalTaxLiability_')).reduce((sum, key) => sum + (form[key] || 0), 0).toFixed(2));

    case 'MaySemiWeeklyFederalTaxLiability':
      return parseFloat(Object.keys(form).filter(key => key.includes('MaySemiWeeklyFederalTaxLiability_')).reduce((sum, key) => sum + (form[key] || 0), 0).toFixed(2));

    case 'JuneSemiWeeklyFederalTaxLiability':
      return parseFloat(Object.keys(form).filter(key => key.includes('JuneSemiWeeklyFederalTaxLiability_')).reduce((sum, key) => sum + (form[key] || 0), 0).toFixed(2));

    case 'JulySemiWeeklyFederalTaxLiability':
      return parseFloat(Object.keys(form).filter(key => key.includes('JulySemiWeeklyFederalTaxLiability_')).reduce((sum, key) => sum + (form[key] || 0), 0).toFixed(2));

    case 'AugustSemiWeeklyFederalTaxLiability':
      return parseFloat(Object.keys(form).filter(key => key.includes('AugustSemiWeeklyFederalTaxLiability_')).reduce((sum, key) => sum + (form[key] || 0), 0).toFixed(2));

    case 'SeptemberSemiWeeklyFederalTaxLiability':
      return parseFloat(Object.keys(form).filter(key => key.includes('SeptemberSemiWeeklyFederalTaxLiability_')).reduce((sum, key) => sum + (form[key] || 0), 0).toFixed(2));

    case 'OctoberSemiWeeklyFederalTaxLiability':
      return parseFloat(Object.keys(form).filter(key => key.includes('OctoberSemiWeeklyFederalTaxLiability_')).reduce((sum, key) => sum + (form[key] || 0), 0).toFixed(2));

    case 'NovemberSemiWeeklyFederalTaxLiability':
      return parseFloat(Object.keys(form).filter(key => key.includes('NovemberSemiWeeklyFederalTaxLiability_')).reduce((sum, key) => sum + (form[key] || 0), 0).toFixed(2));

    case 'DecemberSemiWeeklyFederalTaxLiability':
      return parseFloat(Object.keys(form).filter(key => key.includes('DecemberSemiWeeklyFederalTaxLiability_')).reduce((sum, key) => sum + (form[key] || 0), 0).toFixed(2));

    case 'TotalFederalTaxLiability':
      if (form[semiweeklyScheduleDepositorFlagField] === 'X') {
        return parseFloat(Object.keys(form).filter(key => key.includes('SemiWeeklyFederalTaxLiability_')).reduce((sum, key) => sum + (form[key] || 0), 0).toFixed(2));
      }
      else if (form[monthlyScheduleDepositorFlagField] === 'X') {
        return parseFloat(monthlyFederalTaxLiabilityFields.reduce((sum, key) => sum + (form[key] || 0), 0).toFixed(2));
      }
      else {
        return null;
      }
    case 'JanuaryTotalTax':
      return parseFloat(Object.keys(form).filter(key => key !== 'JanuaryTotalTax' && key.includes('January')).reduce((sum, key) => sum + (form[key] || 0), 0).toFixed(2));
    case 'FebruaryTotalTax':
      return parseFloat(Object.keys(form).filter(key => key !== 'FebruaryTotalTax' && key.includes('February')).reduce((sum, key) => sum + (form[key] || 0), 0).toFixed(2));
    case 'MarchTotalTax':
      return parseFloat(Object.keys(form).filter(key => key !== 'MarchTotalTax' && key.includes('March')).reduce((sum, key) => sum + (form[key] || 0), 0).toFixed(2));
    case 'AprilTotalTax':
      return parseFloat(Object.keys(form).filter(key => key !== 'AprilTotalTax' && key.includes('April')).reduce((sum, key) => sum + (form[key] || 0), 0).toFixed(2));
    case 'MayTotalTax':
      return parseFloat(Object.keys(form).filter(key => key !== 'MayTotalTax' && key.includes('May')).reduce((sum, key) => sum + (form[key] || 0), 0).toFixed(2));
    case 'JuneTotalTax':
      return parseFloat(Object.keys(form).filter(key => key !== 'JuneTotalTax' && key.includes('June')).reduce((sum, key) => sum + (form[key] || 0), 0).toFixed(2));
    case 'JulyTotalTax':
      return parseFloat(Object.keys(form).filter(key => key !== 'JulyTotalTax' && key.includes('July')).reduce((sum, key) => sum + (form[key] || 0), 0).toFixed(2));
    case 'AugustTotalTax':
      return parseFloat(Object.keys(form).filter(key => key !== 'AugustTotalTax' && key.includes('August')).reduce((sum, key) => sum + (form[key] || 0), 0).toFixed(2));
    case 'SeptemberTotalTax':
      return parseFloat(Object.keys(form).filter(key => key !== 'SeptemberTotalTax' && key.includes('September')).reduce((sum, key) => sum + (form[key] || 0), 0).toFixed(2));
    case 'OctoberTotalTax':
      return parseFloat(Object.keys(form).filter(key => key !== 'OctoberTotalTax' && key.includes('October')).reduce((sum, key) => sum + (form[key] || 0), 0).toFixed(2));
    case 'NovemberTotalTax':
      return parseFloat(Object.keys(form).filter(key => key !== 'NovemberTotalTax' && key.includes('November')).reduce((sum, key) => sum + (form[key] || 0), 0).toFixed(2));
    case 'DecemberTotalTax':
      return parseFloat(Object.keys(form).filter(key => key !== 'DecemberTotalTax' && key.includes('December')).reduce((sum, key) => sum + (form[key] || 0), 0).toFixed(2));
    case 'TotalGrossAmountsReported':
      return parseFloat(((form['TotalFDAPIncome'] || 0) + (form['TotalSubstituteDividendPaymentReported'] || 0) + (form['TotalSubstitutePaymentReported'] || 0)).toFixed(2));
    case 'TotalTaxReportedByAgent':
      return parseFloat(((form['TaxWithheldByAgent'] || 0) + (form['TaxWithheldByOtherAgentPayments'] || 0) + (form['TaxWithheldByOtherAgentSubstituteDividends'] || 0)
        - (form['AdjustmentsToOverwithholding'] || 0) + (form['AdjustmentsToUnderwithholding'] || 0) + (form['TaxPaidByAgent'] || 0)).toFixed(2));
    case 'TotalNetTaxLiability':
      return parseFloat(((form['TotalNetTaxLiabilityAdjustments'] || 0) + (form['TotalNetTaxLiabilityChapter3'] || 0)
        + (form['TotalNetTaxLiabilityChapter4'] || 0) + (form['ExciseTaxOnPayments'] || 0)).toFixed(2));
    case 'TotalPayments':
      return parseFloat(((form['TotalPaidByElectronicOnCalendarYear'] || 0) + (form['TotalPaidByElectronicOnSubsequentYear'] || 0)
        + (form['OverpaymentAppliedAsPrevYearCredit'] || 0) + (form['CreditForSubstituteDividendPaymentWithheld'] || 0)
        + (form['CreditForOtherPaymentWithheld'] || 0)).toFixed(2));
    case 'BalanceDueDiff':
      return (form['TotalNetTaxLiability'] > form['TotalPayments']) ? parseFloat(((form['TotalNetTaxLiability'] || 0) - (form['TotalPayments'] || 0)).toFixed(2)) : '';
    case 'TotalFDAPWithheldIncomeNotRequiredChapter4':
      return parseFloat(((form['IncomePaidToRecipients'] || 0) + (form['ExcludedNonFinantialPayments'] || 0)
        + (form['IncomePaidToGrandfatheredObligations'] || 0) + (form['IncomeTradeOrBusinessAmount'] || 0)).toFixed(2));
    case 'TotalFDAPWithheldIncomeChapter4':
      return parseFloat(((form['TotalFDAPWithheldIncomeRequiredChapter4'] || 0) + (form['TotalFDAPWithheldIncomeNotRequiredChapter4'] || 0)).toFixed(2));
    case 'TotalFDAPReportedIncome1042S':
      return parseFloat(((form['TotalFDAPIncome'] || 0) + (form['TotalSubstituteDividendPaymentReported'] || 0) + (form['TotalSubstitutePaymentReported'] || 0)).toFixed(2));
    case 'TotalVariance':
      return parseFloat(((form['TotalFDAPReportedIncome1042S'] || 0) - (form['TotalFDAPWithheldIncomeChapter4'] || 0)).toFixed(2));

    default:
      return '';
  }
};

const form499R2 = {
  A06: 'A06'
};

export {
  calculateValue,
  nonDbFields,
  form499R2
};
