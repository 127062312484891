import { useEffect, useState } from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import MultipleRows from '../shared/multipleRows/MultipleRows';
import AmountDifferenceFieldForm from 'shared/standardInput/textField/AmountDifferenceFieldForm';
import PreviousCurrentTextFieldForm from 'shared/standardInput/textField/PreviousCurrentTextFieldForm';
import ConfirmationDialog from 'shared/confirmationDialog/ConfirmationDialog';
import W2TransmittalStatus from '../w2TransmittalStatus/W2TransmittalStatus';
import './w2MultipleLocalWage.scss';

const PSTATE_ABBR = 'PStateAbbreviation';
const PLOCAL_WAGES = 'PLocalWages';
const PLOCAL_TAX = 'PLocalTax';
const PLOCAL_NAME = 'PLocalityName';
const PLOCAL_CODE = 'PLocalityCode';
const PCOUNTY_CODE = 'PCountyCode';
const PTAX_TYPE = 'PTaxType';
const PRESIDENCE_PSD = 'PResidencePSD';
const PWORK_LOCATION_PSD = 'PWorkLocationPSD';
const PSCHOOL_DISTRICT = 'PSchoolDistrict';
const STATE_ABBR = 'StateAbbreviation';
const LOCAL_WAGES = 'LocalWages';
const LOCAL_TAX = 'LocalTax';
const LOCAL_NAME = 'LocalityName';
const LOCAL_CODE = 'LocalityCode';
const COUNTY_CODE = 'CountyCode';
const TAX_TYPE = 'TaxType';
const RESIDENCE_PSD = 'ResidencePSD';
const WORK_LOCATION_PSD = 'WorkLocationPSD';
const SCHOOL_DISTRICT = 'SchoolDistrict';
const RETURN_STATUS = 'ReturnStatus';
const XMITRUN = 'Xmitrun';

const addIfHasValue = (object, key) => object[key] && { [key]: object[key] };

const cleanMultipleLocalities = localities => localities
  .filter(locality => locality[STATE_ABBR] && locality[LOCAL_NAME])
  .map(locality => ({
    [STATE_ABBR]: locality[STATE_ABBR],
    ...(addIfHasValue(locality, LOCAL_WAGES)),
    ...(addIfHasValue(locality, LOCAL_TAX)),
    [LOCAL_NAME]: locality[LOCAL_NAME],
    ...(addIfHasValue(locality, LOCAL_CODE)),
    ...(addIfHasValue(locality, COUNTY_CODE)),
    ...(addIfHasValue(locality, TAX_TYPE)),
    ...(addIfHasValue(locality, RETURN_STATUS)),
    ...(addIfHasValue(locality, XMITRUN)),
    CustomFieldsData: {
      ...(addIfHasValue(locality, RESIDENCE_PSD)),
      ...(addIfHasValue(locality, WORK_LOCATION_PSD)),
      ...(addIfHasValue(locality, SCHOOL_DISTRICT))
    }
  }));

const checkForDuplicateLocalities = localities => {
  let tracker = {};
  for (const locality of localities) {
    if (tracker[locality]) {
      return locality;
    }
    tracker[locality] = true;
  }
  return null;
};

const getLocalityCombination = locality => `${locality[STATE_ABBR]}-${locality[LOCAL_NAME]}${getLocalityFieldCombination(locality)}`;

const getLocalityFieldCombination = locality => {
  const taxType = locality[TAX_TYPE]
    ? `-${locality[TAX_TYPE]}`
    : '';
  const residencePSD = locality[RESIDENCE_PSD] || locality.CustomFieldsData?.[RESIDENCE_PSD]
    ? `-${locality[RESIDENCE_PSD] || locality.CustomFieldsData?.[RESIDENCE_PSD]}`
    : '';
  const workLocationPSD = locality[WORK_LOCATION_PSD] || locality.CustomFieldsData?.[WORK_LOCATION_PSD]
    ? `-${locality[WORK_LOCATION_PSD] || locality.CustomFieldsData?.[WORK_LOCATION_PSD]}`
    : '';

  return `${taxType}${residencePSD}${workLocationPSD}`;
};

const W2MultipleLocalWage = ({
  localities,
  previousValue,
  formActions,
  readOnlyUser,
  isFormTransmitted,
  isClone,
  taxEntityCode
}) => {
  const [showModal, setShowModal] = useState(false);
  const [rowToSetZeroed, setRowToSetZeroed] = useState(null);
  const [duplicateState, setDuplicateState] = useState();
  const [localMultipleLocalities, setLocalMultipleLocalities] = useState(() => localities.length
    ? [...localities
      .map((locality, id) => {
        const previousLocality = previousValue.find(previousCLocality =>
          previousCLocality[STATE_ABBR] === locality[STATE_ABBR]
          && previousCLocality[LOCAL_NAME] === locality[LOCAL_NAME]
          && (previousCLocality.CustomFieldsData[RESIDENCE_PSD] || ' ') === (locality.CustomFieldsData[RESIDENCE_PSD] || ' ')
          && (previousCLocality.CustomFieldsData[WORK_LOCATION_PSD] || ' ') === (locality.CustomFieldsData[WORK_LOCATION_PSD] || ' ')
        ) || {};

        return {
          Id: id + 1,
          [STATE_ABBR]: locality[STATE_ABBR] || '',
          [LOCAL_WAGES]: locality[LOCAL_WAGES],
          [LOCAL_TAX]: locality[LOCAL_TAX],
          [LOCAL_NAME]: locality[LOCAL_NAME] || '',
          [LOCAL_CODE]: locality[LOCAL_CODE] || '',
          [COUNTY_CODE]: locality[COUNTY_CODE] || '',
          [TAX_TYPE]: locality[TAX_TYPE] || '',
          [RETURN_STATUS]: locality[RETURN_STATUS] || '',
          [XMITRUN]: locality[XMITRUN] || '',
          [RESIDENCE_PSD]: locality.CustomFieldsData[RESIDENCE_PSD] || '',
          [WORK_LOCATION_PSD]: locality.CustomFieldsData[WORK_LOCATION_PSD] || '',
          [SCHOOL_DISTRICT]: locality.CustomFieldsData[SCHOOL_DISTRICT] || '',
          [PSTATE_ABBR]: previousLocality[STATE_ABBR] || '',
          [PLOCAL_WAGES]: previousLocality[LOCAL_WAGES],
          [PLOCAL_TAX]: previousLocality[LOCAL_TAX],
          [PLOCAL_NAME]: previousLocality[LOCAL_NAME] || '',
          [PLOCAL_CODE]: previousLocality[LOCAL_CODE] || '',
          [PCOUNTY_CODE]: previousLocality[COUNTY_CODE] || '',
          [PTAX_TYPE]: previousLocality[TAX_TYPE] || '',
          [PRESIDENCE_PSD]: previousLocality.CustomFieldsData?.[RESIDENCE_PSD] || '',
          [PWORK_LOCATION_PSD]: previousLocality.CustomFieldsData?.[WORK_LOCATION_PSD] || '',
          [PSCHOOL_DISTRICT]: previousLocality.CustomFieldsData?.[SCHOOL_DISTRICT] || '',
          disableLocalityInfo: true
        };
      })
    ]
    : []);

  useEffect(() => {
    const completedRows = cleanMultipleLocalities(localMultipleLocalities);

    formActions.setFieldValue('LOCALITIES', completedRows, isClone, taxEntityCode);

    const duplicate = checkForDuplicateLocalities(completedRows.map(item => getLocalityCombination(item)));
    setDuplicateState(duplicate);
    formActions.setSaveDisableByValidation(Boolean(duplicate));
  }, [localMultipleLocalities, formActions, isClone]);

  const handleAddLocality = () => {
    const newMultipleLocalities = [
      ...localMultipleLocalities,
      {
        Id: (localMultipleLocalities.at(-1)?.Id || 0) + 1,
        [STATE_ABBR]: '',
        [LOCAL_WAGES]: null,
        [LOCAL_TAX]: null,
        [LOCAL_NAME]: '',
        [LOCAL_CODE]: '',
        [COUNTY_CODE]: '',
        [TAX_TYPE]: '',
        [RESIDENCE_PSD]: '',
        [WORK_LOCATION_PSD]: '',
        [SCHOOL_DISTRICT]: '',
        disableLocalityInfo: false
      }
    ];

    setLocalMultipleLocalities(newMultipleLocalities);
  };

  const zeroAmountFields = id => {
    setLocalMultipleLocalities(prevState => prevState.map(
      multipleLocalityInfo => multipleLocalityInfo.Id === id
        ? { ...multipleLocalityInfo, [LOCAL_WAGES]: null, [LOCAL_TAX]: null }
        : multipleLocalityInfo)
    );
  };

  const handleDeleteLocality = id => {
    if (isFormTransmitted) {
      setShowModal(true);
      setRowToSetZeroed(id);
    } else {
      setLocalMultipleLocalities(prevState => prevState.filter(record => record.Id !== id));
    }
  };

  const handleChange = (key, value, id) => {
    setLocalMultipleLocalities(prevState => prevState.map(
      multipleLocalityInfo => multipleLocalityInfo.Id === id
        ? { ...multipleLocalityInfo, [key]: value }
        : multipleLocalityInfo)
    );
  };

  const handleConfirmChangeLocalTransmitted = () => {
    setShowModal(false);
    if (Number(rowToSetZeroed)) {
      zeroAmountFields(rowToSetZeroed);
      setRowToSetZeroed(null);
    }
  };

  const hasLocalityTransmitted = locality => Boolean(locality[PSTATE_ABBR] && (locality[PLOCAL_NAME] || ' ') && (locality[PRESIDENCE_PSD] || ' ') && (locality[PWORK_LOCATION_PSD] || ' '));

  return (
    <>
      <div className='w2-multiple-local-wage__container'>
        <MultipleRows
          onAddRow={ handleAddLocality }
          showAddButton={ !readOnlyUser }
          addButtonProps={ { label: '+ Add additional locality' } }
        >
          {
            localMultipleLocalities.map(locality => (
              <MultipleRows.Row
                key={ locality.Id }
                onDeleteRow={ handleDeleteLocality }
                row={ locality }
                showDeleteButton={ !readOnlyUser }
              >
                <div className='w2-multiple-local-wage__row'>
                  <W2TransmittalStatus
                    name={ locality[LOCAL_NAME] || 'NEW LOCALITY' }
                    returnStatus= { locality[RETURN_STATUS] || 'O' }
                    transmitStatus= { Boolean(locality[XMITRUN]) }
                  />
                  <AmountDifferenceFieldForm
                    boxName='18'
                    name={ `l18-${locality.Id}` }
                    label='Local wages, tips, etc.'
                    value={ locality[LOCAL_WAGES] }
                    previousValue={ locality[PLOCAL_WAGES] }
                    onChange={ (_, value) => handleChange(LOCAL_WAGES, value, locality.Id) }
                    showPreviousDifferentFields={ hasLocalityTransmitted(locality) }
                    readOnlyUser={ readOnlyUser }
                    mask='CURRENCY'
                    enableCallbackWhenSettingValueViaProps={ false }
                  />
                  <AmountDifferenceFieldForm
                    boxName='19'
                    name={ `l19-${locality.Id}` }
                    label='Local income tax'
                    value={ locality[LOCAL_TAX] }
                    previousValue={ locality[PLOCAL_TAX] }
                    onChange={ (_, value) => handleChange(LOCAL_TAX, value, locality.Id) }
                    showPreviousDifferentFields={ hasLocalityTransmitted(locality) }
                    readOnlyUser={ readOnlyUser }
                    mask='CURRENCY'
                    enableCallbackWhenSettingValueViaProps={ false }
                  />
                  <PreviousCurrentTextFieldForm
                    boxName='20'
                    name={ `l20-${locality.Id}` }
                    label='Locality name'
                    labelValidation='Locality name'
                    value={ locality[LOCAL_NAME] }
                    previousValue={ locality[PLOCAL_NAME] }
                    readOnlyUser={ readOnlyUser || locality.disableLocalityInfo }
                    validation={ {
                      pattern: 'MAXLENGTH20',
                      specialCharacters: '-.',
                      message: 'Must contain 20 valid characters or less',
                      validateOn: 'keyup',
                      required: true
                    } }
                    errorText={ duplicateState === getLocalityCombination(locality) ? 'Locality Name must be unique.' : '' }
                    onChange={ (_, value) => handleChange(LOCAL_NAME, value, locality.Id) }
                    showPreviousDifferentFields={ hasLocalityTransmitted(locality) }
                    toUpper
                  />
                  <PreviousCurrentTextFieldForm
                    name={ `state-${locality.Id}` }
                    label='Local state'
                    labelValidation='Local state'
                    value={ locality[STATE_ABBR] }
                    previousValue={ locality[PSTATE_ABBR] }
                    readOnlyUser={ readOnlyUser || locality.disableLocalityInfo }
                    validation={ {
                      pattern: 'MAXLENGTH2',
                      message: 'Must contain 2 valid characters or less',
                      validateOn: 'keyup',
                      required: true
                    } }
                    errorText={ duplicateState === getLocalityCombination(locality) ? 'Local state must be unique.' : '' }
                    onChange={ (_, value) => handleChange(STATE_ABBR, value, locality.Id) }
                    showPreviousDifferentFields={ hasLocalityTransmitted(locality) }
                    toUpper
                  />
                  <PreviousCurrentTextFieldForm
                    name={ `local-code-${locality.Id}` }
                    label='Locality code'
                    labelValidation='Locality code'
                    value={ locality[LOCAL_CODE] }
                    previousValue={ locality[PLOCAL_CODE] }
                    readOnlyUser={ readOnlyUser }
                    validation={ {
                      pattern: 'MAXLENGTH20',
                      message: 'Must contain 20 valid characters or less',
                      validateOn: 'keyup'
                    } }
                    onChange={ (_, value) => handleChange(LOCAL_CODE, value, locality.Id) }
                    showPreviousDifferentFields={ hasLocalityTransmitted(locality) }
                    toUpper
                  />
                  <PreviousCurrentTextFieldForm
                    name={ `county-code-${locality.Id}` }
                    label='County code'
                    labelValidation='County code'
                    value={ locality[COUNTY_CODE] }
                    previousValue={ locality[PCOUNTY_CODE] }
                    readOnlyUser={ readOnlyUser }
                    validation={ {
                      pattern: 'MAXLENGTH4',
                      message: 'Must contain 4 valid characters or less',
                      validateOn: 'keyup'
                    } }
                    onChange={ (_, value) => handleChange(COUNTY_CODE, value, locality.Id) }
                    showPreviousDifferentFields={ hasLocalityTransmitted(locality) }
                    toUpper
                  />
                  <PreviousCurrentTextFieldForm
                    name={ `tax-type-${locality.Id}` }
                    label='Tax type'
                    labelValidation='Tax type'
                    value={ locality[TAX_TYPE] }
                    previousValue={ locality[PTAX_TYPE] }
                    readOnlyUser={ readOnlyUser || locality.disableLocalityInfo }
                    validation={ {
                      pattern: 'MAXLENGTH2',
                      message: 'Must contain 2 valid characters or less',
                      validateOn: 'keyup'
                    } }
                    errorText={ duplicateState === getLocalityCombination(locality) && locality[TAX_TYPE] ? 'Tax Type must be unique.' : '' }
                    onChange={ (_, value) => handleChange(TAX_TYPE, value, locality.Id) }
                    showPreviousDifferentFields={ hasLocalityTransmitted(locality) }
                    toUpper
                  />
                  <PreviousCurrentTextFieldForm
                    name={ `residence-psd-${locality.Id}` }
                    label='Residence PSD'
                    labelValidation='Residence PSD'
                    value={ locality[RESIDENCE_PSD] }
                    previousValue={ locality[PRESIDENCE_PSD] }
                    readOnlyUser={ readOnlyUser || locality.disableLocalityInfo }
                    validation={ {
                      pattern: 'MAXLENGTH20',
                      message: 'Must contain 20 characters or less',
                      validateOn: 'keyup'
                    } }
                    errorText={ duplicateState === getLocalityCombination(locality) && locality[RESIDENCE_PSD] ? 'Residence PSD must be unique.' : '' }
                    onChange={ (_, value) => handleChange(RESIDENCE_PSD, value, locality.Id) }
                    showPreviousDifferentFields={ hasLocalityTransmitted(locality) }
                    toUpper
                  />
                  <PreviousCurrentTextFieldForm
                    name={ `work-location-psd-${locality.Id}` }
                    label='Work location PSD'
                    labelValidation='Work location PSD'
                    value={ locality[WORK_LOCATION_PSD] }
                    previousValue={ locality[PWORK_LOCATION_PSD] }
                    readOnlyUser={ readOnlyUser || locality.disableLocalityInfo }
                    validation={ {
                      pattern: 'MAXLENGTH20',
                      message: 'Must contain 20 characters or less',
                      validateOn: 'keyup'
                    } }
                    errorText={ duplicateState === getLocalityCombination(locality) && locality[WORK_LOCATION_PSD] ? 'Work location PSD must be unique.' : '' }
                    onChange={ (_, value) => handleChange(WORK_LOCATION_PSD, value, locality.Id) }
                    showPreviousDifferentFields={ hasLocalityTransmitted(locality) }
                    toUpper
                  />
                  <PreviousCurrentTextFieldForm
                    name={ `school-district-${locality.Id}` }
                    label='School district'
                    labelValidation='School district'
                    value={ locality[SCHOOL_DISTRICT] }
                    previousValue={ locality[PSCHOOL_DISTRICT] }
                    readOnlyUser={ readOnlyUser }
                    validation={ {
                      pattern: 'MAXLENGTH4',
                      message: 'Must contain 4 valid characters or less',
                      validateOn: 'keyup'
                    } }
                    onChange={ (_, value) => handleChange(SCHOOL_DISTRICT, value, locality.Id) }
                    showPreviousDifferentFields={ hasLocalityTransmitted(locality) }
                    toUpper
                  />
                </div>
              </MultipleRows.Row>
            ))
          }
        </MultipleRows>
      </div>
      <ConfirmationDialog
        open={ showModal }
        content='Box 18 and 19 will be zeroed out, but Local State will remain because this record has been transmitted. Do you wish to proceed?'
        onCancelClick={ () => setShowModal(false) }
        onOKClick={ handleConfirmChangeLocalTransmitted }
      />
    </>
  );
};

W2MultipleLocalWage.propTypes = {
  localities: arrayOf(shape({
    StateAbbreviation: string,
    LocalWages: number,
    LocalTax: number,
    LocalityName: string,
    LocalityCode: string,
    CountyCode: string,
    TaxType: string,
    ResidencePSD: string,
    WorkLocationPSD: string,
    ReturnStatus: string,
    Xmitrun: number
  })).isRequired,
  isFormTransmitted: bool.isRequired,
  isClone: bool.isRequired,
  formActions: shape({
    setFieldValue: func.isRequired,
    setSaveDisableByValidation: func.isRequired
  }).isRequired,
  readOnlyUser: bool,
  previousValue: arrayOf(shape({
    StateAbbreviation: string,
    LocalWages: number,
    LocalTax: number,
    LocalityName: string,
    LocalityCode: string,
    CountyCode: string,
    TaxType: string,
    ResidencePSD: string,
    WorkLocationPSD: string
  })),
  taxEntityCode: string.isRequired
};

W2MultipleLocalWage.defaultProps = {
  readOnlyUser: false,
  previousValue: []
};

export default W2MultipleLocalWage;
