import { Component } from 'react';
import PropTypes from 'prop-types';
import phnxTheme from './PhoenixTheme';
import './phoenixLayout.scss';

class PhoenixThemeProvider extends Component {
  componentDidMount() {
    // Set defaults
    document.body.style.fontFamily = phnxTheme.fontFamily;
    document.body.style.color = phnxTheme.palette.textColor;
  }

  render() {
    return this.props.children;
  }
}

PhoenixThemeProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default PhoenixThemeProvider;
