import { useState } from 'react';
import PropTypes from 'prop-types';
import { ListItem } from '@mui/material';
import { SovosCollapse, SovosListItemIcon, SovosTooltip } from 'mosaic-react';
import { UnfoldLess, UnfoldMore } from 'mosaic-react-icons';
import './collapsibleListFields.scss';

const CollapsibleListFields = ({ header, children }) => {
  const { defaultCollapsed, tooltipTitle } = header.props.metadata;
  const [expanded, setExpanded] = useState(!Boolean(defaultCollapsed));
  const handleListItemClick = () => () => setExpanded(!expanded);

  return (
    <>
      <SovosCollapse in={ expanded }>
        { children }
      </SovosCollapse>
      <ListItem
        className='collapsible-list-fields__header'
      >
        <div className='collapsible-list-fields__item'>
          { header }
        </div>
        <SovosListItemIcon className='collapsible-list-fields__icon' onClick={ handleListItemClick() }>
          {
            expanded
              ? <UnfoldLess />
              : <SovosTooltip title={ tooltipTitle ?? 'Expand' }>
                <UnfoldMore />
              </SovosTooltip>
          }
        </SovosListItemIcon>
      </ListItem>
    </>
  );
};

CollapsibleListFields.propTypes = {
  header: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired
};

export default CollapsibleListFields;