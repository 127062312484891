import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { determineMask } from './tinMaskHelper';

const constants = {
  tin: 'TIN',
  fk1: 'FK1',
  fk2: 'FK2',
  pein: 'PEIN',
  tinType: 'TINTYPE',
  ptinType: 'PTINTYPE'
};

class TinMask extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showValue: false
    };
  }

  toggleShowValue = () => {
    this.setState({ showValue: !this.state.showValue });
  };

  render() {
    const value = this.props.is1042SForm
      ? this.props.records[constants.fk2] || ''
      : this.props.records[constants.tin] || this.props.records[constants.fk1] || this.props.records[constants.pein] || '';
    const tinType = this.props.records[constants.tinType] || this.props.records[constants.ptinType];
    const mask = value === ''
      ? value
      : determineMask(value, tinType, this.state.showValue);

    return (
      <span onMouseOver={ this.toggleShowValue } onMouseOut={ this.toggleShowValue } >{ mask }</span>
    );
  }
}

TinMask.propTypes = {
  records: PropTypes.object.isRequired,
  is1042SForm: PropTypes.bool
};

export default TinMask;
