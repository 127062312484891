import SovosTypography from 'mosaic-react/sovos-typography';
import './summaryOfQDDHeader.scss';

const SummaryOfQDDHeader = () => (
  <div className='summary-of-qdd-header'>
    <div>
      <SovosTypography variant='body1' className='summary-of-qdd-header__label summary-of-qdd-header__text'>Summary of QDD tax liability</SovosTypography>
    </div>
    <div className='summary-of-qdd-header__columns'>
      <div className='summary-of-qdd-header__columns__column'>
        <SovosTypography variant='body1' align="center">(a)</SovosTypography>
        <SovosTypography variant='body1' align="center">Gross amount</SovosTypography>
      </div>
      <div className='summary-of-qdd-header__columns__column'>
        <SovosTypography variant='body1' align="center">(b)</SovosTypography>
        <SovosTypography variant='body1' align="center">Withholding</SovosTypography>
      </div>
      <div className='summary-of-qdd-header__columns__column'>
        <SovosTypography variant='body1' align="center">(c)</SovosTypography>
        <SovosTypography variant='body1' align="center">Amount of Tax Liability</SovosTypography>
        <SovosTypography variant='body1' align="center">(column (a) x column (b))</SovosTypography>
      </div>
    </div>

  </div>
);

export default SummaryOfQDDHeader;