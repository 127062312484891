import sharedActions from 'redux/constants/SharedConstants';

const actionTypes = {
  ...sharedActions,
  FETCHING_PRINT_NOTES: 'FETCHING_PRINT_NOTES',
  FETCH_PRINT_NOTES_SUCCESS: 'FETCH_PRINT_NOTES_SUCCESS',
  FETCH_PRINT_NOTES_FAILURE: 'FETCH_PRINT_NOTES_FAILURE',
  SAVING_PRINT_NOTE: 'SAVING_PRINT_NOTE',
  SAVE_PRINT_NOTE_SUCCESS: 'SAVE_PRINT_NOTE_SUCCESS',
  SAVE_PRINT_NOTE_FAILURE: 'SAVE_PRINT_NOTE_FAILURE',
  UPDATING_PRINT_NOTE: 'UPDATING_PRINT_NOTE',
  UPDATE_PRINT_NOTE_SUCCESS: 'UPDATE_PRINT_NOTE_SUCCESS',
  UPDATE_PRINT_NOTE_FAILURE: 'UPDATE_PRINT_NOTE_FAILURE',
  DELETING_PRINT_NOTE: 'DELETING_PRINT_NOTE',
  DELETE_PRINT_NOTE_SUCCESS: 'DELETE_PRINT_NOTE_SUCCESS',
  DELETE_PRINT_NOTE_FAILURE: 'DELETE_PRINT_NOTE_FAILURE'

};

export default actionTypes;
