import { Component } from 'react';
import PropTypes from 'prop-types';
import SovosEditableDrawer from 'mosaic-react/sovos-editable-drawer/SovosEditableDrawer';
import ColumnList from './columnList/ColumnList';
import DragDropContext from '../dragAndDropBeautiful/dragDropContext/DragDropContext';
import './columnsSelector.scss';

class ColumnSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleColumns: this.props.visibleColumns,
      hiddenColumns: this.props.hiddenColumns,
      visibleColumnsTemp: [],
      hiddenColumnsTemp: []
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.updatingTableOptions && prevProps.updatingTableOptions) {
      this.props.onSaveChangesSuccess();
      this.props.onClose();
    }
    if (this.props.saveNewOrder && !prevProps.saveNewOrder) {
      this.handleSaveColumns();
    }
    if (!prevProps.open && this.props.open) {
      this.setState({ visibleColumnsTemp: this.state.visibleColumns, hiddenColumnsTemp: this.state.hiddenColumns });
    }
  };

  reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.forEach(( column, index ) => { column.order = index; });
    return result;
  };

  mergeArrays = (arraySource, arrayDestination, resultDrop) => {
    let intermediarySource = [...arraySource];
    let element = intermediarySource.splice(resultDrop.source.index, 1)[0];
    element.isEnabled = !element.isEnabled;
    let intermediaryDestination = [...arrayDestination, element];
    intermediaryDestination = this.reorder(
      intermediaryDestination,
      intermediaryDestination.length - 1,
      resultDrop.destination.index
    );

    return {
      intermediarySource,
      intermediaryDestination
    };
  };

  handleOnDrop = dndInfo => {
    let visibleColumns = [...this.state.visibleColumns];
    let hiddenColumns = [...this.state.hiddenColumns];

    if ( dndInfo.source.droppableId !== dndInfo.destination.droppableId ) {
      if ( dndInfo.destination.droppableId === 'VISIBLECOLUMNS' ) {
        const { intermediarySource, intermediaryDestination } = this.mergeArrays(
          this.state.hiddenColumns,
          this.state.visibleColumns,
          dndInfo
        );

        this.setState({
          visibleColumns: intermediaryDestination,
          hiddenColumns: intermediarySource
        });
      } else {
        const { intermediarySource, intermediaryDestination } = this.mergeArrays(
          this.state.visibleColumns,
          this.state.hiddenColumns,
          dndInfo
        );

        this.setState({
          visibleColumns: intermediarySource,
          hiddenColumns: intermediaryDestination
        });
      }
    } else {
      if (dndInfo.destination.droppableId === 'VISIBLECOLUMNS') {
        visibleColumns = this.reorder(
          visibleColumns,
          dndInfo.source.index,
          dndInfo.destination.index
        );
      } else {
        hiddenColumns = this.reorder(
          hiddenColumns,
          dndInfo.source.index,
          dndInfo.destination.index
        );
      }
      this.setState({ visibleColumns, hiddenColumns });
    }
  };

  hideColumn = column => {
    if (this.state.visibleColumns.length > 1) {
      const hiddenColumns = [...this.state.hiddenColumns];
      column.order = hiddenColumns.length;
      column.isEnabled = !column.isEnabled;
      hiddenColumns.push(column);
      this.setState({
        hiddenColumns,
        visibleColumns: this.state.visibleColumns.filter(columnElement => (columnElement.key !== column.key))
      });
    }
  };

  showColumn = column => {
    const visibleColumns = [...this.state.visibleColumns];
    column.order = visibleColumns.length;
    column.isEnabled = !column.isEnabled;
    visibleColumns.push(column);
    this.setState({
      visibleColumns,
      hiddenColumns: this.state.hiddenColumns.filter(columnElement => (columnElement.key !== column.key))
    });
  };

  handleSaveColumns = () => {
    const orderedFields = {};
    this.state.visibleColumns.forEach((column, index) => {
      orderedFields[column.name] = {
        showOnSearch: column.isEnabled,
        order: index
      };
    });
    this.state.hiddenColumns.forEach((column, index) => {
      orderedFields[column.name] = {
        showOnSearch: column.isEnabled,
        order: index
      };
    });
    this.props.appSettingsActions.addOrMergeColumnSelectorOptions(this.props.fchar, orderedFields);
    this.props.formMetaInformationActions.resetTableOptions(this.props.fchar);
  };

  handleOnClose = () => {
    this.setState({ visibleColumns: this.state.visibleColumnsTemp, hiddenColumns: this.state.hiddenColumnsTemp });
    this.props.onClose();
  };

  render() {
    const { open, onApplyClick } = this.props;

    return (
      <SovosEditableDrawer
        title="Columns"
        open={ open }
        onClose={ this.handleOnClose }
        PrimaryButtonProps={ {
          onClick: onApplyClick
        } }
        SecondaryButtonProps={ {
          onClick: this.handleOnClose,
          variant: 'text'
        } }
      >
        <div>
          <DragDropContext
            onDragEnd={ this.handleOnDrop }
          >
            <ColumnList
              visibleColumns={ this.state.visibleColumns }
              hiddenColumns={ this.state.hiddenColumns }
              onShowColumn={ this.showColumn }
              onHideColumn={ this.hideColumn }
            />
          </DragDropContext>
        </div>
      </SovosEditableDrawer>
    );
  }
}

ColumnSelector.propTypes = {
  open: PropTypes.bool.isRequired,
  updatingTableOptions: PropTypes.bool.isRequired,
  saveNewOrder: PropTypes.bool.isRequired,
  visibleColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
  hiddenColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
  fchar: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onApplyClick: PropTypes.func.isRequired,
  onSaveChangesSuccess: PropTypes.func.isRequired,
  formMetaInformationActions: PropTypes.shape({
    resetTableOptions: PropTypes.func
  }).isRequired,
  appSettingsActions: PropTypes.shape({
    addOrMergeColumnSelectorOptions: PropTypes.func
  }).isRequired
};

ColumnSelector.defaultProps = {
  onClose: () => null
};

export default ColumnSelector;
