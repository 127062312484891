import { useState, useEffect } from 'react';
import { bool, func, string, number } from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import TextField from '@mui/material/TextField';
import SovosTooltip from 'shared/sovos-tooltip/SovosTooltip';
import TextMaskCustom from 'shared/standardInput/textField/TextMaskCustom';
import { determineMask } from 'shared/standardInput/textField/helper/maskHelper';
import { getLabel } from 'shared/dataEntryForms/sovos-dynamic-form/helper/labelHelper';
import 'shared/standardInput/textField/validationGridTextField/validationGridTextField.scss';
import './scheduleQDateRangeField.scss';

const ScheduleQDateRangeField = ({
  label,
  scheduleQId,
  readOnly,
  startDatekey,
  startDatevalue,
  endDatekey,
  endDatevalue,
  handleOnChange
}) => {
  const [internalValue, setInternalValue] = useState(startDatevalue && endDatevalue ? `${startDatevalue || '__/__/____' }-${endDatevalue || '__/__/____'}` : null);
  const [errorMessage, setErrorMessage] = useState(null);

  const maskToApply = determineMask('DATERANGE');

  useEffect(() => {
    if (internalValue !== null) {
      const dates = internalValue.split("-");
      if (startDatevalue !== dates[0] || endDatevalue !== dates[1]) {
        handleOnChange(startDatekey, dates[0], endDatekey, dates[1] || '');
      }
      const datesAreValid = moment(dates[1], 'MM/DD/YYYY', true).isValid() && moment(dates[0], 'MM/DD/YYYY', true).isValid();
      if (datesAreValid) {
        setErrorMessage(null);
      } else {
        setErrorMessage('Invalid date format.');
      }
    }
  }, [internalValue]);

  const isFilled = () => {
    if (!internalValue || internalValue === '') {
      setErrorMessage('Dates are required.');
    }
  };

  return (
    <div className='tirTextfieldForm__wrapper'>
      <div className='label__container'>
        <div className='label__container-left'>
          <label
            className={ classNames('tirTexfield__label', Boolean(errorMessage) && 'tirTexfield__label-error') }
          >
            { getLabel(true, label) }
          </label>
        </div>
      </div>
      <div className='textField__container tirTextField__container-30'>
        <SovosTooltip
          id={ `${startDatekey}-${endDatekey}-tt` }
          label={ errorMessage }
          show={ Boolean(errorMessage) }
          place='right'
          effect='solid'
          className='tir-texfield__error'
        >
          <TextField
            name={ `${startDatekey}-${endDatekey}-${scheduleQId}` }
            value={ internalValue }
            className={ 'tirTextfieldForm__input' }
            InputProps={ {
              classes: { input: 'schedule-q-date-range-field__input' },
              inputComponent: TextMaskCustom,
              disableUnderline: true
            } }
            onChange={ evt => setInternalValue(evt.target.value) }
            autoComplete='off'
            disabled={ readOnly }
            variant='filled'
            inputProps={ { maskToApply } }
            onBlur={ isFilled }
          />
        </SovosTooltip>
      </div>
    </div>
  );
};

ScheduleQDateRangeField.propTypes = {
  label: string.isRequired,
  scheduleQId: number.isRequired,
  readOnly: bool,
  startDatekey: string.isRequired,
  startDatevalue: string,
  endDatekey: string.isRequired,
  endDatevalue: string,
  handleOnChange: func.isRequired
};

export default ScheduleQDateRangeField;