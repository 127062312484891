import { getLocalTimestamp } from 'tir-frontend-common/helpers/dateHelper';

export function processComments(commentsList) {
  return commentsList.map( comment => ({
    key: comment.Id,
    message: comment.Text,
    userName: comment.UserName,
    timeStamp: getLocalTimestamp(comment.CreatedOn)
  }));
}
