import { memo } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import HelpIcon from '@mui/icons-material/Help';
import SovosTooltip from '../../../sovos-tooltip/SovosTooltip';
import { getLabel } from '../../../dataEntryForms/sovos-dynamic-form/helper/labelHelper';
import classNames from 'classnames';
import './validationGridTextField.scss';
import { isChrome } from 'helpers/formHelper';
import useValidation from '../helper/useValidation';
import useMask from '../helper/useMask';
import isANonTaxFieldEditable from '../helper/nonTaxFieldHelper';
import { cleanMask } from '../helper/maskHelper';
import TextMaskCustom from '../TextMaskCustom';

const ValidationGridTextField = ({
  name,
  value,
  label,
  inputClasses,
  hideLabel,
  validation,
  mask,
  onChange,
  onValidation,
  multiLine,
  errorText,
  readOnly,
  readOnlyUser,
  disabled,
  reset,
  decimalLimit,
  labelValidation,
  helpText,
  modLevel,
  runUIValidationAutomatically
}) => {
  const [validationError, validationMessage, setInput] = useValidation(
    value,
    labelValidation || label,
    name,
    validation,
    mask,
    onValidation,
    reset,
    errorText,
    runUIValidationAutomatically
  );
  const [maskToApply] = useMask(mask, decimalLimit);

  const getInputProps = () => {
    const inputProps = {
      className: 'validation-grid-textField__input-base',
      classes: {
        ...inputClasses,
        underline: classNames('validation-grid-textField__input-underline', { [inputClasses.underline]: inputClasses.underline })
      }
    };
    if (maskToApply.mask) {
      inputProps.inputComponent = TextMaskCustom;
    }

    return inputProps;
  };

  const renderHelpIcon = () => (
    <SovosTooltip
      id={ name }
      label={ helpText }
      className="tirTextfield__helpText"
    >
      <HelpIcon className="tirTextfield__helpIcon"/>
    </SovosTooltip>
  );

  const showError = Boolean(validationError || (modLevel >= 0 && validation?.required && !value));

  return (
    <div className="tirTexfield__wrapper">
      {
        !hideLabel &&
        <label
          className={ classNames(`tirTexfield__label`, { 'tirTexfield__label-error': showError }) }
          htmlFor={ name }
        >
          { getLabel(validation?.required, label) }
          { helpText && renderHelpIcon() }
        </label>
      }
      <SovosTooltip
        id={ name }
        label={ validationMessage || errorText || '' }
        show={ showError }
        place="right"
        className="tir-texfield__error"
        effect="solid"
      >
        <TextField
          name={ name }
          id={ name }
          className={ multiLine ? 'tirTexfield__textarea' : 'tirTexfield__input' }
          value={ value }
          onChange={ evt => onChange(evt, cleanMask(evt.target.value, mask)) }
          inputRef={ ref => setInput(ref) }
          InputProps={ getInputProps() }
          inputProps={ maskToApply.mask && {
            maskToApply
          } }
          disabled={ (readOnlyUser && !isANonTaxFieldEditable(name)) || readOnly || disabled }
          error={ showError }
          multiline={ multiLine }
          autoComplete={ isChrome() ? 'disabled' : 'off' }
          variant='filled'
        />
      </SovosTooltip>
    </div>
  );
};

ValidationGridTextField.whyDidYouRender = true;

ValidationGridTextField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  inputClasses: PropTypes.object,
  hideLabel: PropTypes.bool,
  validation: PropTypes.shape({
    pattern: PropTypes.string,
    message: PropTypes.string,
    validateOn: PropTypes.string,
    required: PropTypes.bool,
    maxLength: PropTypes.number
  }),
  mask: PropTypes.string,
  onChange: PropTypes.func,
  onValidation: PropTypes.func,
  multiLine: PropTypes.bool,
  errorText: PropTypes.string,
  readOnly: PropTypes.bool,
  readOnlyUser: PropTypes.bool,
  disabled: PropTypes.bool,
  reset: PropTypes.bool,
  decimalLimit: PropTypes.number,
  labelValidation: PropTypes.string,
  helpText: PropTypes.string,
  runUIValidationAutomatically: PropTypes.bool
};

ValidationGridTextField.defaultProps = {
  label: '',
  inputClasses: {},
  hideLabel: false,
  validation: {},
  mask: '',
  onChange: () => null,
  onValidation: () => null,
  multiLine: false,
  errorText: '',
  readOnly: false,
  readOnlyUser: false,
  disabled: false,
  reset: false,
  decimalLimit: 2,
  labelValidation: '',
  helpText: '',
  runUIValidationAutomatically: false
};

export default memo(ValidationGridTextField);
