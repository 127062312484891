import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { withSnackbar } from 'enhancers';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SovosTableGroup from 'mosaic-react/sovos-table-group';
import SovosTabGroup from 'mosaic-react/sovos-tab-group';
import SovosTabContent from 'mosaic-react/sovos-tab-content';
import SovosButton from 'mosaic-react/sovos-button';
import { getStateColumns, transmitType } from 'pages/transmitCorp/helpers/configTransmitStatus';
import AuthenticationHelper from 'helpers/authenticationHelper';
import { STATE_REPORTING } from 'helpers/configurationConstants';
import useRecentStateXmits from 'helpers/useRecentStateXmits';
import TransmitHistoryTable from 'shared/transmitHistoryTable/TransmitHistoryTable';
import PrintHistoryTable from 'shared/printHistoryTable/PrintHistoryTable';
import './tabbedOutputOverview.scss';

const TabbedOutputOverview = ({
  showErrorSnackbar
}) => {
  const [hasStateReporting] = AuthenticationHelper.checkConfiguration([STATE_REPORTING]);
  const isTIRCorporate = AuthenticationHelper.isTIRCorporate();
  const hasCorporateStateReporting = hasStateReporting && isTIRCorporate;
  const navigate = useNavigate();
  const location = useLocation();
  // corp state transmit table
  const {
    fetchingStateTransmit,
    stateTransmitRecords
  } = useRecentStateXmits(hasCorporateStateReporting, false, showErrorSnackbar);

  return (
    <div>
      <Typography variant='h4'>Recent print and transmit activity</Typography>
      <SovosTabGroup className='tabbed-output-overview'>
        <SovosTabContent TabProps={ { label: 'Print' } }>
          <div className='tabbed-output-overview__content'>
            <PrintHistoryTable
              showMoreButton
              showMoreUrl='print-history'
              noResultsMessage='No print jobs found'
              className='tabbed-output-overview__table-container'
              showPaginationFooter={ false }
              enableSorting={ false }
              initialItemsPerPage={ 5 }
              breadcrumbs={ [{ text: isTIRCorporate ? 'Home ':'Overview', path: location.pathname }] }
            />
          </div>
        </SovosTabContent>
        <SovosTabContent TabProps={ { label: 'Federal transmittals' } }>
          <div className='tabbed-output-overview__content'>
            <TransmitHistoryTable
              transmitType={ transmitType.federal }
              enableSorting={ false }
              enablePagination={ false }
              showMoreButton
              showMoreUrl='transmit-history/federal'
              noResultsMessage='No federal transmittals found'
              pageSizeDefault={ 5 }
              className='tabbed-output-overview__table-container'
              breadcrumbs={ [{ text: isTIRCorporate ? 'Home ':'Overview', path: location.pathname }] }
            />
          </div>
        </SovosTabContent>
        { hasCorporateStateReporting &&
          <SovosTabContent TabProps={ { label: 'State transmittals' } }>
            <div className='tabbed-output-overview__content'>
              <SovosTableGroup
                className='tabbed-output-overview__table'
                TableProps={ {
                  data: stateTransmitRecords,
                  columns: getStateColumns()
                } }
                isLoading={ fetchingStateTransmit }
                showNoData={ !stateTransmitRecords.length }
                noData={ { headingLabel: 'No state transmittals found' } }
              />
              <SovosButton
                className='tabbed-output-overview__button'
                data-for='tabbed-output-overview__button-state'
                variant='text'
                endIcon={ <ArrowForwardIcon /> }
                onClick={ () => navigate('/transmit-history/state', { state: { breadcrumbs: [{ text: 'Home', path: location.pathname }] } }) }
              >
                View all
              </SovosButton>
            </div>
          </SovosTabContent>
        }
      </SovosTabGroup>
    </div>
  );
};

TabbedOutputOverview.propTypes = {
  showErrorSnackbar: PropTypes.func
};

export default withSnackbar(TabbedOutputOverview);