import sharedActions from 'redux/constants/SharedConstants';

const actionTypes = {
  ...sharedActions,
  FETCH_CARTDATA_SUCCESS_TRANSMIT: 'FETCH_CARTDATA_SUCCESS_TRANSMIT',
  FETCH_CARTDATA_FAILURE_TRANSMIT: 'FETCH_CARTDATA_FAILURE_TRANSMIT',
  SET_FETCHING_CARTDATA_TRANSMIT: 'SET_FETCHING_CARTDATA_TRANSMIT',
  CLEAR_FETCH_CARDS_COMPLETED: 'CLEAR_FETCH_CARDS_COMPLETED',
  CLEAR_CARTDATA_TRANSMIT: 'CLEAR_CARTDATA_TRANSMIT'
};

export default actionTypes;
