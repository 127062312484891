import sharedActions from 'redux/constants/SharedConstants';

const actionTypes = {
  ...sharedActions,
  FETCHING_N_RECIPIENT: 'FETCHING_N_RECIPIENT',
  FETCH_N_RECIPIENT_SUCCESS: 'FETCH_N_RECIPIENT_SUCCESS',
  FETCH_N_RECIPIENT_FAILURE: 'FETCH_N_RECIPIENT_FAILURE',
  RESET_N_RECIPIENT: 'RESET_N_RECIPIENT',
  FETCH_N_RECIPIENT_BY_REGEX_SUCCESS: 'FETCH_N_RECIPIENT_BY_REGEX_SUCCESS',
  FETCH_N_RECIPIENT_BY_REGEX_FAILURE: 'FETCH_N_RECIPIENT_BY_REGEX_FAILURE',
  SET_N_RECIPIENT: 'SET_N_RECIPIENT',
  SET_N_RECIPIENT_BOOLEAN: 'SET_N_RECIPIENT_BOOLEAN'
};

export default actionTypes;
