const specialFields = {
  IDENTITYVERIFICATION: 'identityVerification',
  MULTIPLESTATE: 'PayerStates',
  PAYERLOCALITIES: 'PayerLocalities',
  CODEDPAYMENTS: 'CodedPayments',
  OTHERVALUES: 'OtherValues',
  XMITRUN: 'XMITRUN',
  STXMITIND: 'STXMITIND',
  TRANTYPE: 'TRANTYPE',
  TIN: 'TIN',
  RADIOBUTTONFIELDFORM: 'radioButtonFieldForm',
  AUTOCALCULATEDFIELD: 'autoCalculatedField',
  REQUIREDMESSAGE: 'RequiredMessage',
  SECTIONHEADER: 'SectionHeader',
  MAILINGADDRESSAUTOCHECKBOX: 'MailingAddressAutoCheckBox',
  DIFFERENCEVALUESHEADER: 'DifferenceValuesHeader',
  W2MULTIPLESTATES: 'W2MultipleStates',
  W2MULTIPLELOCALWAGE: 'W2MultipleLocalWage',
  FIELDHEADER: 'FieldHeader',
  SEMIWEEKLYAMOUNTS: 'SemiWeeklyAmounts'
};

export default specialFields;
