import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import './refreshIndicatorLoading.scss';

const rootStyle = {
  display: 'inline-block',
  position: 'relative'
};

// The father of this component, should be position: relative
const RefreshIndicatorLoading = props => {
  const { containerStyle, style, size } = props;
  const newStyle = Object.assign(rootStyle, style);
  return (
    <div style={ containerStyle } className="refreshIndicatorLoading__style">
      <CircularProgress
        color='inherit'
        size={ size }
        thickness={ 3 }
        left={ 0 }
        top={ 0 }
        status="loading"
        style={ newStyle }
      />
    </div>
  );
};

RefreshIndicatorLoading.propTypes = {
  containerStyle: PropTypes.object,
  style: PropTypes.object,
  size: PropTypes.number
};

RefreshIndicatorLoading.defaultProps = {
  containerStyle: {},
  style: {},
  size: 100
};

export default RefreshIndicatorLoading;
