import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NonPrintedNonTransmitted from './NonPrintedNonTransmitted';
import { fetchFormsByStatus } from '../redux/RecordActions';

const mapStateToProps = state => ({
  records: state.recordReducer.records,
  total: state.recordReducer.total,
  fetchingRecords: state.recordReducer.fetchingRecords
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  recordActions: bindActionCreators({
    fetchFormsByStatus:
      (searchText, page, itemsPerPage, sortColumn, sortOrder) => fetchFormsByStatus(ownProps.taxDetailStatus, searchText, page, itemsPerPage, sortColumn, sortOrder)
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(NonPrintedNonTransmitted);
