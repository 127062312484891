import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setFieldValue } from '../../../sovos-dynamic-form/redux/DynamicFormActions';
import SemiWeeklyAmount from './SemiWeeklyAmount';

const mapStateToProps = (state, ownProps) => ({
  fieldValue: state.dynamicForm.form[ownProps.taxEntityCode]?.[ownProps.name]
});

const mapDispatchToProps = dispatch => ({
  formActions: bindActionCreators({ setFieldValue }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SemiWeeklyAmount);