import { withholdingForms } from 'helpers/formConstants';

export const getCustomClassesByTaxEntityCode = taxEntityCode => {
  if (withholdingForms.includes(taxEntityCode)) {
    return ({
      amountDifferentFields: 'tirTextField__container-30',
      fieldHeaderHelper: 'tirTextField__container-30',
      radioButtonContainer: 'radio-button__container-30',
      radioButtonGroup: 'radio-button__group-vertical',
      textFieldContainer: 'tirTextField__container-30'
    });
  } else {
    return {};
  }
};