import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DashboardWrapper from './App';
import { migrateClient } from 'redux/adminPanel/AdminPanelActions';
import { fetchForms, fetchContextInfo } from 'redux/manageUser/ManageUserActions';
import { resetMetadata } from 'shared/dataEntryForms/sovos-dynamic-form/redux/FormMetaInformationActions';
import { setContext } from 'redux/context/contextActions';

const mapStateToProps = state => ({
  selectedContext: state.contextReducer.selectedContext,
  user: state.contextReducer.user,
  contexts: state.contextReducer.contextTree,
  isFetchingForms: state.manageUser.isFetchingForms,
  isFetchingPermission: state.manageUser.isFetchingPermission,
  exportStarted: state.adminPanel.exportStarted,
  exportFailed: state.adminPanel.exportFailed,
  exportError: state.adminPanel.exportError
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    migrateClient
  }, dispatch),
  manageUserActions: bindActionCreators({ fetchForms, fetchContextInfo }, dispatch),
  contextActions: bindActionCreators({ setContext }, dispatch),
  formMetaInformationActions: bindActionCreators({ resetMetadata }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardWrapper);