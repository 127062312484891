import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import { routerMiddleware } from 'react-router-redux';
import { createBrowserHistory } from 'history';

import createRootReducer from './combineReducers';

import WsNotifier from 'helpers/webSocket/WsNotifier';
import { setAxiosInterceptorsV1 } from 'helpers/axios/axiosInterceptorsV1';
import { setAxiosInterceptorsV2 } from 'helpers/axios/axiosInterceptorsV2';
import { setAxiosInterceptorsNoVersion } from 'helpers/axios/axiosInterceptorsNoVersion';

export const history = createBrowserHistory();

const configureStore = preloadedState => {
  const middlewares = [
    routerMiddleware(history),
    thunkMiddleware
  ];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);

  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composedEnhancers
  );

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./combineReducers', () => store.replaceReducer(createRootReducer(history)));
  }

  WsNotifier.getInstance(store);
  setAxiosInterceptorsV1(store);
  setAxiosInterceptorsV2(store);
  setAxiosInterceptorsNoVersion(store);

  return store;
};

export default configureStore;
