import { Suspense, useEffect, useState } from 'react';
import createStore from '../redux/createStore';
import { AuthProvider } from 'react-oidc-context';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import SovosProvider from 'mosaic-react/sovos-provider';
import TirThemeProvider from '../theme/PhoenixThemeProvider';
import { getBrowserRouter } from './Routes';
import {
  OIDC_CONFIG
} from 'infrastructure/appConfig';
import { isSSOUser } from 'infrastructure/cookieHelper';

const store = createStore();

const defaultOidcConfig = {
  redirect_uri: `${window.location.protocol}//${window.location.host}/signin-oidc`,
  post_logout_redirect_uri: `${window.location.protocol}//${window.location.host}/`
};

const AuthLayer = () => {
  const [oidcConfig, setOidcConfig] = useState(null);

  useEffect(() => {
    if (window.location.pathname === '/sovos-sso-login' || isSSOUser()) {
      setOidcConfig({
        ...OIDC_CONFIG.pingOidcConfig,
        ...defaultOidcConfig
      });
    } else {
      setOidcConfig({
        ...OIDC_CONFIG.cognitoOidcConfig,
        ...defaultOidcConfig
      });
    }
  }, []);

  return (
    oidcConfig &&
    <SovosProvider>
      <TirThemeProvider>
        <AuthProvider
          key={ oidcConfig.authority }
          { ...oidcConfig }
        >
          <Provider store={ store }>
            <Suspense>
              <RouterProvider router={ getBrowserRouter() } />
            </Suspense>
          </Provider>
        </AuthProvider>
      </TirThemeProvider>
    </SovosProvider>
  );
};

export default AuthLayer;