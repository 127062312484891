import actionTypes from './JobControlConstants';

const initialStore = {
  settingJobState: false,
  jobStateResponse: {}
};

const JobControlReducer = (state = initialStore, action) => {
  switch (action.type) {

    case actionTypes.SETTING_JOB_STATE:
      return { ...state, settingJobState: true, jobStateResponse: {} };

    case actionTypes.SET_JOB_STATE_SUCCESS:
      return { ...state, settingJobState: false, jobStateResponse: action.payload };

    case actionTypes.SET_JOB_STATE_FAIL:
      return { ...state, settingJobState: false, jobStateResponse: action.payload };

    default:
      return state;
  }
};

export default JobControlReducer;
