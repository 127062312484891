import { lazy, Suspense, cloneElement } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './sovosTooltip.scss';
const ReactTooltip = lazy(() => import(/* webpackChunkName: "reactTooltip" */ 'react-tooltip'));

const SovosTooltip = props => {
  const { id, label, children } = props;

  const custom = children ? cloneElement(children, { ['data-tip']: true, ['data-for']: id }) : null; //eslint-disable-line no-useless-computed-key

  return (
    <>
      <Suspense>
        { custom }
        {
          props.show
            ? (
              <ReactTooltip
                id={ props.id }
                className={ classNames(props.className, { 'hide-arrow': props.hideArrow }) }
                place={ props.place }
                effect={ props.effect }>
                <span>{ label }</span>
              </ReactTooltip>
            )
            : null
        }
      </Suspense>
    </>
  );
};

SovosTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  show: PropTypes.bool,
  place: PropTypes.oneOf(['bottom', 'top', 'right', 'left']),
  effect: PropTypes.oneOf(['float', 'solid']),
  className: PropTypes.string,
  hideArrow: PropTypes.bool,
  children: PropTypes.element
};

SovosTooltip.defaultProps = {
  label: '',
  show: true,
  place: 'bottom',
  effect: 'float',
  className: '',
  hideArrow: false,
  children: null
};

export default SovosTooltip;
