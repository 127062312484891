import actionTypes from './AppSettingsConstants';

const initialStore = {
  updatingTableOptions: false,
  openEula: false
};

export default function appSettingsReducer(state = initialStore, action) {
  switch (action.type) {
    case actionTypes.UPDATE_TABLE_OPTIONS_PENDING:
      return { ...state, updatingTableOptions: true };

    case actionTypes.UPDATE_TABLE_OPTIONS_SUCCESS:
      return { ...state, updatingTableOptions: false };

    case actionTypes.RESET_EULA_SETTING:
      return { ...state, openEula: false };

    default:
      return { ...state };
  }
}
