import actionTypes from './ControlTotalConstants';
import States from 'redux/transmittal/transmittalCard/States';

const initialStore = {
  controlTotals: {},
  fetchingControlTotals: {},
  showMessageEmptyControlTotals: false
};

let controlTotalsEmpty = {};

export default function ControlTotalReducer(state = initialStore, action) {
  switch (action.type) {
    case actionTypes.CLEAR_CONTROL_TOTALS:
      controlTotalsEmpty = {};
      return { ...state, controlTotals: {}, showMessageEmptyControlTotals: false };

    case actionTypes.FETCHING_CONTROLTOTALS: {
      controlTotalsEmpty = {
        ...controlTotalsEmpty,
        [action.fChar]: false
      };

      return {
        ...state,
        fetchingControlTotals: {
          ...state.fetchingControlTotals,
          [action.fChar]: true
        }
      };
    }

    case actionTypes.FETCH_CONTROLTOTALS_SUCCESS: {
      const controlTotals = action.panelState === States.READY_TO_TRANSMIT_STATE
        ? {
          ...state.controlTotals,
          [action.fChar]: {
            rows: action.controlTotals.ControlTotals,
            formCount: action.controlTotals.FormCount
          }
        }
        : {
          ...state.controlTotals,
          [action.fChar]: {
            ...action.controlTotals,
            formCount: action.controlTotals.Payers.reduce((accumulator, currentValue) => accumulator + currentValue.Payees, 0)
          }
        };

      controlTotalsEmpty = action.panelState === States.READY_TO_TRANSMIT_STATE
        ? {
          ...controlTotalsEmpty,
          [action.fChar]: Boolean(!action.controlTotals.ControlTotals.length)
        }
        : {
          ...controlTotalsEmpty,
          [action.fChar]: Boolean(!action.controlTotals.Payers.length)
        };

      const fetchingControlTotals = {
        ...state.fetchingControlTotals,
        [action.fChar]: false
      };

      return {
        ...state,
        controlTotals,
        fetchingControlTotals,
        showMessageEmptyControlTotals:
          Object.keys(controlTotalsEmpty).every(key => controlTotalsEmpty[key] === true)
          && Object.keys(fetchingControlTotals).every(key => fetchingControlTotals[key] === false)
      };
    }
    case actionTypes.FETCH_CONTROLTOTALS_FAILURE: {
      return {
        ...state,
        fetchingControlTotals: { ...state.fetchingControlTotals, [action.fChar]: false }
      };
    }
    case actionTypes.LOGOUT_SUCCESS:
      return { ...initialStore };
    default:
      return state;
  }
}
