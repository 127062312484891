// TODO: In the future this file will be moved to a library.
// Please do not modify, take consideration this file is duplicated in another project
import { useState, useEffect, useRef } from 'react';
import * as axiosInterceptor from './axios/axiosInterceptorsV1';
import { ERR_CANCELED } from './axios/axiosInterceptors';

const getNestedProp = (obj, pathString) => pathString.split('.').reduce((prev, curr) => (prev ? prev[curr] : null), obj);

const useFetchWithAbort = ({ url = undefined, onError, fetchOnMount = true, customDataPath = 'data.data', method = 'get', body = undefined }) => {
  const [data, setData] = useState();
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const controller = useRef(new AbortController());

  const fetchData = async dynamicUrl => {
    if (url !== undefined && dynamicUrl !== undefined) {
      throw new Error('Please provide a url');
    }

    const config = {
      signal: controller.current.signal
    };
    try {
      !loading && setLoading(true);
      const response = await axiosInterceptor[method](dynamicUrl || url, body || config, body ? config : undefined);
      setData(getNestedProp(response, customDataPath));
      setTotalRecords(response?.data?.Total || 0);
      setLoading(false);
    } catch (error) {
      if (error.code === ERR_CANCELED) {
        return;
      }
      setLoading(false);
      onError(error);
    }
  };

  useEffect(() => {
    fetchOnMount && fetchData();

    return () => {
      controller.current.abort();
    };
  }, []);

  return {
    data,
    loading,
    fetchData,
    signal: controller.current.signal,
    totalRecords
  };
};

export default useFetchWithAbort;
