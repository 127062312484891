import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SovosDynamicForm from './SovosDynamicForm';
import { fetchMetadata } from './redux/FormMetaInformationActions';
import { fetchProvinceCountryCode } from '../sovos-dynamic-form/redux/SelectFieldOptionsActions';

const mapStateToProps = (state, ownProps) => ({
  form: state.dynamicForm.isClone ? state.dynamicForm.cloneForm : state.dynamicForm.form[ownProps.taxEntityCode] || {},
  formMetadata: state.formMetaInformation.formsMetadata || {},
  fetchingMetadata: state.formMetaInformation.fetchingMetadata,
  fetchingForm: state.dynamicForm.fetchingForm,
  savingForm: state.dynamicForm.savingForm,
  isClone: state.dynamicForm.isClone,
  fetchingSelects: state.selectFieldOptions.fetchingSelects,
  selects: state.selectFieldOptions.selects
});

const mapDispatchToProps = dispatch => ({
  metadataActions: bindActionCreators({ fetchMetadata }, dispatch),
  selectFieldOptionsActions: bindActionCreators({ fetchProvinceCountryCode }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SovosDynamicForm);
