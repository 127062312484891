import PropTypes from 'prop-types';
import SovosButton from 'mosaic-react/sovos-button';

const ConfirmationDialogButtons = ({ disabledActionLabel, cancelLabel, onCancelClick, actionLabel, onOKClick, okButtonProps, cancelButtonProps }) => (
  disabledActionLabel
    ? <SovosButton variant='text' onClick={ onCancelClick } id="cancel-button">{ cancelLabel }</SovosButton>
    : (
      <>
        <SovosButton
          style={ { marginRight: 10 } }
          variant="text"
          onClick={ onCancelClick }
          id="cancel-button"
          { ...cancelButtonProps }
        >
          { cancelLabel }
        </SovosButton>
        <SovosButton
          onClick={ onOKClick }
          id="action-button"
          { ...okButtonProps }
        >
          { actionLabel }
        </SovosButton>
      </>
    )
);

ConfirmationDialogButtons.defaultProps = {
  actionLabel: 'Ok',
  cancelLabel: 'Cancel',
  disabledActionLabel: false,
  okButtonProps: {},
  cancelButtonProps: {}
};

ConfirmationDialogButtons.propTypes = {
  onOKClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  actionLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  disabledActionLabel: PropTypes.bool,
  okButtonProps: PropTypes.object,
  cancelButtonProps: PropTypes.object
};

export default ConfirmationDialogButtons;