import { useState, useEffect } from 'react';
import { get } from 'helpers/axios/axiosInterceptorsV1';
import { getErrorMessage } from 'helpers/errorMessageHelper';
import { buildUrlsParams } from 'helpers/buildUrlParams';
import { identityManagementCards } from 'helpers/identityManagementHelper';

const useDataOverview = (shouldFetchAgain, showErrorSnackbar, shouldFetchOnMount = true) => {
  const [fetchingOverview, setFetchingOverview] = useState(false);
  const [fetchingSortedOverview, setFetchingSortedOverview] = useState(false);
  const [overview, setOverview] = useState({
    totals: {
      Errors: 0,
      resolved: 0,
      total: 0,
      [identityManagementCards.MismatchesCount.type]: 0,
      [identityManagementCards.OfacRankCount.type]: 0,
      [identityManagementCards.DmfCount.type]: 0,
      NonPrinted: 0,
      NonTransmitted: 0,
      TotalForms: 0
    },
    summary: []
  });

  const getSortQueryParam = (sortColumn, isAscending) => sortColumn ? `${ isAscending ? '+' : '-' }${ sortColumn }` : '';

  const fetchRecordsSummary = async () => {
    setFetchingOverview(true);
    try {
      const response = await get('tax-entities/overviews');
      setOverview({
        summary: response.data.TaxRecordOverview.TaxRecords,
        totals: {
          ...response.data.IdentityManagement,
          NonPrinted: response.data.TaxDetailOverview.FormsReadyToPrint,
          NonTransmitted: response.data.TaxDetailOverview.FormsReadyToTransmit,
          TotalForms: response.data.TaxDetailOverview.TotalForms,
          Errors: response.data.TaxRecordOverview.TotalErrors
        }
      });
    } catch (error) {
      showErrorSnackbar({ message: getErrorMessage(error) });
    } finally {
      setFetchingOverview(false);
    }
  };

  const fetchRecordsSummarySorted = async (sortColumn, isAscending) => {
    const qsParsed = buildUrlsParams(Object.assign({ sortBy: getSortQueryParam(sortColumn, isAscending) }));
    const path = `tax-entities/overviews?${qsParsed}`;

    setFetchingSortedOverview(true);
    try {
      const response = await get(path);
      setOverview({
        summary: response.data.TaxRecordOverview.TaxRecords,
        totals: {
          ...response.data.IdentityManagement,
          NonPrinted: response.data.TaxDetailOverview.FormsReadyToPrint,
          NonTransmitted: response.data.TaxDetailOverview.FormsReadyToTransmit,
          TotalForms: response.data.TaxDetailOverview.TotalForms,
          Errors: response.data.TaxRecordOverview.TotalErrors
        }
      });
    } catch (error) {
      showErrorSnackbar({ message: getErrorMessage(error) });
    } finally {
      setFetchingSortedOverview(false);
    }
  };

  useEffect(() => {
    if (shouldFetchOnMount) {
      fetchRecordsSummary();
    }
  }, []);

  useEffect(() => {
    if (shouldFetchAgain) {
      fetchRecordsSummary();
    }
  }, [shouldFetchAgain]);

  return {
    fetchRecordsSummary,
    overview,
    fetchingOverview,
    fetchingSortedOverview,
    fetchRecordsSummarySorted
  };
};

export default useDataOverview;