import { Component } from 'react';
import PropTypes from 'prop-types';
import theme from 'mosaic-react/themes/sovos-theme';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import classNames from 'classnames';
import SovosTooltip from '../../sovos-tooltip/SovosTooltip';
import './selectField.scss';

const { palette } = theme;

const defaultSelectStyle = {
  width: 'auto',
  height: '100%',
  borderLeft: `1px solid ${palette.divider}`,
  fontSize: '13px'
};

class SelectFieldForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.validateValueAndModLevel(props)
        ? props.value
        : "",
      showError: !this.validateValueAndModLevel(props)
    };
  }

  componentDidUpdate = prevProps => {
    if (this.props.value !== prevProps.value) {
      this.validateValueAndModLevel(this.props) && this.setState({ value: this.props.value });
    }
  };

  validateValue = props => (props.value || props.value === 0)
    ? props.options.some(option => option.value == props.value) //eslint-disable-line
    : true;

  validateValueAndModLevel = props => !(props.value === "" && this.props.modLevel >= 0 && this.props.validation?.required) && this.validateValue(props);

  handleOnChange = event => {
    const value = event.target.value;
    this.props.onChange(event, value, this.props.onBlur);
    this.setState({ value, showError: false });
  };

  handleOnInvalid = event => {
    event.preventDefault();
    this.setState({ showError: true });
  };

  generateOptionMapText = option => {
    if (option.text === "") {
      return '(blank)';
    }
    if (!this.props.customRender) {
      return option.text;
    }
    const val = this.props.customRender === 'pad0' ? String(option.value).padStart(2, '0') : option.value;
    return `${val} - ${option.text}`;
  };

  getBoxNameLabel = className => {
    const { name, boxName } = this.props;
    return (
      boxName &&
      <label
        className={ classNames("select-field__label-box", className) }
        htmlFor={ name }
      >
        { boxName }
      </label>
    );
  };

  render() {
    const {
      label,
      labelValidation,
      options,
      wrapperStyle,
      selectStyle,
      name,
      validation,
      disabledByEntityFetcher,
      readOnly,
      readOnlyUser,
      classes,
      customRender
    } = this.props;

    return (
      <div
        style={ wrapperStyle }
        className={ classNames("select-field-form__wrapper", classes.selectFieldFormWrapper) }
      >
        {
          <div className="label__container">
            <div className="label__container-left">
              { this.getBoxNameLabel('label__left-box-name') }
              <div>
                { label &&
                <label className={ classNames("select-field__label", classes.selectFieldLabel) }>
                  { label }
                </label> }
              </div>
            </div>
            { this.getBoxNameLabel() }
          </div>
        }
        <SovosTooltip
          id={ name }
          label={ this.validateValueAndModLevel(this.props) ? `Value "${this.props.value}" is invalid` : `${labelValidation} is required.` }
          show={ this.state.showError }
          place="right"
          className="tir-texfield__error"
          effect="solid"
        >
          <Select
            error={ this.state.showError }
            required={ validation && validation.required }
            autoWidth={ true }
            className={ classNames(
              'select-field-form__mui-select-field',
              { 'select-field-form__disabled': readOnly || disabledByEntityFetcher.includes(name) },
              classes.selectFieldForm
            ) }
            style={ { ...defaultSelectStyle, ...selectStyle, flexBasis: this.props.inputWidth || 120 } }
            value={ this.state.value }
            onChange={ this.handleOnChange }
            disabled={ readOnlyUser || readOnly || disabledByEntityFetcher.includes(name) }
            onInvalid={ this.handleOnInvalid }
            inputProps={ { 'data-for': name } }
            variant='outlined'
          >
            {
              (validation && validation.required)
                ? !options.find(option => option.value === 'null' || option.value === '') && <MenuItem value="" disabled>Select...</MenuItem>
                : !options.find(option => option.value === 'null' || option.value === '') && <MenuItem value="">(blank)</MenuItem>
            }
            { options.map(option => (
              <MenuItem
                value={ option.value === 'null' ? '' : customRender === "int" ? parseInt(option.value) : option.value }
                key={ option.value }
              >
                { this.generateOptionMapText(option) }
              </MenuItem>
            )) }
          </Select>
        </SovosTooltip>
      </div>
    );
  }
}

SelectFieldForm.propTypes = {
  label: PropTypes.node,
  labelValidation: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ])
    })
  ).isRequired,
  wrapperStyle: PropTypes.object,
  selectStyle: PropTypes.object,
  name: PropTypes.string,
  boxName: PropTypes.string,
  validation: PropTypes.shape({
    pattern: PropTypes.string,
    message: PropTypes.string,
    validateOn: PropTypes.string,
    required: PropTypes.bool,
    maxLength: PropTypes.number
  }),
  disabledByEntityFetcher: PropTypes.array,
  readOnly: PropTypes.bool,
  readOnlyUser: PropTypes.bool.isRequired,
  classes: PropTypes.objectOf(PropTypes.string),
  customRender: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number
  ])
};

SelectFieldForm.defaultProps = {
  errorText: '',
  value: '',
  onBlur: () => {},
  wrapperStyle: {},
  classes: {}
};

export default SelectFieldForm;
