import actionsTypes from "./WsConstants";

const initialSore = {
  msg: {}
};

export default function WsReducer(state = initialSore, action) {
  switch (action.type) {
    case actionsTypes.WS_UPDATE:
      return {
        ...state,
        msg: typeof(action.msg) === 'object' ? action.msg : {}
      };

    case actionsTypes.RESET_WS_STORE:
      return { ...initialSore };

    case actionsTypes.LOGOUT_SUCCESS:
      return { ...initialSore };

    default:
      return { ...state };
  }
}
