import sharedActions from 'redux/constants/SharedConstants';

const actionTypes = {
  ...sharedActions,
  CLEAR_CONTROL_TOTALS: 'CLEAR_CONTROL_TOTALS',
  FETCHING_CONTROLTOTALS: 'FETCHING_CONTROLTOTALS',
  FETCH_CONTROLTOTALS_SUCCESS: 'FETCH_CONTROLTOTALS_SUCCESS',
  FETCH_CONTROLTOTALS_FAILURE: 'FETCH_CONTROLTOTALS_FAILURE'
};

export default actionTypes;
