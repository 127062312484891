import filingStatusConstants from 'helpers/filingStatusConstants';

export const semiWeeklyFederalTaxLiabilityFields = [
  'JanuarySemiWeeklyFederalTaxLiability',
  'FebruarySemiWeeklyFederalTaxLiability',
  'MarchSemiWeeklyFederalTaxLiability',
  'AprilSemiWeeklyFederalTaxLiability',
  'MaySemiWeeklyFederalTaxLiability',
  'JuneSemiWeeklyFederalTaxLiability',
  'JulySemiWeeklyFederalTaxLiability',
  'AugustSemiWeeklyFederalTaxLiability',
  'SeptemberSemiWeeklyFederalTaxLiability',
  'OctoberSemiWeeklyFederalTaxLiability',
  'NovemberSemiWeeklyFederalTaxLiability',
  'DecemberSemiWeeklyFederalTaxLiability'
];

export const totalFederalTaxLiabilityField = 'TotalFederalTaxLiability';
export const monthlyScheduleDepositorFlagField = 'MonthlyScheduleDepositorFlag';
export const semiweeklyScheduleDepositorFlagField = 'SemiweeklyScheduleDepositorFlag';
export const payerIdField = 'PayerId';

export const prepare945FormForSaving = form => {
  const Form945X = {
    AdjustedReturn: form.AdjustedReturn,
    Claim: form.Claim,
    UnderAndOverReported: form.UnderAndOverReported,
    Explanation: form.Explanation
  };

  var formToSave = { ...form, ...form.ReturnStatus === filingStatusConstants.CORRECTION && { Form945X } };

  delete formToSave.PAYERID;
  formToSave[payerIdField] = formToSave.PAYERRECNO;
  delete formToSave.PAYERRECNO;
  delete formToSave.RecordId;
  delete formToSave.NoneDepositorFlag;
  delete formToSave.AdjustedReturn;
  delete formToSave.Claim;
  delete formToSave.UnderAndOverReported;
  delete formToSave.Explanation;
  delete formToSave.Status;
  delete formToSave.ReturnStatus;
  delete formToSave.WFMarkAsCorrectionButton;

  semiWeeklyFederalTaxLiabilityFields.forEach(field => {
    formToSave[field] = { ...Object.keys(formToSave).filter(key => key.includes(`${field}_`)).reduce((acc, key) => Object.assign(acc, { [key.replace(`${field}_`, '')]: formToSave[key] }), {}) };
  });

  const propertyToRemove = Object.keys(formToSave).filter(key => semiWeeklyFederalTaxLiabilityFields.some(s => key.includes(`${s}_`)));

  propertyToRemove.forEach(property => {
    delete formToSave[property];
  });

  if (!Boolean(formToSave[monthlyScheduleDepositorFlagField]) && !Boolean(formToSave[semiweeklyScheduleDepositorFlagField])) {
    delete formToSave[totalFederalTaxLiabilityField];
  }

  return { Form: formToSave };
};

export const prepare1042FormForSaving = form => {
  const Form1042Correction = {
    ReasonableCauseStatement: form.ReasonableCauseStatement
  };

  var formToSave = { ...form, ...form.ReturnStatus === filingStatusConstants.CORRECTION && { Form1042Correction } };

  delete formToSave.PAYERID;
  formToSave[payerIdField] = formToSave.PAYERRECNO;
  delete formToSave.PAYERRECNO;
  delete formToSave.ReasonableCauseStatement;
  if (formToSave.PaymentByQddFlag !== 'X') {
    delete formToSave.Form1042ScheduleQs;
  } else {
    formToSave.Form1042ScheduleQs = formToSave.Form1042ScheduleQs?.map(({ id, ...scheduleQ }) => scheduleQ);
  }

  formToSave['NumberOfFormsFilledElectronically'] = Boolean(formToSave['NumberOfFormsFilledElectronically']) ? formToSave['NumberOfFormsFilledElectronically'] : '0';
  formToSave['NumberOfFormsFilledOnPaper'] = Boolean(formToSave['NumberOfFormsFilledOnPaper']) ? formToSave['NumberOfFormsFilledOnPaper'] : '0';

  delete formToSave.RecordId;

  return { Form: formToSave };
};