//TODO: Please remove this file after clients and sso clients migration is done... this only exists because of sso clients
//needs to login using old s1 login
import { connect } from 'react-redux';
import AuthRoute from './AuthRoute';

const mapStateToProps = state =>({
  userLogged: state.authentication.logged
});

export default connect(mapStateToProps)(AuthRoute);
