import actionTypes from './JobConfigConstants';
import FederalLimit from 'helpers/federalLimit';
import { getFcharByName } from 'helpers/formConstants';
import printStates from '../../printPage/printCard/States';
import transmittalStates from '../../transmittal/transmittalCard/States';
import JobSteps from '../JobSteps';
import jobTypes from '../jobTypes';
import { CORRECTION } from 'helpers/trackerHelper';
import {
  getForms,
  getMaskedForms,
  getSelectedForms,
  getSelectedPrintNotes,
  getSelectedPayers,
  getReprintOption
} from './JobConfigHelper';
import { allRecords } from 'helpers/formConstants';
import { transmitJobState } from 'helpers/enums';
import reprintFilterOption from 'pages/printCorp/helpers/reprintFilterOption';

const initialStore = {
  isSliderOpen: false,
  isReviewButtonEnabled: false,
  config: {
    formsSelected: [],
    payersSelected: [],
    formsMasked: [],
    isPaPaperSelected: false,
    areFormsUFLIncluded: false,
    areFormsWithErrorIncluded: false,
    isSovosPrintServiceSelected: true,
    isFedStateCombineSelected: false,
    isTestTransmittalSelected: false,
    printNotesSelected: {},
    jobId: 0,
    reprintOption: reprintFilterOption.IGNORE,
    transmittalId: 0
  },
  createJobSuccess: false,
  fetchingJobById: false,
  jobType: '',
  jobStep: JobSteps.NOT_SET,
  jobStatus: 'Non Started',
  panelState: printStates.NOT_SET,
  transmittalId: 0,
  controlTotalsAreRefreshable: false,
  filingStatus: '',
  error: {}
};

export default function JobConfigReducer(state = initialStore, action) {

  const setConfigValue = (field, value) => ({
    ...state,
    config: {
      ...state.config,
      [field]: value
    }
  });
  let jobStepValue;

  switch (action.type) {

    /* Data Configuration on options selection */
    case actionTypes.OPEN_PT_SLIDING_PANEL:
      if (action.panelState === printStates.READY_TO_PRINT || action.panelState === transmittalStates.READY_TO_TRANSMIT_FEDERAL ||
        action.panelState === transmittalStates.READY_TO_TRANSMIT_STATE){
        jobStepValue = JobSteps.SETUP;
      } else {
        jobStepValue = JobSteps.REVIEW;
      }
      return {
        ...state,
        isSliderOpen: true,
        panelState: action.panelState,
        jobStep: jobStepValue,
        jobType: action.jobType,
        filingStatus: action.filingStatus,
        config: {
          ...state.config,
          areFormsUFLIncluded: action.panelState === printStates.READY_TO_PRINT ? action.filingStatus === CORRECTION : state.config.areFormsUFLIncluded
        }
      };

    case actionTypes.PTJOB_START_PT_PROCESS:
      if (action.panelState === printStates.READY_TO_PRINT || action.panelState === transmittalStates.READY_TO_TRANSMIT_FEDERAL ||
        action.panelState === transmittalStates.READY_TO_TRANSMIT_STATE){
        jobStepValue = JobSteps.SETUP;
      } else {
        jobStepValue = JobSteps.REVIEW;
      }
      return {
        ...state,
        panelState: action.panelState,
        jobStep: jobStepValue,
        jobType: action.jobType,
        filingStatus: action.filingStatus,
        config: {
          ...state.config,
          areFormsUFLIncluded: action.panelState === printStates.READY_TO_PRINT ? action.filingStatus === CORRECTION : state.config.areFormsUFLIncluded
        }
      };

    case actionTypes.CLOSE_PT_SLIDING_PANEL:
      return { ...initialStore };

    case actionTypes.SET_FORM_SELECTED: {
      const printNotes = { ...state.config.printNotesSelected };

      const currentSelectedForms = state.config.formsSelected.filter(form => form.include).map(form => form.fChar);
      const incomingForms = action.forms.filter(form => form.include).map(form => form.fChar);
      const fCharToAdd = incomingForms.filter(form => !currentSelectedForms.includes(form))[0];

      //Handle TIN Masking selection
      let formsMasked;
      if (fCharToAdd) {
        formsMasked = state.config.formsMasked.map(formMasked => {
          if (formMasked.fChar === fCharToAdd) {
            return {
              ...formMasked,
              include: true
            };
          }

          return formMasked;
        });
      } else {
        formsMasked = state.config.formsMasked.map(formMasked => {
          if (!incomingForms.includes(formMasked.fChar)) {
            return { ...formMasked, include: false };
          }

          return formMasked;
        });
      }

      //Handle Print Notes selection
      Object.keys(printNotes).forEach(fChar => {
        if (!incomingForms.includes(fChar)) {
          delete printNotes[fChar];
        }
      });

      if (state.jobType === jobTypes.TRANSMITTAL) {
        switch (action.name) {
          case allRecords().F.name:
            action.forms.forEach(form => {
              if (form.name !== allRecords().F.name) {
                form.include = false;
              }
            });
            break;
          case allRecords().X9N.name:
            action.forms.forEach(form => {
              if (form.name !== allRecords().X9N.name) {
                form.include = false;
              }
            });
            break;
          default:
            if (action.forms.length > 1) {
              action.forms.forEach(form => {
                if (form.name === allRecords().F.name || form.name === allRecords().X9N.name) {
                  form.include = false;
                }
              });
            }
        }
      }

      //Return new state
      return {
        ...state,
        config: {
          ...state.config,
          formsSelected: action.forms,
          printNotesSelected: printNotes,
          formsMasked
        },
        isReviewButtonEnabled: Boolean(
          action.forms.filter(form => form.include).length
          && state.config.payersSelected.filter(payer => payer.include).length
        )
      };
    }

    case actionTypes.SET_PAYERS_SELECTED:
      return {
        ...setConfigValue('payersSelected', action.payers),
        isReviewButtonEnabled: Boolean(
          action.payers.filter(payer => payer.include).length
          && state.config.formsSelected.filter(form => form.include).length
        )
      };

    case actionTypes.SET_FORMS_WITH_MASK:
      return setConfigValue('formsMasked', action.formsMasked);

    case actionTypes.TOGGLE_OPTION_SELECTION:
      return setConfigValue(action.option, !state.config[action.option]);

    case actionTypes.SET_OPTION_SELECTION:
      return setConfigValue(action.field, action.value);

    case actionTypes.SET_PRINT_NOTES: {
      const printNotes = { ...action.printNotesSelected };
      Object.keys(printNotes).forEach(fChar => {
        if (printNotes[fChar] === null) {
          delete printNotes[fChar];
        }
      });

      return setConfigValue('printNotesSelected', printNotes);
    }

    case actionTypes.SET_JOB_STATUS: {
      const config = { ...state.config, jobId: action.jobId, transmittalId: action.transmittalId };
      return { ...state, jobStatus: action.status, transmittalId: action.transmittalId, config };
    }

    /* Setup based on requests */

    case actionTypes.CREATING_PRINT_JOB:
      return { ...state, jobStep: JobSteps.REVIEW };

    case actionTypes.CREATE_PRINT_JOB_SUCCESS:
      return { ...state, createJobSuccess: true, ...setConfigValue('jobId', action.data.Id) };

    case actionTypes.CREATING_PT_JOB:
      return { ...state, jobStep: JobSteps.REVIEW };

    case actionTypes.CREATE_PT_JOB_SUCCESS:
      return { ...state, createJobSuccess: true };

    case actionTypes.FETCHING_JOB_BY_ID:
      return { ...state, fetchingJobById: true };

    case actionTypes.FETCH_JOB_BY_ID_SUCCESS: {
      const forms = getForms(action.data.Request.Forms, action.data.Response);

      return {
        ...state,
        config: {
          ...state.config,
          areFormsUFLIncluded: forms.some(form => form.FederalLimit === FederalLimit.UNDER),
          areFormsWithErrorIncluded: forms.some(form => form.IncludeErrors),
          isSovosPrintServiceSelected: forms.some(form => form.UsePrintServices),
          formsMasked: getMaskedForms(forms),
          formsSelected: getSelectedForms(forms, action.data.Response),
          printNotesSelected: getSelectedPrintNotes(forms),
          payersSelected: getSelectedPayers(forms, state),
          reprintOption: getReprintOption(forms),
          jobId: action.data.Id
        },
        fetchingJobById: false
      };
    }

    case actionTypes.SET_PRINT_RESULTS: {
      return {
        ...state,
        config: {
          ...state.config,
          formsSelected: getSelectedForms(state.config.formsSelected, action.results)
        }
      };
    }

    case actionTypes.SET_CONFIG_TRANSMIT: {
      const request = action.jobInfo.detail;
      const response = action.jobInfo.forms;

      let payersSelected = [];
      request.Payers.forEach(payer => {
        const payerSel = response.flatMap(form => form.Payers).find(payerSel => payerSel.Ptin === payer);
        if (payerSel) {
          payersSelected.push({ ...payerSel, id: payerSel.PayerId, name: payerSel.PayerName, include: true });
        }
      });

      const formsSelected = request.FormsToRun.map(value => ({
        fChar: getFcharByName(value),
        include: true,
        name: value
      }));

      return {
        ...state,
        jobStatus: transmitJobState.COMPLETED,
        transmittalId: request.TransmittalId,
        config: {
          ...state.config,
          payersSelected,
          formsSelected,
          areFormsUFLIncluded: request.FederalLimit === 'Ignore',
          areFormsWithErrorIncluded: request.FormsWithErrors === 'true' || request.FormsWithErrors === true,
          isFedStateCombineSelected: request.CombFedState === 'true' || request.CombFedState === true,
          jobId: action.jobInfo.jobId,
          transmittalId: request.TransmittalId
        }
      };
    }

    case actionTypes.FETCH_JOB_BY_ID_FAILURE:
      return { ...state, fetchingJobById: false, error: action.error };

    case actionTypes.CANCEL_PT_JOB_SUCCESS:
      return { ...state, jobStatus: initialStore.jobStatus };

    case actionTypes.CANCEL_PT_JOB_FAILURE:
      return { ...state, jobStatus: initialStore.jobStatus };

    case actionTypes.TRANSMIT_TO_IRS_FAILURE:
      return { ...state, isSliderOpen: false };

    case actionTypes.TRANSMIT_TO_IRS_SUCCESS:
      return { ...state, isSliderOpen: false };

    case actionTypes.SET_CONTROLTOTALS_REFRESHABLE:
      return { ...state, controlTotalsAreRefreshable: action.payload };

    case actionTypes.LOGOUT_SUCCESS:
      return { ...initialStore };

    case actionTypes.SET_REPRINT_OPTIONS:
      return { ...state, createJobSuccess: true, ...setConfigValue('reprintOption', action.reprintOption) };

    default:
      return { ...state };
  }
}
