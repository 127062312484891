import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CodedPayments from './CodedPayments';
import { setFieldValue } from '../../../sovos-dynamic-form/redux/DynamicFormActions';

const mapStateToProps = (state, ownProps) => ({
  codedPayments: (state.dynamicForm.isClone ? state.dynamicForm.cloneForm : state.dynamicForm.form[ownProps.taxEntityCode])?.CODEDPAYMENTS || [],
  previousValue: state.dynamicForm.previousValueForm['CODEDPAYMENTS'] || [],
  isClone: state.dynamicForm.isClone
});

const mapDispatchToProps = dispatch => ({
  formActions: bindActionCreators({ setFieldValue }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CodedPayments);
