import authenticationHelper from 'helpers/authenticationHelper';
import { TIR_R_RECORD_PAYER } from 'helpers/permissionConstants';
import { get } from 'helpers/axios/axiosInterceptorsV1';
import useFetchWithAbort from './useFetchWithAbort';

const useContextHasPayers = () => {
  const { signal } = useFetchWithAbort({ fetchOnMount: false });

  const shouldRedirect = async () => {
    if (authenticationHelper.checkAllUserPermissions([TIR_R_RECORD_PAYER]) && authenticationHelper.isTIRCorporate()) {
      try {
        const response = await get('/payers/context-has-payers', { signal });
        return !response.data;
      } catch (error) {
        throw error;
      }
    }
    return false;
  };

  return { shouldRedirect };
};

export default useContextHasPayers;
