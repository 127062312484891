import { get } from './axios/axiosInterceptorsV1';

export const getUserContexts = contexts => {
  let userContexts = [];
  const findContext = childContext => {
    if (childContext.nestedAccounts) {
      childContext.nestedAccounts.forEach(context => {
        findContext(context);
      });
    } else {
      const customAttributes = JSON.parse(childContext.customAttributes);
      userContexts.push({
        ...childContext,
        name: customAttributes.contextType.toUpperCase(),
        taxYear: customAttributes.taxYear,
        customAttributes
      });
    }
  };

  findContext(contexts[0]);
  return userContexts;
};

export const getOrgs = async orgId => {
  let path = '/authorization/user/structure';
  if (orgId) {
    path = `${path}?orgId=${orgId}`;
  }
  const orgsResponse = await get(path);
  orgsResponse.data[0].nestedAccounts.forEach(context => {
    context.nestedAccounts.sort((a, b) => {
      const aCustomAttributes = JSON.parse(a.customAttributes);
      const bCustomAttributes = JSON.parse(b.customAttributes);
      return aCustomAttributes.taxYear > bCustomAttributes.taxYear ? 1 : -1 ;
    });
  });
  return orgsResponse.data;
};