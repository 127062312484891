import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MuiTextField from '@mui/material/TextField';
import TextMaskCustom from './TextMaskCustom';
import SovosTooltip from '../../sovos-tooltip/SovosTooltip';
import { determineMask, cleanMask } from './helper/maskHelper';
import isANonTaxFieldEditable from './helper/nonTaxFieldHelper';
import { getLabel } from '../../dataEntryForms/sovos-dynamic-form/helper/labelHelper';
import Validation from './helper/Validation';

class TextFieldPlaceholder extends Component {
  constructor(props) {
    super(props);
    this.objRef = {};
    this.isMaxLengthInvalid = false;
    this.validation = new Validation(
      this.props.name,
      this.props.labelValidation,
      this.props.validation,
      this.props.mask,
      this.handleOnValidation
    );
  }

  state = {
    validationErrorMessage: ''
  };

  componentDidMount = () => {
    this.validation.addValidation();
    this.appyUppercase(this.props.value);
    if (this.props.modLevel) {
      const { name, value } = this.props;
      this.validation.validateComponent({ target: { name, value } }, null, this.isMaxLengthInvalid);
    }
  };

  UNSAFE_componentWillReceiveProps = prevProps => {
    if (!prevProps.reset && this.props.reset) {
      this.validation.validateComponent(null, this.props.reset, this.isMaxLengthInvalid, (valid, message) => {
        this.setState({ validationErrorMessage: message }, () => this.props.onValid(valid));
      });
    }
  };

  appyUppercase = value => this.getInputRef().style.textTransform = value.length ? 'uppercase' : 'none';

  getInputRef = () => {
    if (this.objRef && this.objRef.input) {
      if (this.props.multiLine) {
        return this.objRef.input.refs.input;
      }
      return this.props.mask ? this.objRef.input.inputElement : this.objRef.input;
    }
    return document.getElementById(this.props.name);
  };

  handleOnValidation = (valid, message) => {
    this.setState({ validationErrorMessage: message }, () => this.props.onValid(valid));
  };

  handleOnInvalid = evt => {
    this.validation.validateComponent(evt, null, this.isMaxLengthInvalid, (valid, message) => {
      this.setState({ validationErrorMessage: message }, () => this.props.onValid(valid));
    });
    evt.preventDefault();
  };

  handleOnChange = evt => {
    const value = cleanMask(evt.target.value, this.props.mask);
    this.appyUppercase(value);
    if (this.props.validation && this.props.validation.maxLength) {
      this.isMaxLengthInvalid = value.length > this.props.validation.maxLength;
      this.getInputRef().setCustomValidity(this.isMaxLengthInvalid || '');
    }

    if (!this.isMaxLengthInvalid){
      this.props.onChange(evt, value);
    }
  };

  render = () => {
    const {
      handleOnChange,
      handleOnInvalid
    } = this;
    const {
      validationErrorMessage
    } = this.state;
    const {
      name,
      multiLine,
      value,
      label,
      placeholder,
      mask,
      onBlur,
      onKeyPress,
      readOnly,
      readOnlyUser,
      validation
    } = this.props;

    const maskToApply = determineMask(mask);

    const showError = Boolean(validationErrorMessage);

    return (
      <div className="tir-texfield-placeholder__wrapper">
        <SovosTooltip
          id={ name }
          label={ validationErrorMessage }
          show={ showError }
          place="right"
          className="tir-texfield__error"
          effect="solid"
        >
          <MuiTextField
            id={ name }
            className={ classNames(
              { 'tirTexfield__input': !multiLine && !showError },
              { 'tirTexfield__textarea': multiLine && !showError },
              { 'tirTexfield__error': showError }
            ) }
            name={ name }
            label={ getLabel(validation?.required, label) || placeholder }
            InputLabelProps = { { shrink: true } }
            margin={ 'dense' }
            autoComplete='off'
            disabled={ readOnly || (readOnlyUser && !isANonTaxFieldEditable(name)) }
            value={ value }
            onChange={ handleOnChange }
            onBlur={ onBlur }
            onKeyPress={ onKeyPress }
            error={ showError }
            onInvalid={ handleOnInvalid }
            multiline={ multiLine }
            inputRef={ obj => { this.objRef = obj; } }
            InputProps={ {
              inputComponent: maskToApply.mask ? TextMaskCustom : 'input'
            } }
            inputProps={ maskToApply.mask && { //eslint-disable-line react/jsx-no-duplicate-props
              maskToApply
            } }
            variant='standard'
          />
        </SovosTooltip>
      </div>
    );
  };
}

TextFieldPlaceholder.propTypes = {
  label: PropTypes.node,
  labelValidation: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  mask: PropTypes.string,
  decimalLimit: PropTypes.number,
  validation: PropTypes.shape({
    pattern: PropTypes.string,
    message: PropTypes.string,
    validateOn: PropTypes.string,
    required: PropTypes.bool,
    maxLength: PropTypes.number
  }),
  multiLine: PropTypes.bool,
  multiLineConfig: PropTypes.shape({
    rows: PropTypes.number.isRequired,
    rowsMax: PropTypes.number.isRequired
  }),
  reset: PropTypes.bool,
  onValid: PropTypes.func,
  readOnly: PropTypes.bool,
  readOnlyUser: PropTypes.bool.isRequired
};

TextFieldPlaceholder.defaultProps = {
  value: '',
  mask: '',
  onBlur: null,
  onKeyDown: null,
  onKeyPress: null,
  decimalLimit: 2,
  validation: null,
  multiLine: false,
  multiLineConfig: {
    rows: 1,
    rowsMax: 4
  },
  reset: false,
  onValid: () => {}
};

export default TextFieldPlaceholder;
