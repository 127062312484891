import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ColumnSelector from './ColumnsSelector';
import { addOrMergeColumnSelectorOptions } from 'redux/appSettings/AppSettingsActions';
import { resetTableOptions } from 'shared/dataEntryForms/sovos-dynamic-form/redux/FormMetaInformationActions';

const mapStateToProps = state => ({
  updatingTableOptions: state.appSettings.updatingTableOptions
});

const mapDispatchToProps = dispatch => ({
  formMetaInformationActions: bindActionCreators({ resetTableOptions }, dispatch),
  appSettingsActions: bindActionCreators({ addOrMergeColumnSelectorOptions }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ColumnSelector);
