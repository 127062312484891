import sharedActions from '../constants/SharedConstants';

const actionTypes = {
  ...sharedActions,
  FETCHING_ORG: 'FETCHING_ORG',
  FETCH_ORG_SUCCESS: 'FETCH_ORG_SUCCESS',
  ASSIGN_CONTEXT_TREE: 'ASSIGN_CONTEXT_TREE',
  FETCHING_PERMISSIONS: 'FETCHING_PERMISSIONS',
  FETCH_PERMISSIONS_SUCCESS: 'FETCH_PERMISSIONS_SUCCESS',
  FETCH_PERMISSIONS_FAILURE: 'FETCH_PERMISSIONS_FAILURE',
  FETCHING_SOLUTION_CONFIGURATIONS: 'FETCHING_SOLUTION_CONFIGURATIONS',
  FETCH_SOLUTION_CONFIGURATIONS_SUCCESS: 'FETCH_SOLUTION_CONFIGURATIONS_SUCCESS',
  FETCH_SOLUTION_CONFIGURATIONS_FAILURE: 'FETCH_SOLUTION_CONFIGURATIONS_FAILURE',
  FETCHING_FORMS: 'FETCHING_FORMS',
  FETCH_FORMS_SUCCESS: 'FETCH_FORMS_SUCCESS',
  FETCH_FORMS_FAILURE: 'FETCH_FORMS_FAILURE'
};

export default actionTypes;
