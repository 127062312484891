import { Component } from 'react';
import PropTypes from 'prop-types';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import classNames from 'classnames';
import './radioButton.scss';

class RadioButtonFieldForm extends Component {
  state = {
    valueSelected: ""
  };

  componentDidMount = () => {
    this.setState({ valueSelected: this.getSelectedValue() });
    if (this.props.defaultValue && Object.keys(this.props.columnToUnset).every(field => !this.props.form[field])) {
      this.setFieldsValues(this.props.defaultValue);
    }
  };

  setFieldsValues = value => {
    this.setState({ valueSelected: value }, () => {
      this.props.formActions.setFieldValue(this.state.valueSelected, this.getCheckedValue(this.props.options.find(option => option.name.toUpperCase() === this.state.valueSelected.toUpperCase()).options), this.props.isClone, this.props.taxEntityCode);
      const columnToUnset = this.props.columnToUnset[this.state.valueSelected]
        .find(column => Boolean(this.props.form[column]));
      if (Boolean(columnToUnset)) {
        this.props.formActions.setFieldValue(columnToUnset, null, this.props.isClone, this.props.taxEntityCode);
      }
    });
  };

  handleOnChange = e => {
    this.setFieldsValues(e.target.value);
  };

  getCheckedValue = options => {
    for (const key in options) {
      if (options[key]) {
        return key;
      }
    }
    return '1';
  };

  getSelectedValue = () => {
    let value = (this.props.defaultValue && Object.keys(this.props.columnToUnset).every(field => !this.props.form[field])) ? this.props.defaultValue : '';
    Object.keys(this.props.columnToUnset).forEach(field => {
      if (this.props.form[field] === this.getCheckedValue(this.props.options.find(option => option.name.toUpperCase() === field.toUpperCase()).options)) {
        value = field;
      }
    });
    return value;
  };

  render = () => {
    const { label, boxName, options, readOnly, readOnlyUser, customClasses } = this.props;
    return (
      <div className="checkbox-placeholder-form__wrapper">
        <div className="label__container">
          <div className="label__container-left">
            <div>
              <label
                className="checkbox__label-box"
              >
                { boxName }
              </label>
            </div>
            <div>
              <label
                className="checkbox__label"
              >
                { label }
              </label>
            </div>
          </div>
        </div>
        <div className={ classNames("radio-button__container", customClasses.radioButtonContainer) }>
          <RadioGroup
            name="radioButton"
            className={ classNames("radio-button__group", customClasses.radioButtonGroup) }
            value={ this.state.valueSelected }
            onChange={ ev => this.handleOnChange(ev) }
          >
            { options.map(option => (
              <FormControlLabel
                key={ option.name }
                value={ option.dbColumn || option.entityField }
                label={ option.labelForm }
                className={ classNames("radio-button_size", { "radio-button_size--disabled": readOnlyUser || readOnly }) }
                control={ <Radio color="default" /> }
                style={ { width: 'auto', padding: '0 5px' } }
                disabled={ readOnlyUser || readOnly }
              />
            )) }
          </RadioGroup>
        </div>
      </div>
    );
  };
}

RadioButtonFieldForm.propTypes = {
  value: PropTypes.string,
  boxName: PropTypes.string,
  label: PropTypes.node,
  labelValidation: PropTypes.string,
  onChangeCallback: PropTypes.func,
  options: PropTypes.array,
  style: PropTypes.object,
  iconStyle: PropTypes.object,
  readOnly: PropTypes.bool,
  readOnlyUser: PropTypes.bool.isRequired,
  customClasses: PropTypes.objectOf(PropTypes.string),
  isClone: PropTypes.bool.isRequired,
  taxEntityCode: PropTypes.string.isRequired
};

RadioButtonFieldForm.defaultProps = {
  boxName: '',
  label: '',
  options: { 'true': true, 'false': false },
  value: '',
  onChangeCallback: () => {},
  style: {},
  iconStyle: {},
  labelStyle: {},
  customClasses: {}
};

export default RadioButtonFieldForm;
