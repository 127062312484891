import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setFieldValue } from '../../../sovos-dynamic-form/redux/DynamicFormActions';
import Form1042ScheduleQs from './Form1042ScheduleQs';

const mapStateToProps = (state, ownProps) => ({
  paymentByQddFlag: state.dynamicForm.form[ownProps.taxEntityCode]?.PaymentByQddFlag
});

const mapDispatchToProps = dispatch => ({
  formActions: bindActionCreators({ setFieldValue }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Form1042ScheduleQs);