import PropTypes from 'prop-types';
import CommentList from './commentList/CommentListConnector';
import NewComment from './newComment/NewCommentConnector';

const CommentsTab = ({ formType, formId }) => (
  <div>
    <CommentList formType={ formType } formId={ formId } />
    <NewComment formType={ formType } formId={ formId } />
  </div>
);

CommentsTab.propTypes = {
  formId: PropTypes.number.isRequired,
  formType: PropTypes.string.isRequired
};

export default CommentsTab;
