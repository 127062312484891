import actionTypes from './UserAdministrationConstants';

const initialState = {
  usersToShare: [],
  openEula: false,
  eulaSetting: {}
};

export default function ShareEmailsReducer(state = initialState, action) {
  switch (action.type) {

    case actionTypes.FETCH_USERS_TO_SHARE_SUCCESS:
      return { ...state, usersToShare: action.usersToShare };

    case actionTypes.CLEAN_USERS_TO_SHARE_SUCCESS:
      return { ...state, usersToShare: [] };

    case actionTypes.LOGOUT_SUCCESS:
      return { ...initialState };

    case actionTypes.FETCH_SETTINGEULA_SUCCESS:
      return { ...state, openEula: action.openEula };

    default:
      return state;
  }
}
