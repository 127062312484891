import { Component } from 'react';
import PropTypes from 'prop-types';
import TrieSearch from 'trie-search';
import Autosuggest from 'react-autosuggest';
import { areEquals } from 'helpers/arrayHelper';
import { hideAutocompleteForChrome } from 'helpers/formHelper';
import classNames from 'classnames';
import './Typeahead.scss';

class Typeahead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: props.initialValue,
      suggestions: [],
      trieSearch: this.createTrieSearchStructure(props.data, props.keys)
    };
  }

  componentDidMount = () => {
    hideAutocompleteForChrome('react-autosuggest__input');
  };

  componentDidUpdate(prevProps) {
    if (!areEquals(prevProps.data, this.props.data)) {
      this.onSuggestionsClearRequested();
      this.setState({ suggestions: this.props.data });
    }
  };

  createTrieSearchStructure = (metadata, keys) => {
    const ts = new TrieSearch(keys, { min: 3, indexField: 'index' });
    ts.addAll(metadata);
    return ts;
  };

  getSuggestionValue = suggestion => suggestion[this.props.keys[0]];

  onSearchValueChange = (_, { newValue }) => {
    this.setState({ searchValue: newValue || '' });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const suggestions = this.state.trieSearch.get(value).slice(0, 10);
    this.setState({ suggestions });
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  handleFetchRequested = result => {
    if (result.reason !== 'suggestion-selected') {
      return this.props.customFetchRequested
        ? this.props.customFetchRequested(result)
        : this.onSuggestionsFetchRequested(result);
    }

    return null;
  };

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  render() {
    const { handleFetchRequested, onSuggestionsClearRequested } = this;
    const { suggestions, searchValue } = this.state;
    const { placeholder, onSuggestionSelected, renderSuggestion, id, alwaysRenderSuggestions, renderSuggestionsContainer } = this.props;
    const getSuggestionValue = this.props.getSuggestionValue || this.getSuggestionValue;
    const inputProps = {
      placeholder,
      value: searchValue,
      onChange: this.onSearchValueChange,
      onKeyPress: this.handleKeyPress
    };
    const isSuggestionsEmpty = suggestions[0]?.Name === 'notFound';

    return (
      <div className={
        classNames(
          { 'typeahead__suggestions-disabled': isSuggestionsEmpty }
        )
      }>
        <Autosuggest
          id={ id }
          alwaysRenderSuggestions={ alwaysRenderSuggestions }
          suggestions={ suggestions }
          onSuggestionsFetchRequested={ handleFetchRequested }
          onSuggestionsClearRequested={ onSuggestionsClearRequested }
          getSuggestionValue={ getSuggestionValue }
          renderSuggestion={ renderSuggestion }
          renderSuggestionsContainer={ renderSuggestionsContainer }
          inputProps={ inputProps }
          onSuggestionSelected={ (_, { suggestion }) => onSuggestionSelected(suggestion) }
        />
      </div>
    );
  }
}

Typeahead.propTypes = {
  placeholder: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  keys: PropTypes.array.isRequired,
  onSuggestionSelected: PropTypes.func.isRequired,
  renderSuggestion: PropTypes.func.isRequired,
  customFetchRequested: PropTypes.func,
  getSuggestionValue: PropTypes.func,
  alwaysRenderSuggestions: PropTypes.bool,
  id: PropTypes.string,
  initialValue: PropTypes.string,
  renderSuggestionsContainer: PropTypes.func
};

Typeahead.defaultProps = {
  alwaysRenderSuggestions: false,
  customFetchRequested: null,
  getSuggestionValue: null,
  id: '',
  initialValue: '',
  renderSuggestionsContainer: undefined
};

export default Typeahead;
