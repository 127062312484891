import TextField from '@mui/material/TextField';
import './wrapperMuiTextField.scss';

const customClasses = {
  root: 'wrapper-mui-textfield__root',
  input: 'wrapper-mui-textfield__input'
};

const WrapperMuiTextField = ({ classes, ...rest }) => (
  <TextField
    className={ 'tirTextfieldForm__input' }
    classes={ { root: customClasses.root } }
    InputProps={ {
      classes: { input: customClasses.input },
      disableUnderline: true
    } }
    variant='filled'
    { ...rest }
  />
);

export default WrapperMuiTextField;
