import { get, patch, remove } from 'helpers/axios/axiosInterceptorsV1';
import { getLocalTimestampForFileName } from 'tir-frontend-common/helpers/dateHelper';
import actionsTypes from './FileManagerFilesConstants';
import {
  downloadFileByKey,
  bulkDownloadByKey,
  downloadFileByPath as downloadByPath,
  uploadFile as upload
} from 'helpers/fileTransferHelper';
import { buildUrlsParams } from 'helpers/buildUrlParams';

const fetchSuccess = filesInfo => ({ type: actionsTypes.FETCH_FILES_SUCCESS, filesInfo });

const fetchOverviewSuccess = overview => ({ type: actionsTypes.FETCH_OVERVIEW_SUCCESS, overview });

const getStorageOverview = () => async dispatch => {
  const path = 'file-manager';
  dispatch({ type: actionsTypes.FETCHING_OVERVIEW });

  try {
    const response = await get(path);
    dispatch(fetchOverviewSuccess(response.data.data));
  } catch (error) {
    dispatch({ type: actionsTypes.FETCH_OVERVIEW_FAILURE });
    throw (error);
  }
};

const resetPageSettings = () => dispatch => dispatch({ type: actionsTypes.RESET_PAGE_SETTINGS });

const fetchFiles = (pageSize, pageNum, folderKey, fileName, fileExtensionsAllowed, sortColumn, sortOrder) => async dispatch => {
  const qsParsed = buildUrlsParams(
    Object.assign(
      {
        q: fileName,
        pageOffset: pageNum,
        pageSize: pageSize,
        sortBy: sortOrder + sortColumn || null
      },
      fileExtensionsAllowed
        ? { fileExtensionsAllowed }
        : {}
    )
  );

  const path = folderKey
    ? `file-manager/folders/${folderKey}/files?${qsParsed}`
    : `file-manager/files?${qsParsed}`;

  dispatch({ type: actionsTypes.FETCHING_FILES });

  try {
    const response = await get(path);
    dispatch(fetchSuccess(response.data.data));
  } catch (error) {
    dispatch({ type: actionsTypes.FETCH_FILES_FAILURE, error: error.data.errMsg });
    throw (error);
  }
};

const renameFile = (file, newFileName) => async dispatch => {
  const path = `file-manager/files/${file.key}`;
  const data = {
    fileName: newFileName
  };
  dispatch({ type: actionsTypes.RENAMING_FILE });

  try {
    await patch(path, data);
    dispatch({ type: actionsTypes.RENAME_FILE_SUCCESS });
  } catch (error) {
    dispatch({ type: actionsTypes.RENAME_FILE_FAILURE, error: error.data.message });
    throw (error);
  }
};

const deleteFile = file => async dispatch => {
  const path = `file-manager/files/${file.key}`;
  dispatch({ type: actionsTypes.DELETING_FILE });

  try {
    await remove(path);
    dispatch({ type: actionsTypes.DELETE_FILE_SUCCESS });
  } catch (error) {
    dispatch({ type: actionsTypes.DELETE_FILE_FAILURE, error: error.data.message });
    throw (error);
  }
};

const moveFile = (idFile, idNewFolder) => async dispatch => {
  const path = `file-manager/files/${idFile}`;
  const data = {
    folderId: idNewFolder
  };
  dispatch({ type: actionsTypes.MOVING_FILE });

  try {
    await patch(path, data);
    dispatch({ type: actionsTypes.MOVE_FILE_SUCCESS });
  } catch (error) {
    dispatch({ type: actionsTypes.MOVE_FILE_FAILURE, error: error.data.errMsg });
    throw (error);
  }
};

const bulkFileDownload = files => (dispatch, getState) => {
  const fChar = 'fileManager';
  dispatch({ type: actionsTypes.DOWNLOADING_FILE, fChar });

  if (files.length === 1) {
    const [file] = files;
    downloadFileByKey(file.key, file.name, downloadFileSuccess, downloadFileFailed, fChar, dispatch);
  } else {
    const fileIds = files.map(file => file.key);
    const { client } = getState().contextReducer;
    const fileName = `${client.Name}_${getLocalTimestampForFileName()}.zip`;
    bulkDownloadByKey(fileIds, fileName, downloadFileSuccess, downloadFileFailed, fChar, dispatch);
  }
};

const downloadFileByPath = (file, fChar, resolve, reject) => dispatch => {
  dispatch({ type: actionsTypes.DOWNLOADING_FILE, fChar });
  downloadByPath(file.path, file.name, downloadFileSuccess, downloadFileFailed, fChar, dispatch, resolve, reject);
};

const downloadFileSuccess = fChar => dispatch => dispatch({ type: actionsTypes.DOWNLOAD_FILE_SUCCESS, fChar });

const downloadFileFailed = fChar => dispatch => dispatch({ type: actionsTypes.DOWNLOAD_FILE_FAIL, fChar });

const downloadingAll = () => dispatch => dispatch({ type: actionsTypes.DOWNLOADING_ALL });

const resetDownloadFileStatus = () => dispatch => dispatch({ type: actionsTypes.RESET_DOWNLOAD_FILE_STATUS });

const resetDownloadAllStatus = () => dispatch => dispatch({ type: actionsTypes.RESET_DOWNLOAD_ALL_STATUS });

const uploadFile = file => dispatch => {
  dispatch({ type: actionsTypes.UPLOADING_FILE });
  upload(file, dispatch);
};

const uploadedFileSuccess = () => dispatch => dispatch({ type: actionsTypes.UPLOADING_FILE_SUCCESS });

export {
  getStorageOverview,
  resetPageSettings,
  fetchFiles,
  renameFile,
  deleteFile,
  moveFile,
  bulkFileDownload,
  downloadFileByPath,
  downloadingAll,
  resetDownloadFileStatus,
  resetDownloadAllStatus,
  uploadFile,
  uploadedFileSuccess
};
