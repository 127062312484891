import sharedActions from 'redux/constants/SharedConstants';

const actionTypes = {
  ...sharedActions,
  FETCHING_RECORDS_BY_FORM_SUMMARY: 'FETCHING_RECORDS_BY_FORM_SUMMARY',
  FETCHING_RECORDS_BY_FORM_SUMMARY_SUCCESS: 'FETCHING_RECORDS_BY_FORM_SUMMARY_SUCCESS',
  FETCHING_RECORDS_BY_FORM_SUMMARY_FAILURE: 'FETCHING_RECORDS_BY_FORM_SUMMARY_FAILURE',
  FETCHING_RECORDS_BY_FORM: 'FETCHING_RECORDS_BY_FORM',
  FETCH_RECORDS_BY_FORM_SUCCESS: 'FETCH_RECORDS_BY_FORM_SUCCESS',
  FETCH_RECORDS_BY_FORM_FAILURE: 'FETCH_RECORDS_BY_FORM_FAILURE'
};

export default actionTypes;
