import types from './RecipientConstants';
import { get, post } from 'helpers/axios/axiosInterceptorsV1';
import { buildUrlsParams } from 'helpers/buildUrlParams';

const fetchRecipientSuccess = recipient => ({ type: types.FETCH_RECIPIENT_SUCCESS, recipient });
const fetchByRegexSuccess = recipients => ({ type: types.FETCH_RECIPIENT_BY_REGEX_SUCCESS, recipients });

const fetchRecipient = (modRecno, recipientType) => async dispatch => {
  const path = `/${recipientType}/${modRecno}`;
  dispatch({ type: types.FETCHING_RECIPIENT });

  try {
    const response = await get(path);
    dispatch(fetchRecipientSuccess(response.data.Entity));
  } catch (error) {
    dispatch({ type: types.FETCH_RECIPIENT_FAILURE });
    throw (error);
  }
};

const recipientExists = tin => async dispatch => {
  if (tin && tin.length === 9) {
    const path = `/payees/search?${buildUrlsParams({ pageOffset: 1, pageSize: 50, auto: true })}`;

    dispatch({ type: types.CHECKING_FOR_DUPLICATE_FIELD });

    try
    {
      const response = await post(path, { SearchText: tin });

      dispatch({
        type: types.CHECK_FOR_DUPLICATE_FIELD_SUCCESS,
        field: 'TIN',
        isDuplicate: response.data.Data.some(recipient => recipient.Tin === tin),
        recipients: response.data.Data
      });
    }

    catch (err) {
      dispatch({ type: types.CHECK_FOR_DUPLICATE_FIELD_FAILURE });
    }
  } else {
    dispatch({
      type: types.CHECK_FOR_DUPLICATE_FIELD_SUCCESS,
      field: 'TIN',
      isDuplicate: false
    });
  }
};

const fetchRecipientByRegex = (searchText, recipientType) => async dispatch => {
  const query = { pageOffset: 1, pageSize: 15, auto: true };
  const path = `/${recipientType}/search?${buildUrlsParams(query)}`;
  dispatch({ type: types.FETCHING_RECIPIENT });

  try {
    const response = await post(path, { SearchText: searchText });
    dispatch(fetchByRegexSuccess(response.data.PageSize ? response.data.Data : [{ Name: 'notFound' }]));
  } catch (error) {
    dispatch({ type: types.FETCH_RECIPIENT_BY_REGEX_FAILURE });
    throw (error);
  }
};

const setRecipient = recipient => dispatch => dispatch({ type: types.SET_RECIPIENT, recipient });

const setRecipientBoolean = () => dispatch => dispatch({ type: types.SET_RECIPIENT_BOOLEAN });

const clearRecipient = () => dispatch => dispatch({ type: types.RESET_RECIPIENT });

export {
  setRecipient,
  setRecipientBoolean,
  fetchRecipient,
  fetchRecipientByRegex,
  clearRecipient,
  recipientExists
};
