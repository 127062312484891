import actionsTypes from "./WsConstants";

const WsNotifierSingleton = () => {
  let wsNotifierInstance = null;

  class WsNotifier {
    constructor(store) {
      this.store = store;
    }

    static getInstance = store => {
      if (!wsNotifierInstance) {
        wsNotifierInstance = new WsNotifier(store);
      }

      return wsNotifierInstance;
    };

    emit = msg => {
      wsNotifierInstance.store.dispatch({ type: actionsTypes.WS_UPDATE, msg });
    };
  }

  return WsNotifier;
};

export default WsNotifierSingleton();