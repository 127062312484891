import PropTypes from 'prop-types';
import classNames from 'classnames';
import Typeahead from 'shared/typeahead/Typeahead';
import { Loader } from 'enhancers';
import CloseIcon from '@mui/icons-material/Close';

const EntityFetchTypeAhead = ({
  data,
  onSuggestionSelected,
  onFetchRequest, show,
  initialValue,
  loading,
  onClose,
  onBlur,
  disabled,
  entityLabel
}) => {
  const renderSuggestion = suggestion => {
    if (suggestion.Name === "notFound") {
      return (
        <div>
          <div className="suggestion-majorDetails">
            { `No matching ${ entityLabel } found` }
          </div>
        </div>
      );
    }

    switch (entityLabel) {
      case '1042-S recipient':
      case '1042-S Recipient':
        return (
          <div>
            <div className="suggestion-minorDetails">
              Account: { suggestion.AccountNo }
            </div>
            <div className="suggestion-majorDetails">
              { suggestion.Name }
            </div>
            <div className="suggestion-minorDetails">
              Tin: { suggestion.Tin }
            </div>
          </div>
        );
      case 'recipient':
      case 'Recipient':
        return (
          <div>
            <div className="suggestion-minorDetails">
              TIN: { suggestion.Tin }
            </div>
            <div className="suggestion-majorDetails">
              { suggestion.Name }
            </div>
            <div className="suggestion-minorDetails">
              Account: { suggestion.AccountNo }
            </div>
          </div>
        );
      case 'payer':
      case 'Payer':
        return (
          <div>
            <div className="suggestion-minorDetails">
              Payer ID: { suggestion.Ptin }
            </div>
            <div className="suggestion-majorDetails">
              { suggestion.Pname }
            </div>
            <div className="suggestion-minorDetails">
              Payer EIN: { suggestion.Pein }
            </div>
          </div>
        );
      default:
        return (<div />);
    }
  };

  const renderSuggestionsContainer = ({ containerProps, children }) => (
    <div { ...containerProps } className={ classNames(containerProps.className) }>
      <Loader
        isLoading={ loading }
      >
        {
          children
        }
      </Loader>
    </div>
  );

  return (
    <>
      {
        show && <div className="entity-fetcher__type-ahead-container">
          <Typeahead
            id={ entityLabel }
            placeholder={ `Search ${ entityLabel }, name or EIN` }
            data={ data }
            keys={ ['Name', 'Id', 'Ein'] }
            alwaysRenderSuggestions
            onSuggestionSelected={ onSuggestionSelected }
            renderSuggestion={ renderSuggestion }
            customFetchRequested={ onFetchRequest }
            initialValue={ initialValue }
            renderSuggestionsContainer={ renderSuggestionsContainer }
            onBlur={ onBlur }
            disabled={ disabled }
          />
          <CloseIcon onClick={ onClose } />
        </div>
      }
    </>
  );
};

EntityFetchTypeAhead.propTypes = {
  data: PropTypes.array.isRequired,
  onSuggestionSelected: PropTypes.func,
  onFetchRequest: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  initialValue: PropTypes.string,
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  entityLabel: PropTypes.oneOf(['Recipient', '1042-S recipient', 'Payer', 'recipient', 'payer']).isRequired
};

EntityFetchTypeAhead.defaultProps = {
  onSuggestionSelected: () => null,
  show: false,
  initialValue: '',
  loading: false
};

export default EntityFetchTypeAhead;
