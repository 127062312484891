import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MultipleState from './MultipleState';
import { setFieldValue, setSaveDisableByValidation } from '../../../sovos-dynamic-form/redux/DynamicFormActions';

const mapStateToProps = (state, ownProps) => ({
  fetchingForm: state.dynamicForm.fetchingForm,
  multipleState: (state.dynamicForm.isClone ? state.dynamicForm.cloneForm : state.dynamicForm.form[ownProps.taxEntityCode])?.PAYERSTATES || [],
  isClone: state.dynamicForm.isClone
});

const mapDispatchToProps = dispatch => ({
  formActions: bindActionCreators({ setFieldValue, setSaveDisableByValidation }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MultipleState);
