import { useEffect } from 'react';
import PropTypes from 'prop-types';
import ValidationGridTextField from 'shared/standardInput/textField/validationGridTextField/ValidationGridTextFieldInterface';

const PayerShortName = ({
  tinType,
  payerName,
  value,
  name,
  formActions,
  label,
  readOnlyUser,
  readOnly,
  helpText,
  isClone,
  taxEntityCode,
  dbColumn
}) => {

  useEffect(() => {
    const getShortName = () => {
      if (payerName) {
        const arrayOfNames = payerName.split(' ');
        switch (tinType) {
          //Case for EIN
          case '1': {
            let finalName;
            const arrLength = arrayOfNames.length;
            const isSpecialCase = ['THE', 'A'].includes(arrayOfNames[0].toUpperCase());
            switch (arrLength) {
              case 1: {
                finalName = arrayOfNames[0];
                break;
              }
              case 2: {
                finalName = isSpecialCase
                  ? arrayOfNames.join('')
                  : arrayOfNames[0];
                break;
              }
              default: {
                finalName = isSpecialCase
                  ? arrayOfNames[1]
                  : arrayOfNames[0];
              }
            }
            return finalName.substring(0, 4);
          }
          //Case for SSN
          case '2': {
            const lastName = arrayOfNames.slice(-1).pop();
            return lastName.substring(0, 4);
          }
          default: return '';
        }
      }
      return '';
    };
    const shortName = getShortName();
    if (shortName) {
      formActions.setFieldValue(dbColumn, shortName, isClone, taxEntityCode);
    }
  }, [tinType, payerName]);

  return (
    <ValidationGridTextField
      label={ label }
      name={ name }
      value={ value }
      readOnlyUser={ readOnlyUser }
      readOnly={ readOnly }
      helpText={ helpText }
    />
  );
};

PayerShortName.propTypes = {
  tinType: PropTypes.string,
  payerName: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  formActions: PropTypes.shape({
    setFieldValue: PropTypes.func
  }).isRequired,
  label: PropTypes.node,
  labelValidation: PropTypes.string,
  readOnlyUser: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  isClone: PropTypes.bool.isRequired,
  taxEntityCode: PropTypes.string.isRequired
};

PayerShortName.defaultProps = {
  tinType: '',
  payerName: '',
  value: ''
};

export default PayerShortName;