import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ValidationGridTextField from 'shared/standardInput/textField/validationGridTextField/ValidationGridTextFieldInterface';
import MultipleRows from '../shared/multipleRows/MultipleRows';
import './multipleState.scss';

const STATE = 'State';
const STATE_ID = 'StateId';
const STATE_ALABAMA = 'AL';

const validations = {
  [STATE]: {
    pattern: '^[a-zA-Z]{2}$|^$',
    message: 'State must be 2 letters',
    validateOn: 'keyup'
  },
  [STATE_ID]: {
    pattern: '^.{0,20}$',
    message: 'State ID must not exceed 20 valid characters',
    validateOn: 'keyup'
  },
  [`${STATE_ID}_AL_ONLY`]: {
    pattern: '^[0-9]{0,20}$',
    message: 'State ID must be numeric and no longer than 20 digits when the state is AL',
    validateOn: 'keyup'
  }
};

const newRow = { [STATE]: '', [STATE_ID]: '' };

const cleanStates = states => states.filter(state => state[STATE] && state[STATE_ID]);

const checkForDuplicateStates = states => {
  let tracker = {};
  for (const state of states) {
    if (tracker[state]) {
      return state;
    }
    tracker[state] = true;
  }
  return null;
};

const MultipleState = ({ multipleState, isClone, formActions, readOnlyUser, runUIValidationAutomatically, taxEntityCode }) => {
  const [localMultipleState, setLocalMultipleState] = useState(multipleState.length ? [...multipleState] : [newRow]);
  const [duplicateState, setDuplicateState] = useState();

  useEffect(() => {
    const completedRows = cleanStates(localMultipleState);
    formActions.setFieldValue('PAYERSTATES', completedRows, isClone, taxEntityCode);

    const duplicate = checkForDuplicateStates(completedRows.map(item => item.State));
    setDuplicateState(duplicate);
    formActions.setSaveDisableByValidation(Boolean(duplicate));
  }, [localMultipleState]);

  const checkForStateIDValidations = (state, validations) =>
    state === STATE_ALABAMA
      ? validations[`${STATE_ID}_AL_ONLY`]
      : validations[STATE_ID];

  const handleAddNewState = () => {
    setLocalMultipleState(prevState => [...prevState, newRow]);
  };

  const handleDeleteState = index => {
    setLocalMultipleState(prevState => {
      if (prevState.length === 1) {
        return [newRow];
      }
      return prevState.filter((_, idx) => idx !== index);
    });
  };

  const handleChange = (key, value, index) => {
    setLocalMultipleState(prevState => prevState.map(
      (stateInfo, idx) => idx === index
        ? { ...stateInfo, [key]: value.toUpperCase() }
        : stateInfo));
  };

  return (
    <div className='multiple-state__container'>
      <MultipleRows
        onAddRow={ handleAddNewState }
        showAddButton={ !readOnlyUser }
        addButtonProps={ { label: '+ Add another state' } }
      >
        {
          Boolean(localMultipleState.length) &&
          localMultipleState.map((mState, idx) => (
            <MultipleRows.Row
              key={ idx }
              onDeleteRow={ handleDeleteState }
              row={ { Id: idx } }
              showDeleteButton={ !readOnlyUser && localMultipleState.length >= 2 }
            >
              <ValidationGridTextField
                label='State'
                name={ `${STATE}-${idx}` }
                value={ mState.State }
                onChange={ ({ target: { value } }) => handleChange(STATE, value, idx) }
                errorText={ duplicateState === mState[STATE] ? 'State must be unique.' : '' }
                validation= { validations[STATE] }
                readOnlyUser={ readOnlyUser }
                runUIValidationAutomatically={ runUIValidationAutomatically }
              />
              <ValidationGridTextField
                label='State ID'
                name={ `${STATE_ID}-${idx}` }
                value={ mState[STATE_ID] }
                onChange={ ({ target: { value } }) => handleChange(STATE_ID, value, idx) }
                validation= { checkForStateIDValidations(mState.State, validations) }
                readOnlyUser={ readOnlyUser }
                runUIValidationAutomatically={ runUIValidationAutomatically }
              />
            </MultipleRows.Row>
          ))
        }
      </MultipleRows>
    </div>
  );
};

MultipleState.propTypes = {
  multipleState: PropTypes.array.isRequired,
  isClone: PropTypes.bool.isRequired,
  formActions: PropTypes.shape({
    setFieldValue: PropTypes.func,
    setSaveDisableByValidation: PropTypes.func
  }).isRequired,
  readOnlyUser: PropTypes.bool.isRequired,
  runUIValidationAutomatically: PropTypes.bool,
  taxEntityCode: PropTypes.string.isRequired
};

MultipleState.defaultProps = {
  runUIValidationAutomatically: false
};

export default MultipleState;
