import AuthorAvatar from 'shared/authorAvatar/AuthorAvatar';
import ActionDescription from '@mui/icons-material/Description';
import theme from 'mosaic-react/themes/sovos-theme';
import { formatDateUTC } from 'tir-frontend-common/helpers/dateHelper';
import Typography from '@mui/material/Typography';
import SovosFixedWidthText from 'mosaic-react/sovos-fixed-width-text/SovosFixedWidthText';
import PropTypes from 'prop-types';
import './attachmentList.scss';

const { palette } = theme;

const AttachmentList = ({ fileName, description, createdOn, userName, onClick, fileId }) => {

  const iconStyle = {
    fill: palette.grey[600]
  };

  const showAttachment = () => {
    onClick({
      fileName: fileName,
      fileId: fileId
    });
  };

  return (
    <div
      className="attachment-list"
      onClick={ showAttachment }
    >
      <ActionDescription className="attachment-list__icon" style={ iconStyle } />
      <SovosFixedWidthText className="attachment-list__filename" text={ fileName }/>
      <SovosFixedWidthText className="attachment-list__description" text={ description }/>
      <AuthorAvatar userName={ userName } />
      <Typography className="attachment-list__date">
        { formatDateUTC(createdOn) }
      </Typography>
    </div>
  );
};

AttachmentList.propTypes = {
  fileName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  createdOn: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  fileId: PropTypes.number.isRequired
};

export default AttachmentList;