import { get, post } from 'helpers/axios/axiosInterceptorsV1';
import { buildUrlsParams } from 'helpers/buildUrlParams';
import actionTypes from './formCommentsConstants';

const fetchingComments = () => ({ type: actionTypes.FETCHING_COMMENTS });

const fetchCommentsSuccess = commentList => ({ type: actionTypes.FETCH_COMMENTS_SUCCESS, commentList });

const fetchCommentsFailure = () => ({ type: actionTypes.FETCH_COMMENTS_FAILURE });

const addingComment = () => ({ type: actionTypes.ADDING_COMMENT });

const addCommentSuccess = () => ({ type: actionTypes.ADD_COMMENT_SUCCESS });

const addCommentFailure = () => ({ type: actionTypes.ADD_COMMENT_FAILURE });

const fetchComments = (recordType, recordId, pageNum, pageSize) => async (dispatch, getState) => {
  if (getState().dynamicForm.taxEntityCode) {
    const qsParsed = buildUrlsParams({ pageOffset: pageNum, pageSize: pageSize });

    dispatch(fetchingComments());
    try {
      const response = await get(`/record-activity/${recordType}-${recordId}/comments?${qsParsed}`);
      dispatch(fetchCommentsSuccess(response.data.data));
    } catch (error) {
      dispatch(fetchCommentsFailure());
      throw (error);
    }
  }
};

const addNewComment = (recordType, recordId, comment) => async dispatch => {
  dispatch(addingComment());

  try {
    const response = await post(`/record-activity/${recordType}-${recordId}/comments`, { text: comment });
    dispatch(addCommentSuccess(response.data.data));
  } catch (error) {
    dispatch(addCommentFailure());
    throw (error);
  }
};

export {
  fetchComments,
  addNewComment
};
