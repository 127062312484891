import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  setFieldValue,
  fetchingIdentityManagement,
  fetchingIdentityManagementComplete,
  setFormModifiedByTINValidation
} from 'shared/dataEntryForms/sovos-dynamic-form/redux/DynamicFormActions';
import IdentityVerification from './IdentityVerification';

const mapStateToProps = (state, ownProps) => ({
  formData: state.dynamicForm.isClone ? state.dynamicForm.cloneForm : (state.dynamicForm.form[ownProps.taxEntityCode] || {}),
  isClone: state.dynamicForm.isClone
});

const mapDispatchToProps = dispatch => ({
  formActions: bindActionCreators({
    setFieldValue,
    fetchingIdentityManagement,
    fetchingIdentityManagementComplete,
    setFormModifiedByTINValidation
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(IdentityVerification);
