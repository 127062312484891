import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import OrderStatusTable from 'shared/orderStatusTable/OrderStatusTable';
import {
  federalColumns,
  getStateColumns,
  EOTColumns,
  transmitType as transmitTypeConst
} from '../../pages/transmitCorp/helpers/configTransmitStatus';
import { withSnackbar } from 'enhancers';
import { getErrorMessage } from 'helpers/errorMessageHelper';
import { FE_TRANSMIT_001 } from 'helpers/errorMessageConstants';
import useTransmitHistory from 'shared/transmit/useTransmitHistory';

const TransmitHistoryTable = ({
  transmitType,
  showErrorSnackbar,
  enableSorting,
  enablePagination,
  showMoreButton,
  showMoreUrl,
  noResultsMessage,
  pageSizeDefault,
  title,
  configViewAllButton,
  breadcrumbs,
  className
}) => {
  const navigate = useNavigate();

  const handleOnError = () => showErrorSnackbar({ message: getErrorMessage(null, FE_TRANSMIT_001) });

  const {
    fetchingData,
    data,
    total,
    itemsPerPage,
    currentPage,
    sortColumnId,
    onPageChange,
    onColumnSortChanged
  } = useTransmitHistory({ transmitType, defaultItemsPerPage: pageSizeDefault, onError: handleOnError });

  const getSortingProps = () => {
    if (enableSorting && [transmitTypeConst.federal, transmitTypeConst.EOT].includes(transmitType)) {
      return ({
        onColumnSortChanged,
        sortColumnId
      });
    };
    return null;
  };

  const getPaginationProps = () => enablePagination ? {
    onPageChange,
    itemsPerPage,
    currentPage,
    totalItems: total
  } : null;

  const getTableColumns = () => {
    switch (transmitType) {
      case transmitTypeConst.federal:
        return federalColumns;
      case transmitTypeConst.state:
        return getStateColumns(true);
      case transmitTypeConst.EOT:
        return EOTColumns;
      default:
        return [];
    }
  };

  const handleRowClick = row => {
    if (row.FORMS !== 0) {
      navigate(`/transmit-history/${transmitType}/detail/${row.TransmitId}`, {
        state: {
          FilingStatus: row.FilingStatus,
          breadcrumbs: breadcrumbs
        }
      });
    }
  };

  return (
    <OrderStatusTable
      title={ title }
      configViewAllButton={ configViewAllButton }
      className={ className }
      columns={ getTableColumns() }
      data={ data }
      isUpdating={ fetchingData }
      paginationFooterProps={ getPaginationProps() }
      showMoreButton={ showMoreButton }
      showMoreUrl={ showMoreUrl }
      noResultsMessage={ noResultsMessage }
      onRowClick={ transmitType === transmitTypeConst.federal ? handleRowClick : () => {} }
      { ...getSortingProps() }
      breadcrumbs={ breadcrumbs }
    />
  );
};

TransmitHistoryTable.propTypes = {
  transmitType: PropTypes.oneOf([transmitTypeConst.federal, transmitTypeConst.state, transmitTypeConst.EOT]),
  enablePagination: PropTypes.bool,
  enableSorting: PropTypes.bool,
  showMoreButton: PropTypes.bool,
  showMoreUrl: PropTypes.string,
  noResultsMessage: PropTypes.string,
  pageSizeDefault: PropTypes.number,
  title: PropTypes.string,
  configViewAllButton: PropTypes.object,
  className: PropTypes.string,
  showErrorSnackbar: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.array
};

TransmitHistoryTable.defaultProps = {
  enablePagination: true,
  enableSorting: true,
  showMoreButton: false,
  pageSizeDefault: 20,
  breadcrumbs: []
};

export default withSnackbar(TransmitHistoryTable);
