import PropTypes from 'prop-types';
import { withSnackbar } from 'enhancers';
import DynamicForm from '../sovos-dynamic-form/SovosDynamicFormConnector';
import DynamicFormValidations from './dynamicFormValidations/DynamicFormValidationsConnector';
import DynamicFormInformation from './dynamicFormInformation/DynamicFormInformationConnector';
import CorrectionDialog from './correctionDialog/CorrectionDialog';
import getMessage from './correctionDialog/correctionMessage';

const FormContainer = ({
  taxEntityCode,
  formId,
  modLevel,
  applyStyle,
  onTabChange,
  showCorrectionDialog,
  dynamicFormActions,
  showErrorSnackbar
}) => (
  <div style={
    {
      padding: `${applyStyle ? '7px' : '0'}`,
      borderTop: applyStyle ? '1px solid rgba(0, 0, 0, 0.12)' : 'none',
      margin: applyStyle ? 'auto' : '0 10px 0 0px'
    }
  }>
    {
      modLevel >= 0 && <DynamicFormValidations />
    }
    <DynamicForm
      taxEntityCode={ taxEntityCode }
      key='dynamic-form'
      onTabChange={ onTabChange }
      formId={ formId }
    />
    {
      modLevel >= 0 &&
      <DynamicFormInformation
        key='form-tabs'
        taxEntityCode={ taxEntityCode }
        formId={ formId }
      />
    }
    <CorrectionDialog
      open={ showCorrectionDialog }
      message={ getMessage(taxEntityCode) }
      onClose={ () => dynamicFormActions.setShowCorrectionDialog(false) }
      onDoNotMark={ () => dynamicFormActions.saveWithoutMarkAsCorrection(showErrorSnackbar) }
      onMarkAsCorrectionAndClose={ () => dynamicFormActions.saveWithMarkAsCorrection(showErrorSnackbar) }
    />
  </div>
);

FormContainer.propTypes = {
  taxEntityCode: PropTypes.string.isRequired,
  formId: PropTypes.number.isRequired,
  modLevel: PropTypes.number,
  applyStyle: PropTypes.bool,
  onTabChange: PropTypes.func,
  showCorrectionDialog: PropTypes.bool.isRequired,
  dynamicFormActions: PropTypes.shape({
    setShowCorrectionDialog: PropTypes.func,
    saveWithMarkAsCorrection: PropTypes.func,
    saveWithoutMarkAsCorrection: PropTypes.func
  }),
  showErrorSnackbar: PropTypes.func.isRequired
};

FormContainer.defaultProps = {
  applyStyle: false,
  onTabChange: () => null
};

export default withSnackbar(FormContainer);
