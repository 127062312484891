import PropTypes from 'prop-types';
import InfoCard from 'shared/infoCard/InfoCard';
import sovosTheme from 'mosaic-react/themes/sovos-theme';
import authenticationHelper from 'helpers/authenticationHelper';
import { TIR_RTT_USER } from 'helpers/permissionConstants';
import { identityManagementCards } from 'helpers/identityManagementHelper';
import './recordCards.scss';

const colors = {
  Errors: sovosTheme.palette.error.main,
  resolved: sovosTheme.palette.success.main,
  TotalForms: sovosTheme.palette.grey[600],
  [identityManagementCards.DmfCount.type]: sovosTheme.palette.grey[600],
  [identityManagementCards.MismatchesCount.type]: sovosTheme.palette.warning.main,
  [identityManagementCards.OfacRankCount.type]: sovosTheme.palette.grey[600],
  NonPrinted: sovosTheme.palette.grey[600],
  NonTransmitted: sovosTheme.palette.grey[600]
};

const cardText = {
  Errors: 'Errors',
  resolved: 'Resolved',
  TotalForms: 'Total forms',
  [identityManagementCards.DmfCount.type]: identityManagementCards.DmfCount.text,
  [identityManagementCards.MismatchesCount.type]: identityManagementCards.MismatchesCount.text,
  [identityManagementCards.OfacRankCount.type]: identityManagementCards.OfacRankCount.text,
  NonPrinted: 'Forms to print',
  NonTransmitted: 'Forms to transmit'
};

const RecordCards = ({
  cards,
  infoCardStyle,
  showBorderBottom,
  onRecordCardClick,
  style,
  totals,
  fetchingTotals
}) => (
  <div className="records-card__cards-container" style={ style }>
    {
      cards.map(key => (
        <InfoCard
          key={ key }
          type={ key }
          total={ totals[key] }
          text={ cardText[key] }
          color={ colors[key] }
          isUpdating ={ fetchingTotals }
          style={ infoCardStyle }
          showBorderBottom={ showBorderBottom }
          onRecordCardClick={ onRecordCardClick }
          hover={ !authenticationHelper.checkSomeUserPermissions(TIR_RTT_USER) }
        />
      ))
    }
  </div>
);

RecordCards.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.string).isRequired,
  onRecordCardClick: PropTypes.func,
  infoCardStyle: PropTypes.object,
  showBorderBottom: PropTypes.bool,
  style: PropTypes.object,
  totals: PropTypes.shape({
    [identityManagementCards.MismatchesCount.type]: PropTypes.number,
    [identityManagementCards.OfacRankCount.type]: PropTypes.number,
    [identityManagementCards.DmfCount.type]: PropTypes.number
  }).isRequired,
  fetchingTotals: PropTypes.bool.isRequired
};

RecordCards.defaultProps = {
  infoCardStyle: {},
  showBorderBottom: true,
  onRecordCardClick: () => {},
  style: {},
  totals: {
    [identityManagementCards.MismatchesCount.type]: 0,
    [identityManagementCards.OfacRankCount.type]: 0,
    [identityManagementCards.DmfCount.type]: 0
  },
  fetchingTotals: false
};

export default RecordCards;
