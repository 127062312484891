import { useState } from 'react';
import { number, string, func, shape } from 'prop-types';
import TextField from '@mui/material/TextField';
import SovosButton from 'mosaic-react/sovos-button';
import { withSnackbar } from 'enhancers';
import './newComment.scss';

const newCommentTextfieldClasses = { root: 'comments-tab__text-field-root' };

const newCommentTextFieldInputProps = {
  input: 'comments-tab__internal-input',
  'data-testid': 'content-input'
};

const validationErrorMessage = 'Comment has to be less than 2000 characters';

const NewComment = ({
  formType,
  formId,
  commentsActions,
  showSnackbar,
  showErrorSnackbar
}) => {
  const [comment, setComment] = useState('');
  const [error, setError] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const saveComment = async () => {
    const { addNewComment, fetchComments } = commentsActions;
    setIsButtonDisabled(true);
    try {
      await addNewComment(formType, formId, comment);
      fetchComments(formType, formId, 1, 50);
      setComment('');
      showSnackbar({ message: 'Comment added successfully.' });
    } catch (error) {
      setIsButtonDisabled(false);
      showErrorSnackbar({ message: 'There was an error adding the comment. Please try again.' });
    };
  };

  const handleTextChange = event => {
    const text = event.target.value;
    setComment(text);
    setError(text.length > 2000);
    setIsButtonDisabled(text.length < 1 || text.length > 2000);
  };

  return (
    <div className='comments-tab__input-div' >
      <TextField
        className='comments-tab__text-field'
        placeholder='Begin typing here to leave a comment...'
        classes={ newCommentTextfieldClasses }
        InputProps={ newCommentTextFieldInputProps }
        onChange={ handleTextChange }
        value={ comment }
        multiline
        fullWidth
        maxRows={ 8 }
        helperText={ error ? validationErrorMessage : '' }
        error={ error }
        inputProps={ {
          'data-for': 'commentInput'
        } }
      />
      <div className='comments-tab__right-padding'>
        <div className='comments-tab__right-actions'>
          <div>
            <SovosButton
              data-for='commentButton'
              onClick={ saveComment }
              disabled={ isButtonDisabled }
            >
              Comment
            </SovosButton>
          </div>
        </div>
      </div>
    </div>
  );
};

NewComment.propTypes = {
  formId: number.isRequired,
  formType: string.isRequired,
  commentsActions: shape({
    addNewComment: func.isRequired,
    fetchComments: func.isRequired
  }).isRequired,
  showSnackbar: func,
  showErrorSnackbar: func
};

export { NewComment };
export default withSnackbar(NewComment);