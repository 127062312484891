import actionTypes from './formHistoryConstants';

const initialStore = {
  shouldFetchAgain: false,
  fetchingHistory: false,
  history: []
};

export default function FormHistoryReducer(state = initialStore, action) {
  switch (action.type) {
    case actionTypes.FETCHING_HISTORY:
      return {
        ...state,
        shouldFetchAgain: false,
        fetchingHistory: true,
        history: []
      };

    case actionTypes.FETCH_HISTORY_SUCCESS:
      return {
        ...state,
        history: action.formHistory,
        fetchingHistory: false
      };

    case actionTypes.FETCH_HISTORY_FAILURE:
      return { ...state, fetchingHistory: false };

    case actionTypes.SAVE_FORM_SUCCESS:
      return { ...state, shouldFetchAgain: true };

    case actionTypes.LOGOUT_SUCCESS:
      return { ...initialStore };

    default:
      return state;
  }
}
