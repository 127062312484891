import sharedActions from '../constants/SharedConstants';

const actionTypes = {
  ...sharedActions,
  FETCH_NOTIFICATIONS_SUCCESS: 'FETCH_NOTIFICATIONS_SUCCESS',
  FETCH_NOTIFICATIONS_FAIL: 'FETCH_NOTIFICATIONS_FAIL',
  FETCHING_NOTIFICATIONS: 'FETCHING_NOTIFICATIONS',
  FETCHING_LATEST_NOTIFICATION: 'FETCHING_LATEST_NOTIFICATION',
  FETCH_LATEST_NOTIFICATION_SUCCESS: 'FETCH_LATEST_NOTIFICATION_SUCCESS',
  FETCH_LATEST_NOTIFICATION_FAIL: 'FETCH_LATEST_NOTIFICATION_FAIL',
  CREATING_NEW_NOTIFICATION: 'CREATING_NEW_NOTIFICATION',
  CREATE_NEW_NOTIFICATION_SUCCESS: 'CREATE_NEW_NOTIFICATION_SUCCESS',
  CREATE_NEW_NOTIFICATION_FAIL: 'CREATE_NEW_NOTIFICATION_FAIL',
  EDITING_NOTIFICATION: 'EDITING_NOTIFICATION',
  EDIT_NOTIFICATION_SUCCESS: 'EDIT_NOTIFICATION_SUCCESS',
  EDIT_NOTIFICATION_FAIL: 'EDIT_NOTIFICATION_FAIL',
  FETCHING_NOTIFICATION_FILTERS: 'FETCHING_NOTIFICATION_FILTERS',
  FETCH_NOTIFICATION_FILTERS_SUCCESS: 'FETCH_NOTIFICATION_FILTERS_SUCCESS',
  FETCH_NOTIFICATION_FILTERS_FAIL: 'FETCH_NOTIFICATION_FILTERS_FAIL',
  DELETING_NOTIFICATION: 'DELETING_NOTIFICATION',
  DELETE_NOTIFICATION_SUCCESS: 'DELETE_NOTIFICATION_SUCCESS',
  DELETE_NOTIFICATION_FAIL: 'DELETE_NOTIFICATION_FAIL'
};

export default actionTypes;
