import { Component } from 'react';
import PropTypes from 'prop-types';
import GetApp from '@mui/icons-material/GetApp';
import SovosTableGroup from 'mosaic-react/sovos-table-group';
import { getFailedHeaders } from 'helpers/importTableHelper';

class Failed extends Component {
  componentDidMount() {
    this.props.recordActions.fetchAllFailed(this.props.recordType, this.props.job, '', this.props.isTestImport);
  }

  handleOnSearch = value =>
    this.props.recordActions.fetchAllFailed(this.props.recordType, this.props.job, value, this.props.isTestImport);

  handleDownload = () =>
    this.props.recordActions.exportRecords(this.props.recordType, 'failed', '', '', '', this.props.job, this.props.isTestImport);

  actionIconButtons = [{
    icon: <GetApp />,
    tooltipText: 'Download',
    onClick: this.handleDownload
  }];

  render() {
    const headers = getFailedHeaders(this.props.recordType);
    const { records, fetchingFailed } = this.props;

    return (
      <div className="failed-records__container">
        <SovosTableGroup
          TableProps={ {
            data: records,
            columns: headers
          } }
          isLoading={ fetchingFailed }
          search={ {
            onSearch: this.handleOnSearch
          } }
          actionIconButtons={ this.actionIconButtons }
        />
      </div>
    );
  }
}

Failed.defaultProps = {
  isTestImport: false
};

Failed.propTypes = {
  job: PropTypes.number,
  isTestImport: PropTypes.bool,
  records: PropTypes.array.isRequired,
  recordType: PropTypes.string.isRequired,
  fetchingFailed: PropTypes.bool.isRequired,
  recordActions: PropTypes.shape({
    fetchAllFailed: PropTypes.func
  }).isRequired
};

export default Failed;
