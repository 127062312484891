import { string, bool } from 'prop-types';
import SemiWeeklyAmount from '../semiWeeklyAmount/SemiWeeklyAmountConnector';
import './semiWeeklyAmounts.scss';

export const DAYS_BY_MONTH = {
  January: 31,
  February: 29,
  March: 31,
  April: 30,
  May: 31,
  June: 30,
  July: 31,
  August: 31,
  September: 30,
  October: 31,
  November: 30,
  December: 31
};

const SemiWeeklyAmounts = ({ name, taxEntityCode, readOnly }) => {
  const month = name.replace('Amounts', '');

  const generateColumn2Numbers = monthDays => {
    let column2Numbers = [];
    for (let i = 17; i <= monthDays; i++) {
      column2Numbers[i-17] = i;
    }
    return column2Numbers;
  };

  return (
    <div className='semi-weekly-amounts__container'>
      <div className='semi-weekly-amounts__column-1'>
        { Array(16).fill(0).map((_, i) => {
          const fieldName = `${month}SemiWeeklyFederalTaxLiability_${i+1}`;
          const boxName = `${month} ${i+1}`;

          return (
            <SemiWeeklyAmount
              taxEntityCode={ taxEntityCode }
              name={ fieldName }
              entityName={ fieldName }
              boxName={ boxName }
              key={ fieldName }
              readOnly={ readOnly }
            />);
        }) }
      </div>
      <div className='semi-weekly-amounts__column-2'>
        { generateColumn2Numbers(DAYS_BY_MONTH[month]).map(numberDay => {
          const fieldName = `${month}SemiWeeklyFederalTaxLiability_${numberDay}`;
          const boxName = `${month} ${numberDay}`;

          return (
            <SemiWeeklyAmount
              taxEntityCode={ taxEntityCode }
              name={ fieldName }
              entityName={ fieldName }
              boxName={ boxName }
              key={ fieldName }
              readOnly={ readOnly }
            />);
        }) }
      </div>
    </div>
  );
};

SemiWeeklyAmounts.propTypes = {
  name: string.isRequired,
  taxEntityCode: string.isRequired,
  readOnly: bool.isRequired
};

SemiWeeklyAmounts.defaultProps = {
  name: ''
};

export default SemiWeeklyAmounts;