import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import SovosButton from 'mosaic-react/sovos-button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AuthenticationHelper from 'helpers/authenticationHelper';
import { eula, eulaSetting, eulaEcommerce, eulaEcommerceSetting } from 'helpers/eulaContent';
import './eula.scss';

const Eula = ({
  openEula,
  selectedContext,
  usersActions
}) => {
  const navigate = useNavigate();

  const settings = AuthenticationHelper.isTIRCorporateECommerce() ? eulaEcommerceSetting : eulaSetting;
  const content = AuthenticationHelper.isTIRCorporateECommerce() ? eulaEcommerce.content : eula.content;

  useEffect(() => {
    const eulaStored = AuthenticationHelper.isEulaStored();
    const isContextStored = AuthenticationHelper.isContextStored();
    if (!eulaStored && isContextStored) {
      usersActions.fetchEulaSetting(settings);
    }
  }, [selectedContext, usersActions]);

  const handleAccept = () => usersActions.acceptEula(settings);

  const handleDecline = () => navigate('/logout');

  return (
    <Dialog open={ openEula } className='eula-dialog'>
      <DialogTitle className='eula-dialog__title'>
        Terms and conditions
      </DialogTitle>
      <DialogContent className='eula-dialog__content'>
        <div dangerouslySetInnerHTML={ { __html: content } }/>
      </DialogContent>
      <DialogActions className='eula-dialog__footer'>
        <span>By clicking Accept you agree to the Terms and conditions above.</span>
        <SovosButton
          color='error'
          onClick={ handleDecline }
        >
          Decline
        </SovosButton>
        <SovosButton variant='text' onClick={ handleAccept }>
          Accept
        </SovosButton>
      </DialogActions>
    </Dialog>
  );
};

Eula.propTypes = {
  openEula: PropTypes.bool.isRequired,
  selectedContext: PropTypes.object,
  usersActions: PropTypes.shape({
    fetchEulaSetting: PropTypes.func,
    acceptEula: PropTypes.func
  }).isRequired
};

Eula.defaultProps = {
  selectedContext: undefined
};

export default Eula;
