import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RecordTable from './RecordTable';
import { fetchAllRecords, resetRecords, exportRecords } from '../redux/RecordActions';
import { fetchTableOptions, fetchMetadata } from 'shared/dataEntryForms/sovos-dynamic-form/redux/FormMetaInformationActions';
import { setFormId, getTableValues, setTaxEntityCode, setTaxEntityCodeBase } from 'shared/dataEntryForms/sovos-dynamic-form/redux/DynamicFormActions';

const mapStateToProps = (state, ownProps) => ({
  shouldFetchAgain: state.dynamicForm.saveFormSuccess || state.dynamicForm.deleting,
  records: state.recordReducer.records,
  total: state.recordReducer.total,
  fetchingRecords: state.recordReducer.fetchingRecords,
  fetchingFailed: state.recordReducer.fetchingFailed,
  fetchingTableOptions: state.formMetaInformation.fetchingTableOptions,
  fetchingMetadata: state.formMetaInformation.fetchingMetadata,
  formMetadata: state.formMetaInformation.formsMetadata[ownProps.fchar] || {},
  enabledFields: state.formMetaInformation.enabledFields[ownProps.fchar] || {},
  disabledFields: state.formMetaInformation.disabledFields[ownProps.fchar] || {},
  deleting: state.dynamicForm.deleting
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  recordActions: bindActionCreators({
    fetchAllRecords:
      (searchText, page, itemsPerPage, sortColumn, sortOrder, isTestImport) =>
        fetchAllRecords(ownProps.fchar, ownProps.job, ownProps.type, searchText, page, itemsPerPage, sortColumn, sortOrder, isTestImport, ownProps.validationId),
    resetRecords,
    exportRecords
  }, dispatch),
  formMetaInformationActions: bindActionCreators({ fetchTableOptions, fetchMetadata }, dispatch),
  formActions: bindActionCreators({ setFormId, getTableValues, setTaxEntityCode, setTaxEntityCodeBase }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(RecordTable);
