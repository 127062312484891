import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Errors from './Errors';
import { fetchAllErrors, fetchAllRecordsByValidationId, exportRecords } from '../redux/RecordActions';

const mapStateToProps = state => ({
  formHasBeenSaved: state.dynamicForm.saveFormSuccess,
  formHasBeenDeleted: !state.dynamicForm.deleting,
  records: state.recordReducer.errors,
  total: state.recordReducer.errorsTotal,
  fetchingErrors: state.recordReducer.fetchingErrors
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  recordActions: bindActionCreators({
    fetchAllErrors,
    exportRecords,
    fetchAllRecordsByValidationId: (validationId, recordType, status, searchText, page, itemsPerPage, filterBy, sortColumn, sortOrder, isTestImport) =>
      fetchAllRecordsByValidationId(
        ownProps.job || null,
        validationId,
        recordType,
        status,
        searchText,
        page,
        itemsPerPage,
        filterBy,
        sortColumn,
        sortOrder,
        isTestImport
      )
  }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Errors);
