import sharedActions from 'redux/constants/SharedConstants';

const actionTypes = {
  ...sharedActions,
  FETCHING_RECORDS_UNDER_FEDERAL_LIMIT: 'FETCHING_PRINT_NOTES',
  FETCH_ALL_RECORDS_UNDER_FEDERAL_SUCCESS: 'FETCH_ALL_RECORDS_UNDER_FEDERAL_SUCCESS',
  FETCH_RECORDS_UNDER_FEDERAL_SUCCESS: 'FETCH_RECORDS_UNDER_FEDERAL_SUCCESS',
  FETCH_RECORDS_UNDER_FEDERAL_FAILURE: 'FETCH_RECORDS_UNDER_FEDERAL_FAILURE',
  CLEAN_UP_STORE: 'CLEAN_UP_STORE'
};

export default actionTypes;
