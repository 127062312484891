import actionTypes from './RecordsSummaryConstants';
import { identityManagementCards } from 'helpers/identityManagementHelper';

const defaultValue = {
  totals: {
    Errors: 0,
    resolved: 0,
    total: 0,
    [identityManagementCards.MismatchesCount.type]: 0,
    [identityManagementCards.OfacRankCount.type]: 0,
    [identityManagementCards.DmfCount.type]: 0,
    NonPrinted: 0,
    NonTransmitted: 0,
    TotalForms: 0
  }
};

const initialStore = {
  summary: [],
  fetchingSummary: false,
  fetchingSummarySorted: false,
  totals: defaultValue.totals,
  fetchingRecordsByFormSummary: false,
  recordsByFormSummary: [],
  fetchingRecordsByForm: false,
  recordsByForm: {},
  fetchingOfacAlerts: false,
  ofacAlerts: {
    Data: [],
    CurrentPage: 0,
    Total: 0
  },
  fetchingDmfAlerts: false,
  dmfAlerts: {
    Data: [],
    CurrentPage: 0,
    Total: 0
  }
};

export default function RecordsSummaryReducer(state = initialStore, action) {
  switch (action.type) {
    case actionTypes.LOGOUT_SUCCESS:
      return { ...initialStore };

    case actionTypes.FETCHING_RECORDS_BY_FORM_SUMMARY:
      return { ...state, fetchingRecordsByFormSummary: true };

    case actionTypes.FETCHING_RECORDS_BY_FORM_SUMMARY_SUCCESS:
      return { ...state, fetchingRecordsByFormSummary: false, recordsByFormSummary: action.summary };

    case actionTypes.FETCHING_RECORDS_BY_FORM_SUMMARY_FAILURE:
      return { ...state, fetchingRecordsByFormSummary: false, recordsByFormSummary: [] };

    case actionTypes.FETCHING_RECORDS_BY_FORM:
      return { ...state, fetchingRecordsByForm: true };

    case actionTypes.FETCH_RECORDS_BY_FORM_SUCCESS:
      return { ...state, fetchingRecordsByForm: false, recordsByForm: action.records };

    case actionTypes.FETCH_RECORDS_BY_FORM_FAILURE:
      return { ...state, fetchingRecordsByForm: false, recordsByForm: {} };

    default:
      return state;
  }
}
