import PropTypes from 'prop-types';
import SovosIconMenu from 'mosaic-react/sovos-icon-menu/SovosIconMenu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SovosTooltip from 'mosaic-react/sovos-tooltip/SovosTooltip';
import { noFormsFchars } from 'helpers/formConstants';
import {
  TIR_D_RECORD_PAYER,
  TIR_D_RECORD_PAYEE,
  TIR_D_RECORD_NON_WITHHOLDING,
  TIR_R_RECORD_PAYER,
  TIR_R_RECORD_PAYEE,
  TIR_R_RECORD_NON_WITHHOLDING,
  TIR_R_PRINT_PREVIEW,
  TIR_C_REPRINT
} from 'helpers/permissionConstants';
import SovosSlidingPanelHeaderAdditionalButtons from 'mosaic-react/sovos-sliding-panel-header-additional-buttons';
import { reprintMenuItems } from './slidingFormEditorHelper';
import AuthenticationHelper from 'helpers/authenticationHelper';
import './slidingFormEditor.scss';

const FormOptions = ({
  currentTab,
  formPrinted,
  formModified,
  reprintStatus,
  canBeDeleted,
  fcharToDelete,
  deleteForm,
  cloneForm,
  previewForm,
  handleReprintDialog,
  enableReprint
}) => {
  const getDeleteTooltip = () =>
    noFormsFchars.includes(fcharToDelete)
      ? 'Records with forms that have been printed/transmitted cannot be deleted'
      : 'Forms that have been printed/transmitted cannot be deleted';

  const canDelete = AuthenticationHelper.checkSomeUserPermissions([TIR_D_RECORD_PAYER, TIR_D_RECORD_PAYEE, TIR_D_RECORD_NON_WITHHOLDING]);
  const canClone = Boolean(noFormsFchars.includes(currentTab) && AuthenticationHelper.checkSomeUserPermissions([TIR_R_RECORD_PAYER, TIR_R_RECORD_PAYEE, TIR_R_RECORD_NON_WITHHOLDING]));
  const canPreviewPrint = Boolean(!noFormsFchars.includes(currentTab) && AuthenticationHelper.checkSomeUserPermissions(TIR_R_PRINT_PREVIEW));
  const canReprint = Boolean(!noFormsFchars.includes(currentTab) && formPrinted && AuthenticationHelper.checkSomeUserPermissions(TIR_C_REPRINT));

  const isDisabledReprint = () => formModified || (!reprintStatus.IsPendingReprint && !enableReprint);

  const showFormOptions = canDelete || canClone || canPreviewPrint || canReprint;

  if (showFormOptions) {
    return (
      <SovosSlidingPanelHeaderAdditionalButtons>
        <SovosIconMenu
          key="OptionMenu"
          iconElement={ <MoreVertIcon /> }
        >
          {
            canDelete &&
          (
            canBeDeleted
              ?
              <MenuItem
                onClick={ deleteForm }
              >
                Delete
              </MenuItem>
              :
              <MenuItem
                disabled
              >
                <SovosTooltip
                  title={ getDeleteTooltip() }
                  placement="left"
                >
                  <span className='form-options__tooltip_child'>Delete</span>
                </SovosTooltip>
              </MenuItem>

          )
          }
          {
            canClone &&
            <MenuItem
              onClick={ () => cloneForm() }
            >
              Clone
            </MenuItem>
          }
          {
            canPreviewPrint &&
            <MenuItem
              onClick={ previewForm }
            >
              View form
            </MenuItem>
          }
          {
            canReprint &&
            <MenuItem
              disabled={ isDisabledReprint() }
              onClick={ handleReprintDialog }
            >
              {
                reprintStatus.IsPendingReprint
                  ? reprintMenuItems.unmarkReprintedRecord
                  : reprintMenuItems.reprintRecord
              }
            </MenuItem>
          }
        </SovosIconMenu>
      </SovosSlidingPanelHeaderAdditionalButtons>
    );
  }

  return null;
};

FormOptions.propTypes = {
  currentTab: PropTypes.string.isRequired,
  formPrinted: PropTypes.bool.isRequired,
  formModified: PropTypes.bool.isRequired,
  reprintStatus: PropTypes.shape({
    IsPendingReprint: PropTypes.bool
  }).isRequired,
  canBeDeleted: PropTypes.bool.isRequired,
  fcharToDelete: PropTypes.string.isRequired,
  deleteForm: PropTypes.func.isRequired,
  cloneForm: PropTypes.func.isRequired,
  previewForm: PropTypes.func.isRequired,
  handleReprintDialog: PropTypes.func.isRequired,
  enableReprint: PropTypes.bool.isRequired
};

export default FormOptions;