import DynamicFormHelper from '../DynamicFormHelper';
import { getFieldsNames } from '../helper/formMetaInformationHelper';
import actionTypes from './FormMetaInformationConstants';

const initialStore = {
  formsMetadata: {},
  enabledFields: {},
  disabledFields: {},
  fetchingMetadata: false,
  fetchingTableOptions: false,
  allFields: {}
};

const fetchBulkMetadaSuccess = (fchars, bulkMetadata, state) => {
  const newMetada = {};
  const newFields = {};

  fchars.forEach((fchar, index) => {
    newMetada[fchar] = bulkMetadata[index];
    newFields[fchar] = getFieldsNames(bulkMetadata[index]);
  });

  return {
    ...state,
    formsMetadata: {
      ...state.formsMetadata,
      ...newMetada
    },
    allFields: {
      ...state.allFields,
      ...newFields
    },
    fetchingMetadata: false
  };
};

export default function FormMetaInformationReducer(state = initialStore, action) {
  switch (action.type) {
    case actionTypes.FETCH_METADATA_SUCCESS:
      return {
        ...state,
        formsMetadata: {
          ...state.formsMetadata,
          [action.fchar]: action.metadata
        },
        allFields: {
          ...state.allFields,
          [action.fchar]: getFieldsNames(action.metadata)
        },
        fetchingMetadata: false
      };

    case actionTypes.FETCH_BULK_METADATA_SUCCESS:
      return fetchBulkMetadaSuccess(action.fchars, action.bulkMetadata, state);

    case actionTypes.FETCH_METADATA_FAILURE:
      return { ...state, fetchingMetadata: false };

    case actionTypes.FETCHING_METADATA:
      return { ...state, fetchingMetadata: true };

    case actionTypes.RESET_METADATA:
      return {
        ...state,
        formsMetadata: {},
        allFields: {}
      };

    case actionTypes.FETCH_TABLE_OPTIONS_SUCCESS:
      return {
        ...state,
        enabledFields: {
          ...state.enabledFields,
          [action.fchar]: DynamicFormHelper.filterFieldsByShowOnSearch(action.tableOptions.Value, true)
        },
        disabledFields: {
          ...state.disabledFields,
          [action.fchar]: DynamicFormHelper.filterFieldsByShowOnSearch(action.tableOptions.Value, false)
        },
        fetchingTableOptions: false
      };

    case actionTypes.FETCH_TABLE_OPTIONS_FAILURE:
      return { ...state, fetchingTableOptions: false };

    case actionTypes.FETCHING_TABLE_OPTIONS:
      return { ...state, fetchingTableOptions: true };

    case actionTypes.RESET_TABLE_OPTIONS:
      return {
        ...state,
        enabledFields: {
          ...state.enabledFields,
          [action.fchar]: {}
        },
        disabledFields: {
          ...state.disabledFields,
          [action.fchar]: {}
        }
      };

    case actionTypes.LOGOUT_SUCCESS:
      return { ...initialStore };

    default:
      return state;
  }
}
