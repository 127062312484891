import auth from 'helpers/authenticationHelper';
import { productTypeCode } from 'helpers/enums';

const includeZendesk = token => {
  if (typeof pendo === "object") {
    window.zESettings = {
      webWidget: {
        authenticate: {
          jwtFn: callback => {
            callback(token);
          }
        },
        helpCenter: {
          originalArticleButton: false
        }
      }
    };
    const key = auth.getShortProductName() === productTypeCode.TIRC
      ? 'e1e7e108-f48e-4dbb-b00a-df0168fb0b60' : '2e5f64bd-ccaa-4103-9164-5ada8f77bac4';
    const script = `https://static.zdassets.com/ekr/snippet.js?key=${key}`;
    const newScriptElement = document.createElement('script');
    newScriptElement.src = script;
    newScriptElement.async = true;
    newScriptElement.id = 'ze-snippet';
    document.head.appendChild(newScriptElement);
  }
};

const zendeskLogout = () => {
  const element = document.getElementById('ze-snippet');
  if (element) {
    const event = new Event('zdlogout', {
      bubbles: true,
      cancelable: true,
      composed: false
    });

    window.dispatchEvent(event);
  }
};

export {
  zendeskLogout,
  includeZendesk
};
