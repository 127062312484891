import { connect } from 'react-redux';
import { setFormId, setTaxEntityCode, setTaxEntityCodeBase } from 'shared/dataEntryForms/sovos-dynamic-form/redux/DynamicFormActions';
import TinSuggestions from './TinSuggestions';

const mapDispatchToProps = {
  setFormId,
  setTaxEntityCode,
  setTaxEntityCodeBase
};

export default connect(null, mapDispatchToProps)(TinSuggestions);
