import actionTypes from './FormMetaInformationConstants';
import { get } from 'helpers/axios/axiosInterceptorsV1';
import authenticationHelper from 'helpers/authenticationHelper';
import fetchMetadataServ from 'shared/metadata/metadataService';

const resetMetadata = () => ({ type: actionTypes.RESET_METADATA });
const fetchBulkMetadataSuccess = (bulkMetadata, fchars) => ({ type: actionTypes.FETCH_BULK_METADATA_SUCCESS, bulkMetadata, fchars });
const fetchMetadataSuccess = (metadata, fchar) => ({ type: actionTypes.FETCH_METADATA_SUCCESS, metadata, fchar });
const fetchMetadataFailure = (metadata, fchar) => ({ type: actionTypes.FETCH_METADATA_FAILURE, metadata, fchar });
const fetchingMetadata = () => ({ type: actionTypes.FETCHING_METADATA });

const fetchTableOptionsSuccess = (tableOptions, fchar) => ({ type: actionTypes.FETCH_TABLE_OPTIONS_SUCCESS, tableOptions, fchar });
const fetchingTableOptions = () => ({ type: actionTypes.FETCHING_TABLE_OPTIONS });

const fetchMetadata = fchar => async (dispatch, getState) => {
  if (getState().formMetaInformation.formsMetadata[fchar]) {
    return;
  }
  dispatch(fetchingMetadata());

  try {
    const metadata = await fetchMetadataServ(fchar);
    dispatch(fetchMetadataSuccess(metadata, fchar));
  } catch (error) {
    dispatch(fetchMetadataFailure());
  }
};

const fetchBulkMetadata = fchars => async (dispatch, getState) => {
  const fcharsToFetch =
    fchars.filter(fchar => !getState().formMetaInformation.formsMetadata[fchar]);
  if (!fcharsToFetch.length) {
    return;
  }
  dispatch(fetchingMetadata());
  try {
    const getFcharsToFetch = fcharsToFetch.map(fchar => fetchMetadataServ(fchar));
    const metadatas = await Promise.all(getFcharsToFetch);
    dispatch(fetchBulkMetadataSuccess(metadatas, fcharsToFetch));
  } catch (error) {
    dispatch(fetchMetadataFailure());
  }
};

const fetchTableOptions = fchar => async dispatch => {
  const path = `/tax-entities/${fchar}/settings/search`;
  dispatch(fetchingTableOptions());
  try {
    const response = await get(path);
    // This conditional statement will be deleted in a future story
    if (authenticationHelper.isTIRCorporate() && fchar === "B") {
      delete response.data.Value.S28;
    }
    dispatch(fetchTableOptionsSuccess(response.data, fchar));
  } catch (error) {
    dispatch({ type: actionTypes.FETCH_TABLE_OPTIONS_FAILURE });
    throw (error);
  }
};

const resetTableOptions = fchar => ({ type: actionTypes.RESET_TABLE_OPTIONS, fchar });

export {
  resetMetadata,
  fetchMetadata,
  fetchTableOptions,
  resetTableOptions,
  fetchBulkMetadata
};
