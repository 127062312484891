import sharedActions from 'redux/constants/SharedConstants';

const actionTypes = {
  MIGRATING_CLIENT: 'MIGRATING_CLIENT',
  CLIENT_MIGRATION_SUCCESS: 'CLIENT_MIGRATION_SUCCESS',
  CLIENT_MIGRATION_FAILURE: 'CLIENT_MIGRATION_FAILURE',
  CREATING_TAX_YEAR: 'CREATING_TAX_YEAR',
  CREATE_TAX_YEAR_SUCCESS: 'CREATE_TAX_YEAR_SUCCESS',
  CREATE_TAX_YEAR_FAIL: 'CREATE_TAX_YEAR_FAIL',
  FETCHING_RESET_DATA_CONFIG: 'FETCHING_RESET_DATA_CONFIG',
  RESET_DATA_CONFIG_SUCCESS: 'RESET_DATA_CONFIG_SUCCESS',
  RESET_DATA_CONFIG_FAILURE: 'RESET_DATA_CONFIG_FAILURE',
  RESETING_DATA: 'RESETING_DATA',
  RESET_DATA_SUCCESS: 'RESET_DATA_SUCCESS',
  RESET_DATA_FAILURE: 'RESET_DATA_FAILURE',
  FETCHING_SERVICES: 'FETCHING_SERVICES',
  FETCH_SERVICES_SUCCESS: 'FETCH_SERVICES_SUCCESS',
  FETCH_SERVICES_FAIL: 'FETCH_SERVICES_FAIL',
  FETCHING_VERSIONS: 'FETCHING_VERSIONS',
  FETCH_VERSIONS_SUCCESS: 'FETCH_VERSIONS_SUCCESS',
  FETCH_VERSIONS_FAIL: 'FETCH_VERSIONS_FAIL',
  FETCHING_LOG_LEVELS: 'FETCHING_LOG_LEVELS',
  FETCH_LOG_LEVELS_SUCCESS: 'FETCH_LOG_LEVELS_SUCCESS',
  FETCH_LOG_LEVELS_FAIL: 'FETCH_LOG_LEVELS_FAIL',
  SETTING_LOG_LEVEL: 'SETTING_LOG_LEVEL',
  SET_LOG_LEVEL_SUCCESS: 'SET_LOG_LEVEL_SUCCESS',
  SET_LOG_LEVEL_FAIL: 'SET_LOG_LEVEL_FAIL',
  CLEAR_SERVICES: 'CLEAR_SERVICES',
  EXPORT_STARTED: 'EXPORT_STARTED',
  EXPORT_FINISHED: 'EXPORT_FINISHED',
  EXPORT_FAILED: 'EXPORT_FAILED',
  RECALCULATING_STATUSES_STARTED: 'RECALCULATING_STATUSES_STARTED',
  RECALCULATING_STATUSES_SUCCESS: 'RECALCULATING_STATUSES_SUCCESS',
  RECALCULATING_STATUSES_FAIL: 'RECALCULATING_STATUSES_FAIL',
  CALCULATING_BW_STATUSES_STARTED: 'CALCULATING_BW_STATUSES_STARTED',
  CALCULATING_BW_STATUSES_SUCCESS: 'CALCULATING_BW_STATUSES_SUCCESS',
  CALCULATING_BW_STATUSES_FAIL: 'CALCULATING_BW_STATUSES_FAIL',
  ...sharedActions
};

export default actionTypes;
