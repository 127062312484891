export const identityManagementCards = {
  MismatchesCount: {
    text: 'TIN mismatches',
    type: 'MismatchesCount'
  },
  OfacRankCount: {
    text: 'OFAC alerts',
    type: 'OfacRankCount'
  },
  DmfCount: {
    text: 'DMF alerts',
    type: 'DmfCount'
  }
};

export const identityManagementCardNames = Object.values(identityManagementCards).map(card => card.text);

export const identityManagementCardTypes = Object.values(identityManagementCards).map(card => card.type);