import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import './fullPageLoader.scss';

const portalRoot = document.getElementById("full-page-loader");

const LoadingAnimation = (
  <div className='full-page-loader__loading-animation'>
    <Typography variant='h3' className='full-page-loader__typography'>Fetching data</Typography>
    <CircularProgress size={ 80 } className='full-page-loader__spinner' />
    <Typography variant='subtitle1' className='full-page-loader__typography'>This may take a moment</Typography>
  </div>
);

const FullPageLoader = ({ show }) => show ? createPortal(LoadingAnimation, portalRoot) : null;

FullPageLoader.propTypes = {
  show: PropTypes.bool.isRequired
};

export default FullPageLoader;