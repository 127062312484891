import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SovosButton from 'mosaic-react/sovos-button';
import Popover from '@mui/material/Popover';
import Typeahead from 'shared/typeahead/Typeahead';
import AuthenticationHelper from 'helpers/authenticationHelper';
import CircularProgress from '@mui/material/CircularProgress';
import { getRecordName, payerFchar } from 'helpers/formConstants';
import useDebounce from 'helpers/useDebounceHelper';
import '../formComponents.scss';
import './payerInformation.scss';

const PayerInformation = ({
  taxEntityCode,
  payerID,
  payerInformation: { Pein, Pname, Pname2, Paddress, Paddress2, Pcity, Pstate, Pzip },
  readOnlyUser,
  readOnly,
  payers,
  hideChangeButton,
  showAddNewPayerButton,
  actions,
  formActions }) => {
  const [payerSearch, setPayerSearch] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const debouncePayerSearch = useDebounce(payerSearch, 350);

  useEffect(() => {
    if (payerID) {
      actions.fetchPayer(payerID);
    }
    else if (AuthenticationHelper.isTIRCorporate()) {
      actions.fetchPayerByRegex();
    }
  }, [payerID]);

  useEffect(() => {
    const handleSearchCall = async () => {
      if (payerSearch.length >= 3 && debouncePayerSearch) {
        setIsLoading(true);
        await actions.fetchPayerByRegex(payerSearch);
      }
      else if (AuthenticationHelper.isTIRCorporate() && !payerSearch) {
        setIsLoading(true);
        await actions.fetchPayerByRegex();
      }
      setIsLoading(false);
    };

    handleSearchCall();
  }, [debouncePayerSearch]);

  const handleOnChangePayer = ({ value }) => setPayerSearch(value);

  const renderSuggestion = suggestion => {
    if (suggestion.Name === 'notFound') {
      return (
        <div>
          <div className='suggestion-majorDetails'>
            No matching payer found
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className='suggestion-majorDetails'>
          { suggestion.Pname }
        </div>
        <div className='suggestion-minorDetails'>
          <strong>Payer ID: </strong>{ suggestion.Ptin }
        </div>
        <div className='suggestion-minorDetails'>
          <strong>EIN: </strong>{ suggestion.Pein }
        </div>
      </div>
    );
  };

  const toggleSearch = () => setShowSearch(!showSearch);

  const handlePayerClick = event => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    toggleSearch();
    window.setTimeout(function () {
      document.getElementById('react-autowhatever-selectPayer').previousSibling.focus();
    }, 0);
  };

  const handlePayerSelect = selection => {
    toggleSearch();
    actions.setPayer(selection);
    formActions.setRecipientPayer(
      {
        'PAYERID': selection['Ptin'],
        'PAYERRECNO': selection['Id']
      }
    );
  };

  const handleAddNewPayer = () => {
    toggleSearch();
    formActions.setTaxEntityCodeBase(taxEntityCode);
    formActions.setTaxEntityCode(payerFchar);
    formActions.resetExtraForm(payerFchar);
    formActions.setFormEditorTitle(`Add ${getRecordName(payerFchar)}`);
    formActions.setOpenExtraFormSliding(true);
  };

  return (
    <>
      <div className='payer-information__container'>
        <div className='formComponents-information__container-top'>
          <h3 className='formComponents-information__title'>Payer information</h3>
          {
            !readOnlyUser && !hideChangeButton &&
            <div className='formComponents-information__container-button'>
              <SovosButton
                variant='text'
                onClick={ handlePayerClick }
                disabled={ Boolean(readOnly) }
              >
                { Pein ? 'Change' : 'Add' }
              </SovosButton>
              <Popover
                className='payer__popover'
                open={ showSearch }
                anchorEl={ anchorEl }
                anchorOrigin={ { horizontal: 'left', vertical: 'bottom' } }
                onClose={ toggleSearch }
                style={ { marginTop: 10, display: 'flex' } }
              >
                <Typeahead
                  id='selectPayer'
                  placeholder='Name or ID'
                  data={ payers }
                  keys={ ['Name', 'Id', 'Ein'] }
                  alwaysRenderSuggestions
                  onSuggestionSelected={ handlePayerSelect }
                  renderSuggestion={ renderSuggestion }
                  customFetchRequested={ handleOnChangePayer }
                />
                { showAddNewPayerButton &&
                  <SovosButton
                    variant='text'
                    onClick={ handleAddNewPayer }
                  >
                      New payer
                  </SovosButton>
                }
                { isLoading && <CircularProgress className='payer-recipient__search-spinner'/> }
              </Popover>
            </div>
          }
        </div>
        {
          Pein &&
            <div className='formComponents-information__container-bottom'>
              <p><label>TIN:</label> { Pein }</p>
              <p>{ Pname }</p>
              <p>{ Pname2 }</p>
              <p>{ Paddress }</p>
              {
                Paddress2 &&
                <p>{ Paddress2 }</p>
              }
              {
                Pcity &&
                <p>{ Pcity }, { Pstate } { Pzip }</p>
              }
            </div>
        }
      </div>
    </>
  );
};

PayerInformation.propTypes = {
  payers: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    fetchPayer: PropTypes.func.isRequired,
    setPayer: PropTypes.func.isRequired,
    fetchPayerByRegex: PropTypes.func.isRequired
  }).isRequired,
  formActions: PropTypes.shape({
    setRecipientPayer: PropTypes.func.isRequired,
    setOpenExtraFormSliding: PropTypes.func.isRequired,
    setTaxEntityCode: PropTypes.func.isRequired,
    setTaxEntityCodeBase: PropTypes.func.isRequired,
    resetExtraForm: PropTypes.func.isRequired,
    setFormEditorTitle: PropTypes.func.isRequired
  }).isRequired,
  payerID: PropTypes.number,
  payerInformation: PropTypes.object.isRequired,
  readOnlyUser: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  hideChangeButton: PropTypes.bool,
  showAddNewPayerButton: PropTypes.bool
};

PayerInformation.defaultProps = {
  payerID: 0,
  hideChangeButton: false
};

export default PayerInformation;
