import { EIN, SSN } from 'helpers/tinConstants';

const currencyFormat = value => {
  if (isNaN(value)) {
    return value;
  } else {
    return `$ ${Number(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
  }
};

const currencyFormatTable = value => {
  if (isNaN(value)) {
    return value;
  } else {
    return `${Number(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
  }
};

const numberThousandSeperatorFormat = value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const formatEin = tin => `${tin.substr(0, 2)}-${tin.substr(2)}`;
const formatSsn = tin => `${tin.substr(0, 3)}-${tin.substr(3, 2)}-${tin.substr(5)}`;

const formatTin = (tin, tintype) => {
  switch (tintype?.toString()) {
    case EIN:
      return formatEin(tin);
    case SSN:
      return formatSsn(tin);
    default:
      return tin;
  }
};

const splitFullName = fullName => {
  if (!fullName) {
    return ['', ''];
  }
  const nameArray = fullName.trim().split(' ');

  if (nameArray.length > 1) {
    return [
      nameArray.slice(0, -1).join(' '),
      nameArray.slice(-1)[0]
    ];
  }

  return [nameArray[0], ''];
};

export {
  currencyFormat,
  currencyFormatTable,
  numberThousandSeperatorFormat,
  formatEin,
  formatSsn,
  formatTin,
  splitFullName
};
