import { lazy, Suspense, Component } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import theme from 'mosaic-react/themes/sovos-theme';
const DNDDraggable = lazy(() => import(/* webpackChunkName: "Draggable" */ './DraggableInterface'));

const { palette } = theme;

const style = {
  boxShadow: 'rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px'
};

class Draggable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOver: false
    };
  }

  getContainerStyle = () => ({
    boxShadow: this.state.isOver ? style.boxShadow : '',
    backgroundColor: this.state.isOver ? this.props.hoverColor : '',
    ...this.props.containerStyle
  });

  getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    display: 'flex',
    alignItems: isDragging ? 'flex-start' : 'center',
    background: isDragging ? this.props.draggingColor.withDragging : this.props.draggingColor.withoutDragging,
    boxShadow: isDragging ? style.boxShadow : '',
    height: this.props.dragStyle.height,
    ...draggableStyle,
    ...this.props.style
  });

  getSvgStyle = () => ({
    backgroundColor: 'transparent'
  });

  handleOnMouseOver = () => this.setState({ isOver: !this.props.isDragDisabled });

  handleOnMouseOut = () => this.setState({ isOver: false });

  render() {
    const { children, dragKey, index, isDragDisabled } = this.props;
    return (
      <>
        <Suspense fallback={ <LinearProgress /> }>
          <DNDDraggable
            key={ dragKey }
            draggableId={ dragKey }
            index={ index }
            isDragDisabled={ isDragDisabled }
          >
            {
              (provided, snapshot) => (
                <div
                  style={ this.getContainerStyle() }
                >
                  <div
                    ref={ provided.innerRef }
                    { ...provided.draggableProps }
                    { ...provided.dragHandleProps }
                    style={ this.getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    ) }
                    onMouseOver={ this.handleOnMouseOver }
                    onMouseOut={ this.handleOnMouseOut }
                  >
                    <div
                      style={ this.getSvgStyle() }
                    >
                      <svg style={ { width: 24, height: 24 } } viewBox="0 0 24 24">
                        <path
                          fill={ this.state.isOver || snapshot.isDragging ? palette.text.primary : palette.text.contrast }
                          d="M9,3H11V5H9V3M13,3H15V5H13V3M9,7H11V9H9V7M13,7H15V9H13V7M9,11H11V13H9V11M13,11H15V13H13V11M9,15H11V17H9V15M13,15H15V17H13V15M9,19H11V21H9V19M13,19H15V21H13V19Z" /* eslint-disable-line max-len */
                        />
                      </svg>
                    </div>
                    <div style={ { width: '100%' } }>
                      { children }
                    </div>
                  </div>
                  { provided.placeholder }
                </div>
              )
            }
          </DNDDraggable>
        </Suspense>
      </>
    );
  }
}

Draggable.defaultProps = {
  isDragDisabled: false,
  style: {},
  containerStyle: {},
  draggingColor: {
    withDragging: '#f1eff7',
    withoutDragging: ''
  },
  hoverColor: '#f1eff7',
  dragStyle: {
    height: 35
  }
};

Draggable.propTypes = {
  children: PropTypes.element.isRequired,
  dragKey: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isDragDisabled: PropTypes.bool,
  style: PropTypes.object,
  draggingColor: PropTypes.shape({
    withDragging: PropTypes.string,
    withoutDragging: PropTypes.string
  }),
  containerStyle: PropTypes.object,
  hoverColor: PropTypes.string,
  dragStyle: PropTypes.object
};

export default Draggable;
