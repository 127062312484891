const applyMaskForSSN = (value, showValue) => (
  showValue ? `${value.substr(0, 3)}-${value.substr(3, 2)}-${value.substr(5, 4)}` : `XXX-XX-${value.substr(5, 4)}`
);

const applyMaskForEIN = (value, showValue) => (
  showValue ? `${value.substr(0, 2)}-${value.substr(2)}` : `XX-XXX${value.substr(5, 4)}`
);

const applyMaskForUnknown = (value, showValue) => (
  showValue ? value : `XXXXX${value.substr(5, 4)}`
);

export const determineMask = (value, tinType, showValue ) => {
  switch (tinType?.toString()) {
    case '1':
      return applyMaskForEIN(value, showValue);
    case '2':
      return applyMaskForSSN(value, showValue);
    default:
      return applyMaskForUnknown(value, showValue);
  }
};