import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setFieldValue, overrideFormModified } from 'shared/dataEntryForms/sovos-dynamic-form/redux/DynamicFormActions';
import AutoCalculatedField from './AutoCalculatedField';

const mapStateToProps = ({ dynamicForm, payer }, ownProps) => ({
  isClone: dynamicForm.isClone,
  form: dynamicForm.isClone ? dynamicForm.cloneForm : (dynamicForm.form[ownProps.taxEntityCode] || {}),
  payerInformation: payer.data
});

const mapDispatchToProps = dispatch => ({
  formActions: bindActionCreators({ setFieldValue, overrideFormModified }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoCalculatedField);
