import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

export function determineMask(maskProp) {
  let mask = '';
  let placeholder = '';
  let pipe = null;

  switch (maskProp) {
    case 'EIN':
      mask = [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
      placeholder = '99-9999999';
      break;
    case 'SSN':
      mask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
      placeholder = '999-99-9999';
      break;
    case 'TIN':
      mask = [/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
      placeholder = '999999999';
      break;
    case 'DATE':
      mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
      placeholder = 'MM/DD/YYYY';
      pipe = createAutoCorrectedDatePipe('mm/dd/yyyy');
      break;
    case 'DATERANGE':
      mask = [/[0-1]/, /\d/, '/', /[0-3]/, /\d/, '/', /[2]/, /[0]/, /\d/, /\d/, '-', /[0-1]/, /\d/, '/', /[0-3]/, /\d/, '/', /[2]/, /[0]/, /\d/, /\d/];
      placeholder = 'MM/DD/YYYY - MM/DD/YYYY';
      break;
    default:
      mask = undefined;
      break;
  }

  return { mask, placeholder, pipe };
}

export function cleanMask(value, maskProp) {

  switch (maskProp) {

    case 'EIN':
    case 'SSN':
      return value.replace(/[- ]+/g, '').replace(/[_ ]+/g, '');

    default:
      return value;

  }
}
