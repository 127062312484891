import actionTypes from './FileManagerFilesConstants';
import { calcFileSize, processFiles } from './fileManagerHelper';

const initialStore = {
  fetchingOverview: false,
  fetchingFiles: false,
  deletingFile: false,
  renamingFile: false,
  uploadingFile: false,
  downloadingFiles: {},
  downloadingAll: false,
  downloadFileFailed: false,
  files: [],
  currentPage: 1,
  from: 1,
  lastPage: 1,
  pageSize: 1,
  to: 1,
  totalItems: 1,
  totalFilesSize: '0 Bytes',
  errorMessage: ''
};

const FileManagerFilesReducer = (state = initialStore, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_OVERVIEW:
      return { ...state,
        fetchingOverview: true };

    case actionTypes.FETCH_OVERVIEW_SUCCESS:
      return {
        ...state,
        totalFilesSize: calcFileSize(action.overview.UsedSpace),
        fetchingOverview: false,
        errorMessage: ''
      };

    case actionTypes.RESET_PAGE_SETTINGS:
      return { ...state, currentPage: 1 };

    case actionTypes.FETCH_OVERVIEW_FAILURE:
      return { ...state, fetchingOverview: false, errorMessage: action.error };

    case actionTypes.FETCHING_FILES:
      return { ...state, fetchingFiles: true };

    case actionTypes.FETCH_FILES_SUCCESS:
      return (action.filesInfo)? {
        ...state,
        fetchingFiles: false,
        files: processFiles(action.filesInfo.Data).files,
        currentPage: action.filesInfo.CurrentPage,
        from: action.filesInfo.From,
        lastPage: action.filesInfo.LastPage,
        to: action.filesInfo.To,
        totalItems: action.filesInfo.Total,
        pageSize: action.filesInfo.PageSize,
        errorMessage: ''
      } : {
        ...state,
        fetchingFiles: false,
        files: [],
        currentPage: 1,
        from: 0,
        lastPage: 0,
        to: 0,
        totalItems: 0,
        pageSize: 0,
        errorMessage: ''
      };

    case actionTypes.FETCH_FILES_FAILURE:
      return {
        ...initialStore,
        fetchingFiles: false,
        errorMessage: action.error
      };

    case actionTypes.UPLOADING_FILE:
      return { ...state, uploadingFile: true };

    case actionTypes.UPLOADING_FILE_SUCCESS:
      return { ...state, uploadingFile: false, errorMessage: '' };

    case actionTypes.DELETING_FILE:
      return { ...state, deletingFile: true };

    case actionTypes.DELETE_FILE_SUCCESS:
      return { ...state, deletingFile: false, errorMessage: '' };

    case actionTypes.DELETE_FILE_FAILURE:
      return { ...state, deletingFile: false, errorMessage: action.error };

    case actionTypes.RENAMING_FILE:
      return { ...state, renamingFile: true };

    case actionTypes.RENAME_FILE_SUCCESS:
      return { ...state, renamingFile: false, errorMessage: '' };

    case actionTypes.RENAME_FILE_FAILURE:
      return { ...state, renamingFile: false, errorMessage: action.error };

    case actionTypes.DOWNLOADING_FILE:
      return {
        ...state,
        downloadingFiles: {
          ...state.downloadingFiles,
          [action.fChar]: true
        }
      };

    case actionTypes.DOWNLOAD_FILE_SUCCESS:
      return {
        ...state,
        downloadFileFailed: false,
        downloadingFiles: {
          ...state.downloadingFiles,
          [action.fChar]: false
        }
      };

    case actionTypes.DOWNLOAD_FILE_FAIL:
      return {
        ...state,
        downloadFileFailed: true,
        downloadingFiles: {
          ...state.downloadingFiles,
          [action.fChar]: false
        }
      };

    case actionTypes.DOWNLOADING_ALL:
      return {
        ...state,
        downloadingAll: true
      };

    case actionTypes.RESET_DOWNLOAD_FILE_STATUS:
      return {
        ...state,
        downloadFileFailed: false
      };

    case actionTypes.RESET_DOWNLOAD_ALL_STATUS:
      return {
        ...state,
        downloadingAll: false
      };

    case actionTypes.LOGOUT_SUCCESS:
      return { ...state, editedState: initialStore };

    default:
      return state;
  }
};

export default FileManagerFilesReducer;
