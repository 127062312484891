const isEmpty = value => {
  if (value === null || value === undefined) {
    return '';
  }
  return value;
};

export const buildUrlsParams = params => {
  const searchParams = new URLSearchParams();
  Object.keys(params).forEach(key => {
    if (Array.isArray(params[key])) {
      params[key].forEach(value => searchParams.append(key, isEmpty(value)));
    } else {
      searchParams.append(key, isEmpty(params[key]));
    }
  });
  return searchParams.toString();
};