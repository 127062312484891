export default [
  {
    title: 'Record type',
    id: 1,
    sortIndicator: 'FormType',
    dataKey: 'FormType'
  },
  {
    title: 'No. of records',
    align: 'right',
    id: 2,
    sortIndicator: 'TotalRecords',
    dataKey: 'TotalRecords'
  },
  {
    title: 'Errors',
    align: 'right',
    id: 3,
    width: 'greedy',
    sortIndicator: 'ErrorCount',
    dataKey: 'ErrorCount'
  }
];
