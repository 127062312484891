import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TotalFormsByTypeTable from './TotalFormsByTypeTable';
import { fetchRecordsByForm } from 'redux/recordsSummary/RecordsSummaryActions';
import { fetchMetadata } from 'shared/dataEntryForms/sovos-dynamic-form/redux/FormMetaInformationActions';
import { setFormId, setTaxEntityCode, setTaxEntityCodeBase } from 'shared/dataEntryForms/sovos-dynamic-form/redux/DynamicFormActions';

const mapStateToProps = state => ({
  fetchingRecordsByForm: state.recordsSummary.fetchingRecordsByForm,
  recordsByForm: state.recordsSummary.recordsByForm
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchRecordsByForm }, dispatch),
  formActions: bindActionCreators({ setFormId, setTaxEntityCode, setTaxEntityCodeBase }, dispatch),
  formMetaInformationActions: bindActionCreators({ fetchMetadata }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TotalFormsByTypeTable);
