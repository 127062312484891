import AuthenticationHelper from 'helpers/authenticationHelper';
import { TIR_C_REPRINT_LOCALLY } from 'helpers/permissionConstants';

const isOptionDisabled = permission => !AuthenticationHelper.checkSomeUserPermissions(permission);

const reprintMenuItems = {
  reprintRecord: 'Reprint record',
  unmarkReprintedRecord: 'Unmark reprinted record'
};

const reprintDialogOptions = {
  reprintRecord: {
    title: 'Reprint record',
    options: [
      {
        value: 'availableReprint',
        label: 'Mark as available to reprint',
        disabled: false
      },
      {
        value: 'printLocally',
        label: `Download and mark as 'Printed locally'`,
        disabled: isOptionDisabled(TIR_C_REPRINT_LOCALLY)
      }
    ]
  },
  unmarkRecord: {
    title: 'Unmark record for reprint',
    options: []
  },
  alreadyPrinted: {
    title: 'Form already printed',
    options: [
      {
        value: 'availableReprint',
        label: 'Mark as available to reprint',
        disabled: false
      },
      {
        value: 'printLocally',
        label: `Download and mark as 'Printed locally'`,
        disabled: isOptionDisabled(TIR_C_REPRINT_LOCALLY)
      },
      {
        value: 'updateAndIgnore',
        label: 'Update and ignore reprint',
        disabled: false
      }
    ]
  }
};

export {
  reprintMenuItems,
  reprintDialogOptions
};