import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SovosTableGroup from 'mosaic-react/sovos-table-group';
import SlidingFormEditor from 'shared/dataEntryForms/slidingFormEditor/SlidingFormEditorConnector';
import useColumnSort from 'helpers/useColumnSort';
import WithColumnsByProduct from './WithColumnsByProduct';
import totalFormsTableColumns from './TotalFormsTableColumns';

const TotalFormsByTypeTable = ({ columns, fChar, recordsByForm: { Data, Total }, formActions, formMetaInformationActions, actions, fetchingRecordsByForm }) => {
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [openFormSlidingPanel, setOpenFormSlidingPanel] = useState(false);
  const [searchText, setSearchText] = useState('');

  const {
    sortOrderIndicator,
    sortColumnIndicator,
    sortColumnId,
    onColumnSortChanged
  } = useColumnSort(totalFormsTableColumns.find(c => c.dataKey === 'ErrorCount'), false);

  useEffect(() => {
    formMetaInformationActions.fetchMetadata(fChar);
  }, []);

  useEffect(() => {
    if (!openFormSlidingPanel) {
      actions.fetchRecordsByForm(fChar, itemsPerPage, currentPage, searchText, sortColumnIndicator, sortOrderIndicator);
    }
  }, [fChar, itemsPerPage, currentPage, searchText, sortColumnIndicator, sortOrderIndicator]);

  const handlePerPageChange = (currentPage, itemsPerPage) => {
    setItemsPerPage(itemsPerPage);
    setCurrentPage(currentPage);
  };

  const handleRowClick = row => {
    formActions.setTaxEntityCode(fChar);
    formActions.setTaxEntityCodeBase(fChar);
    formActions.setFormId(row.FormId);
    setOpenFormSlidingPanel(true);
  };

  const handleSearch = _searchText => {
    setCurrentPage(1);
    setSearchText(_searchText);
  };

  return (
    <div>
      <div className="record-sliding-panel__margin">
        {
          Data &&
          <SovosTableGroup
            isLoading={ fetchingRecordsByForm }
            noResults={ {
              headingLabel: `No records available matching search term: ${searchText}`,
              helpLabel: 'Please try a new search'
            } }
            TableProps={ {
              columns,
              data: Data,
              onRowClick: handleRowClick,
              onColumnSort: onColumnSortChanged,
              columnSortId: sortColumnId
            } }
            PaginationFooterProps={ {
              currentPage,
              itemsPerPage,
              totalItems: Total,
              onPageChange: handlePerPageChange
            } }
            search={ {
              term: searchText,
              onSearch: handleSearch
            } }
          />
        }
      </div>
      <SlidingFormEditor
        openFormSlidingPanel={ openFormSlidingPanel }
        onCloseSlidingPanel={ () => setOpenFormSlidingPanel(false) }
        mountDialog
      />
    </div>
  );
};

TotalFormsByTypeTable.propTypes = {
  columns: PropTypes.array.isRequired,
  fChar: PropTypes.string.isRequired,
  recordsByForm: PropTypes.object.isRequired,
  formActions: PropTypes.shape({
    setFormId: PropTypes.func,
    setTaxEntityCode: PropTypes.func,
    setTaxEntityCodeBase: PropTypes.func
  }).isRequired,
  formMetaInformationActions: PropTypes.shape({
    fetchMetadata: PropTypes.func
  }),
  actions: PropTypes.shape({
    fetchRecordsByForm: PropTypes.func
  }).isRequired,
  fetchingRecordsByForm: PropTypes.bool.isRequired
};

export default WithColumnsByProduct(TotalFormsByTypeTable);
