import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'enhancers';
import DynamicFormComponent from './dynamicFormComponent/DynamicFormComponent';
import { addFormHelper } from 'helpers/formHelper';
import RefreshIndicatorLoading from 'shared/refreshIndicatorLoading/refreshIndicatorLoading';
import { TIR_U_RECORD_PAYER, TIR_U_RECORD_PAYEE, TIR_U_RECORD_NON_WITHHOLDING } from 'helpers/permissionConstants';
import { payerFchar, recipient1042SFchar, recipientFchar, the1042SFchar } from 'helpers/formConstants';
import authenticationHelper from 'helpers/authenticationHelper';
import './sovosDynamicForm.scss';

const SovosDynamicForm = ({
  taxEntityCode,
  form,
  isClone,
  formMetadata,
  selects,
  fetchingForm,
  savingForm,
  fetchingSelects,
  fetchingMetadata,
  forceReadOnly,
  metadataActions,
  selectFieldOptionsActions }) => {

  const idDynamicForm = isClone ? 'sovosDynamicFormClone' : 'sovosDynamicForm';

  useEffect(() => {
    if (taxEntityCode && !formMetadata.hasOwnProperty(taxEntityCode) && !fetchingMetadata) {
      metadataActions.fetchMetadata(taxEntityCode);
    }

    if (!Object.keys(selects).length && [payerFchar, recipientFchar, recipient1042SFchar, the1042SFchar].includes(taxEntityCode)) {
      selectFieldOptionsActions.fetchProvinceCountryCode();
    }

    addFormHelper(idDynamicForm, 'saveForm');
  }, []);

  const getFieldValue = field => form[field];

  return (
    !fetchingForm && !fetchingMetadata && !fetchingSelects && Object.keys(formMetadata[taxEntityCode] || {}).length > 0 && !savingForm
      ? <div className='sovos-dynamic-form'>
        <DynamicFormComponent
          taxEntityCode={ taxEntityCode }
          metadata={ formMetadata[taxEntityCode] }
          getFieldValue={ getFieldValue }
          type='root'
          readOnlyUser={ !authenticationHelper.checkSomeUserPermissions(TIR_U_RECORD_PAYER, TIR_U_RECORD_PAYEE, TIR_U_RECORD_NON_WITHHOLDING) }
          forceReadOnly={ forceReadOnly }
        />
      </div>
      : <RefreshIndicatorLoading containerStyle={ { position: 'relative', marginTop: 25 } }/>
  );
};

SovosDynamicForm.propTypes = {
  taxEntityCode: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  isClone: PropTypes.bool.isRequired,
  formMetadata: PropTypes.object.isRequired,
  selects: PropTypes.object.isRequired,
  fetchingForm: PropTypes.bool.isRequired,
  savingForm: PropTypes.bool.isRequired,
  fetchingSelects: PropTypes.bool.isRequired,
  forceReadOnly: PropTypes.bool,
  fetchingMetadata: PropTypes.bool.isRequired,
  metadataActions: PropTypes.shape({
    fetchMetadata: PropTypes.func
  }).isRequired,
  selectFieldOptionsActions: PropTypes.shape({
    fetchProvinceCountryCode: PropTypes.func
  })
};

export default withSnackbar(SovosDynamicForm);
