import sharedActions from 'redux/constants/SharedConstants';

const actionTypes = {
  ...sharedActions,
  FETCHING_ENTITIES_BY_PAYEE: 'FETCHING_ENTITIES_BY_PAYEE',
  FETCH_ENTITIES_BY_PAYEE_SUCCESS: 'FETCH_ENTITIES_BY_PAYEE_SUCCESS',
  FETCH_ENTITIES_BY_PAYEE_FAILURE: 'FETCH_ENTITIES_BY_PAYEE_FAILURE',
  RESET_ENTITIES_BY_PAYEE: 'RESET_ENTITIES_BY_PAYEE'
};

export default actionTypes;
