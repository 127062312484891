import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import GetAppIcon from '@mui/icons-material/GetApp';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import SovosTableGroup from 'mosaic-react/sovos-table-group';
import DynamicFormHelper from 'shared/dataEntryForms/sovos-dynamic-form/DynamicFormHelper';
import SlidingFormEditor from 'shared/dataEntryForms/slidingFormEditor/SlidingFormEditorConnector';
import ColumnSelector from 'shared/columnsSelector/ColumnSelectorConnector';
import { getKey } from 'helpers/formConstants';
import { withSnackbar } from 'enhancers';
import { getErrorMessage } from 'helpers/errorMessageHelper';
import { FE_FECHING_RECORDS_001 } from 'helpers/errorMessageConstants';
import { TIR_C_REPORT } from 'helpers/permissionConstants';
import authenticationHelper from 'helpers/authenticationHelper';
import withRouter from 'helpers/withRouter';

const defaultSortColumn = { sortIndicator: 'ERRORVALIDATIONCOUNT', id: '' };

const getOrderIndicator = isAscending => {
  if (isAscending === null) {
    return '';
  }

  return isAscending ? '+' : '-';
};

class RecordTable extends Component {
  state = {
    itemsPerPage: 20,
    currentPage: 1,
    searchText: '',
    enabledFields: [],
    disabledFields: [],
    openFormSlidingPanel: false,
    openColumnsSelector: false,
    saveNewOrder: false,
    sortColumn: defaultSortColumn,
    isAscending: false
  };

  componentDidMount() {
    this.fetchRecords();
    this.props.formMetaInformationActions.fetchMetadata(this.props.fchar);
    this.props.formMetaInformationActions.fetchTableOptions(this.props.fchar);
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.formMetadata &&
      props.enabledFields &&
      Object.keys(props.enabledFields).length &&
      !state.enabledFields.length
    ) {
      const enabledFields = DynamicFormHelper.getColumnLabels(props.enabledFields, props.formMetadata, true);
      const disabledFields = DynamicFormHelper.getColumnLabels(props.disabledFields, props.formMetadata, false);

      return {
        enabledFields,
        disabledFields,
        sortColumn: { ...defaultSortColumn, id: enabledFields.findIndex(field => field.key === 'ERRORVALIDATIONCOUNT') }
      };
    }

    return null;
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.shouldFetchAgain && this.props.shouldFetchAgain)
      || (prevProps.deleting && !this.props.deleting)
    ) {
      this.fetchRecords(this.state.currentPage);
    }
    if (!prevProps.fetchingFailed && this.props.fetchingFailed) {
      this.props.showErrorSnackbar({ message: getErrorMessage(null, FE_FECHING_RECORDS_001) });
    }
  }

  componentWillUnmount() {
    this.props.recordActions.resetRecords();
  }

  handlePerPageChange = (selectedPage, itemPerPage) => {
    this.setState({
      itemsPerPage: itemPerPage,
      currentPage: selectedPage
    }, () => this.fetchRecords(selectedPage)
    );
  };

  fetchRecords = (page = 1) => {
    const sortOrderIndicator = getOrderIndicator(this.state.isAscending);
    this.props.customFetchRecords
      ? this.props.customFetchRecords(
        this.state.searchText,
        page,
        this.state.itemsPerPage,
        this.state.sortColumn.sortIndicator,
        sortOrderIndicator
      )
      : this.props.recordActions.fetchAllRecords(
        this.state.searchText,
        page,
        this.state.itemsPerPage,
        this.state.sortColumn.sortIndicator,
        sortOrderIndicator,
        this.props.isTestImport
      );
  };

  handleRowClick = row => {
    this.props.formActions.setTaxEntityCode(this.props.fchar);
    this.props.formActions.setTaxEntityCodeBase(this.props.fchar);
    this.props.formActions.setFormId(this.props.records[row.index][getKey(this.props.fchar)]);
    this.props.router.navigate('/data-entry');
  };

  handleCloseFormSlidingPanel = () => this.setState({ openFormSlidingPanel: false }, () => {
    if (!this.props.records.length) {
      this.props.onDetailClose();
    }
  });

  handleOnSearch = value => this.setState(
    { searchText: value },
    () => this.fetchRecords()
  );

  onColumnSortChanged = newColumn => {
    const isAscending = this.state.sortColumn.id === newColumn.id ? !this.state.isAscending : true;
    this.setState({ sortColumn: newColumn, isAscending, currentPage: 1 }, () => this.fetchRecords());
  };

  handleColumnSelectorClick = () => this.setState({ openColumnsSelector: true });

  handleColumnsSelectorClose = () => this.setState({ openColumnsSelector: false });

  handleSearchAgain = () => {
    this.setState({ enabledFields: [], disabledFields: [], saveNewOrder: false });
    this.props.formMetaInformationActions.fetchTableOptions(this.props.fchar);
    this.fetchRecords();
  };

  handleApplyClick = () => this.setState({ saveNewOrder: true, openColumnsSelector: false });

  handleDownload = () => {
    if (this.props?.onExportClick) {
      this.props.onExportClick(this.props.fchar, this.state.searchText);
    } else {
      this.props.recordActions.exportRecords(this.props.recordType, 'accepted', '', '', '', this.props.job, this.props.isTestImport);
    }
  };

  getValues = () => (
    !this.props.showEmptyTable
      ? this.props.formActions.getTableValues(this.state.enabledFields.map(field => ({ key: field.key, modifier: field.dataModifier || null })), this.props.records, this.props.fchar)
      : []
  );

  getToolbarActions = () => {
    const toolbarActions = [];
    this.props.enableColumnSelector && toolbarActions.push({ icon: <ViewWeekIcon />, onClick: this.handleColumnSelectorClick, tooltipText: 'Columns selector' });
    authenticationHelper.checkAllUserPermissions([TIR_C_REPORT]) && toolbarActions.push({ icon: <GetAppIcon />, onClick: this.handleDownload, tooltipText: 'Download' });
    return toolbarActions;
  };

  render() {
    const {
      fetchingRecords,
      fetchingTableOptions,
      fetchingMetadata,
      fchar,
      total,
      isUpdating,
      toolbarTitle,
      enableSearch,
      isTestImport
    } = this.props;

    return (
      <>
        <SovosTableGroup
          isLoading={ !this.state.enabledFields.length || fetchingRecords || fetchingTableOptions || fetchingMetadata || isUpdating }
          toolbarTitle={ toolbarTitle }
          noResults={ this.state.searchText
            ? {
              headingLabel: `No records available matching search term: ${this.state.searchText}`,
              helpLabel: 'Please try a new search'
            }
            : {
              headingLabel: 'No records found',
              helpLabel: 'Please try a different group'
            }
          }
          TableProps={ {
            columns: DynamicFormHelper.getCustomizedColumns(this.state.enabledFields),
            data: this.getValues(),
            onRowClick: isTestImport ? null : this.handleRowClick,
            onColumnSort: this.state.sortColumn.id !== -1 ? this.onColumnSortChanged : null,
            columnSortId: this.state.sortColumn.id !== -1 ? this.state.sortColumn.id : null
          } }
          PaginationFooterProps={ {
            currentPage: this.state.currentPage,
            itemsPerPage: this.state.itemsPerPage,
            totalItems: total,
            onPageChange: this.handlePerPageChange
          } }
          search={
            enableSearch
              ? {
                term: this.state.searchText,
                onSearch: this.handleOnSearch
              }
              : null
          }
          actionIconButtons={ this.getToolbarActions() }
        />
        <SlidingFormEditor
          onCloseSlidingPanel={ this.handleCloseFormSlidingPanel }
          openFormSlidingPanel={ this.state.openFormSlidingPanel }
          mountDialog
        />
        {
          this.state.enabledFields.length > 0 &&
          <ColumnSelector
            open={ this.state.openColumnsSelector }
            visibleColumns={ this.state.enabledFields }
            hiddenColumns={ this.state.disabledFields }
            fchar={ fchar }
            onApplyClick={ this.handleApplyClick }
            onClose={ this.handleColumnsSelectorClose }
            onSaveChangesSuccess={ this.handleSearchAgain }
            saveNewOrder={ this.state.saveNewOrder }
          />
        }
      </>
    );
  }
}

RecordTable.propTypes = {
  job: PropTypes.number,
  toolbarTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  type: PropTypes.string,
  records: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  fetchingRecords: PropTypes.bool.isRequired,
  fetchingFailed: PropTypes.bool.isRequired,
  fetchingTableOptions: PropTypes.bool.isRequired,
  fetchingMetadata: PropTypes.bool.isRequired,
  shouldFetchAgain: PropTypes.bool.isRequired,
  fchar: PropTypes.string.isRequired,
  enabledFields: PropTypes.object.isRequired,
  disabledFields: PropTypes.object.isRequired,
  formMetadata: PropTypes.object.isRequired,
  sortColumn: PropTypes.string,
  sortOrder: PropTypes.string,
  recordActions: PropTypes.shape({
    fetchAllRecords: PropTypes.func,
    resetRecords: PropTypes.func,
    exportReports: PropTypes.func
  }).isRequired,
  formMetaInformationActions: PropTypes.shape({
    fetchTableOptions: PropTypes.func,
    fetchMetadata: PropTypes.func
  }).isRequired,
  formActions: PropTypes.shape({
    setFormId: PropTypes.func,
    getTableValues: PropTypes.func,
    setTaxEntityCode: PropTypes.func,
    setTaxEntityCodeBase: PropTypes.func
  }).isRequired,
  router: PropTypes.shape({
    navigate: PropTypes.func.isRequired
  }).isRequired,
  isUpdating: PropTypes.bool,
  isTestImport: PropTypes.bool,
  customFetchRecords: PropTypes.func,
  onDetailClose: PropTypes.func,
  onExportClick: PropTypes.func,
  enableColumnSelector: PropTypes.bool,
  enableSearch: PropTypes.bool,
  showEmptyTable: PropTypes.bool,
  showErrorSnackbar: PropTypes.func.isRequired,
  validationId: PropTypes.number
};

RecordTable.defaultProps = {
  job: null,
  isUpdating: false,
  isTestImport: false,
  customFetchRecords: null,
  onDetailClose: () => null,
  sortColumn: '',
  type: '',
  enableColumnSelector: true,
  enableSearch: true,
  toolbarTitle: '',
  showEmptyTable: false,
  validationId: null
};

export default withRouter(withSnackbar(RecordTable));
