import types from './DynamicFormConstants';
import { get, patch, post, remove } from 'helpers/axios/axiosInterceptorsV1';
import { get as getV2, patch as patchV2, post as postV2, remove as removeV2, put as putV2 } from 'helpers/axios/axiosInterceptorsV2';
import { getBasePath, getKey, payeesFChars, theW2Fchar, the499R2Fchar, theW2MFchar, recipientFchar, payerFchar, recipient1042SFchar, noFormsFchars, mailingAddressAutoCheckBoxFields } from 'helpers/formConstants';
import { clearPayer } from 'redux/payer/PayerActions';
import { clearRecipient, setRecipientBoolean } from 'redux/recipient/RecipientActions';
import { updateAction as updateActionEnum } from 'helpers/enums';
import { clearNRecipient } from 'redux/nrecipient/NRecipientActions';
import DynamicFormHelper from '../DynamicFormHelper';
import {
  BE_TAX_ENTITY_SERVICE_100003,
  BE_TAX_ENTITY_SERVICE_140000,
  BE_TAX_ENTITY_SERVICE_150001,
  BE_TAX_ENTITY_SERVICE_V2_100109,
  BE_TAX_ENTITY_SERVICE_V2_100503,
  BE_TAX_ENTITY_SERVICE_130008
} from 'helpers/errorMessageConstants';
import { getErrorMessage } from 'helpers/errorMessageHelper';
import auth from 'helpers/authenticationHelper';
import sovosFunctions from 'helpers/sovosFunctions';
import { productTypeCode as product } from 'helpers/enums';
import { buildUrlsParams } from 'helpers/buildUrlParams';

const postAction = fchar => fchar === theW2MFchar ? postV2 : post;

const patchAction = fchar => fchar === theW2MFchar ? patchV2 : patch;

const getAction = fchar => fchar === theW2MFchar ? getV2 : get;

const removeAction = fchar => fchar === theW2MFchar ? removeV2 : remove;

const fetchFormSuccess = (form, fchar, metadataFields, previousValue, formPrinted, enableReprint) => ({ type: types.FETCH_FORM_SUCCESS, form, fchar, metadataFields, previousValue, formPrinted, enableReprint });
const saveFormSuccess = (form, fchar) => ({ type: types.SAVE_FORM_SUCCESS, form, fchar });
const fetchingForm = () => ({ type: types.FETCHING_FORM });

const overrideFormModified = () => dispatch => dispatch({ type: types.OVERRIDE_FORM_MODIFIED });

const setFieldValue = (fieldName, fieldValue, isClone, taxEntityCode, isDefault) => dispatch => {
  const type = isClone
    ? types.SET_FIELD_VALUE_CLONE
    : types.SET_FIELD_VALUE;

  dispatch({ type, fieldName, fieldValue, taxEntityCode, isDefault });
};

const setFormValue = form => dispatch => dispatch({ type: types.SET_FORM_VALUE, form });

const setFormErrors = formErrors => dispatch => dispatch({ type: types.SET_FORM_ERRORS, formErrors });

const setFetchingForm = fetchingForm => dispatch => dispatch({ type: types.SET_FETCHING_FORM, fetchingForm });

const setSavingForm = savingForm => dispatch => dispatch({ type: types.SAVING_FORM, savingForm });

const setRecipientPayer = (form, updateBaseForm = false) => (dispatch, getState) => {
  const state = getState();
  const taxEntityCode = updateBaseForm ? state.dynamicForm.taxEntityCodeBase : state.dynamicForm.taxEntityCode;
  dispatch({ type: types.ASSIGNED_RECIPIENT_PAYER, form, taxEntityCode, updateBaseForm });
};

const setSaveFormSuccess = saveFormSuccess => dispatch => dispatch({ type: types.SET_SAVE_FORM_SUCCESS, saveFormSuccess });

const getAllFieldsFromMetadata = (state, fchar) => state.formMetaInformation.allFields[fchar];

const fetchForm = (id, fchar) => async (dispatch, getState) => {
  dispatch(fetchingForm());
  const path = `/${getBasePath(fchar)}/${id}`;
  try {
    const response = await getAction(fchar)(path);

    let formInfo = { ...response.data };
    let isAnyW2MStateOrLocalityCorreted = false;

    if (fchar === theW2MFchar) {
      const formFilingStatus = (await get(`transmits/forms/${id}/filing-status?taxEntityType=${fchar}`)).data.data;
      formInfo.Entity = DynamicFormHelper.injectFormFilingStatusToFormInfo(formInfo.Entity, formFilingStatus);
      formInfo.Entity = DynamicFormHelper.cleanW2MResponse(formInfo.Entity);
      const supportedTrantypes = DynamicFormHelper.supportedTrantypes;
      isAnyW2MStateOrLocalityCorreted = formInfo.Entity.States?.some(state => supportedTrantypes.includes(state.ReturnStatus)) || formInfo.Entity.Localities?.some(locality => supportedTrantypes.includes(locality.ReturnStatus));
    }

    if (noFormsFchars.includes(fchar)){
      formInfo.Entity.MailingAddressAutoCheckBox = mailingAddressAutoCheckBoxFields.some(field => formInfo.Entity[field] && formInfo.Entity[field] !== '');
    }

    let previousValues = {};

    if (DynamicFormHelper.showAmountDifferenceField(formInfo.Entity.TranType, fchar) || isAnyW2MStateOrLocalityCorreted) {
      if (fchar === theW2MFchar) {
        let responsePreviousValues = (await getV2(`forms/W-2M/${id}/original`)).data.Entity;
        responsePreviousValues = DynamicFormHelper.cleanW2MResponse(responsePreviousValues);
        previousValues = sovosFunctions.ObjectKeysToUppercase(responsePreviousValues);
      } else {
        previousValues = (await get(`transmits/forms/${id}/last-transmit?fchar=${fchar}`)).data.data;
      }
    }

    const formPrinted = noFormsFchars.includes(fchar)
      ? false
      : (await get(`prints/forms/${id}/print-status`)).data.data === DynamicFormHelper.printStatus.printed;

    const enableReprint =
      (formPrinted
        && DynamicFormHelper.validateToReprint(fchar)
        && auth.getShortProductName() === product.TIRC)
        ? (await get(`prints/forms/${id}/valid-to-reprint`)).data.data
        : true;

    dispatch(fetchFormSuccess(formInfo, fchar, getAllFieldsFromMetadata(getState(), fchar), previousValues, formPrinted, enableReprint));
  } catch (error) {
    dispatch({ type: types.FETCH_FORM_FAILURE });
    throw (error);
  }
};

const resetForm = () => dispatch => dispatch({ type: types.RESET_FORM });

const resetPayer = () => dispatch => dispatch(clearPayer());

const resetExtraForm = taxEntityCode => dispatch => {
  dispatch(clearPayer());
  dispatch(setRecipientBoolean());
  dispatch({ type: types.RESET_EXTRA_FORM, taxEntityCode });
};

const resetCloneForm = () => dispatch => dispatch({ type: types.RESET_STATE_CLONE_FORM });

const resetToOriginalForm = () => dispatch => dispatch({ type: types.RESET_TO_ORIGINAL_FORM });

const saveForm = (showErrorSnackbar, actionType = updateActionEnum.NotSet ) => async (dispatch, getState) => {
  const state = getState();
  const taxEntityCode = state.dynamicForm.taxEntityCode;
  var form = state.dynamicForm.isClone ? state.dynamicForm.cloneForm : state.dynamicForm.form[taxEntityCode];
  const isUpdate = form.hasOwnProperty('MODLEVEL') || form.hasOwnProperty('VERSION');
  let param = isUpdate ? `/${form[getKey(taxEntityCode)]}` : '';
  const updateAction = auth.canEditOnlyNonTaxFields()
    ? taxEntityCode === theW2MFchar
      ? updateActionEnum.UpdateWithoutCorrection
      : updateActionEnum.Force
    : actionType;
  if (
    form.hasOwnProperty('XMITRUN')
    || payeesFChars.includes(taxEntityCode)
    || taxEntityCode === payerFchar
    || ((form.hasOwnProperty('STXMITIND') || form.hasOwnProperty('LCLXMITIND')) && (theW2Fchar === taxEntityCode || the499R2Fchar === taxEntityCode))
    || auth.canEditOnlyNonTaxFields()
  ) {
    param = taxEntityCode === theW2MFchar
      ? param
      : `${param}?${buildUrlsParams({ actionType: updateAction })}`;
  }

  const path = `/${getBasePath(taxEntityCode)}${param}`;

  if (noFormsFchars.includes(taxEntityCode)){
    if (!form.MAILINGADDRESSAUTOCHECKBOX) {
      mailingAddressAutoCheckBoxFields.forEach(field => delete form[field.toLocaleUpperCase()]);
    }
  } else {
    form = { ...form, FCHAR: taxEntityCode };
  }

  if (taxEntityCode === the499R2Fchar && form.C14 !== 'F' && form.S06 !== undefined ) {
    form = { ...form, S06: '' };
  }

  if (taxEntityCode === theW2MFchar) {
    form = DynamicFormHelper.prepareW2MtoSave(form, updateAction);
  }

  dispatch(setSavingForm(true));

  try {
    let callAction = null;

    if (isUpdate && taxEntityCode === theW2MFchar) {
      callAction = putV2; // PUT is the correct action when we are sending all resource to update
    } else {
      callAction = isUpdate ? patchAction(taxEntityCode) : postAction(taxEntityCode);
    }

    const response = await (callAction(path, form));
    dispatch(saveFormSuccess(form, taxEntityCode));
    setShowCorrectionDialog(false)(dispatch);

    const formId = taxEntityCode === theW2MFchar ? response.data.Entity.Recno : response.data;

    const isClone = state.dynamicForm.isClone;
    if (!isClone && !isUpdate) {
      setFormId(formId)(dispatch);
    }
    switch (taxEntityCode) { //eslint-disable-line default-case
      case payerFchar:
        dispatch(clearPayer());
        break;
      case recipientFchar:
        dispatch(clearRecipient());
        break;
      case recipient1042SFchar:
        dispatch(clearNRecipient());
        break;
    }
    fetchForm(formId, taxEntityCode)(dispatch, getState);
  } catch (ex) {
    dispatch(setSavingForm(false));
    switch (ex.data.code) {
      case BE_TAX_ENTITY_SERVICE_140000:
      case BE_TAX_ENTITY_SERVICE_150001:
      case BE_TAX_ENTITY_SERVICE_V2_100109:
      case BE_TAX_ENTITY_SERVICE_130008:
        setShowCorrectionDialog(true)(dispatch);
        break;

      case BE_TAX_ENTITY_SERVICE_100003:
      case BE_TAX_ENTITY_SERVICE_V2_100503:
        showErrorSnackbar({
          message: parseValidationMessages(ex.data.supportData.ValidationFailures)
        });
        break;

      default:
        showErrorSnackbar({ message: getErrorMessage(ex) });
    }
  }
};

const setFormEditorTitle = title => dispatch => dispatch({ type: types.SET_FORM_EDITOR_TITLE, title });

const setFcharToDelete = fchar => dispatch => dispatch({ type: types.SET_FCHAR_TO_DELETE, fchar });

const setFcharToClone = fchar => dispatch => dispatch({ type: types.SET_FCHAR_TO_CLONE, fchar });

const setFormId = id => dispatch => dispatch({ type: types.SET_FORM_ID, id });

const addNewForm = defaultForm => dispatch => dispatch({ type: types.ADD_NEW_FORM, defaultForm });

const checkConditionToDeleteSuccess = (isReady, canBeDeleted, countFormLinked) => dispatch => dispatch({ type: types.CHECK_CONDITION_DELETE_SUCCESS, isReady, canBeDeleted, countFormLinked });

const checkConditionToDelete = (fchar, idForm) => async (dispatch, getState) => {
  if (!getState().dynamicForm.checkingConditionToDelete) {
    dispatch({ type: types.CHEKING_CONDITION_DELETE });
    if ([noFormsFchars].includes(fchar)) {
      const path = `/${getBasePath(fchar)}/${idForm}`;
      try {
        const responses = await Promise.all([
          get(`${path}/can-delete`),
          get(`${path}/linked-forms-count`)
        ]);
        dispatch(checkConditionToDeleteSuccess(true, responses[0].data, responses[1].data));
      } catch (error) {
        throw (error);
      }
    } else {
      setTimeout(() => {
        dispatch(checkConditionToDeleteSuccess(false, true, 0));
      }, 0);
    }
  }
};

const createCloneSuccess = cloneForm => (dispatch, getState) => {
  const state = getState();
  dispatch({ type: types.CREATED_CLONE_SUCCESS, fchar: state.dynamicForm.taxEntityCode, cloneForm, metadataFields: getAllFieldsFromMetadata(state, state.dynamicForm.taxEntityCode) });
};

const createClone = () => async (dispatch, getState) => {
  const state = getState();
  dispatch({ type: types.CREATING_CLONE });
  const fchar = state.dynamicForm.taxEntityCode;
  const path = `/${getBasePath(fchar)}/${state.dynamicForm.formId}`;

  try {
    const response = await get(path);
    let entity = response.data.Entity;
    if (noFormsFchars.includes(fchar)){
      entity.MailingAddressAutoCheckBox = mailingAddressAutoCheckBoxFields.some(field => entity[field] && entity[field] !== '');
    }
    dispatch(createCloneSuccess(entity));
  } catch (error) {
    dispatch({ type: types.CREATED_CLONE_FAILURE });
    throw (error);
  }
};

const deleteForm = (idForm, showErrorSnackbar) => async (dispatch, getState) => {
  dispatch({ type: types.DELETING_FORM });
  let path = '';
  const state = getState();

  if (state.taxEntityCode === theW2MFchar) {
    const version = state.dynamicForm.form[state.taxEntityCode].VERSION;
    path = `/${getBasePath(state.dynamicForm.taxEntityCode)}/${idForm}/${version}`;
  } else {
    path = `/${getBasePath(state.dynamicForm.taxEntityCode)}/${idForm}`;
  }

  try {
    await removeAction(state.dynamicForm.taxEntityCode)(path);
    dispatch({ type: types.DELETE_FORM_SUCCESS });
  } catch (error) {
    dispatch({ type: types.DELETE_FORM_FAILURE });
    showErrorSnackbar({ message: getErrorMessage(error) });
    console.error(error);
  }
};

const setDeleted = value => dispatch => dispatch({ type: types.SET_DELETED, value });

const setSaveDisableByValidation = value => dispatch => {
  dispatch({ type: types.SET_SAVE_DISABLE_BY_VALIDATION, saveDisable: value });
};

const setShowCorrectionDialog = value => dispatch => dispatch({ type: types.SET_SHOW_MARK_AS_CORRECTION, payload: value });

const saveWithMarkAsCorrection = showErrorSnackbar => (dispatch, getState) => {
  saveForm(showErrorSnackbar, updateActionEnum.Correct )(dispatch, getState);
};

const saveWithoutMarkAsCorrection = showErrorSnackbar => (dispatch, getState) => {
  const state = getState();
  saveForm(
    showErrorSnackbar,
    state.dynamicForm.taxEntityCode === theW2MFchar ? updateActionEnum.UpdateWithoutCorrection : updateActionEnum.Force
  )(dispatch, getState);
};

const parseValidationMessages = errors => errors.reduce((accumulator, value) => `${accumulator} ${value}`);

const setDisabledByEntityFetcher = fieldsMap => dispatch => dispatch({ type: types.SET_DISABLED_BY_ENTITY_FETCHER, fieldsMap });

const unsetDisabledByEntityFetcher = fieldsMap => dispatch => dispatch({ type: types.UNSET_DISABLED_BY_ENTITY_FETCHER, fieldsMap });

const getTableValues = (tableHeaders, records, fchar) => (dispatch, getState) => DynamicFormHelper.getTableValues(tableHeaders, records, getAllFieldsFromMetadata(getState(), fchar), fchar);

const fetchingIdentityManagement = () => dispatch => dispatch({ type: types.FETCHING_IDENTITY_MANAGEMENT });

const fetchingIdentityManagementComplete = () => dispatch => dispatch({ type: types.FETCHING_IDENTITY_MANAGEMENT_COMPLETE });

const setFormModifiedByTINValidation = value => dispatch => dispatch({ type: types.SET_FORM_MODIFIED_BY_TIN_VALIDATION, formModified: value });

const setOpenExtraFormSliding = value => dispatch => dispatch({ type: types.SET_OPEN_EXTRA_FORM_SLIDING, value });

const setTaxEntityCode = taxEntityCode => dispatch => dispatch({ type: types.SET_TAX_ENTITY_CODE, taxEntityCode });

const setTaxEntityCodeBase = taxEntityCodeBase => dispatch => dispatch({ type: types.SET_TAX_ENTITY_CODE_BASE, taxEntityCodeBase });

const setFetchFormSuccess = value => dispatch => dispatch({ type: types.SET_FETCH_FORM_SUCCESS, value });

const clearStoreForm = () => dispatch => dispatch({ type: types.CLEAR_STORE_FORM });

export {
  setFieldValue,
  setFormValue,
  setFormErrors,
  setFetchingForm,
  setSavingForm,
  setRecipientPayer,
  fetchForm,
  saveForm,
  resetForm,
  resetPayer,
  resetExtraForm,
  resetCloneForm,
  resetToOriginalForm,
  setFormEditorTitle,
  setSaveFormSuccess,
  saveFormSuccess,
  overrideFormModified,
  addNewForm,
  setFormId,
  checkConditionToDelete,
  createClone,
  deleteForm,
  setDeleted,
  setFcharToDelete,
  setFcharToClone,
  setSaveDisableByValidation,
  setDisabledByEntityFetcher,
  unsetDisabledByEntityFetcher,
  setShowCorrectionDialog,
  saveWithMarkAsCorrection,
  saveWithoutMarkAsCorrection,
  getTableValues,
  fetchingIdentityManagement,
  fetchingIdentityManagementComplete,
  setFormModifiedByTINValidation,
  setOpenExtraFormSliding,
  setTaxEntityCode,
  setTaxEntityCodeBase,
  setFetchFormSuccess,
  clearStoreForm
};
