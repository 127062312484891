import actionTypes from './FormsUnderFederalLimitConstants';

const initialStore = {
  isFetchingRecords: false,
  records: {}
};

export default function PrintNotesReducer(state = initialStore, action) {
  switch (action.type) {
    case actionTypes.FETCHING_RECORDS_UNDER_FEDERAL_LIMIT:
      return { ...state, isFetchingRecords: true };

    case actionTypes.FETCH_ALL_RECORDS_UNDER_FEDERAL_SUCCESS:
      return {
        ...state,
        isFetchingRecords: false,
        records: {
          ...state.records,
          ...action.data
        }
      };

    case actionTypes.FETCH_RECORDS_UNDER_FEDERAL_SUCCESS:
      return {
        ...state,
        isFetchingRecords: false,
        records: {
          ...state.records,
          [action.form]: action.data
        }
      };

    case actionTypes.CLEAN_UP_STORE:
      return { ...initialStore };

    case actionTypes.LOGOUT_SUCCESS:
      return { ...initialStore };

    default:
      return { ...state };

  }
}
