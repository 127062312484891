import sharedActions from 'redux/constants/SharedConstants';

const actionTypes = {
  ...sharedActions,
  FETCHING_FILE_HEADERS: 'FETCHING_FILE_HEADERS',
  FETCH_FILE_HEADERS_SUCCESS: 'FETCH_FILE_HEADERS_SUCCESS',
  FETCH_FILE_HEADERS_FAILURE: 'FETCH_FILE_HEADERS_FAILURE',
  SAVING_MAP: 'SAVING_MAP',
  SAVE_MAP_SUCCESS: 'SAVE_MAP_SUCCESS',
  SAVE_MAP_FAILURE: 'SAVE_MAP_FAILURE',
  FETCHING_MAP_BY_ID: 'FETCHING_MAP_BY_ID',
  FETCH_MAP_BY_ID_SUCCESS: 'FETCH_MAP_BY_ID_SUCCESS',
  FETCH_MAP_BY_ID_FAILURE: 'FETCH_MAP_BY_ID_FAILURE',
  FETCHING_MAP_PREVIEW: 'FETCHING_MAP_PREVIEW',
  FETCH_MAP_PREVIEW_SUCCESS: 'FETCH_MAP_PREVIEW_SUCCESS',
  FETCH_MAP_PREVIEW_FAILURE: 'FETCH_MAP_PREVIEW_FAILURE',
  RESET_MAP_FORM: 'RESET_MAP_FORM',
  DELETING_MAP_BY_ID: 'DELETING_MAP_BY_ID',
  DELETE_MAP_BY_ID_SUCCESS: 'DELETE_MAP_BY_ID_SUCCESS',
  DELETE_MAP_BY_ID_FAILURE: 'DELETE_MAP_BY_ID_FAILURE',
  RESET_MAP_HEADERS: 'RESET_MAP_HEADERS'
};

export default actionTypes;
