import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import AuthenticationHelper from 'helpers/authenticationHelper';

const AuthRoute = ({ userLogged, children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    if (AuthenticationHelper.isJwtTokenStored() && (auth.isAuthenticated || userLogged)) {
      setAuthenticated(true);
    }
    else {
      auth.signinRedirect();
    }
  }, []);

  return (
    authenticated && children
  );
};

AuthRoute.propTypes = {
  userLogged: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

export default AuthRoute;