import hash from 'object-hash';
import actionTypes from './DynamicFormConstants';
import sovosFunctions from 'helpers/sovosFunctions';
import { getRecordName, getPanelTitle, getKey, noFormsFchars } from 'helpers/formConstants';
import DynamicFormHelper from '../DynamicFormHelper';

const initialStore = {
  fcharToDelete: '',
  fcharToPreview: '',
  formId: -1,
  formIdToDelete: -1,
  formIdToClone: -1,
  formIdToPreview: -1,
  currentFormId: -1,
  fetchingForm: false,
  form: {},
  previousValueForm: {},
  cloneForm: {},
  formErrors: {},
  cloneFormErrors: [],
  isValid: false,
  formEditorTitle: '',
  formsModified: {},
  cloneFormModified: false,
  savingForm: false,
  saveFormSuccess: false,
  resetForm: false,
  checkingConditionToDelete: false,
  creatingClone: false,
  canBeDeleted: false,
  isReadyToClone: false,
  isClone: false,
  countFormLinked: 0,
  deleting: false,
  deleted: false,
  cloning: false,
  formPrinted: false,
  formTransmittedToIrs: false,
  formTransmittedToState: false,
  formTransmittedToLocality: false,
  saveDisableByValidation: false,
  showMarkAsCorrectionDialog: false,
  formFieldErrorMessage: '',
  fieldValueIsInUseList: [],
  checkingDuplicatedValuesInFields: false,
  checkingDuplicatedFailed: false,
  disabledByEntityFetcher: [],
  showAmountDifferenceField: false,
  fetchingIdentityManagement: false,
  enableReprint: false,
  openExtraFormSliding: false,
  taxEntityCode: '',
  taxEntityCodeBase: '',
  fetchFormSuccess: false
};

let formsOriginalSerialize = {};
let originalForms = {};
let originalCloneForm = {};
let originalCloneSerialize = {};

export default function DynamicFormReducer(state = initialStore, action) {
  let form;

  switch (action.type) {
    case actionTypes.SET_FORM_ID:
      return { ...state, formId: action.id };

    case actionTypes.SET_FIELD_VALUE: {
      if (!state.form[action.taxEntityCode]?.[action.fieldName] && action.fieldValue && action.fieldValue.length === 0) {
        return { ...state };
      }
      if (!originalForms[action.taxEntityCode]?.[action.fieldName] && (action.fieldValue && action.fieldValue.length === 0)) {
        const newForm = { ...state.form[action.taxEntityCode] };
        delete newForm[action.fieldName];
        return {
          ...state,
          form: { ...state.form, [action.taxEntityCode]: newForm },
          formsModified: {
            ...state.formsModified,
            [action.taxEntityCode]: action.isDefault
              ? false
              : formsOriginalSerialize[action.taxEntityCode] !== hash(newForm)
          },
          resetForm: false
        };
      }
      if (state.form[action.taxEntityCode]?.[action.fieldName] === action.fieldValue) {
        return { ...state };
      }
      const newForm = { ...state.form[action.taxEntityCode], [action.fieldName]: action.fieldValue };
      return {
        ...state,
        form: { ...state.form, [action.taxEntityCode]: newForm },
        formsModified: {
          ...state.formsModified,
          [action.taxEntityCode]: action.isDefault
            ? false
            : formsOriginalSerialize[action.taxEntityCode] !== hash(newForm)
        },
        resetForm: false,
        formFieldErrorMessage: ''
      };
    }

    case actionTypes.SET_FIELD_VALUE_CLONE: {
      if (!state.cloneForm[action.fieldName] && action.fieldValue && action.fieldValue.length === 0) {
        return { ...state };
      }
      if (!originalCloneForm[action.fieldName] && (action.fieldValue && action.fieldValue.length === 0)) {
        const newFormClone = { ...state.cloneForm };
        delete newFormClone[action.fieldName];
        return {
          ...state,
          cloneForm: newFormClone,
          cloneFormModified: action.isDefault
            ? false :
            originalCloneSerialize !== hash(newFormClone),
          resetForm: false
        };
      }
      if (state.cloneForm[action.fieldName] === action.fieldValue) {
        return { ...state };
      }
      const newFormClone = { ...state.cloneForm, [action.fieldName]: action.fieldValue };
      return {
        ...state,
        cloneForm: newFormClone,
        cloneFormModified: action.isDefault
          ? false
          : originalCloneSerialize !== hash(newFormClone),
        resetForm: false,
        formFieldErrorMessage: ''
      };
    }

    case actionTypes.SET_FORM_VALUE:
      return { ...state, form: { ...state.form, [state.taxEntityCode]: action.form } };

    case actionTypes.SET_FORM_ERRORS:
      return { ...state, formErrors: { ...state.formErrors, [state.taxEntityCode]: action.formErrors } };

    case actionTypes.SET_FETCHING_FORM:
      return { ...state, fetchingForm: action.fetchingForm };

    case actionTypes.RESET_STATE_CLONE_FORM:
      return {
        ...state,
        isClone: false,
        formId: state.formIdToClone,
        resetForm: true,
        cloneForm: {},
        cloneFormModified: false,
        taxEntityCode: state.taxEntityCodeBase
      };

    case actionTypes.ASSIGNED_RECIPIENT_PAYER:
      if (state.isClone) {
        return {
          ...state,
          cloneForm: {
            ...state.cloneForm,
            ...action.form
          },
          cloneFormModified: (state.formId !== -1)
            ? originalCloneSerialize !== hash(action.form)
            : state.cloneFormModified
        };
      }
      else {
        return {
          ...state,
          form: {
            ...state.form,
            [action.taxEntityCode]: {
              ...state.form[action.taxEntityCode],
              ...action.form
            }
          },
          formsModified: {
            ...state.formsModified,
            [action.taxEntityCode]: action.updateBaseForm
            || ((state.formId !== -1)
              ? formsOriginalSerialize[action.taxEntityCode] !== hash(action.form)
              : state.formsModified[action.taxEntityCode])
            || false
          }
        };
      }

    case actionTypes.FETCHING_FORM:
      return {
        ...state,
        fetchingForm: true,
        formErrors: { ...state.formErrors, [state.taxEntityCode]: [] },
        cloneFormErrors: [],
        showAmountDifferenceField: false,
        previousValueForm: {}
      };

    case actionTypes.FETCH_FORM_SUCCESS: {
      var newForm = {};
      var newCloneForm = {};
      var newFormErrors = [];
      var newCloneFormErrors = [];
      form = sovosFunctions.ObjectKeysToUppercase(action.form.Entity);
      form = DynamicFormHelper.customizeMetaDataFields(form, action.metadataFields);
      originalForms[state.taxEntityCode] = form;
      formsOriginalSerialize[state.taxEntityCode] = hash(form);
      if (state.isClone) {
        newForm = { ...state.form };
        newCloneForm = { ...form };
        newFormErrors = state.formErrors;
        newCloneFormErrors = action.form.Errors;
      }
      else {
        newForm = { ...state.form, [state.taxEntityCode]: { ...form } };
        newCloneForm = { ...state.cloneForm };
        newFormErrors = { ...state.formErrors, [state.taxEntityCode]: action.form.Errors };
        newCloneFormErrors = state.cloneFormErrors;
      }

      return {
        ...state,
        cloneForm: newCloneForm,
        form: newForm,
        previousValueForm: { ...action.previousValue },
        isClone: state.isClone,
        cloneFormErrors: newCloneFormErrors,
        formErrors: newFormErrors,
        showAmountDifferenceField: DynamicFormHelper.showAmountDifferenceField(form.TRANTYPE, action.fchar),
        fetchingForm: false,
        formEditorTitle: `${getRecordName(action.fchar)} Data: ${form[getPanelTitle(action.fchar)]}`,
        formsModified: { ...state.formsModified, [state.taxEntityCode]: false },
        isValid: false,
        saveFormSuccess: false,
        resetForm: false,
        fcharToDelete: action.fchar,
        fcharToPreview: action.fchar,
        formIdToDelete: form[getKey(action.fchar)],
        formIdToPreview: form[getKey(action.fchar)],
        currentFormId: form[getKey(action.fchar)],
        canBeDeleted: true,
        isReadyToClone: false,
        countFormLinked: 0,
        formPrinted: action.formPrinted,
        formTransmittedToIrs: Boolean(form.XMITRUN),
        formTransmittedToState: Boolean(Number(form.STXMITIND)),
        formTransmittedToLocality: Boolean(Number(form.LCLXMITIND)),
        fieldValueIsInUseList: [],
        enableReprint: action.enableReprint,
        fetchFormSuccess: true
      };
    }

    case actionTypes.FETCH_FORM_FAILURE:
      return { ...state, fetchingForm: false, fetchFormSuccess: false };

    case actionTypes.SAVING_FORM:
      return { ...state, savingForm: action.savingForm };

    case actionTypes.SAVE_FORM_SUCCESS: {
      originalForms[state.taxEntityCode] = action.form;
      formsOriginalSerialize[state.taxEntityCode] = hash(action.form);
      return {
        ...state,
        formsModified: { ...state.formsModified, [state.taxEntityCode]: false },
        isValid: false,
        saveFormSuccess: true,
        savingForm: false
      };
    }

    case actionTypes.SET_SAVE_FORM_SUCCESS:
      return { ...state, saveFormSuccess: action.saveFormSuccess };

    case actionTypes.OVERRIDE_FORM_MODIFIED:
      return { ...state, formsModified: { ...state.formsModified, [state.taxEntityCode]: false } };

    case actionTypes.RESET_FORM:
      return {
        ...state,
        formId: -1,
        form: {},
        formErrors: {},
        previousValueForm: {},
        showAmountDifferenceField: false,
        formsModified: {},
        saveFormSuccess: false,
        resetForm: true,
        formPrinted: false,
        formTransmittedToIrs: false,
        formTransmittedToState: false,
        formTransmittedToLocality: false,
        fieldValueIsInUseList: [],
        checkingDuplicatedValuesInFields: false,
        checkingDuplicatedFailed: false,
        disabledByEntityFetcher: []
      };

    case actionTypes.RESET_EXTRA_FORM:
      form = noFormsFchars.includes(action.taxEntityCode)
        ? {}
        : {
          FK1: state.form[state.taxEntityCodeBase]?.TIN || state.form[state.taxEntityCodeBase]?.FK1,
          FK2: state.form[state.taxEntityCodeBase]?.ACCOUNTNO || state.form[state.taxEntityCodeBase]?.FK2,
          MODRECNO: state.form[state.taxEntityCodeBase]?.MODRECNO,
          TRANTYPE: null
        };

      return {
        ...state,
        formId: -1,
        form: {
          ...state.form,
          [action.taxEntityCode]: form
        },
        showAmountDifferenceField: false,
        formsModified: { ...state.formsModified, [state.taxEntityCode]: false },
        saveFormSuccess: false,
        resetForm: true,
        formPrinted: false,
        formTransmittedToIrs: false,
        formTransmittedToState: false,
        formTransmittedToLocality: false,
        formIdToClone: state.formId
      };

    case actionTypes.RESET_TO_ORIGINAL_FORM:
      return {
        ...state,
        form: { ...state.form, [state.taxEntityCode]: { ...originalForms[state.taxEntityCode] } },
        formsModified: { ...state.formsModified, [state.taxEntityCode]: false },
        resetForm: true
      };

    case actionTypes.CHANGE_VALID_FORM:
      return { ...state, isValid: action.valid, savingForm: false };

    case actionTypes.SET_FORM_EDITOR_TITLE:
      return { ...state, formEditorTitle: action.title };

    case actionTypes.ADD_NEW_FORM: {
      originalForms[state.taxEntityCode] = action.defaultForm || {};
      formsOriginalSerialize[state.taxEntityCode] = hash(originalForms[state.taxEntityCode]);
      return { ...state, form: { ...state.form, [state.taxEntityCode]: { ...originalForms[state.taxEntityCode] } } };
    }

    case actionTypes.CHEKING_CONDITION_DELETE:
      return { ...state, checkingConditionToDelete: true };

    case actionTypes.CHECK_CONDITION_DELETE_SUCCESS: {
      if (noFormsFchars.includes(state.fcharToDelete)) {
        if (action.isReady) {
          return { ...state, checkingConditionToDelete: false, canBeDeleted: action.canBeDeleted, countFormLinked: action.countFormLinked };
        }
        return { ...state, checkingConditionToDelete: false, canBeDeleted: false, countFormLinked: 0 };
      } else {
        if (state.form[state.taxEntityCode]?.STMTRUN > 0 || state.form[state.taxEntityCode]?.XMITRUN > 0 || state.form[state.taxEntityCode]?.STXMITIND || state.form[state.taxEntityCode]?.TRANTYPE) {
          return { ...state, checkingConditionToDelete: false, canBeDeleted: false, countFormLinked: 0 };
        }
        return { ...state, checkingConditionToDelete: false, canBeDeleted: action.canBeDeleted, countFormLinked: 0 };
      }
    }

    case actionTypes.CREATING_CLONE:
      return { ...state, creatingClone: true };

    case actionTypes.CREATED_CLONE_SUCCESS: {
      const formWithUppercaseKeys = sovosFunctions.ObjectKeysToUppercase(action.cloneForm);
      const formWithFormattedDates = DynamicFormHelper.customizeMetaDataFields(formWithUppercaseKeys, action.metadataFields);
      const editedState = { ...state, cloneForm: { ...formWithFormattedDates } };
      delete editedState.cloneForm.MODLEVEL;
      delete editedState.cloneForm.PTIN;
      delete editedState.cloneForm.TIN;
      delete editedState.cloneForm.ACCOUNTNO;
      delete editedState.cloneForm[getKey(action.fchar)];
      originalCloneForm = { ...editedState.cloneForm };
      originalCloneSerialize = hash(originalCloneForm);
      return {
        ...editedState,
        formId: -1,
        cloneFormModified: false,
        saveFormSuccess: false,
        resetForm: true,
        creatingClone: false,
        isClone: true,
        formEditorTitle: `CLONE ${state.formEditorTitle}`,
        formIdToClone: state.formId
      };
    }

    case actionTypes.CREATED_CLONE_FAILURE:
      return { ...state, creatingClone: false };

    case actionTypes.SET_SAVE_DISABLE_BY_VALIDATION:
      return { ...state, saveDisableByValidation: action.saveDisable };

    case actionTypes.SET_DISABLED_BY_ENTITY_FETCHER: {
      let disabledFields = state.disabledByEntityFetcher.concat(Object.keys(action.fieldsMap));
      return { ...state, disabledByEntityFetcher: disabledFields };
    }

    case actionTypes.UNSET_DISABLED_BY_ENTITY_FETCHER: {
      const fieldsToUnset = Object.keys(action.fieldsMap);
      let disabledFields = state.disabledByEntityFetcher.filter(field => !fieldsToUnset.includes(field));
      return { ...state, disabledByEntityFetcher: disabledFields };
    }

    case actionTypes.DELETING_FORM:
      return { ...state, deleting: true };

    case actionTypes.DELETE_FORM_SUCCESS:
      return { ...state, deleting: false, deleted: true, canBeDeleted: false, countFormLinked: 0 };

    case actionTypes.DELETE_FORM_FAILURE:
      return { ...state, deleting: false, deleted: false };

    case actionTypes.SET_DELETED:
      return { ...state, deleted: action.value };

    case actionTypes.SET_FCHAR_TO_DELETE:
      return { ...state, fcharToDelete: action.fchar, canBeDeleted: false, countFormLinked: 0 };

    case actionTypes.CLONING_FORM:
      return { ...state, cloning: true };

    case actionTypes.CLONE_FORM_SUCCESS:
      return { ...state, cloning: false, isReadyToClone: false, countFormLinked: 0 };

    case actionTypes.SET_FCHAR_TO_CLONE:
      return { ...state, fcharToClone: action.fchar, isReadyToClone: false, countFormLinked: 0 };

    case actionTypes.SET_SHOW_MARK_AS_CORRECTION:
      return { ...state, showMarkAsCorrectionDialog: action.payload };

    case actionTypes.CHECKING_FOR_DUPLICATE_FIELD:
      return { ...state, checkingDuplicatedValuesInFields: true, checkingDuplicatedFailed: false };

    case actionTypes.CHECK_FOR_DUPLICATE_FIELD_SUCCESS: {
      let fieldValueIsInUseList = [...state.fieldValueIsInUseList];
      const { isDuplicate, field } = action;
      const fieldIsAlreadyInTheList = fieldValueIsInUseList.includes(action.field);

      if (isDuplicate && !fieldIsAlreadyInTheList) {
        fieldValueIsInUseList.push(field);
      }

      if (!isDuplicate && fieldIsAlreadyInTheList) {
        fieldValueIsInUseList = fieldValueIsInUseList.filter(dupField => dupField !== action.field);
      }

      return {
        ...state,
        fieldValueIsInUseList,
        checkingDuplicatedValuesInFields: false
      };
    }

    case actionTypes.CHECK_FOR_DUPLICATE_FIELD_FAILURE: {
      return {
        ...state,
        checkingDuplicatedFailed: true
      };
    }

    case actionTypes.FETCHING_IDENTITY_MANAGEMENT: {
      return {
        ...state,
        fetchingIdentityManagement: true
      };
    }

    case actionTypes.FETCHING_IDENTITY_MANAGEMENT_COMPLETE: {
      return {
        ...state,
        fetchingIdentityManagement: false
      };
    }

    case actionTypes.SET_FORM_MODIFIED_BY_TIN_VALIDATION: {
      return { ...state, formsModified: { ...state.formsModified, [state.taxEntityCode]: action.formModified } };
    }

    case actionTypes.LOGOUT_SUCCESS:
    case actionTypes.CLEAR_STORE_FORM:
      return { ...initialStore };

    case actionTypes.SET_OPEN_EXTRA_FORM_SLIDING:
      return { ...state, openExtraFormSliding: action.value };

    case actionTypes.SET_TAX_ENTITY_CODE:
      return { ...state, taxEntityCode: action.taxEntityCode };

    case actionTypes.SET_TAX_ENTITY_CODE_BASE:
      return { ...state, taxEntityCodeBase: action.taxEntityCodeBase };

    case actionTypes.SET_FETCH_FORM_SUCCESS:
      return { ...state, fetchFormSuccess: action.value };

    default:
      return state;
  }
}
