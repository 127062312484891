import { connect } from 'react-redux';
import FormTitle from './FormTitle';

const mapStateToProps = state => ({
  recipientInformation: state.recipient.data,
  formPrinted: state.dynamicForm.formPrinted,
  formTransmittedToIrs: state.dynamicForm.formTransmittedToIrs,
  formTransmittedToState: state.dynamicForm.formTransmittedToState,
  formTransmittedToLocality: state.dynamicForm.formTransmittedToLocality,
  isCustomCorrection: state.dynamicForm.showAmountDifferenceField,
  states: (state.dynamicForm.isClone ? state.dynamicForm.cloneForm : state.dynamicForm.form[state.dynamicForm.taxEntityCode])?.STATES || [],
  localities: (state.dynamicForm.isClone ? state.dynamicForm.cloneForm : state.dynamicForm.form[state.dynamicForm.taxEntityCode])?.LOCALITIES || [],
  form: state.dynamicForm.form[state.dynamicForm.taxEntityCode] || {},
  isClone: state.dynamicForm.isClone
});

export default connect(mapStateToProps)(FormTitle);
