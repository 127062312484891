import PropTypes from 'prop-types';
import classNames from 'classnames';
import './fieldHeader.scss';

const FieldHeader = ({ boxName, label, helperText, customClasses }) => (
  <div className='tirTextfieldForm__wrapper'>
    <div className='label__container'>
      <div className='label__container-left'>
        { boxName &&
        <label
          className={ 'tirTexfield__label-box label__left-box-name' }
          htmlFor={ boxName }
        >
          { boxName }
        </label> }
        <label
          className='tirTexfield__label'
          htmlFor={ boxName }
        >
          { label }
        </label>
      </div>
    </div>
    <div className={ classNames('textField__container', 'field-header__helper-container', { 'field-header__helper-disabled': !Boolean(helperText) }, customClasses.fieldHeaderHelper) }>
      { helperText &&
        <div className='field-header__helper-text'>
          { helperText }
        </div>
      }
    </div>
  </div>
);

FieldHeader.propTypes = {
  boxName: PropTypes.string,
  label: PropTypes.node,
  helperText: PropTypes.string,
  customClasses: PropTypes.object
};

export default FieldHeader;