import PropTypes from 'prop-types';
import EntityFetcher from '../entityFetcher/EntityFetcher';

const ThirdPartyPayer = ({ dynamicFormActions, isClone, form, value, readOnlyUser, label, boxName, taxEntityCode }) => {
  const fieldsMap = {
    'S01': 'Ptin'
  };

  const handlePayerSelect = payer => {
    Object.keys(fieldsMap).forEach(key => {
      if (payer) {
        dynamicFormActions.setFieldValue(key, payer[fieldsMap[key]] || '', isClone, taxEntityCode);
      }
      dynamicFormActions.setDisabledByEntityFetcher(fieldsMap);
    });
  };

  const handlePayerRemove = () => {
    Object.keys(fieldsMap).forEach(key => {
      dynamicFormActions.setFieldValue(key, '', isClone, taxEntityCode);
      dynamicFormActions.unsetDisabledByEntityFetcher(fieldsMap);
    });
  };

  const checkForUserInput = () => {
    let containsUserInput = false;
    Object.keys(fieldsMap).forEach(key => {
      if (form[key]) {
        containsUserInput = true;
      }
    });
    return containsUserInput;
  };

  return (
    <EntityFetcher
      boxName={ boxName }
      label={ label }
      onEntitySelect={ handlePayerSelect }
      onEntityRemove={ handlePayerRemove }
      fetchMultipleUrl={ `/payers/search` }
      fetchSingleURL={ '/payers/' }
      entityLabel={ 'payer' }
      initialValueKey={ 'Ptin' }
      containsUserInput={ checkForUserInput }
      value={ value }
      readOnlyUser={ readOnlyUser }
    />
  );
};

ThirdPartyPayer.propTypes = {
  label: PropTypes.string.isRequired,
  boxName: PropTypes.string.isRequired,
  isClone: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  dynamicFormActions: PropTypes.shape({
    setFieldValue: PropTypes.func,
    setDisabledByEntityFetcher: PropTypes.func,
    unsetDisabledByEntityFetcher: PropTypes.func
  }).isRequired,
  readOnlyUser: PropTypes.bool.isRequired,
  taxEntityCode: PropTypes.string.isRequired
};

export default ThirdPartyPayer;
