import actionTypes from './ManageUserConstants';
import { allRecords, getRecords } from 'helpers/formConstants';

const USER_PERMISSIONS = 'userPermissions';
const TIR_PRODUCT = 'tirProduct';
const FORMS = 'forms';
const userPermissions = sessionStorage.getItem(USER_PERMISSIONS);
const forms = sessionStorage.getItem(FORMS);

const initialStore = {
  isFetchingOrg: false,
  selectedOrg: {},
  contextTree: [],
  isFetchingPermission: false,
  userPermissions: userPermissions ? JSON.parse(userPermissions) : [],
  fetchingSolutionConfigurations: false,
  isFetchingForms: false,
  forms: forms ? JSON.parse(forms) : {}
};

const selectRootOrg = orgs => {
  let rootOrg = orgs[0];
  orgs.forEach(currentOrg => {
    if (currentOrg.parentId == null) {
      rootOrg = currentOrg;
    }
  });
  return rootOrg;
};

export default function ManageUser(state = initialStore, action) {
  switch (action.type) {
    case actionTypes.FETCHING_ORG:
      return { ...state, isFetchingOrg: true };

    case actionTypes.FETCH_ORG_SUCCESS:
      return { ...state, isFetchingOrg: false, selectedOrg: selectRootOrg(action.data) };

    case actionTypes.FETCHING_PERMISSIONS:
      return { ...state, isFetchingPermission: true };

    case actionTypes.FETCH_PERMISSIONS_SUCCESS: {
      sessionStorage.setItem(USER_PERMISSIONS, JSON.stringify(action.permissions));
      return {
        ...state,
        isFetchingPermission: false,
        userPermissions: action.permissions
      };
    }

    case actionTypes.FETCH_PERMISSIONS_FAILURE:
      return { ...state, isFetchingPermission: false };

    case actionTypes.LOGOUT_SUCCESS: {
      sessionStorage.removeItem(TIR_PRODUCT);
      sessionStorage.removeItem(USER_PERMISSIONS);
      return { ...initialStore };
    }

    case actionTypes.ASSIGN_CONTEXT_TREE:
      return { ...state, contextTree: action.data.selectedContexTree };

    case actionTypes.FETCHING_SOLUTION_CONFIGURATIONS:
      return { ...state, fetchingSolutionConfigurations: true };

    case actionTypes.FETCH_SOLUTION_CONFIGURATIONS_SUCCESS:
      sessionStorage.setItem('solutionConfigurations', JSON.stringify(action.config));
      return { ...state, fetchingSolutionConfigurations: false };

    case actionTypes.FETCH_SOLUTION_CONFIGURATIONS_FAILURE:
      return { ...state, fetchingSolutionConfigurations: false };

    case actionTypes.FETCHING_FORMS:
      sessionStorage.removeItem(FORMS);
      return { ...state, isFetchingForms: true };

    case actionTypes.FETCH_FORMS_SUCCESS:
      const availableFChars = action.data;
      const formConstants = allRecords();
      const availableFormConstants = availableFChars.reduce((acc, cur) => {

        if (formConstants.hasOwnProperty(cur.FChar)) {
          return { ...acc, [cur.FChar]: formConstants[cur.FChar] };
        }
        return acc;
      }, {});

      sessionStorage.setItem(FORMS, JSON.stringify(availableFormConstants));
      getRecords(true);

      return {
        ...state,
        isFetchingForms: false,
        forms: availableFormConstants
      };

    case actionTypes.FETCH_FORMS_FAILURE:
      sessionStorage.removeItem(FORMS);
      return { ...state, isFetchingForms: false };

    default:
      return { ...state };
  }
}
