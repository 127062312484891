import sharedConstants from 'redux/constants/SharedConstants';

const actionTypes = {
  ...sharedConstants,
  OPEN_CANCEL_PRINT_DIALOG: 'OPEN_CANCEL_PRINT_DIALOG',
  CLOSE_CANCEL_PRINT_DIALOG: 'CLOSE_CANCEL_PRINT_DIALOG',
  OPEN_APPROVE_PRINT_DIALOG: 'OPEN_APPROVE_PRINT_DIALOG',
  CLOSE_APPROVE_PRINT_DIALOG: 'CLOSE_APPROVE_PRINT_DIALOG',
  CREATING_PRINT_JOB: 'CREATING_PRINT_JOB',
  CREATE_PRINT_JOB_SUCCESS: 'CREATE_PRINT_JOB_SUCCESS',
  CREATE_PRINT_JOB_FAILURE: 'CREATE_PRINT_JOB_FAILURE',
  CANCELLING_PRINT_JOB: 'CANCELLING_PRINT_JOB',
  CANCEL_PRINT_JOB_SUCCESS: 'CANCEL_PRINT_JOB_SUCCESS',
  CANCEL_PRINT_JOB_FAILURE: 'CANCEL_PRINT_JOB_FAILURE',
  FETCHING_JOB_BY_ID: 'FETCHING_JOB_BY_ID',
  FETCH_JOB_BY_ID_SUCCESS: 'FETCH_JOB_BY_ID_SUCCESS',
  FETCH_JOB_BY_ID_FAILURE: 'FETCH_JOB_BY_ID_FAILURE',
  APPROVING_PRINT_JOB: 'APPROVING_PRINT_JOB',
  APPROVE_PRINT_JOB_SUCCESS: 'APPROVE_PRINT_JOB_SUCCESS',
  APPROVE_PRINT_JOB_FAILURE: 'APPROVE_PRINT_JOB_FAILURE'
};

export default actionTypes;
