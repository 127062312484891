import actionTypes from './FieldMappingConstants';

const initialStore = {
  rules: [],
  changed: false
};

export default function appSettingsReducer(state = initialStore, action) {
  switch (action.type) {
    case actionTypes.SET_RULES:
      return { ...state, rules: action.rules, changed: !state.changed };

    case actionTypes.FETCH_MAP_BY_ID_SUCCESS: {
      const newRules = action.map.Fields.map(field => {
        if (field.Action === '=') {
          field.fixedValue = true;
        }

        return { ...field, isSelected: false };
      });
      return { ...state, rules: newRules };
    }

    case actionTypes.CLONE_RULE: {
      const newRules = [...state.rules, { ...state.rules[action.index - 1], Index: state.rules.length + 1 }].map(rule => ({ ...rule, isSelected: false }));
      return { ...state, rules: newRules, changed: !state.changed };
    }

    case actionTypes.DELETE_RULE: {
      const idx = action.index;
      const newRules = state.rules.filter(rule => rule.Index !== idx).map((rule, idx) => ({ ...rule, Index: idx + 1 }));
      return { ...state, rules: newRules, changed: !state.changed };
    }

    default:
      return state;
  }
}
