import { memo } from 'react';
import PropTypes from 'prop-types';
import SovosTextField from 'mosaic-react/sovos-text-field';
import MaskedInput from 'react-text-mask';
import useValidation from '../helper/useValidation';
import useMask from '../helper/useMask';
import TextMaskCustom from '../TextMaskCustom';
import './validationTextField.scss';

const baseClasses = { root: 'validation-text-field__root' };

const ValidationTextField = ({
  label,
  name,
  value,
  helperText,
  multiLine,
  validation,
  mask,
  onValidation,
  reset,
  decimalLimit,
  inputClasses,
  inputLabelClasses,
  formHelperTextClasses,
  runUIValidationAutomatically,
  ...restProps
}) => {
  const [validationError, validationMessage, setInput] = useValidation(value, label, name, validation, mask, onValidation, reset, helperText, runUIValidationAutomatically);
  const [maskToApply] = useMask(mask, decimalLimit);

  const getInputProps = () => {
    const inputProps = {
      classes: inputClasses
    };
    if (maskToApply.mask) {
      inputProps.inputComponent = TextMaskCustom;
    }

    return inputProps;
  };

  const getInputLabelProps = () => {
    const props = {
      classes: {
        ...inputLabelClasses,
        root: `validation-text-field__label-root ${ inputLabelClasses?.root ? inputLabelClasses.root : '' }`
      }
    };

    return props;
  };

  return (
    <SovosTextField
      { ...restProps }
      value={ value }
      label={ label }
      multiline={ multiLine }
      name={ name }
      id={ name }
      error={ validationError }
      maxRows={ multiLine ? 8 : 1 }
      inputRef={ ref => setInput(ref) }
      helperText={ validationMessage || helperText }
      classes={ baseClasses }
      InputProps={ getInputProps() }
      inputProps={ maskToApply.mask && {
        maskToApply
      } }
      InputLabelProps={ getInputLabelProps() }
      FormHelperTextProps={ { classes: formHelperTextClasses } }
    >
      {
        maskToApply.mask &&
        <MaskedInput
          value={ value }
          mask={ maskToApply.mask }
          placeholder={ maskToApply.placeholder }
        />
      }
    </SovosTextField>
  );
};

ValidationTextField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  helperText: PropTypes.string,
  multiLine: PropTypes.bool,
  validation: PropTypes.shape({
    pattern: PropTypes.string,
    message: PropTypes.string,
    validateOn: PropTypes.string,
    required: PropTypes.bool,
    maxLength: PropTypes.number
  }),
  mask: PropTypes.string,
  onValidation: PropTypes.func,
  reset: PropTypes.bool,
  decimalLimit: PropTypes.number,
  inputClasses: PropTypes.object,
  inputLabelClasses: PropTypes.object,
  formHelperTextClasses: PropTypes.object,
  runUIValidationAutomatically: PropTypes.bool
};

ValidationTextField.defaultProps = {
  label: '',
  helperText: '',
  multiLine: false,
  validation: null,
  mask: '',
  onValidation: () => null,
  reset: false,
  decimalLimit: 2,
  inputClasses: {},
  inputLabelClasses: {},
  formHelperTextClasses: {},
  runUIValidationAutomatically: false
};

export default memo(ValidationTextField);
