const TIMStatus = {
  0: 'U',
  1: 'U',
  2: 'B',
  3: 'A',
  4: 'D',
  5: 'C',
  46: 'E'
};

const matchTIAandTINStatus = (tinResult, tinValidation) => TIMStatus[tinResult] === tinValidation;

export {
  TIMStatus,
  matchTIAandTINStatus
};
