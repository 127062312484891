import { Component, createElement } from 'react';
import PropTypes from 'prop-types';
import * as conditions from '../helper/conditionsHelper';
import TextField from 'shared/standardInput/textField/validationGridTextField/ValidationGridTextFieldInterface';
import TextFieldPlaceholder from 'shared/standardInput/textField/TextFieldPlaceholder';
import TextFieldForm from 'shared/standardInput/textField/TextFieldFormConnector';
import AmountDifferenceFieldForm from 'shared/standardInput/textField/AmountDifferenceFieldForm';
import TotalAmountDifferenceFieldForm from 'shared/standardInput/textField/TotalAmountDifferenceFieldFormConnector';
import PreviousCurrentTextFieldForm from 'shared/standardInput/textField/PreviousCurrentTextFieldForm';
import SelectField from 'shared/standardInput/selectField/SelectFieldConnector';
import SelectDifferenceFieldForm from 'shared/standardInput/selectField/SelectDifferenceFieldFormConnector';
import SelectFieldForm from 'shared/standardInput/selectField/SelectFieldFormConnector';
import CheckBoxField from 'shared/standardInput/checkBox/CheckBox';
import CheckBoxFieldForm from 'shared/standardInput/checkBox/CheckBoxFieldForm';
import PayerInformation from '../../formContainer/formDetailsComponents/payerInformation/PayerInformationConnector';
import RecipientInformation from '../../formContainer/formDetailsComponents/recipientInformation/RecipientInformationConnector';
import TaxYear from '../../formContainer/formDetailsComponents/taxYear/TaxYearConnector';
import RecordStatus from '../../formContainer/formDetailsComponents/recordStatus/RecordStatusConnector';
import WFMarkAsCorrectionButton from '../../formContainer/formDetailsComponents/wfMarkAsCorrectionButton/WFMarkAsCorrectionButtonConnector';
import Form1042ScheduleQButton from '../../formContainer/formDetailsComponents/form1042ScheduleQButton/Form1042ScheduleQButtonConnector';
import Form1042ScheduleQs from '../../formContainer/formDetailsComponents/form1042ScheduleQs/Form1042ScheduleQsConnector';
import FormType from '../../formContainer/formDetailsComponents/formType/FormType';
import IdentityVerification from '../../formContainer/formDetailsComponents/identityVerification/IdentityVerificationConnector';
import MultipleState from '../../formContainer/formDetailsComponents/multipleState/MultipleStateConnector';
import PayerLocalities from '../../formContainer/formDetailsComponents/payerLocalities/PayerLocalitiesConnector';
import PayerShortName from '../../formContainer/formDetailsComponents/payerShortName/PayerShortNameConnector';
import SpecialField from 'helpers/specialFields';
import RadioButtonFieldForm from 'shared/standardInput/radioButton/RadioButtonFieldFormConnector';
import PrimaryWHAgent from '../../formContainer/formDetailsComponents/primaryWHAgent/PrimaryWHAgentConnector';
import NQI from '../../formContainer/formDetailsComponents/nqi/NQIConnector';
import SPID from '../../formContainer/formDetailsComponents/spid/SPIDConnector';
import ThirdPartyPayer from '../../formContainer/formDetailsComponents/thirdPartyPayer/ThirdPartyPayerConnector';
import AutoCalculatedField from '../../formContainer/formDetailsComponents/autoCalculatedField/AutoCalculatedFieldConnector';
import AutoCalculatedSelect from '../../formContainer/formDetailsComponents/autoCalculatedSelect/AutoCalculatedSelectConnector';
import RequiredMessage from '../../formContainer/formDetailsComponents/requiredMessage/RequiredMessage';
import SectionHeader from '../../formContainer/formDetailsComponents/sectionHeader/SectionHeader';
import MailingAddressAutoCheckBox from '../../formContainer/formDetailsComponents/mailingAddressAutoCheckBox/MailingAddressAutoCheckBoxConnector';
import DifferenceValuesHeader from '../../formContainer/formDetailsComponents/differenceValuesHeader/DifferenceValuesHeader';
import TIN from '../../formContainer/formDetailsComponents/tin/TinConnector';
import CodedPayments from '../../formContainer/formDetailsComponents/codedPayments/CodedPaymentsConnector';
import OtherValues from '../../formContainer/formDetailsComponents/otherValues/OtherValuesConnector';
import W2MultipleStates from 'shared/dataEntryForms/formContainer/formDetailsComponents/w2MultipleStates/W2MultipleStatesConnector';
import W2MultipleLocalWage from '../../formContainer/formDetailsComponents/w2MultipleLocalWage/W2MultipleLocalWageConnector';
import FieldHeader from 'shared/dataEntryForms/formContainer/formDetailsComponents/fieldHeader/FieldHeader';
import AuthenticationHelper from 'helpers/authenticationHelper';
import SemiWeeklyAmounts from 'shared/dataEntryForms/formContainer/formDetailsComponents/semiWeeklyAmounts/SemiWeeklyAmounts';
import { debounce } from 'debounce';
import { getLabel, fieldsWithDateMask } from '../helper/labelHelper';
import { theW2MFchar, withholdingForms } from 'helpers/formConstants';
import { getCustomClassesByTaxEntityCode } from '../helper/formFieldHelper';
import './formField.scss';

const formFields = {
  TextField,
  TextFieldPlaceholder,
  TextFieldForm,
  SelectField,
  SelectFieldForm,
  SelectDifferenceFieldForm,
  CheckBoxField,
  CheckBoxFieldForm,
  PayerInformation,
  RecipientInformation,
  TaxYear,
  RecordStatus,
  WFMarkAsCorrectionButton,
  Form1042ScheduleQButton,
  Form1042ScheduleQs,
  FormType,
  IdentityVerification,
  MultipleState,
  PayerLocalities,
  RadioButtonFieldForm,
  PayerShortName,
  PrimaryWHAgent,
  NQI,
  SPID,
  ThirdPartyPayer,
  AutoCalculatedField,
  TotalAmountDifferenceFieldForm,
  AutoCalculatedSelect,
  RequiredMessage,
  SectionHeader,
  MailingAddressAutoCheckBox,
  TIN,
  AmountDifferenceFieldForm,
  PreviousCurrentTextFieldForm,
  DifferenceValuesHeader,
  CodedPayments,
  OtherValues,
  W2MultipleStates,
  W2MultipleLocalWage,
  FieldHeader,
  SemiWeeklyAmounts
};

const getFieldName = ({ taxEntityCode, metadata }) => (
  [theW2MFchar, ...withholdingForms].includes(taxEntityCode)
    ? metadata.entityField
    : metadata.dbColumn
) || metadata.name;

class FormField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      originalValue: props.fieldValue,
      fieldValue: props.fieldValue,
      previousFieldValue: props.previousFieldValue,
      fieldName: getFieldName(props), //remove the or statement once all form fields have dbColumn
      conditionValue: props.conditionValue
    };
    this.isEnterpriseTIN = props.metadata.dbColumn === SpecialField.TIN && !AuthenticationHelper.isTIRCorporate();
    this.handleCheckDuplicated = debounce(this.handleCheckDuplicated, 500);
  }

  componentWillUnmount() {
    this.handleCheckDuplicated.clear();
  }

  //TODO: Refactor this to make this a controlled component by redux, or by context.
  componentDidUpdate = prevProps => {
    if (prevProps.conditionValue !== this.props.conditionValue && this.props.conditionValue !== this.state.conditionValue) {
      this.setState({ conditionValue: this.props.conditionValue });
    }
    if (prevProps.fieldValue !== this.props.fieldValue && this.props.fieldValue !== this.state.fieldValue) {
      this.setState({ fieldValue: this.props.fieldValue });
    }
  };

  handleOnChange = (_, value, callback = () => null, isDefault = false) => {
    this.setState(
      { fieldValue: value },
      () => {
        this.handleCheckDuplicated();
        callback();
        this.props.actions.setFieldValue(this.state.fieldName, value, this.props.isClone, this.props.taxEntityCode, isDefault);
      }
    );
  };

  handleCheckDuplicated = () => {
    if (
      this.state.fieldValue
      && this.props.metadata.validation
      && this.props.metadata.validation.unique
    ) {
      if (this.state.fieldValue.toUpperCase() !== this.state.originalValue.toUpperCase()) {
        this.props.actions.checkDuplicated(this.state.fieldValue);
      } else {
        this.props.actions.checkDuplicated();
      }
    }
  };

  getCustomMessage = () => {
    if (this.props.isInUse) {
      return this.isEnterpriseTIN
        ? 'This combination is already in use'
        : 'This combination is already in use and it must be unique.';
    }

    return this.state.fieldName === SpecialField.TIN ? this.props.formFieldErrorMessage : '';
  };

  getValidation = validation => {
    if (validation) {
      if (validation.hasOwnProperty('activateOn')) {
        if (Array.isArray(validation.activateOn) && validation.operator){
          return validation.activateOn.map((activationCondition, index) => {
            const { condition, value } = activationCondition;
            return conditions[condition](this.props.conditionValue[index], value)
              ? validation
              : null;
          }).reduce((acc, nextVal) => conditions[validation.operator](acc, nextVal));
        }
        const { condition, value } = validation.activateOn;
        return conditions[condition](this.props.conditionValue, value)
          ? validation
          : null;
      }
      return validation;
    }
    return null;
  };

  render() {
    const { getValidation, getCustomMessage } = this;
    const { fieldName, fieldValue, previousFieldValue } = this.state;
    const { metadata, isInUse, resetForm, readOnlyUser, showAmountDifferenceField, modLevel, taxEntityCode, forceReadOnly } = this.props;
    const { type, multiLine, labelForm, placeholder, boxName, validation, permissions, readOnly, ...otherMetadataProps } = metadata;
    const processedValidation = getValidation(validation);

    return (AuthenticationHelper.verifyFieldProductMatch(permissions)) && createElement(
      formFields[type],
      {
        ...otherMetadataProps,
        validation: processedValidation,
        multiLine: multiLine || false,
        floatingLabelText: labelForm,
        label: fieldsWithDateMask.includes(type) ? labelForm : getLabel(processedValidation?.required, labelForm),
        labelValidation: labelForm,
        placeholder,
        boxName,
        name: fieldName,
        value: fieldValue,
        modLevel,
        previousValue: previousFieldValue,
        showPreviousDifferentFields: showAmountDifferenceField,
        isCustomCorrection: showAmountDifferenceField,
        //TODO: Refactor: remove fchar from here and inject that into the element using context
        taxEntityCode,
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
        reset: resetForm,
        isInUse,
        errorText: getCustomMessage(),
        readOnly: forceReadOnly || readOnly,
        readOnlyUser,
        runUIValidationAutomatically: true,
        customClasses: getCustomClassesByTaxEntityCode(taxEntityCode)
      }
    );
  }
}

FormField.propTypes = {
  getFieldValue: PropTypes.func.isRequired,
  metadata: PropTypes.object.isRequired,
  fieldValue: PropTypes.any,
  modLevel: PropTypes.number,
  previousFieldValue: PropTypes.any,
  resetForm: PropTypes.bool.isRequired,
  formFieldErrorMessage: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    setFieldValue: PropTypes.func,
    checkDuplicated: PropTypes.func
  }).isRequired,
  isClone: PropTypes.bool.isRequired,
  isInUse: PropTypes.bool.isRequired,
  conditionValue: PropTypes.any,
  readOnlyUser: PropTypes.bool.isRequired,
  forceReadOnly: PropTypes.bool,
  taxEntityCode: PropTypes.string.isRequired
};

FormField.defaultProps = {
  fieldValue: '',
  previousFieldValue: null,
  conditionValue: null,
  forceReadOnly: false
};

export default FormField;
