import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  fetchContextInfo,
  fetchSolutionConfigurations,
  fetchForms
} from 'redux/manageUser/ManageUserActions';
import { fetchClient, setOrganizationsTree, fetchContextsSuccess } from 'redux/context/contextActions';
import { fetchLatestNotification } from 'redux/notifications/NotificationsActions';
import PostLogin from './PostLogin';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      fetchContextInfo,
      fetchClient,
      fetchLatestNotification,
      fetchSolutionConfigurations,
      fetchForms,
      setOrganizationsTree,
      fetchContextsSuccess
    },
    dispatch
  )
});

export default connect(null, mapDispatchToProps)(PostLogin);
