import PropTypes from 'prop-types';
import SovosDialog from 'mosaic-react/sovos-dialog';
import SovosDialogTitle from 'mosaic-react/sovos-dialog-title';
import SovosDialogActions from 'mosaic-react/sovos-dialog-actions';
import SovosButton from 'mosaic-react/sovos-button';
import './correctionDialog.scss';

const CorrectionDialog = ({
  open,
  message,
  onClose,
  onDoNotMark,
  onMarkAsCorrectionAndClose
}) => (
  <SovosDialog
    modal
    open={ open }
    width='medium'
    onClose={ onClose }
    bodyStyle={ { overflowY: 'none' } }
    bodyClassName='correction-dialog__body'
  >
    <SovosDialogTitle
      showCloseButton
      onClose={ onClose }
    >
      Modify transmitted form?
    </SovosDialogTitle>
    { message }
    <SovosDialogActions>
      <SovosButton
        variant='text'
        onClick={ onDoNotMark }
      >
        Do not mark
      </SovosButton>
      <SovosButton
        onClick={ onMarkAsCorrectionAndClose }
      >
        Mark as correction
      </SovosButton>
    </SovosDialogActions>
  </SovosDialog>
);

CorrectionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onDoNotMark: PropTypes.func.isRequired,
  onMarkAsCorrectionAndClose: PropTypes.func.isRequired
};

export default CorrectionDialog;
