import actionTypes from './EntityConstants';

const initialStore = {
  entities: [],
  fetchingEntities: false
};

export default function EntityReducer(state = initialStore, action) {
  switch (action.type) {
    case actionTypes.FETCHING_ENTITIES_BY_PAYEE:
      return { ...state, fetchingEntities: true };

    case actionTypes.FETCH_ENTITIES_BY_PAYEE_SUCCESS:
      return { ...state, entities: action.entities, fetchingEntities: false };

    case actionTypes.FETCH_ENTITIES_BY_PAYEE_FAILURE:
      return { ...state, entities: [] };

    case actionTypes.RESET_ENTITIES_BY_PAYEE:
      return { ...state, entities: [], fetchingEntities: false };

    case actionTypes.LOGOUT_SUCCESS:
      return { ...initialStore };

    default:
      return state;
  }
}
