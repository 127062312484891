export const replaceStatusWording = status => {
  switch (status) {
    case 'Awaiting Approval':
      return 'Awaiting your approval';
    case 'In Queue':
      return 'In queue';
    case 'Pending Submission':
      return 'Pending submission';
    default:
      return status;
  }
};