import { isNoticeType } from 'pages/printCorp/helpers/notice/NoticeConstants';
import { getRecordName } from 'helpers/formConstants';
import { get } from 'helpers/axios/axiosInterceptorsV1';
import { buildUrlsParams } from 'helpers/buildUrlParams';

const usePrintHistory = () => {
  const fetchPrintHistory = async (pageSize = 5, pageOffset = 1, sortOrder, sortColumn) => {
    try {
      const query = {
        pageSize,
        pageOffset
      };
      if (sortOrder && sortColumn) {
        query.sortBy = sortOrder + sortColumn;
      }
      const path = `/prints/forms/history?${buildUrlsParams(query)}`;
      const response = await get(path);
      return response.data.data;
    } catch (err) {
      throw (err);
    }
  };

  const fetchPrintHistoryDetail = async (taxEntityCode, printNo) => {
    const isNotice = isNoticeType(taxEntityCode);
    const formPath = isNotice ? 'notice' : 'forms';
    const formType = isNotice ? taxEntityCode : getRecordName(taxEntityCode);

    try {
      const path = `/prints/${formPath}/${formType}/${printNo}`;
      const response = await get(path);
      const detail = response.data.data;
      return isNotice
        ? {
          ControlTotals: detail.ControlTotals,
          Form: {
            Pdf: detail.RenderedFiles.find(file => file.toLowerCase().includes('.pdf'))
          },
          NoticeData: detail.Id
            ? { ...detail }
            : {}
        }
        : {
          ControlTotals: detail.ControlTotals,
          Form: {
            ...detail.Form,
            FilingType: detail.PrintHistory.FilingType,
            Forms: detail.PrintHistory.Forms,
            PrintNo: detail.PrintHistory.PrintNo,
            Status: detail.PrintHistory.PrintStatus,
            Pdf: detail.PrintHistory.RenderedFiles.find(file => file.toLowerCase().includes('.pdf')),
            UsePrintServices: detail.PrintHistory.UsePrintServices === 'true'
          }
        };
    } catch (err) {
      throw (err);
    }
  };

  return {
    fetchPrintHistory,
    fetchPrintHistoryDetail
  };
};

export default usePrintHistory;