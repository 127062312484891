import { getRecordName } from 'helpers/formConstants';
import reprintFilterOption from 'pages/printCorp/helpers/reprintFilterOption';

export const getForms = (forms, response) => forms.map((form, idx) => ({
  ...form,
  Fchar: response[idx].FChar
}));

export const getMaskedForms = forms => forms.map(form => ({
  fChar: form.Fchar,
  name: getRecordName(form.Fchar),
  include: true
}));

export const getSelectedForms = (forms, result) => forms.map(form => {
  const fchar = form.Fchar || form.fChar;
  const printFile = result.find(responseForm => responseForm.FChar === fchar) || {};

  return {
    fChar: fchar,
    name: getRecordName(fchar),
    include: form.include ?? true,
    displayPrintFile: printFile.RenderedFiles ? printFile.RenderedFiles.find(element => element.endsWith('.pdf')) : null,
    controlTotals: printFile.ControlTotals,
    statements: printFile.Statements
  };
});

export const getSelectedPrintNotes = forms => {
  const printNotesSelected = {};
  forms
    .filter(form => form.PrintNoteKey)
    .forEach(form => printNotesSelected[form.Fchar] = form.PrintNoteKey);

  return printNotesSelected;
};

export const getSelectedPayers = (forms, state) => {
  let payersSelected = [];
  forms.forEach((form, idx) => {
    if (idx === 0) {
      payersSelected = form.Payers;
    } else {
      payersSelected = [
        ...payersSelected,
        ...form.Payers.filter(payer => (
          !payersSelected.find(payerSel => payerSel.PayerId === payer.PayerId)
        ))
      ];
    }
  });

  payersSelected = [
    ...state.config.payersSelected.map(payer => {
      const payerSel = payersSelected.find(payerSel => payerSel.PayerId === payer.id.toString());
      return {
        ...payer,
        include: Boolean(payerSel)
      };
    })
  ];

  return payersSelected;
};

export const getReprintOption = forms => {
  const reprintFilterOptionNumber = forms[0];

  switch (reprintFilterOptionNumber)
  {
    case 1:
      return reprintFilterOption.ONLY_NOT_MARKED_FOR_REPRINT;
    case 2:
      return reprintFilterOption.ONLY_MARKED_FOR_REPRINT;
    case 0:
    default:
      return reprintFilterOption.IGNORE;
  }
};
