import { useEffect, useState } from 'react';
import { buildUrlsParams } from 'helpers/buildUrlParams';
import { transmitType as transmitTypeConst } from 'pages/transmitCorp/helpers/configTransmitStatus';
import usePaginationForFetch from 'helpers/usePaginationForFetch';
import useColumnSort from 'helpers/useColumnSort';
import { get } from 'helpers/axios/axiosInterceptorsV1';
import { replaceStatusWording } from 'helpers/statusWordingHelper';
import { getLocalDate, getLocalTimestamp } from 'tir-frontend-common/helpers/dateHelper';
import { STATE_REPORTING } from 'helpers/configurationConstants';
import authenticationHelper from 'helpers/authenticationHelper';
import { fetchStateTransmit } from 'helpers/corpStateTransmitHelper';
import useFetchWithAbort from 'helpers/useFetchWithAbort';

const EOTFormTypes = {
  2: '1099',
  3: '5498',
  4: '1042-S'
};

const useTransmitHistory = ({
  transmitType,
  defaultItemsPerPage = 20,
  onError = () => {}
}) => {
  const [fetchingData, setFetchingData] = useState(true);
  const [transmittalsData, setTransmittalsData] = useState({ data: [], total: 0 });

  const { signal } = useFetchWithAbort({ fetchOnMount: false, onError });

  const {
    currentPage,
    itemsPerPage,
    handlePageChanged
  } = usePaginationForFetch(transmittalsData.total, defaultItemsPerPage);

  const {
    sortOrderIndicator,
    sortColumnIndicator,
    sortColumnId,
    onColumnSortChanged
  } = useColumnSort();

  useEffect(() => {
    const [stateReporting] = authenticationHelper.checkConfiguration([STATE_REPORTING]);
    const corporateStateReporting = stateReporting && authenticationHelper.isTIRCorporate();

    if (corporateStateReporting && transmitType === transmitTypeConst.state) {
      fetchStateTransmitRecords();
    } else {
      fetchTransmitHistory();
    }
  }, [itemsPerPage, currentPage, sortOrderIndicator, sortColumnId]);

  const fetchStateTransmitRecords = async () => {
    try {
      setFetchingData(true);
      const result = await fetchStateTransmit(currentPage, itemsPerPage);

      setTransmittalsData({
        total: result.Total,
        data: result.Data
      });
    } catch (error) {
      onError();
    } finally {
      setFetchingData(false);
    }
  };

  const fetchTransmitHistory = async () => {
    const fetchUrl = transmitType === transmitTypeConst.federal
      ? 'transmits/forms/history?'
      : 'transmittals/extensionOfTime/history?';
    try {
      setFetchingData(true);
      const parsedQueryString = buildUrlsParams({ pageSize: itemsPerPage, pageOffset: currentPage, sortBy: sortOrderIndicator + sortColumnIndicator });
      const response = await get(`${fetchUrl}${parsedQueryString}`, { signal });
      const formattedData = transmitType === transmitTypeConst.federal
        ? formatFederalTransmitHistory(response.data.data.Data)
        : formatEOTTransmitHistory(response.data.Data);
      const total = transmitType === transmitTypeConst.federal
        ? response.data.data.Total
        : response.data.Total;

      setTransmittalsData({
        total: total,
        data: formattedData
      });
    } catch {
      onError();
    }
    setFetchingData(false);
  };

  const formatFederalTransmitHistory = data => data.map(record => {
    let displayType = '';
    if (record.FormsToRun) {
      if (record.FormsToRun.length === 1) {
        displayType = record.FormsToRun[0];
      }
      else if (record.FormsToRun.length > 1) {
        displayType = `Multiple (${record.FormsToRun.length})`;
      }
    }

    return {
      ...record,
      DisplayFormType: displayType,
      DisplayStatus: `${replaceStatusWording(record.Status)} ${getLocalTimestamp(record.UpdatedOn)}`
    };
  });

  const formatEOTTransmitHistory = data => data.map(data => ({
    ...data,
    FormType: EOTFormTypes[data.FormType],
    CreatedOn: getLocalDate(data.CreatedOn)
  }));

  return {
    fetchingData,
    data: transmittalsData.data,
    total: transmittalsData.total,
    itemsPerPage,
    currentPage,
    sortColumnId,
    onPageChange: handlePageChanged,
    onColumnSortChanged
  };
};

export default useTransmitHistory;
