export const equals = (a, b) => a === b;
export const doesNotEqual = (a, b) => a !== b;
export const greater = (a, b) => a > b;
export const lower = (a, b) => a < b;
export const greaterEqual = (a, b) => a >= b;
export const lowerEqual = (a, b) => a <= b;
export const includes = (a, b) => a.includes(b);
export const isTruthy = a => !!a;
export const isFalsy = a => !a;
export const or = (a, b) => a || b;
export const and = (a, b) => a && b;
