/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';

const ColumnListItem = ({ visible, column }) => (
  <div
    className={ `column-selector__label column-selector__label_${(visible) ? 'visible' : 'hidden'}` }
  >
    { column.label }
  </div>
);

ColumnListItem.propTypes = {
  column: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired
};

export default ColumnListItem;
