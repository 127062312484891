import { batchType, batchFileMetadataType } from 'helpers/enums';
import { preprocessTransmittalBatches } from 'helpers/trackerHelper';
import { getLocalTimestamp } from 'tir-frontend-common/helpers/dateHelper';
import { get } from 'helpers/axios/axiosInterceptorsV1';
import { buildUrlsParams } from 'helpers/buildUrlParams';

const fetchStateTransmit = async (pageOffset = 1, pageSize = 5) => {
  const parsedQueryString = buildUrlsParams({ batchType: batchType.CorporateStateTransmittal, pageSize, pageOffset });
  let batchSummary = await get(`transmittals/corpstate/batches/summary?${parsedQueryString}`);

  const stateTransmits = batchSummary.data.Data.map(preprocessTransmittalBatches)
    .map(batch => {
      const events = batch.events.filter(event => event.date).sort((a, b) => new Date(b.date) - new Date(a.date));

      return {
        BatchId: String(batch.Id),
        TransmitRunNumber: batch.TransmitRunNumber,
        Agency: batch.regimeOrState,
        FormCount: batch.FormCountsByBatchID,
        PayerCount: batch.PayerCountsByBatchID,
        TransmitStatus: `${events[0].key} ${getLocalTimestamp(events[0].date)}`,
        ConfirmationNumber: batch.Files[0]?.Metadata.find(meta => meta.MetadataTypeId === batchFileMetadataType.StateConfirmationCode)?.MetadataValue || '--',
        FilingStatus: batch.filingStatus,
        FileName: batch.Files[0]?.FileName || '--'
      };
    });

  return {
    ...batchSummary.data,
    Data: stateTransmits
  };
};

export {
  fetchStateTransmit
};
