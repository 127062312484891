import actionTypes from './SelectFieldOptionsConstants';
import { get } from 'helpers/axios/axiosInterceptorsV1';

const fetchProvinceCountryCode = () => async dispatch => {
  dispatch({ type: actionTypes.FETCHING_PROVINCE_COUNTRY_CODE });
  try {
    const response = await get('codes/address-codes');
    dispatch({ type: actionTypes.FETCH_PROVINCE_COUNTRY_CODE_SUCCESS, payload: {
      'RESCOUNTRYCODE': response.data.Country.map(codes => ({ text: codes.AddressName, value: codes.AddressCode })),
      'PROVINCECODE': response.data.Province.map(codes => ({ text: codes.AddressName, value: codes.AddressCode })),
      'COUNTRYCODE': response.data.Country.map(codes => ({ text: codes.AddressName, value: codes.AddressCode })),
      'NQCOUNTRYCODE': response.data.Country.map(codes => ({ text: codes.AddressName, value: codes.AddressCode })),
      'NQCOUNTRY': response.data.Country.map(codes => ({ text: codes.AddressName, value: codes.AddressCode })),
      'NQPROVINCE': response.data.Province.map(codes => ({ text: codes.AddressName, value: codes.AddressCode })),
      'MAILINGCOUNTRYCODE': response.data.Country.map(codes => ({ text: codes.AddressName, value: codes.AddressCode })),
      'MAILINGPROVINCECODE': response.data.Province.map(codes => ({ text: codes.AddressName, value: codes.AddressCode }))
    } });
  } catch (ex) {
    dispatch({ type: actionTypes.FETCH_PROVINCE_COUNTRY_CODE_FAILED });
    throw (ex);
  }
};

export { fetchProvinceCountryCode };
