import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import classNames from 'classnames';
import RefreshIndicatorLoading from '../refreshIndicatorLoading/refreshIndicatorLoading';
import Typography from '@mui/material/Typography';
import './infoCard.scss';

const InfoCard = ({ type, total, text, color, isUpdating, className, style, hover, showBorderBottom, onRecordCardClick }) => (
  <Card
    className={ classNames(
      'info-card__card',
      { 'active': !isUpdating && parseInt(total) && hover },
      className
    ) }
    style={ {
      ...style,
      borderBottom: showBorderBottom ? `6px solid ${color}` : 'none'
    } }
    onClick={ () => !isUpdating && parseInt(total)
      ? onRecordCardClick(text, type)
      : () => {}
    }
  >
    <div className="info-card__content">
      {
        isUpdating ? <RefreshIndicatorLoading /> : (
          <div>
            <Typography variant="h2" style={ { color } }>{ total }</Typography>
            <Typography variant="body1" className="info-card__paragraph">{ text }</Typography>
          </div>
        )
      }
    </div>
  </Card>
);

InfoCard.propTypes = {
  className: PropTypes.string,
  onRecordCardClick: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  color: PropTypes.string.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  style: PropTypes.object,
  showBorderBottom: PropTypes.bool,
  hover: PropTypes.bool
};

InfoCard.defaultProps = {
  className: '',
  type: '',
  style: {},
  showBorderBottom: true,
  hover: true
};

export default InfoCard;
