import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setFieldValue } from '../../../sovos-dynamic-form/redux/DynamicFormActions';
import MailingAddressAutoCheckBox from './MailingAddressAutoCheckBox';

const mapStateToProps = (state, ownProps) => ({
  checkBoxValue: state.dynamicForm.isClone ? state.dynamicForm.cloneForm?.MAILINGADDRESSAUTOCHECKBOX : state.dynamicForm.form[ownProps.taxEntityCode]?.MAILINGADDRESSAUTOCHECKBOX,
  taxEntityCode: state.dynamicForm.taxEntityCode,
  isClone: state.dynamicForm.isClone
});

const mapDispatchToProps = dispatch => ({
  formActions: bindActionCreators({ setFieldValue }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MailingAddressAutoCheckBox);