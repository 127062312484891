import { get } from 'helpers/axios/axiosInterceptorsV1';
import actionTypes from './PrintPreviewConstants';

const fetchPreview = (formId, fileName) => async dispatch => {
  const path = `/prints/forms/${formId}/preview`;

  try {
    dispatch({ type: actionTypes.FETCHING_PRINT_PREVIEW });
    const response = await get(path, { responseType: 'arraybuffer' });
    downloadFile(response.data, fileName);
    dispatch({ type: actionTypes.FETCH_PRINT_PREVIEW_SUCCESS });
  } catch (err) {
    dispatch({ type: actionTypes.FETCH_PRINT_PREVIEW_FAILURE });
    throw (err);
  }
};

const downloadFile = (fileData, fileName) => {
  const blob = new Blob([fileData], { type: 'application/pdf' });
  const url = window.URL.createObjectURL(blob);

  const link = document.createElement("a");
  document.body.appendChild(link);
  link.style = "display: none";
  link.href = url;
  link.download = fileName;
  link.click();

  window.URL.revokeObjectURL(url);
};

export {
  fetchPreview,
  downloadFile
};
