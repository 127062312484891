import actionTypes from './ImportMappingConstants';

const defaultMapToEdit = {
  Id: 0,
  Name: '',
  Description: '',
  Fields: []
};

const initialStore = {
  mapHeaders: [],
  fetchingHeaders: false,
  savingMap: false,
  fetchingMapById: false,
  fetchingMapPreview: false,
  mapToEdit: defaultMapToEdit,
  deletedMapId: 0,
  deletingMapId: false,
  mapPreview: {},
  currentFchar: '',
  userError: ''
};

const importHistoryGridReducer = (state = initialStore, action) => {
  switch (action.type) {

    case actionTypes.FETCHING_FILE_HEADERS:
      return { ...state, fetchingHeaders: true, userError: '' };

    case actionTypes.FETCH_FILE_HEADERS_SUCCESS:
      return { ...state, mapHeaders: action.headers, userError: '', fetchingHeaders: false };

    case actionTypes.RESET_MAP_HEADERS:
      return { ...state, mapHeaders: [] };

    case actionTypes.FETCH_FILE_HEADERS_FAILURE:
      return { ...state, userError: action.error, fetchingHeaders: false };

    case actionTypes.SAVING_MAP:
      return { ...state, savingMap: true };

    case actionTypes.SAVE_MAP_SUCCESS:
      return { ...state, savingMap: false, mapToEdit: defaultMapToEdit, currentFchar: action.currentFchar, userError: '' };

    case actionTypes.SAVE_MAP_FAILURE:
      return { ...state, savingMap: false, userError: action.errorMessage };

    case actionTypes.FETCHING_MAP_BY_ID:
      return { ...state, fetchingMapById: true, currentFchar: '' };

    case actionTypes.FETCH_MAP_BY_ID_SUCCESS: {
      const newMapToEdit = {
        ...action.map,
        Fields: action.map.Fields.map(field => ({ ...field, isSelected: false })),
        RecordFilters: action.map.RecordFilters || [],
        Description: action.map.Description || ''
      };
      return { ...state, fetchingMapById: false, mapToEdit: newMapToEdit, currentFchar: action.currentFchar };
    }

    case actionTypes.FETCH_MAP_BY_ID_FAILURE:
      return { ...state, fetchingMapById: false, mapToEdit: defaultMapToEdit };

    case actionTypes.FETCHING_MAP_PREVIEW:
      return { ...state, fetchingMapPreview: true };

    case actionTypes.FETCH_MAP_PREVIEW_SUCCESS: {
      const newMapPreview = { ...action.map };
      return {
        ...state,
        fetchingMapPreview: false,
        mapPreview: {
          ...state.mapPreview,
          [action.fchar]: newMapPreview
        },
        userError: ''
      };
    }

    case actionTypes.FETCH_MAP_PREVIEW_FAILURE:
      return {
        ...state,
        fetchingMapPreview: false,
        mapPreview: {
          ...state.mapPreview,
          [action.fchar]: {}
        },
        userError: action.errorMessage
      };

    case actionTypes.RESET_MAP_FORM:
      return { ...state, mapToEdit: defaultMapToEdit };

    case actionTypes.DELETING_MAP_BY_ID:
      return { ...state, deletingMapId: true };

    case actionTypes.DELETE_MAP_BY_ID_SUCCESS:
      return { ...state, deletedMapId: action.deletedMapId };

    case actionTypes.DELETE_MAP_BY_ID_FAILURE:
      return { ...state, deletingMapId: false };

    default:
      return state;
  }
};

export default importHistoryGridReducer;
