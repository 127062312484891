import { useState } from 'react';

const defaultSortColumn = { sortIndicator: '', id: '' };

const getOrderIndicator = isAscending => {
  if (isAscending === null) {
    return '';
  }

  return isAscending ? '+' : '-';
};

const useColumnSort = (_sortColumn = null, _isAscending = null) => {
  const [sortColumn, setSortColumn] = useState(_sortColumn || defaultSortColumn);
  const [isAscending, setIsAscending] = useState(_isAscending);

  const sortOrderIndicator = getOrderIndicator(isAscending);
  const sortColumnIndicator = sortColumn.sortIndicator;
  const sortColumnId = sortColumn.id;

  const onColumnSortChanged = newColumn => {
    const _isAscending = sortColumn.id === newColumn.id ? !isAscending : true;
    setSortColumn(newColumn);
    setIsAscending(_isAscending);
  };

  const resetColumnSort = () => {
    setSortColumn(_sortColumn || defaultSortColumn);
    setIsAscending(_isAscending);
  };

  return {
    resetColumnSort,
    // used for fetch
    sortOrderIndicator,
    sortColumnIndicator,
    // used for sovos table
    sortColumnId,
    onColumnSortChanged
  };
};

export default useColumnSort;