import { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { getLabel } from '../../dataEntryForms/sovos-dynamic-form/helper/labelHelper';
import SovosTooltip from '../../sovos-tooltip/SovosTooltip';
import { cleanMask } from './helper/maskHelper';
import Validation from './helper/Validation';
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';
import theme from 'mosaic-react/themes/sovos-theme';
import './validationGridTextField/validationGridTextField.scss';

const { palette } = theme;

const styles = {
  root: {
    boxSizing: 'border-box',
    '& .MuiInputBase-root': {
      '&:before': {
        content: 'none'
      },
      '&:after': {
        borderBottom: 'none'
      }
    }
  },
  input: {
    height: 50,
    boxSizing: 'border-box',
    padding: '0 10px',
    borderLeft: `1px solid ${palette.divider}`
  }
};

class PreviousCurrentTextFieldForm extends Component {
  constructor(props) {
    super(props);
    this.objRef = {};
    this.isMaxLengthInvalid = false;
    this.validation = new Validation(this.props.name, this.props.labelValidation, this.props.validation, this.props.mask, this.handleOnValidation);
    this.state = {
      validationErrorMessage: ''
    };
  }

  componentDidMount() {
    this.validation.addValidation(this.getInputRef());
  }

  componentDidUpdate = prevProps => {
    if (!prevProps.reset && this.props.reset) {
      this.validation.validateComponent(null, this.props.reset, this.isMaxLengthInvalid, (valid, message) => {
        this.setState({ validationErrorMessage: message }, () => this.props.onValid(valid));
      });
    }

    if (!prevProps.errorText && this.props.errorText) {
      this.validation.validateComponent(
        null,
        this.props.reset,
        this.isMaxLengthInvalid,
        (valid, message) => {
          this.setState({ validationErrorMessage: message }, () => this.props.onValid(valid));
        },
        this.props.errorText
      );
    }

    if (prevProps.errorText && !this.props.errorText) {
      this.validation.validateComponent(
        null,
        true,
        this.isMaxLengthInvalid,
        (valid, message) => {
          this.setState({ validationErrorMessage: message }, () => this.props.onValid(valid));
        }
      );
    }
  };

  getInputRef = () => {
    if (this.objRef && this.objRef.input) {
      if (this.props.multiLine) {
        return this.objRef.input.refs.input;
      }
      return this.props.mask ? this.objRef.input.inputElement : this.objRef.input;
    }
    return document.getElementById(this.props.name);
  };

  handleOnValidation = (valid, message) => {
    this.setState({ validationErrorMessage: message }, () => this.props.onValid(valid));
  };

  handleOnInvalid = evt => {
    this.validation.validateComponent(evt, null, this.isMaxLengthInvalid, (valid, message) => {
      this.setState({ validationErrorMessage: message }, () => this.props.onValid(valid));
    });
    evt.preventDefault();
  };

  handleOnChange = (evt, val) => {
    const value = cleanMask(val, this.props.mask);
    if (this.props.validation && this.props.validation.maxLength) {
      this.isMaxLengthInvalid = value.length > this.props.validation.maxLength;
      this.getInputRef().setCustomValidity(this.isMaxLengthInvalid || '');
    }
    if (!this.isMaxLengthInvalid) {
      this.props.onChange(evt, this.props.toUpper ? value.toUpperCase() : value);
    }
  };

  getFixedValue = (value, mask) => {
    const fractionDigits = mask === 'SHARES' ? 4 : 2;
    return parseFloat(value).toFixed(fractionDigits);
  };

  getBoxNameLabel = (showError, className) => {
    const { boxName, name } = this.props;
    return (
      boxName &&
      <label
        className={ classNames('tirTexfield__label-box', { 'tirTexfield__label-error': showError }, className) }
        htmlFor={ name }
      >
        { boxName }
      </label>
    );
  };

  getInputProps = () => {
    const inputProps = {
      'data-for': this.props.name
    };
    return inputProps;
  };

  render = () => {

    const {
      validationErrorMessage
    } = this.state;

    const {
      label,
      hideLabel,
      name,
      previousValue,
      validation,
      customClasses,
      showPreviousDifferentFields,
      readOnlyUser,
      readOnly,
      onBlur,
      value,
      classes
    } = this.props;

    const showError = Boolean(validationErrorMessage);

    return (
      <div className={ classNames('tirTextfieldForm__wrapper', 'amountDifference__wrapper', customClasses.tirTextfieldFormWrapper) }>
        { !hideLabel &&
          <div className='label__container'>
            <div className='label__container-left'>
              { this.getBoxNameLabel(showError, 'label__left-box-name') }
              <label
                className={ classNames('tirTexfield__label', showError && 'tirTexfield__label-error', customClasses.tirTexfieldLabel) }
                htmlFor={ name }
              >
                { getLabel(validation?.required, label) }
              </label>
            </div>
            { this.getBoxNameLabel(showError) }
          </div>
        }

        <div className={ classNames({ 'amount-field__display': showPreviousDifferentFields }) }>
          {
            showPreviousDifferentFields &&
            <div className={ classNames('textField__container', customClasses.textFieldContainer ) }>
              <TextField
                className='tirTextfieldForm__input'
                autoComplete='off'
                value={ previousValue }
                disabled={ true }
                classes={ { root: classes.root } }
                InputProps={ {
                  classes: { input: classes.input }
                } }
                variant='filled'
              />
            </div>
          }

          <div className={ classNames('textField__container', customClasses.textFieldContainer ) }>
            <SovosTooltip
              id={ name }
              label={ validationErrorMessage }
              show={ showError }
              place='right'
              className='tir-texfield__error'
              effect='solid'
            >
              <TextField
                id={ name }
                name={ name }
                className='tirTextfieldForm__input'
                autoComplete='off'
                value={ value }
                disabled={ readOnlyUser || readOnly }
                onChange={ evt => this.handleOnChange(evt, evt.target.value) }
                onBlur={ onBlur }
                classes={ { root: classes.root } }
                InputProps={ {
                  classes: { input: classes.input },
                  disableUnderline: true
                } }
                onInvalid={ this.handleOnInvalid }
                inputProps={ this.getInputProps() }
                variant='filled'
              />
            </SovosTooltip>
          </div>
        </div>

      </div>
    );
  };
}

PreviousCurrentTextFieldForm.propTypes = {
  label: PropTypes.node.isRequired,
  labelValidation: PropTypes.string,
  hideLabel: PropTypes.bool,
  boxName: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  placeholder: PropTypes.string,
  previousValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  mask: PropTypes.string,
  multiLine: PropTypes.bool,
  multiLineConfig: PropTypes.shape({
    rows: PropTypes.number.isRequired,
    rowsMax: PropTypes.number.isRequired
  }),
  validation: PropTypes.shape({
    pattern: PropTypes.string,
    message: PropTypes.string,
    validateOn: PropTypes.string,
    required: PropTypes.bool,
    maxLength: PropTypes.number
  }),
  disabledByEntityFetcher: PropTypes.array,
  reset: PropTypes.bool,
  onValid: PropTypes.func,
  readOnly: PropTypes.bool,
  readOnlyUser: PropTypes.bool.isRequired,
  customClasses: PropTypes.objectOf(PropTypes.string),
  showPreviousDifferentFields: PropTypes.bool,
  toUpper: PropTypes.bool,
  errorText: PropTypes.string
};

PreviousCurrentTextFieldForm.defaultProps = {
  labelValidation: '',
  boxName: '',
  hideLabel: false,
  placeholder: '',
  previousValue: '',
  value: '',
  mask: '',
  multiLine: false,
  multiLineConfig: {
    rows: 1,
    rowsMax: 4
  },
  onBlur: () => {},
  onKeyDown: null,
  onKeyPress: null,
  validation: null,
  reset: false,
  onValid: () => {},
  customClasses: {},
  disabledByEntityFetcher: [],
  showPreviousDifferentFields: false,
  toUpper: false,
  errorText: ''
};

export default withStyles(styles)(PreviousCurrentTextFieldForm);
