import { Component } from 'react';
import PropTypes from 'prop-types';
import RefreshIndicatorLoading from 'shared/refreshIndicatorLoading/refreshIndicatorLoading';
import FormHistory from './formHistory/FormHistoryConnector';
import { ActionType } from './helpers/formHistoryHelper';
import './historyTab.scss';

class HistoryTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formHistory: []
    };
  }

  componentDidMount = () => {
    this.props.historyActions.fetchRecordHistory();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.fetchingHistory && !this.props.fetchingHistory) {
      this.setState({ formHistory: this.props.formHistory });
    }
    if (!prevProps.shouldFetchAgain && this.props.shouldFetchAgain) {
      this.props.historyActions.fetchRecordHistory();
    }
  }

  render() {
    return (
      this.props.fetchingHistory || this.props.fetchingMetadata
        ? <div className='history-tab__loader'>
          <RefreshIndicatorLoading size={ 40 } containerStyle={ { position: 'relative' } } />
        </div>
        : (
          <div className="history-tab__main-div" data-testid='history-tab'>
            {
              this.props.formHistory &&
              this.props.formHistory.length > 0 &&
              this.props.formHistory
                .filter(history => !(history.Undone || history.Action === ActionType.cancelExtensionOfTime))
                .map((history, idx) => (
                  <FormHistory
                    formType={ this.props.formType }
                    history={ history }
                    key={ idx }
                  />
                ))
            }
          </div>
        )
    );
  }
}

HistoryTab.propTypes = {
  shouldFetchAgain: PropTypes.bool.isRequired,
  formType: PropTypes.string.isRequired,
  formHistory: PropTypes.array.isRequired,
  fetchingHistory: PropTypes.bool.isRequired,
  fetchingMetadata: PropTypes.bool.isRequired,
  historyActions: PropTypes.shape({
    fetchRecordHistory: PropTypes.func
  }).isRequired
};

export default HistoryTab;
