import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SearchIcon from '@mui/icons-material/Search';
import SovosSelect from 'mosaic-react/sovos-select';
import SovosMenuItem from 'mosaic-react/sovos-menu-item';
import SovosIconButton from 'mosaic-react/sovos-icon-button';
import ValidationTextField from 'shared/standardInput/textField/validationTextField/ValidationTextField';
import withRouter from 'helpers/withRouter';
import './searchBar.scss';

const defaultValidation = {
  pattern: '^[a-zA-Z0-9]{3,}$',
  message: 'Please input at least 3 characters to begin search'
};

const fields = [
  {
    key: 'AccountNumber',
    text: 'Account Number',
    validation: {
      pattern: 'MAXLENGTH20',
      specialCharacters: '\\\\\\/#\\(\\)_\\-\\|:><',
      message: 'Account number must contain 20 or fewer valid characters'
    }
  },
  {
    key: 'Name',
    text: 'Name',
    validation: {
      pattern: 'MAXLENGTH40',
      specialCharacters: '&\\-',
      message: 'Name must contain 40 or fewer valid characters'
    }
  },
  {
    key: 'GML',
    text: 'GML',
    validation: {
      pattern: '^.{0,100}$',
      message: 'GML must contain 100 or fewer characters'
    }
  },
  {
    key: 'Group',
    text: 'Group',
    validation: {
      pattern: 'MAXLENGTH100',
      message: 'Group must contain 100 or fewer valid characters'
    }
  },
  {
    key: 'TIN',
    text: 'TIN',
    validation: {
      pattern: '(^\\d{9}|\\d\\d-\\d\\d\\d\\d\\d\\d\\d{1}|\\d\\d\\d-\\d\\d-\\d\\d\\d\\d{1}$)', // 111111111, 11-1111111, 111-11-1111
      message: 'TIN must contain only 9 digits'
    }
  }
];

const SearchBar = ({ router, fetchAvailableEntityTypes, searchBarOpenedByDefault, updateSearchFilters }) => {
  const initialValue = router.location.pathname === '/search-result'
    ? Boolean(sessionStorage.getItem('search-filters')) && JSON.parse(sessionStorage.getItem('search-filters'))
    : { searchText: '', fieldSelected: 'TIN' };

  const [isSearchOpen, setIsSearchOpen] = useState(searchBarOpenedByDefault);
  const [searchTextValid, setSearchTextValid] = useState(false);
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (router.location.pathname === '/search-result') {
      const searchCriteria = getBody(value.searchText, value.fieldSelected);

      fetchAvailableEntityTypes(searchCriteria);
      setIsSearchOpen(true);
    }
  }, []);

  const handleSearch = () => {
    if (value.searchText && searchTextValid) {
      const searchCriteria = getBody(value.searchText, value.fieldSelected);
      sessionStorage.setItem('search-filters', JSON.stringify(value));
      switch (router.location.pathname) {
        case '/search-result':
          fetchAvailableEntityTypes(searchCriteria);
          break;
        case '/tax-identity-management/recipient-grouping':
          router.navigate('/tax-identity-management/recipient-grouping/search');
          break;
        case '/tax-identity-management/recipient-grouping/search':
          updateSearchFilters(searchCriteria);
          break;
        default:
          router.navigate('/search-result');
      }
    }
  };

  const handleIconButton = () => {
    if (isSearchOpen) {
      handleSearch();
    } else {
      setIsSearchOpen(true);
    }
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const getValidationBasedFieldSelected = (value, fieldSelected) => {
    if (value.length === 0) {
      return {};
    }

    if (value.length < 3) {
      return defaultValidation;
    }

    return fields.find(field => field.key === fieldSelected).validation;
  };

  const getBody = (searchText, fieldSelected) => {
    const searchCriteria = {
      searchText: cleanSearchText(searchText, fieldSelected),
      fieldSelected: fieldSelected
    };

    return searchCriteria;
  };

  const cleanSearchText = (searchText, fieldSelected) => {
    let value = searchText;
    if (fieldSelected === 'TIN') {
      return value.replace(/[- ]+/g, '');
    }

    return value;
  };

  return (
    <div className='search-bar__container'>
      <div className='search-bar__container-wrapper'>
        <div className={ classnames('search-bar__fields', { 'search-bar__fields--open': isSearchOpen, 'search-bar__fields--closed': !isSearchOpen }) }>
          <ValidationTextField
            className='search-bar__input'
            variant='standard'
            name='search-text'
            placeholder='Search'
            value={ value.searchText }
            onChange={ evt => setValue(prevValue => (
              {
                ...prevValue,
                searchText: evt.target.value
              }
            )) }
            validation={ {
              validateOn: 'keyup',
              ...getValidationBasedFieldSelected(value.searchText, value.fieldSelected)
            } }
            onValidation={ valid => setSearchTextValid(valid) }
            onKeyDown={ handleKeyDown }
            runUIValidationAutomatically
            data-for='search-bar-input'
          />
          <SovosSelect
            className='search-bar__select'
            variant='standard'
            value={ value.fieldSelected }
            onChange={ evt => setValue({
              searchText: value.searchText,
              fieldSelected: evt.target.value
            }) }
            data-for='search-bar-select'
          >
            {
              fields.map(field =>
                <SovosMenuItem
                  value={ field.key }
                  key={ field.key }
                >
                  { field.text }
                </SovosMenuItem>
              )
            }
          </SovosSelect>
        </div>
        <SovosIconButton
          tooltipText=''
          onClick={ handleIconButton }
          disabled={ !searchTextValid }
          data-for='expand-search-bar'
        >
          <SearchIcon/>
        </SovosIconButton>
      </div>
    </div>
  );

};

SearchBar.propTypes = {
  router: PropTypes.shape({
    navigate: PropTypes.func,
    location: PropTypes.object
  }).isRequired,
  fetchAvailableEntityTypes: PropTypes.func,
  searchBarOpenedByDefault: PropTypes.bool,
  updateSearchFilters: PropTypes.func
};

SearchBar.defaultProps = {
  fetchAvailableEntityTypes: () => {},
  searchBarOpenedByDefault: false,
  updateSearchFilters: () => {}
};

export default withRouter(SearchBar);
