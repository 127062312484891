import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { post } from 'helpers/axios/axiosInterceptorsV1';
import authenticationHelper from 'helpers/authenticationHelper';
import RefreshIndicatorLoading from 'shared/refreshIndicatorLoading/refreshIndicatorLoading';

const LoginOidc = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.isAuthenticated) {
      sessionStorage.clear();
      authenticationHelper.storeJwtToken(auth.user.access_token, 'true');
      authenticationHelper.storeIdToken(auth.user.id_token);
      trackUser();
      navigate('/post-login');
    }
  }, [auth]);

  const trackUser = async () => {
    try {
      await post('/authorization/user/login', { authenticationIssuer: authenticationHelper.getValueFromJwtPayload('iss') });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <RefreshIndicatorLoading style={ { backgroundColor: 'transparent' } } />
    </div>
  );
};

export default LoginOidc;