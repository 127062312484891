import actionsTypes from './authConstants';
import AuthenticationHelper from 'helpers/authenticationHelper';

const initialState = {
  user: {
    Username: '',
    Domain: '',
    Schema: '',
    Seghash: '',
    ClientName: '',
    LiteUsername: '',
    iat: 0
  },
  logged: false,
  errorMessage: '',
  loggingIn: false
};
const usableState = Object.assign({}, initialState);

if (AuthenticationHelper.isJwtTokenStored()) {
  usableState.user = AuthenticationHelper.getDecodedToken();
  usableState.logged = true;
}

export default function loginReducer(state = usableState, action) {
  switch (action.type) {

    case actionsTypes.PERFORMING_LOGIN:
      return {
        ...state,
        loggingIn: true,
        logged: false
      };

    case actionsTypes.LOGIN_SUCCESS:
      sessionStorage.setItem('s1User', action.s1User);
      return {
        ...state,
        user: AuthenticationHelper.getDecodedToken(),
        loggingIn: false,
        logged: true
      };

    case actionsTypes.LOGIN_FAILURE:
      return {
        ...state,
        loggingIn: false,
        logged: false,
        errorMessage: action.errorMessage
      };

    case actionsTypes.PERFORMING_LOGOUT:
      return {
        ...state,
        loggingIn: false
      };

    case actionsTypes.LOGOUT_SUCCESS:
      return { ...initialState };

    case actionsTypes.LOGOUT_FAILURE:
      return {
        ...initialState
      };

    default:
      return { ...state };
  }
}
