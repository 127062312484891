import { useLocation, useNavigate, useParams } from "react-router-dom";

const withRouter = Component => {
  const useComponentWithRouterProp = props => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return (
      <Component
        { ...props }
        router={ { location, navigate, params } }
      />
    );
  };

  return useComponentWithRouterProp;
};

export default withRouter;
