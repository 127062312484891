import actionTypes from './NRecipientConstants';
import formActionTypes from 'shared/dataEntryForms/sovos-dynamic-form/redux/DynamicFormConstants';

const initialStore = {
  data: {},
  nRecipients: [],
  fetching: false,
  nRecipientSet: false
};

export default function RecipientReducer(state = initialStore, action) {
  switch (action.type) {
    case actionTypes.FETCHING_N_RECIPIENT:
      return { ...state, fetching: true };

    case actionTypes.FETCH_N_RECIPIENT_SUCCESS:
      return { ...state, data: action.nRecipient, fetching: false };

    case actionTypes.FETCH_N_RECIPIENT_FAILURE:
      return { ...state, fetching: false, data: {} };

    case actionTypes.RESET_N_RECIPIENT:
      return { ...state, data: {} };

    case actionTypes.FETCH_N_RECIPIENT_BY_REGEX_SUCCESS:
      return { ...state, nRecipients: action.nRecipients, fetching: false };

    case actionTypes.FETCH_N_RECIPIENT_BY_REGEX_FAILURE:
      return { ...state, fetching: false, nRecipients: [] };

    case actionTypes.SET_N_RECIPIENT:
      return { ...state, data: action.nRecipient, nRecipients: [], nRecipientSet: true };

    case actionTypes.SET_N_RECIPIENT_BOOLEAN:
      return { ...state, nRecipientSet: true };

    case formActionTypes.RESET_FORM:
    case actionTypes.LOGOUT_SUCCESS:
      return { ...initialStore };

    default:
      return state;
  }
}
