import sharedActions from 'redux/constants/SharedConstants';

const dynamicFormActions = {
  ...sharedActions,
  SET_FIELD_VALUE: 'SET_FIELD_VALUE',
  SET_FIELD_VALUE_CLONE: 'SET_FIELD_VALUE_CLONE',
  SET_FORM_VALUE: 'SET_FORM_VALUE',
  SET_FORM_ERRORS: 'SET_FORM_ERRORS',
  ASSIGNED_RECIPIENT_PAYER: 'ASSIGNED_RECIPIENT_PAYER',
  FETCH_FORM_SUCCESS: 'FETCH_FORM_SUCCESS',
  FETCH_FORM_FAILURE: 'FETCH_FORM_FAILURE',
  FETCHING_FORM: 'FETCHING_FORM',
  RESET_FORM: 'RESET_FORM',
  RESET_STATE_CLONE_FORM: 'RESET_STATE_CLONE_FORM',
  RESET_EXTRA_FORM: 'RESET_EXTRA_FORM',
  RESET_TO_ORIGINAL_FORM: 'RESET_TO_ORIGINAL_FORM',
  CHANGE_VALID_FORM: 'CHANGE_VALID_FORM',
  SET_FORM_EDITOR_TITLE: 'SET_FORM_EDITOR_TITLE',
  SET_SAVE_FORM_SUCCESS: 'SET_SAVE_FORM_SUCCESS',
  OVERRIDE_FORM_MODIFIED: 'OVERRIDE_FORM_MODIFIED',
  ADD_NEW_FORM: 'ADD_NEW_FORM',
  SET_FORM_ID: 'SET_FORM_ID',
  CHEKING_CONDITION_DELETE: 'CHEKING_CONDITION_DELETE',
  CREATING_CLONE: 'CREATING_CLONE',
  CHECK_CONDITION_DELETE_SUCCESS: 'CHECK_CONDITION_DELETE_SUCCESS',
  CREATED_CLONE_SUCCESS: 'CREATED_CLONE_SUCCESS',
  CREATED_CLONE_FAILURE: 'CREATED_CLONE_FAILURE',
  DELETING_FORM: 'DELETING_FORM',
  DELETE_FORM_SUCCESS: 'DELETE_FORM_SUCCESS',
  DELETE_FORM_FAILURE: 'DELETE_FORM_FAILURE',
  SET_DELETED: 'SET_DELETED',
  SET_FCHAR_TO_DELETE: 'SET_FCHAR_TO_DELETE',
  CLONING_FORM: 'CLONING_FORM',
  CLONE_FORM_SUCCESS: 'CLONE_FORM_SUCCESS',
  SET_FCHAR_TO_CLONE: 'SET_FCHAR_TO_CLONE',
  SET_SAVE_DISABLE_BY_VALIDATION: 'SET_SAVE_DISABLE_BY_VALIDATION',
  SET_SHOW_MARK_AS_CORRECTION: 'SET_SHOW_MARK_AS_CORRECTION',
  SET_DISABLED_BY_ENTITY_FETCHER: 'SET_DISABLED_BY_ENTITY_FETCHER',
  UNSET_DISABLED_BY_ENTITY_FETCHER: 'UNSET_DISABLED_BY_ENTITY_FETCHER',
  FETCHING_IDENTITY_MANAGEMENT: 'FETCHING_IDENTITY_MANAGEMENT',
  FETCHING_IDENTITY_MANAGEMENT_COMPLETE: 'FETCHING_IDENTITY_MANAGEMENT_COMPLETE',
  SET_FETCHING_FORM: 'SET_FETCHING_FORM',
  SET_FORM_MODIFIED_BY_TIN_VALIDATION: 'SET_FORM_MODIFIED_BY_TIN_VALIDATION',
  SET_OPEN_EXTRA_FORM_SLIDING: 'SET_OPEN_EXTRA_FORM_SLIDING',
  SET_TAX_ENTITY_CODE: 'SET_TAX_ENTITY_CODE',
  SET_TAX_ENTITY_CODE_BASE: 'SET_TAX_ENTITY_CODE_BASE',
  SET_FETCH_FORM_SUCCESS: 'SET_FETCH_FORM_SUCCESS',
  CLEAR_STORE_FORM: 'CLEAR_STORE_FORM'
};

export default dynamicFormActions;
