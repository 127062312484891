import actionTypes from './SelectFieldOptionsConstants';

const initialStore = {
  selects: {},
  fetchingSelects: false
};

export default function SelectFieldOptionsReducer(state = initialStore, action) {

  switch (action.type) {
    case actionTypes.FETCHING_PROVINCE_COUNTRY_CODE:
      return { ...state, fetchingSelects: true };

    case actionTypes.FETCH_PROVINCE_COUNTRY_CODE_SUCCESS:
      return { ...state, fetchingSelects: false, selects: { ...state.selects, ...action.payload } };

    case actionTypes.FETCH_PROVINCE_COUNTRY_CODE_FAILED:
      return { ...state, fetchingSelects: false };

    case actionTypes.LOGOUT_SUCCESS:
      return { ...initialStore };

    default:
      return state;
  }
}
