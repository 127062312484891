import { Component } from 'react';
import PropTypes from 'prop-types';
import SovosTableGroup from 'mosaic-react/sovos-table-group';
import SovosReadOnlySlidingPanel from 'mosaic-react/sovos-readonly-sliding-panel';
import RecordTable from '../recordTable/RecordTableConnector';
import TaxEntityEnum from '../TaxtEntityStatusEnum';
import columns from './ErrorTableColumns';
import './errors.scss';

const initialSort = { id: columns[0].id, sortIndicator: columns[0].sortIndicator };

class Errors extends Component {
  state = {
    pageOffset: 1,
    pageSize: 20,
    openValidationDetails: false,
    titleValidationDetails: '',
    validationId: null,
    fchar: '',
    errorDetail: {
      searchText: '',
      pageOffset: 1,
      pageSize: 15,
      sortColumn: '',
      sortOrder: ''
    },
    sortColumn: initialSort,
    isAscending: true
  };

  componentDidMount() {
    this.fetchAllErrors();
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.formHasBeenSaved && this.props.formHasBeenSaved)
      || (!prevProps.formHasBeenDeleted && this.props.formHasBeenDeleted)
    ) {
      this.fetchAllErrors();
      this.fetchErrorDetails();
    }
  }

  fetchAllErrors = () => {
    const { job, filterBy, isTestImport } = this.props;
    const { pageOffset, pageSize, sortColumn, isAscending } = this.state;
    this.props.recordActions.fetchAllErrors(job, filterBy, pageSize, pageOffset, sortColumn.sortIndicator, isAscending, isTestImport);
  };

  handleRowSelection = element =>
    this.setState({
      openValidationDetails: true,
      titleValidationDetails: element.Message,
      validationId: element.Id,
      fchar: element.Fchar
    });

  closeValildationDetails = () =>
    this.setState({ openValidationDetails: false }, () => {
      this.fetchAllErrors();
      if (!this.props.records.length) {
        this.props.closeRecordSlidingPanel();
      }
    });

  fetchErrorDetails = () => {
    const { validationId, fchar } = this.state;
    const { filterBy, isTestImport } = this.props;
    const { searchText, pageOffset, pageSize, sortColumn, sortOrder } = this.state.errorDetail;

    this.props.recordActions.fetchAllRecordsByValidationId(
      validationId,
      fchar,
      TaxEntityEnum.Error,
      searchText,
      pageOffset,
      pageSize,
      filterBy,
      sortColumn,
      sortOrder,
      isTestImport
    );
  };

  handleColumnSortChanged = newColumn => {
    const isAscending = this.state.sortColumn.id === newColumn.id ? !this.state.isAscending : true;
    this.setState({ isAscending, sortColumn: newColumn, pageOffset: 1 }, () => this.fetchAllErrors());
  };

  handlePerPageChange = (selectedPage, itemsPerPage) => {
    this.setState({ pageOffset: selectedPage, pageSize: itemsPerPage }, () => this.fetchAllErrors());
  };

  handleFectchCustomRecords = (searchText, pageOffset, pageSize, sortColumn, sortOrder) => {
    this.setState(
      { errorDetail: { searchText, pageOffset, pageSize, sortColumn, sortOrder } },
      () => this.fetchErrorDetails()
    );
  };

  handleExportClick = () => { this.props.recordActions.exportRecords(this.state.fchar, TaxEntityEnum.Error, this.state.validationId); };

  render() {
    const { fetchingErrors, isTestImport, records, job, total } = this.props;
    const { openValidationDetails, titleValidationDetails, fchar, pageOffset, pageSize, sortColumn } = this.state;

    return (
      <>
        <SovosTableGroup
          TableProps={ {
            data: records,
            columns,
            onRowClick: this.handleRowSelection,
            onColumnSort: this.handleColumnSortChanged,
            columnSortId: sortColumn.id
          } }
          PaginationFooterProps={ {
            currentPage: pageOffset,
            itemsPerPage: pageSize,
            totalItems: total,
            onPageChange: this.handlePerPageChange
          } }
          isLoading={ fetchingErrors }
        />
        <SovosReadOnlySlidingPanel
          title={ `Errors / ${titleValidationDetails}` }
          open={ openValidationDetails }
          onClose={ this.closeValildationDetails }
        >
          <div className="record-sliding-panel__margin">
            <RecordTable
              isTestImport={ isTestImport }
              job={ job }
              fchar={ fchar }
              onExportClick={ this.handleExportClick }
              type={ TaxEntityEnum.Error }
              isUpdating={ fetchingErrors }
              onDetailClose={ this.closeValildationDetails }
              customFetchRecords={ (searchText, pageOffset, pageSize, sortColumn, sortOrder) => this.handleFectchCustomRecords(searchText, pageOffset, pageSize, sortColumn, sortOrder) }
              sortColumn={ this.state.errorDetail.sortColumn }
              sortOrder={ this.state.errorDetail.sortOrder }
            />
          </div>
        </SovosReadOnlySlidingPanel>
      </>
    );
  }
}

Errors.propTypes = {
  job: PropTypes.number,
  filterBy: PropTypes.oneOf([0, 1, 2]),
  records: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  fetchingErrors: PropTypes.bool.isRequired,
  isTestImport: PropTypes.bool,
  recordActions: PropTypes.shape({
    fetchAllErrors: PropTypes.func,
    exportRecords: PropTypes.func,
    fetchAllRecordsByValidationId: PropTypes.func
  }).isRequired,
  closeRecordSlidingPanel: PropTypes.func,
  formHasBeenSaved: PropTypes.bool.isRequired,
  formHasBeenDeleted: PropTypes.bool.isRequired
};

Errors.defaultProps = {
  job: null,
  filterBy: 0,
  isTestImport: false,
  closeRecordSlidingPanel: null
};

export default Errors;
