const lookUp = {
  1: "EIN",
  2: "SSN",
  3: "QI-EIN",
  4: "NQI-EIN",
  5: "Other"
};

export const getTinTypeName = typeId => lookUp.hasOwnProperty(typeId) ? lookUp[typeId] : '';

export const EIN = '1';
export const SSN = '2';
