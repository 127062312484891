import { Component } from 'react';
import SovosSnackbar from 'mosaic-react/sovos-snackbar/SovosSnackbar';
import Portal from './Portal';

export const CONFIRMATION = 'confirmation';
const ERROR = 'error';

const initialState = {
  open: false,
  message: '',
  length: 'medium',
  actionLabel: '',
  variant: CONFIRMATION,
  onAction: () => {},
  onClose: () => {}
};

const withSnackbar = Children => class Snackbar extends Component {
  state = initialState;

  showSnackbar = ({ onClose = this.hideSnackbar, onAction = this.hideSnackbar, ...params }) => this.setState({
    open: true,
    onClose,
    onAction,
    variant: CONFIRMATION,
    ...params
  });

  showErrorSnackbar = params => this.showSnackbar({
    ...params,
    variant: ERROR,
    onClose: () => {}
  });

  hideSnackbar = () => this.setState(oldState => ({ ...initialState, variant: oldState.variant }));

  render() {
    return (
      <>
        <Children
          { ...this.props }
          showSnackbar={ this.showSnackbar }
          showErrorSnackbar={ this.showErrorSnackbar }
          hideSnackbar={ this.hideSnackbar }
        />
        <Portal>
          <SovosSnackbar { ...this.state } />
        </Portal>
      </>
    );
  }
};

export default withSnackbar;
