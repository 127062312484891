import { lazy, Suspense } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import theme from 'mosaic-react/themes/sovos-theme';
const DNDDroppable = lazy(() => import(/* webpackChunkName: "Droppable" */ './DroppableInterface'));

const { palette } = theme;

const Droppable = ({ children, droppableId, type, className, style, draggingColor }) => {
  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? draggingColor.withDragging : draggingColor.withoutDragging,
    display: 'inline-block',
    width: 175,
    ...style
  });

  return (
    <>
      <Suspense fallback={ <LinearProgress /> }>
        <DNDDroppable
          droppableId={ droppableId }
          type={ type }
        >
          {
            (provided, snapshot) => (
              <div
                ref={ provided.innerRef }
                className={ className }
                style={ getListStyle(snapshot.isDraggingOver) }
              >
                { children }
                { provided.placeholder }
              </div>
            )
          }
        </DNDDroppable>
      </Suspense>
    </>
  );
};

Droppable.defaultProps = {
  type: 'DEFAULT',
  className: '',
  style: {},
  draggingColor: {
    withDragging: palette.primary.light,
    withoutDragging: palette.text.contrast
  }
};

Droppable.propTypes = {
  children: PropTypes.array.isRequired,
  droppableId: PropTypes.string.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  draggingColor: PropTypes.shape({
    withDragging: PropTypes.string,
    withoutDragging: PropTypes.string
  })
};

export default Droppable;
