import ErrorIcon from '@mui/icons-material/Error';
import CheckCircle from '@mui/icons-material/CheckCircle';
import theme from 'mosaic-react/themes/sovos-theme';

const { palette } = theme;

const transmitType = {
  federal: 'federal',
  state: 'state',
  EOT: 'EOT'
};

const getIcon = status => {
  if (!status) {
    return '';
  } else if (status.includes('Accepted by IRS')) {
    return <CheckCircle style={ { fill: palette.success.main } } />;
  } else if (status.includes('Rejected by IRS')) {
    return <ErrorIcon color='error' />;
  }
  return '';
};

const configViewAllButton = {
  federal: {
    to: `/transmit-history/${transmitType.federal}`
  },
  state: {
    to: `/transmit-history/${transmitType.state}`
  }
};

const federalColumns = [
  {
    title: 'Transmit #',
    id: 1,
    sortIndicator: 'TransmitId',
    dataKey: 'TransmitId',
    width: 72
  },
  {
    title: 'Type',
    id: 2,
    sortIndicator: 'FilingStatus',
    component: ({ content }) => content.FilingStatus ? content.FilingStatus : '',
    width: 72
  },
  {
    title: 'Form',
    id: 3,
    sortIndicator: 'FormsToRun',
    component: ({ content }) => content.DisplayFormType ? content.DisplayFormType : '',
    width: 88
  },
  {
    title: 'Forms',
    id: 4,
    sortIndicator: 'FormCount',
    dataKey: 'FormCount',
    width: 72,
    align: 'right'
  },
  {
    title: 'Payers',
    id: 5,
    sortIndicator: 'PayerCount',
    dataKey: 'PayerCount',
    width: 72,
    align: 'right'
  },
  {
    title: '',
    id: 6,
    sortIndicator: 'status',
    component: ({ content }) => getIcon(content.Status),
    width: 20
  },
  {
    title: 'Transmit status',
    id: 7,
    sortIndicator: 'Status',
    dataKey: 'DisplayStatus',
    width: 'greedy'
  }
];

const stateColumns = [
  {
    title: 'Batch ID',
    id: 1,
    sortIndicator: 'BatchId',
    dataKey: 'BatchId',
    width: 72
  },
  {
    title: 'Transmit run number',
    id: 10,
    sortIndicator: 'TransmitRunNumber',
    dataKey: 'TransmitRunNumber',
    width: 72
  },
  {
    title: 'Agency',
    id: 2,
    sortIndicator: 'Agency',
    dataKey: 'Agency',
    width: 48
  },
  {
    title: 'Type',
    id: 3,
    sortIndicator: 'FilingStatus',
    extended: true,
    dataKey: 'FilingStatus',
    width: 96
  },
  {
    title: 'Forms',
    id: 4,
    sortIndicator: 'FormCount',
    dataKey: 'FormCount',
    width: 48,
    align: 'right'
  },
  {
    title: 'Payers',
    id: 5,
    sortIndicator: 'PayerCount',
    dataKey: 'PayerCount',
    width: 48,
    align: 'right'
  },
  {
    title: 'Confirmation number',
    id: 6,
    sortIndicator: 'ConfirmationNumber',
    dataKey: 'ConfirmationNumber',
    width: 140
  },
  {
    title: '',
    id: 7,
    sortIndicator: 'status',
    component: ({ content }) => getIcon(content.TransmitStatus),
    width: 20
  },
  {
    title: 'Transmit status',
    id: 8,
    sortIndicator: 'TransmitStatus',
    component: ({ content }) => content.FileName?.includes('CANCELLED_') ? 'Transmittal Rejected' : content.TransmitStatus,
    width: 'greedy'
  },
  {
    title: 'File',
    id: 9,
    sortIndicator: 'FileName',
    extended: true,
    dataKey: 'FileName',
    width: 'greedy'
  }
];

const EOTColumns = [
  {
    dataKey: 'PayerId',
    id: 1,
    title: 'Payer',
    width: 'greedy',
    sortIndicator: 'PayerId'
  },
  {
    dataKey: 'RunNo',
    id: 2,
    title: 'Run number',
    width: 'greedy',
    sortIndicator: 'RunNo'
  },
  {
    dataKey: 'FormType',
    id: 3,
    title: 'Form category',
    width: 'greedy',
    sortIndicator: 'FormType'
  },
  {
    dataKey: 'CreatedOn',
    id: 4,
    title: 'Date',
    width: 'greedy',
    sortIndicator: 'CreatedOn'
  },
  {
    dataKey: 'ConfirmationNo',
    id: 5,
    title: 'Confirmation #',
    width: 'greedy',
    sortIndicator: 'ConfirmationNo'
  }
];

const getStateColumns = (extended = false) => {
  if (extended) {
    return stateColumns;
  }
  else {
    return stateColumns.filter(column => !column.extended);
  }
};

export {
  federalColumns,
  EOTColumns,
  getStateColumns,
  configViewAllButton,
  transmitType
};
