import actionTypes from './PayerConstants';
import formActionTypes from 'shared/dataEntryForms/sovos-dynamic-form/redux/DynamicFormConstants';

const initialStore = {
  data: {},
  payers: [],
  fetching: false,
  payerSet: false
};

export default function PayerReducer(state = initialStore, action) {
  switch (action.type) {
    case actionTypes.FETCHING_PAYER:
      return { ...state, fetching: true };

    case actionTypes.FETCH_PAYER_SUCCESS:
      return { ...state, data: action.payer, fetching: false };

    case actionTypes.FETCH_PAYER_FAILURE:
      return { ...state, fetching: false, data: {} };

    case actionTypes.RESET_PAYER:
      return { ...state, data: {}, payerSet: false };

    case actionTypes.FETCH_PAYER_BY_REGEX_SUCCESS:
      return { ...state, payers: action.payers, fetching: false };

    case actionTypes.FETCH_PAYER_BY_REGEX_FAILURE:
      return { ...state, fetching: false, payers: [] };

    case actionTypes.SET_PAYER:
      return { ...state, data: action.payer, payers: [], payerSet: true };

    case actionTypes.SET_PAYER_SET:
      return { ...state, payerSet: action.value };

    case formActionTypes.RESET_FORM:
    case actionTypes.LOGOUT_SUCCESS:
      return { ...initialStore };

    default:
      return state;
  }
}
