import { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'enhancers';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import SovosCheckbox from 'mosaic-react/sovos-checkbox';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import withStyles from '@mui/styles/withStyles';
import SovosDialog from 'mosaic-react/sovos-dialog';
import SovosDialogActions from 'mosaic-react/sovos-dialog-actions';
import SovosDialogTitle from 'mosaic-react/sovos-dialog-title';
import SovosButton from 'mosaic-react/sovos-button';
import theme from 'mosaic-react/themes/sovos-theme';
import RefreshIndicatorLoading from 'shared/refreshIndicatorLoading/refreshIndicatorLoading';
import { forms } from './dataResetDialogHelper';
import { getRecords, payerFchar, recipient1042SFchar, recipientFchar, the1042FormTaxEntityCode, the1042SFchar, the945FormTaxEntityCode, withholdingForms } from 'helpers/formConstants';
import { get, remove } from 'helpers/axios/axiosInterceptorsV1';
import { get as getV2, remove as removeV2 } from 'helpers/axios/axiosInterceptorsV2';
import { buildUrlsParams } from 'helpers/buildUrlParams';
import { getErrorMessage } from 'helpers/errorMessageHelper';
import useFetchWithAbort from 'helpers/useFetchWithAbort';
import authenticationHelper from 'helpers/authenticationHelper';
import { WITHHOLDING_FORMS } from 'helpers/configurationConstants';
import classnames from 'classnames';
import './dataResetDialog.scss';

const { palette } = theme;

const lastIndex = forms.length - 1;

const styles = {
  input: {
    height: 50,
    padding: '0 10px',
    boxSizing: 'border-box',
    backgroundColor: palette.grey[100],
    fontSize: 16
  }
};

const DataResetDialog = ({
  modalOpen,
  toggleDialog,
  classes,
  showSnackbar,
  showErrorSnackbar }) => {

  const generateCode = () => Math.random().toString(36).substr(2, 6).toUpperCase();

  const [resetWithholdingDataConfig, setResetWithholdingDataConfig] = useState();
  const [fetchingWithholdingResetData, setFetchingWithholdingResetData] = useState(false);
  const [resetDataConfig, setResetDataConfig] = useState();
  const [fetchingResetData, setFetchingResetData] = useState(false);
  const [securityCode, setSecurityCode] = useState('');
  const [recordsSelected, setRecordsSelected] = useState([]);
  const [userSecurityCode, setUserSecurityCode] = useState('');
  const [resettingData, setResettingData] = useState(false);
  const [withholdingFormsEnabledLocal, setWithholdingFormsEnabledLocal] = useState(false);
  const [allForms, setAllForms] = useState([]);

  const { signal } = useFetchWithAbort({ fetchOnMount: false });

  useEffect(() => {
    const fetchResetData = async () => {
      try {
        setFetchingResetData(true);
        const response = await get('tax-entities/resets', { signal });
        setResetDataConfig(response.data);
      } catch (error) {
        showErrorSnackbar({ message: getErrorMessage(error) });
      } finally {
        setFetchingResetData(false);
      }
    };

    const fetchResetWithholdingData = async () => {
      try {
        setFetchingWithholdingResetData(true);
        const response = await getV2('forms/WithholdingForms/resets', { signal });
        setResetWithholdingDataConfig(response.data);
      } catch (error) {
        showErrorSnackbar({ message: getErrorMessage(error) });
      } finally {
        setFetchingWithholdingResetData(false);
      }
    };

    if (modalOpen) {
      const [withholdingFormsEnabled] = authenticationHelper.checkConfiguration([WITHHOLDING_FORMS]);
      const all1099Fchars = Object.keys(getRecords());
      const allFChars = withholdingFormsEnabled ? all1099Fchars.concat(withholdingForms) : all1099Fchars;
      setAllForms(allFChars);
      setWithholdingFormsEnabledLocal(withholdingFormsEnabled);
      fetchResetData();
      if (withholdingFormsEnabled) {
        fetchResetWithholdingData();
      }

      setSecurityCode(generateCode());
    }
  }, [modalOpen]);

  const resetData = async () => {
    setResettingData(true);
    await resetRecords();
    await resetWithholdingRecords();
    setResettingData(false);
    closePanel();
  };

  const resetRecords = async () => {
    try {
      var entities = recordsSelected.filter(record => !withholdingForms.includes(record));
      if (entities.length) {
        const query = buildUrlsParams({ entities: entities });
        await remove(`tax-entities/resets?${query}`, recordsSelected );
        showSnackbar({ message: 'Records were deleted successfully.' });
      }
    } catch (error) {
      showErrorSnackbar({ message: 'We could not delete the records. Please, try again.' });
    }
  };

  const resetWithholdingRecords = async () => {
    try {
      var withholdingEntities = recordsSelected.filter(record => withholdingForms.includes(record));
      if (withholdingEntities.length) {
        await removeV2('forms/WithholdingForms/resets', { data: { TaxEntities: withholdingEntities } } );
        showSnackbar({ message: 'Records were deleted successfully.' });
      }
    } catch (error) {
      showErrorSnackbar({ message: 'We could not delete the withholding records. Please, try again.' });
    }
  };

  const closePanel = () => {
    setSecurityCode('');
    setRecordsSelected([]);
    toggleDialog();
  };

  const handleOnClickCheckBox = ({ target: { name } }) => {
    if (!recordsSelected.includes(name)) {
      setRecordsSelected([...recordsSelected, name]);
    } else {
      const newRecordsSelected = recordsSelected.filter(record => record !== name);
      setRecordsSelected(newRecordsSelected);
    }
  };

  const handleUserSecurityCodeChange = evt => {
    setUserSecurityCode(evt.target.value);
  };

  const isEnablePayerRecipient = value => {
    if (resetDataConfig[value] && resetDataConfig[value] === 'Y' ) {
      const forms = Object.keys(resetDataConfig).filter(key => key !== payerFchar && key !== recipientFchar && key !== recipient1042SFchar && key !== the1042SFchar);
      const formsSelected = recordsSelected.filter(record => record !== payerFchar && record !== recipientFchar && record !== recipient1042SFchar && record !== the1042SFchar);

      if (forms.length) {
        let allFormsSelected = false;

        forms.every(form => {
          if (formsSelected.includes(form) && resetDataConfig[form] === 'Y') {
            allFormsSelected = true;
          } else {
            allFormsSelected = false;
            return false;
          }
          return true;
        });

        if (!allFormsSelected) {
          if (recordsSelected.includes(recipientFchar)) {
            handleOnClickCheckBox(recipientFchar);
          }
          if (recordsSelected.includes(payerFchar)) {
            handleOnClickCheckBox(payerFchar);
          }
        }

        return allFormsSelected;
      }

      return true;
    }
    return false;
  };

  const isEnableleWithholdingPayer = () => resetWithholdingDataConfig.find(resetData => resetData.TaxEntity === payerFchar).ResetStatus;

  const isDisable1042sRecipient = () => {
    if (resetDataConfig[the1042SFchar] && !recordsSelected.includes(the1042SFchar) && recordsSelected.includes(recipient1042SFchar)) {
      handleOnClickCheckBox(recipient1042SFchar);
    }

    return !(
      resetDataConfig[recipient1042SFchar] && resetDataConfig[recipient1042SFchar] === 'Y' &&
      (!resetDataConfig[the1042SFchar] || recordsSelected.includes(the1042SFchar))
    );
  };

  const isCheckboxDisabled = fChar => {
    switch (fChar) {
      case payerFchar:
        return !isEnablePayerRecipient(fChar) || (withholdingFormsEnabledLocal && !isEnableleWithholdingPayer());
      case recipientFchar:
        return !isEnablePayerRecipient(fChar);
      case recipient1042SFchar:
        return isDisable1042sRecipient();
      case the945FormTaxEntityCode:
      case the1042FormTaxEntityCode:
        return !resetWithholdingDataConfig.find(resetData => resetData.TaxEntity === fChar).ResetStatus;
      default:
        return !resetDataConfig[fChar] || resetDataConfig[fChar] !== 'Y';
    }
  };

  return (
    modalOpen &&
    <SovosDialog
      open={ modalOpen }
      width='small'
    >
      <SovosDialogTitle>
        Data reset
      </SovosDialogTitle>
      Select which data you wish to reset. Records that have been printed or transmitted cannot be reset. You will not be able to recover this data once you click, "Reset data".
      {
        fetchingResetData || fetchingWithholdingResetData || resettingData || !Boolean(resetDataConfig) || (withholdingFormsEnabledLocal && !Boolean(resetWithholdingDataConfig))
          ? <div className='reset__indicator'><RefreshIndicatorLoading size={ 40 } /></div>
          : <FormGroup>
            {
              forms.reduce((acc, { fChar, label }, index) => {
                if (allForms.includes(fChar)) {
                  acc.push(
                    <Fragment key={ fChar }>
                      <FormControlLabel
                        control={
                          <SovosCheckbox
                            name={ fChar }
                            onChange={ handleOnClickCheckBox }
                            checked={ recordsSelected.includes(fChar) }
                            disabled={ isCheckboxDisabled(fChar) }
                            classes={ {
                              root: classnames('checkbox__red', { 'checkbox__inactive': isCheckboxDisabled(fChar) })
                            } }
                          />
                        }
                        label={ label }
                        labelPlacement='start'
                        classes={ {
                          root: 'checkbox__label'
                        } }
                      />
                      { lastIndex !== index && <Divider /> }
                    </Fragment>
                  );
                }

                return acc;
              }, [])
            }
          </FormGroup>
      }
      <p>This action cannot be undone. To confirm the data reset, enter the following code.</p>
      <p className='data-reset__code-security'><span>Code:</span> <span id='data-reset-security-code'>{ securityCode }</span></p>
      <TextField
        id='security-code'
        placeholder='Enter code'
        fullWidth
        InputProps={
          {
            classes: {
              input: classes.input
            }
          }
        }
        value={ userSecurityCode }
        onChange={ handleUserSecurityCodeChange }
      />
      <SovosDialogActions>
        <SovosButton
          label='Cancel'
          variant='text'
          onClick={ closePanel }
          disabled={ resettingData }
        >
          Cancel
        </SovosButton>
        <SovosButton
          color='error'
          onClick={ resetData }
          disabled={ !recordsSelected.length || securityCode !== userSecurityCode || !securityCode.length || resettingData }
        >
          Reset data
        </SovosButton>
      </SovosDialogActions>
    </SovosDialog>
  );
};

DataResetDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  showErrorSnackbar: PropTypes.func.isRequired
};

export default withStyles(styles)(withSnackbar(DataResetDialog));
