import SovosAvatar from 'mosaic-react/sovos-avatar';
import { formatDateUTC } from 'tir-frontend-common/helpers/dateHelper';
import { splitFullName } from 'helpers/textFormatHelper';

export const WithholdingFormStatus = {
  READY_TO_GENERATE: 100,
  GENERATING: 101,
  GENERATED: 102,
  SUBMITTING: 103,
  SUBMITTED: 104,
  SUBMISSION_ACCEPTED: 105,
  SUBMISSION_REJECTED: 106,
  VALIDATION_ERROR: 200,
  GENERATION_ERROR: 201,
  SUBMISSION_ERROR: 202,
  IRS_ERROR: 203,
  UNKNOWN_ERROR: 204
};

const getWithholdingFormStatus = (statusCode, hasValidationErrors) => {
  switch (statusCode) {
    case WithholdingFormStatus.READY_TO_GENERATE:
      return hasValidationErrors ? 'Validation error' : 'Ready to generate';
    case WithholdingFormStatus.GENERATING:
      return 'Generating';
    case WithholdingFormStatus.GENERATED:
      return 'PDF generated';
    case WithholdingFormStatus.SUBMITTING:
      return 'Submitting';
    case WithholdingFormStatus.SUBMITTED:
      return 'Submitted';
    case WithholdingFormStatus.SUBMISSION_ACCEPTED:
      return 'Accepted by the IRS';
    case WithholdingFormStatus.SUBMISSION_REJECTED:
      return 'Rejected by the IRS';
    case WithholdingFormStatus.VALIDATION_ERROR:
      return 'Validation error';
    case WithholdingFormStatus.GENERATION_ERROR:
      return 'Generation error';
    case WithholdingFormStatus.SUBMISSION_ERROR:
      return 'Submission error';
    case WithholdingFormStatus.IRS_ERROR:
      return 'IRS error';
    case WithholdingFormStatus.UNKNOWN_ERROR:
      return 'Unknown error';
    default:
      return '';
  }
};

export const WithholdingFormsColumns = [
  {
    id: 1,
    dataKey: 'PayerId',
    title: 'Payer',
    sortIndicator: 'payerId',
    width: 100
  },
  {
    id: 2,
    dataKey: 'FilingStatus',
    title: 'Filing status',
    sortIndicator: 'filingStatus',
    notSortable: true,
    width: 70
  },
  {
    id: 3,
    dataKey: 'FormType',
    title: 'Form type',
    sortIndicator: 'formType',
    width: 60
  },
  {
    id: 4,
    dataKey: 'Status',
    title: 'Status',
    sortIndicator: 'status',
    component: ({ content: { Status, HasValidationErrors } }) => getWithholdingFormStatus(Status, HasValidationErrors),
    width: 110
  },
  {
    id: 5,
    dataKey: 'LastUpdatedDate',
    title: 'Updated',
    sortIndicator: 'lastUpdatedDate',
    component: ({ content }) => formatDateUTC(content.LastUpdatedDate),
    width: 70,
    align: 'right'
  },
  {
    id: 6,
    dataKey: 'LastUpdatedByUserId',
    title: 'Updated by',
    sortIndicator: 'lastUpdatedByUserId',
    notSortable: true,
    component: ({ content }) => {
      const [firstAndMiddle, lastName] = splitFullName(content.LastUpdatedByUserId);
      return (
        <SovosAvatar
          size='small'
          name={ firstAndMiddle }
          surname={ lastName }
        />
      );
    },
    align: 'center',
    width: 70
  },
  {
    id: 7,
    dataKey: 'TransmittalFileName',
    title: 'File name',
    sortIndicator: 'transmittalFileName',
    notSortable: true,
    width: 'greedy'
  },
  {
    id: 8,
    dataKey: 'JobId',
    title: 'Job ID',
    sortIndicator: 'jobId',
    notSortable: true,
    width: 50
  },
  {
    id: 9,
    dataKey: 'ConfirmationNumber',
    title: 'Confirmation #',
    sortIndicator: 'confirmationNumber',
    notSortable: true,
    width: 355
  }
];