import { Component } from 'react';
import PropTypes from 'prop-types';
import { getLocalTimestamp } from 'tir-frontend-common/helpers/dateHelper';
import SpecialFields from 'helpers/specialFields';
import { currencyFormat } from 'helpers/textFormatHelper';
import AuthorAvatar from 'shared/authorAvatar/AuthorAvatar';
import { getNoticeName } from 'pages/printCorp/helpers/notice/NoticeConstants';
import { formCategoriesEOT } from 'helpers/enums';
import { ActionType } from '../helpers/formHistoryHelper';
import { theW2MFchar } from 'helpers/formConstants';
import theme from 'mosaic-react/themes/sovos-theme';
import './formHistory.scss';

const { palette } = theme;

const TC_NOTICE = 'tcnotice';
const TIM_NOTICE = 'timnotice';

const IdentityManagement = {
  SECONDNOTICE: {
    name: 'TIN',
    success: 'A'
  },
  OFACSTATUS: {
    name: 'OFAC',
    success: 'P'
  },
  DMF: {
    name: 'DMF',
    success: 'P'
  }
};

const secondNotice = {
  S: 'Sent',
  _: 'Cancelled',
  A: 'Match',
  B: 'Invalid TIN',
  C: 'TIN Not Issued',
  D: 'No Match',
  E: 'Duplicate or Invalid Request'
};

const ActionVia = {
  0: 'Not Specified',
  1: 'manual entry',
  2: 'import',
  3: '',
  4: '',
  5: 'response processing',
  6: 'IRS bulk TIN match response'
};

class FormHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formattedTimestamp: getLocalTimestamp(props.history.ModifiedOn),
      fieldC06Set: props.history.DeltaValue?.some(change => change.Key === 'C06'),
      fieldC07Set: props.history.DeltaValue?.some(change => change.Key === 'C07')
    };
  }

  showValue = (field, fieldValue) => {
    if (['SelectField', 'CheckBoxField', 'CheckBoxFieldPlaceholder', 'CheckBoxFieldForm'].includes(field.fieldType)) {
      if (field.fieldType === 'SelectField') {
        const option = field.options.filter(option => option.value === fieldValue);
        if (!option.length) {
          return fieldValue || 'Empty';
        }
        return option[0] ? option[0].text : 'Empty';
      } else if (field.options) {
        return field.options[fieldValue] ? 'Checked' : 'Unchecked';
      } else {
        return fieldValue ? 'Checked' : 'Unchecked';
      }
    } else if (field.fieldType === 'TextFieldForm' || field.fieldType === 'AmountDifferenceFieldForm') {
      if (field.mask === 'CURRENCY') {
        if (fieldValue.split('.')[1]) {
          return currencyFormat(fieldValue);
        }
        return currencyFormat(fieldValue).replace('.00', '');
      }
      return fieldValue || 'Empty';
    } else {
      return fieldValue || 'Empty';
    }
  };

  getVerificationStatus = (passValue, value) => passValue === value ? 'Passed' : 'Failed';

  getStateStateId = values => (
    values.length
      ? values.map((value, idx) => (
        <p key={ idx }>
          <strong>State: { value.State } - StateId: { value.StateId }</strong>
        </p>
      ))
      : <strong>Empty</strong>
  );

  listChangesByActionType = action => {
    switch (action) {
      case ActionType.added:
        return this.listAddedChanges();
      case ActionType.correction:
        return this.listUpdatedChanges();
      case ActionType.updated:
        return this.listUpdatedChanges();
      case ActionType.print:
        return this.listPrintChanges();
      case ActionType.irsTransmit:
        return this.listIrsTransmitChanges();
      case ActionType.stateTransmit:
        return this.listStateTransmitChanges();
      case ActionType.localityTransmit:
        return this.listLocalityTransmitChanges();
      case ActionType.markForReprint:
      case ActionType.reprintLocally:
      case ActionType.cancelReprint:
        return this.listReprintChanges(action);
      case ActionType.updateTinMatchStatus:
        return this.listUpdatedChanges();
      case ActionType.extensionOfTime:
        return this.listExtensionOfTimeChanges();
      default:
        return '';
    }
  };

  getRecordName = () => {
    const history = this.props.history;
    if (history.NewValue?.StateAbbreviation){
      if (history.NewValue?.LocalityName){
        return <span>Locality <strong>{ history.NewValue.LocalityName }</strong></span>;
      }
      return <span>State <strong>{ history.NewValue.StateAbbreviation }</strong></span>;
    }
    return 'Record';
  };

  listAddedChanges = () => <p>{ this.getRecordName() } has been added via { ActionVia[this.props.history.Via] } { ActionVia[this.props.history.Via] === 'import' && this.props.history.DeltaValue[0]?.Value.New }</p>;

  // To do: refactor this function after season
  listUpdatedChanges = () => {
    const W2MArrayFields = this.props.formType === theW2MFchar ? Object.values(this.props.allFields) : [];
    return (
      <span>
        {
          this.props.history.Action === ActionType.correction &&
        <p className='form-history__marked-as-correction'>Record has been marked as correction.</p>
        }
        <p>{ this.getRecordName() } has been updated via { ActionVia[this.props.history.Via] } </p>
        {
          this.props.history.DeltaValue
            .filter(change => change.Key !== 'ModId')
            .map(change => {
              const key = change.Key.toUpperCase();

              const field = this.props.formType === theW2MFchar
                ? W2MArrayFields.find(field => field.entityField === key)
                : this.props.allFields[key];

              if (field) {

                if (field.viewColumn === SpecialFields.XMITRUN || field.viewColumn === SpecialFields.TRANTYPE) {
                  return null;
                }

                if (field.name === SpecialFields.IDENTITYVERIFICATION || IdentityManagement[field.name.toUpperCase()]) {
                  // bulk tin check
                  if (this.props.history.Action === ActionType.updateTinMatchStatus) {
                    return (
                      <div className='form-history__history-block' key={ change.Key }>
                        <p className='form-history__text-value'><strong>{ field.labelForm || field.label }:</strong></p>
                        <p className='form-history__text-value'>Old Value: <strong>{ secondNotice[change.Value.Old] || 'Empty' }</strong></p>
                        <p className='form-history__text-value'>New Value: <strong>{ secondNotice[change.Value.New] || 'Empty' }</strong></p>
                      </div>
                    );
                  }
                  // real time TIN check
                  return (
                    <div className='form-history__history-block' key={ change.Key }>
                      <p className='form-history__text-value_identity'>
                        { `${IdentityManagement[change.Key.toUpperCase()].name} ` }
                        <strong>{
                          this.getVerificationStatus(
                            IdentityManagement[change.Key.toUpperCase()].success,
                            change.Value.New
                          ) }</strong>
                      </p>
                    </div>
                  );
                }

                if (field.name === 'multiple-state') {
                  return (
                    <div className='form-history__history-block' key={ change.Key }>
                      <p className='form-history__text-value'><strong>{ field.labelForm || field.label }:</strong></p>
                      <p className='form-history__text-value'>Old Value: </p>
                      <div className='form-history__text-value_statest'>{ this.getStateStateId(JSON.parse(change.Value.Old)) }</div>
                      <p className='form-history__text-value'>New Value: </p>
                      <div className='form-history__text-value_statest'>{ this.getStateStateId(JSON.parse(change.Value.New)) }</div>
                    </div>
                  );
                }

                if (this.props.formType === 'F') {
                  if (field.name === 'c06' || field.name === 'c07') {
                    if (field.name === 'c07' && this.state.fieldC06Set) {
                      return null;
                    }

                    if (field.name === 'c07' && !this.state.fieldC06Set) {
                      return (
                        <div className='form-history__history-block' key={ change.Key }>
                          <p className='form-history__text-value'><strong>Chapter indicator:</strong></p>
                          <p className='form-history__text-value'>Old Value: <strong>Empty</strong></p>
                          <p className='form-history__text-value'>New Value: <strong>BOXCHAP4</strong></p>
                        </div>
                      );
                    }

                    if (field.name === 'c06' && !this.state.fieldC07Set) {
                      return (
                        <div className='form-history__history-block' key={ change.Key }>
                          <p className='form-history__text-value'><strong>Chapter indicator:</strong></p>
                          <p className='form-history__text-value'>Old Value: <strong>Empty</strong></p>
                          <p className='form-history__text-value'>New Value: <strong>BOXCHAP3</strong></p>
                        </div>
                      );
                    }

                    return (
                      <div className='form-history__history-block' key={ change.Key }>
                        <p className='form-history__text-value'><strong>Chapter indicator:</strong></p>
                        <p className='form-history__text-value'>Old Value: <strong>{ change.Value.New ? 'BOXCHAP4' : 'BOXCHAP3' }</strong></p>
                        <p className='form-history__text-value'>New Value: <strong>{ change.Value.New ? 'BOXCHAP3' : 'BOXCHAP4' }</strong></p>
                      </div>
                    );
                  }
                }

                return (
                  <div className='form-history__history-block' key={ change.Key }>
                    <p className='form-history__text-value'><strong>{ field.labelForm || field.label }:</strong></p>
                    <p className='form-history__text-value'>Old Value: <strong>{ this.showValue(field, change.Value.Old) }</strong></p>
                    <p className='form-history__text-value'>New Value: <strong>{ this.showValue(field, change.Value.New) }</strong></p>
                  </div>
                );

              }
              return null;
            })
        }
      </span>);
  };

  getDeltaValue = (deltas, deltaKey) => deltas.find(el => el.Key === deltaKey)?.Value.New;

  listPrintChanges = () => {
    const delta = this.props.history.DeltaValue[0];

    switch (delta.Key) {
      case TC_NOTICE:
        return (<p>{ this.props.history.UserName } sent a <strong>{ getNoticeName(delta.Value.New) }</strong> to the Recipient</p>);
      case TIM_NOTICE:
        return (<p>Record was included in a <strong>{ getNoticeName(delta.Value.New) }</strong> solicitation</p>);
      default:
        return (<p>Record was included in print order #{ this.getDeltaValue(this.props.history.DeltaValue, 'StmtRun') }</p>);
    }
  };

  listReprintChanges = action => {
    switch (action) {
      case ActionType.markForReprint:
        return <p>Record marked to be reprinted</p>;
      case ActionType.cancelReprint:
        return <p>Record unmarked to be reprinted</p>;
      case ActionType.reprintLocally:
        return <p>Record printed locally</p>;
      default:
        return '';
    }
  };

  listIrsTransmitChanges = () => <p>Record was included in IRS transmittal #{ this.getDeltaValue(this.props.history.DeltaValue, 'XmitRun') }</p>;

  listStateTransmitChanges = () => <p>Record was included in state transmittal #{ this.getDeltaValue(this.props.history.DeltaValue, 'StXmitInd') }</p>;

  listLocalityTransmitChanges = () => <p>Record was included in locality transmittal #{ this.getDeltaValue(this.props.history.DeltaValue, 'LclXmitInd') }</p>;

  listExtensionOfTimeChanges = () => {
    const formCategories = this.props.history.NewValue.EOTTransmittalFormCategories;
    return (
      <p>
        Record was included in extension of time #{ this.props.history.NewValue.Runno } for form categor{ formCategories.length === 1 ? 'y' : 'ies' } {
          formCategories.map((formCategory, index) =>
            <span key={ formCategory }>
              { `${index === 0 ? '' : ' and '}${formCategoriesEOT[formCategory]}` }
            </span>
          ) }
      </p>);
  };

  render() {
    return (
      <div className='form-history__container'>
        <span className='form-history__block' >
          <AuthorAvatar userName={ this.props.history.UserName } />
        </span>
        <span className='form-history__block form-history__text'>
          { this.listChangesByActionType(this.props.history.Action) }
        </span>
        <span className='form-history__block form-history__timestamp' style={ { color: palette.text.secondary } }>{ this.state.formattedTimestamp }</span>
      </div>
    );
  }
}

FormHistory.propTypes = {
  allFields: PropTypes.object.isRequired,
  history: PropTypes.shape({
    DeltaValue: PropTypes.array,
    Via: PropTypes.number,
    Action: PropTypes.number,
    ModifiedOn: PropTypes.string,
    UserName: PropTypes.string
  }).isRequired
};

export default FormHistory;
