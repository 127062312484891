import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FormInTabs from './FormInTabs';
import { fetchEntitiesByPayee } from 'redux/entities/EntityActions';
import { setFcharToDelete, setTaxEntityCode, setFormId } from '../sovos-dynamic-form/redux/DynamicFormActions';
import { getRecordName } from 'helpers/formConstants';

const tabSort = (a, b) => {
  const aName = getRecordName(Object.keys(a)[0]);
  const bName = getRecordName(Object.keys(b)[0]);
  if (aName < bName) {
    return -1;
  }
  if (aName > bName) {
    return 1;
  }
  return 0;
};

const mapStateToProps = state => ({
  entities: state.entity.entities.filter(entity => Object.keys(entity)[0] !== state.dynamicForm.taxEntityCodeBase).sort(tabSort),
  modRecNo: state.dynamicForm.form[state.dynamicForm.taxEntityCodeBase]?.MODRECNO,
  allEntities: state.entity.entities,
  fetchingEntities: state.entity.fetchingEntities,
  taxEntityCode: state.dynamicForm.taxEntityCode,
  taxEntityCodeBase: state.dynamicForm.taxEntityCodeBase,
  formModified: state.dynamicForm.formsModified[state.dynamicForm.taxEntityCode],
  fetchingMetadata: state.formMetaInformation.fetchingMetadata,
  fetchingForm: state.dynamicForm.fetchingForm,
  saveFormSuccess: state.dynamicForm.saveFormSuccess
});

const mapDispatchToProps = dispatch => ({
  entityActions: bindActionCreators({ fetchEntitiesByPayee }, dispatch),
  dynamicFormActions: bindActionCreators({ setFcharToDelete, setFormId, setTaxEntityCode }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(FormInTabs);
