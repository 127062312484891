import { useState } from 'react';
import { bool, func, number, string, oneOfType } from 'prop-types';
import CurrencyFormat from 'react-currency-format';
import WrapperMuiTextField from 'shared/standardInput/textField/helper/WrapperMuiTextField';
import classNames from 'classnames';
import 'shared/standardInput/textField/validationGridTextField/validationGridTextField.scss';
import './summaryOfQDDRow.scss';

const SummaryOfQDDRow = ({
  label,
  readOnly,
  boxName,
  rightBoxName,
  scheduleQId,
  inputField,
  inputValue,
  columnAField,
  columnAValue,
  columnAReadOnly,
  columnBField,
  columnBValue,
  columnBReadOnly,
  columnCField,
  columnCValue,
  columnCReadOnly,
  handleOnChange
}) => {
  const [internalColumnAValue, setInternalColumnAValue] = useState(columnAValue || '');
  const [internalColumnBValue, setInternalColumnBValue] = useState(columnBValue || '');
  const [internalColumnCValue, setInternalColumnCValue] = useState(columnCValue || '');

  const getBoxNameLabel = (boxName, className) =>(
    boxName &&
    <div
      className={ classNames('tirTexfield__label-box', className) }
    >
      { boxName }
    </div>
  );

  return (
    <div className='tirTextfieldForm__wrapper amountDifference__wrapper'>
      <div className='label__container'>
        <div className='label__container-left summary-of-qdd-row__label-input'>
          { getBoxNameLabel(boxName, 'label__left-box-name') }
          <label className='tirTexfield__label summary-of-qdd-row__label-input__label' >
            { label }
          </label>
          { inputField &&
            <div className='textField__container summary-of-qdd-row__label-input__input'>
              <WrapperMuiTextField
                value={ inputValue }
                onChange={ evt => handleOnChange(inputField, evt.target.value) }
                autoComplete='off'
                disabled={ readOnly || columnBReadOnly }
                id={ `${inputField}-${scheduleQId}` }
              />
            </div>
          }
        </div>
        { getBoxNameLabel(rightBoxName || boxName, 'summary-of-qdd-row__right-box') }
      </div>

      <div className='amount-field__display'>
        <div className='textField__container summary-of-qdd-row__container'>
          <CurrencyFormat
            value={ columnAReadOnly ? columnAValue : internalColumnAValue }
            thousandSeparator
            prefix='$'
            decimalScale={ 0 }
            customInput={ WrapperMuiTextField }
            onValueChange={ ({ value, floatValue }) => {
              setInternalColumnAValue(value);
              handleOnChange(columnAField, value === '' ? '' : floatValue);
            } }
            onBlur={ () => {
              if (columnAValue === '') {
                handleOnChange(columnAField, 0);
                setInternalColumnAValue(0);
              } else {
                setInternalColumnAValue(columnAValue);
              } } }
            autoComplete='off'
            disabled={ readOnly || columnAReadOnly }
            { ...(columnAField && ({ id: `${columnAField}-${scheduleQId}` })) }
          />
        </div>
        <div className='textField__container summary-of-qdd-row__container'>
          <CurrencyFormat
            value={ internalColumnBValue }
            decimalScale={ 5 }
            allowNegative={ false }
            customInput={ WrapperMuiTextField }
            onValueChange={ ({ value, floatValue }) => {
              setInternalColumnBValue(value);
              handleOnChange(columnBField, value === '' ? '' : floatValue);
            } }
            onBlur={ () => {
              if (columnBValue === '') {
                handleOnChange(columnBField, 0);
                setInternalColumnBValue('0');
              } } }
            autoComplete='off'
            disabled={ readOnly || columnBReadOnly }
            { ...(columnBField && ({ id: `${columnBField}-${scheduleQId}` })) }
          />
        </div>
        <div className='textField__container summary-of-qdd-row__container'>
          <CurrencyFormat
            value={ columnCReadOnly ? columnCValue && Math.round(columnCValue) : internalColumnCValue }
            thousandSeparator
            prefix='$'
            decimalScale={ 0 }
            customInput={ WrapperMuiTextField }
            onValueChange={ ({ value, floatValue }) => {
              setInternalColumnCValue(value);
              handleOnChange(columnCField, value === '' ? '' : floatValue);
            } }
            onBlur={ () => {
              if (columnCValue === '') {
                handleOnChange(columnCField, 0);
                setInternalColumnCValue(0);
              } else {
                setInternalColumnCValue(columnCValue);
              } } }
            autoComplete='off'
            disabled={ readOnly || columnCReadOnly }
            { ...(columnCField && ({ id: `${columnCField}-${scheduleQId}` })) }
          />
        </div>
      </div>
    </div>
  );
};

SummaryOfQDDRow.propTypes = {
  label: string.isRequired,
  readOnly: bool,
  boxName: string,
  rightBoxName: string,
  scheduleQId: number.isRequired,
  columnAField: string,
  columnAValue: oneOfType([number, string]),
  columnAReadOnly: bool,
  columnBField: string,
  columnBValue: oneOfType([number, string]),
  columnBReadOnly: bool,
  columnCField: string,
  columnCValue: oneOfType([number, string]),
  columnCReadOnly: bool,
  handleOnChange: func,
  inputField: string,
  inputValue: string
};

SummaryOfQDDRow.defaultProps = {
  boxName: '',
  rightBoxName: '',
  inputField: null
};

export default SummaryOfQDDRow;