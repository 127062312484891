export default {
  0: {
    snackbarMessage: '',
    snackBarType: ''
  },
  1: {
    snackbarMessage: "Client migration success",
    snackBarType: "confirmation"
  },
  2: {
    snackbarMessage: "Client previously migrated",
    snackBarType: "warning"
  },
  3: {
    snackbarMessage: "Client migration failed",
    snackBarType: "error"
  },
  4: {
    snackbarMessage: "Client seghash is not valid",
    snackBarType: "error"
  }
};
