import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'enhancers';
import GetAppIcon from '@mui/icons-material/GetApp';
import SovosTableGroup from 'mosaic-react/sovos-table-group';
import { getRecordName } from 'helpers/formConstants';
import DataModifier from 'shared/dataModifier/DataModifier';
import useColumnSort from 'helpers/useColumnSort';
import { getFilingType } from 'shared/dataEntryForms/sovos-dynamic-form/DynamicFormHelper';
import { downloadReport } from 'helpers/reportDownloadHelper';
import { getErrorMessage } from 'helpers/errorMessageHelper';
import { FE_REPORT_001 } from 'helpers/errorMessageConstants';
import { DOWNLOAD_REPORT_PAGE_SIZE } from 'helpers/constants';

const columns = [
  {
    title: 'Name',
    id: 1,
    width: 'greedy',
    sortIndicator: 'NAME',
    component: ({ content }) => content.NAME
  },
  {
    title: 'TIN',
    id: 2,
    width: 'greedy',
    sortIndicator: 'FK1',
    component: ({ content }) => (
      <DataModifier
        modifierType="TINMASK"
        records={ {
          TINTYPE: content.TINTYPE || '',
          FK1: content.FK1
        } } />
    )
  },
  {
    title: 'Account number',
    id: 3,
    width: 'greedy',
    sortIndicator: 'FK2',
    component: ({ content }) => content.FK2 || ''
  },
  {
    title: 'Payer ID',
    id: 4,
    width: 'greedy',
    sortIndicator: 'PAYERID',
    component: ({ content }) => content.PAYERID
  },
  {
    title: 'Form type',
    id: 5,
    width: 'greedy',
    sortIndicator: 'FCHARCONVERTED',
    component: ({ content }) => getRecordName(content.FCHAR)
  },
  {
    title: 'Filing type',
    id: 6,
    width: 'greedy',
    sortIndicator: 'TRANTYPE',
    component: ({ content }) => getFilingType(content.TRANTYPE)
  }
];

const initialSort = { id: columns[0].id, sortIndicator: columns[0].sortIndicator };

const NonPrintedNonTransmitted = ({ recordActions, records, taxDetailStatus, fetchingRecords, total, showErrorSnackbar }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [searchValue, setSearchValue] = useState('');
  const { sortOrderIndicator, sortColumnIndicator, sortColumnId, onColumnSortChanged } = useColumnSort(initialSort, true);

  useEffect(() => {
    recordActions.fetchFormsByStatus(searchValue, currentPage, itemsPerPage, sortColumnIndicator, sortOrderIndicator);
  }, [searchValue, currentPage, itemsPerPage, sortOrderIndicator, sortColumnIndicator]);

  const handleDownload = async () => {

    const fileName = taxDetailStatus === 'NonPrinted' ? 'NONPRINTEDFORMS' : 'NONTRANSMITTEDFORMS';
    try {
      const qs = new URLSearchParams({ getAll: true, parseColumnNames: true, pageSize: DOWNLOAD_REPORT_PAGE_SIZE, defaultFields: true, taxDetailStatus });
      const body = {
        FileName: fileName,
        DataRequests: [
          {
            name: fileName,
            url: `/api/v1/tax-forms/search?${qs}`,
            method: 'POST'
          }
        ]
      };

      await downloadReport(body, showErrorSnackbar);
    } catch (error) {
      showErrorSnackbar({ message: getErrorMessage(error, FE_REPORT_001) });
    }
  };

  const handleGoToPage = (selectedPage, itemsPerPage) => {
    setCurrentPage(selectedPage);
    setItemsPerPage(itemsPerPage);
  };

  const handleColumnSortChanged = newColumn => {
    setCurrentPage(1);
    onColumnSortChanged(newColumn);
  };

  const handleOnSearch = searchValue =>
    setSearchValue(searchValue);

  return (
    <SovosTableGroup
      TableProps={ {
        data: records,
        columns,
        onColumnSort: handleColumnSortChanged,
        columnSortId: sortColumnId
      } }
      isLoading={ fetchingRecords }
      PaginationFooterProps={ {
        currentPage: currentPage,
        itemsPerPage: itemsPerPage,
        totalItems: total,
        onPageChange: handleGoToPage
      } }
      search={ {
        onSearch: handleOnSearch
      } }
      actionIconButtons={ [{
        id: `Export-${taxDetailStatus}`,
        icon: <GetAppIcon />,
        onClick: handleDownload,
        tooltipText: 'Download'
      }] }
    />
  );
};

NonPrintedNonTransmitted.propTypes = {
  taxDetailStatus: PropTypes.string.isRequired,
  records: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  fetchingRecords: PropTypes.bool.isRequired,
  recordActions: PropTypes.shape({
    fetchFormsByStatus: PropTypes.func
  })
};

export default withSnackbar(NonPrintedNonTransmitted);
