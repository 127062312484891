import actionTypes from './ManageUserConstants';
import contextActionTypes from '../context/contextConstants';
import { get } from 'helpers/axios/axiosInterceptorsV1';

const fetchContextInfo = () => async dispatch => {
  const path = '/authorization/user/contextInfo';

  dispatch({ type: actionTypes.FETCHING_PERMISSIONS });

  try {
    const response = await get(path);
    const userInfo = {
      firstName: response.data.firstName,
      lastName: response.data.lastName,
      userId: response.data.userId
    };
    dispatch({ type: actionTypes.FETCH_PERMISSIONS_SUCCESS, permissions: response.data.permissions });
    dispatch({ type: contextActionTypes.FETCH_USER_SUCCESS, userInfo });
    return response.data;
  } catch (error) {
    dispatch({ type: actionTypes.FETCH_PERMISSIONS_FAILURE });
    throw (error);
  }
};

const fetchSolutionConfigurations = () => async dispatch => {
  dispatch({ type: actionTypes.FETCHING_SOLUTION_CONFIGURATIONS });

  try {
    const response = await get('/environment/configurations');
    dispatch({ type: actionTypes.FETCH_SOLUTION_CONFIGURATIONS_SUCCESS, config: response.data.data });
  } catch (error) {
    dispatch({ type: actionTypes.FETCH_SOLUTION_CONFIGURATIONS_FAILURE });
    throw (error);
  }
};

const fetchForms = () => async dispatch => {
  const path = '/tax-entities';
  dispatch({ type: actionTypes.FETCHING_FORMS });

  try {
    await get(path).then(response =>
      dispatch({ type: actionTypes.FETCH_FORMS_SUCCESS, data: response.data })
    );
  } catch (e) {
    dispatch({ type: actionTypes.FETCH_FORMS_FAILURE });
  }
};

export {
  fetchContextInfo,
  fetchSolutionConfigurations,
  fetchForms
};
