import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NewComment from './NewComment';
import { addNewComment, fetchComments } from 'redux/formComments/formCommentsActions';

const mapDispatchToProps = dispatch => ({
  commentsActions: bindActionCreators({ addNewComment, fetchComments }, dispatch)
});

export default connect(null, mapDispatchToProps)(NewComment);
