function pendoInitialize(info){
  if (typeof pendo === "object") {
    //eslint-disable-next-line no-undef
    pendo.initialize({
      visitor: {
        id: info.userId,
        domain: info.domain
      },
      account: {
        id: info.clientId,
        taxYear: info.taxYear,
        product: info.product,
        name: info.accountName
      }
    });
  }
}

export default pendoInitialize;
