import { useState, useEffect, useRef } from 'react';
import { ValidationRegex, getMaxLengthRegex, getMaxLengthNumberRegex } from './validationRegex';
import { cleanMask } from './maskHelper';

const useValidation = (
  value,
  label,
  name,
  validation,
  mask,
  onValidation,
  reset,
  errorMessage,
  runAutomatically = false
) => {
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [input, setInput] = useState(null);
  const [inputRefReady, setInputRefReady] = useState(false);
  const inputRef = useRef();
  const [maxLengthInvalid, setMaxLengthInvalid] = useState(false);

  const getPattern = () => {
    if (validation.pattern) {
      if (validation.pattern.includes('MAXLENGTHNUMBER')) {
        return getMaxLengthNumberRegex(validation.pattern.split('MAXLENGTHNUMBER')[1]);
      } else if (validation.pattern.includes('MAXLENGTH')) {
        return getMaxLengthRegex(validation.pattern.split('MAXLENGTH')[1], validation.specialCharacters);
      } else {
        switch (validation.pattern) {
          case 'NUMBER':
          case 'TIN':
          case 'EIN':
          case 'EMAIL':
            return ValidationRegex[validation.pattern];
          default:
            return validation.pattern;
        }
      }
    }
    return '';
  };

  const handleOnInvalid = evt => {
    validateComponent(evt, null, (valid, message) => {
      setMessage(message);
      setError(!!message);
      onValidation(valid, name);
    });
    evt.preventDefault();
  };

  const addValidation = () => {
    if (validation) {
      if (!mask && validation.pattern) {
        inputRef.current.pattern = getPattern();
      }
      if (validation.required){
        inputRef.current.required = true;
      }

      inputRef.current.addEventListener('blur', validateComponent);
      if (validation.validateOn !== 'blur' && validation.activateOn !== 'blur') {
        inputRef.current.addEventListener(validation.validateOn || validation.activateOn, validateComponent);
      }
      inputRef.current.addEventListener('invalid', handleOnInvalid);

      if (runAutomatically) {
        validateComponent();
      }
    }
  };

  const validateComponent = (evt, reset, onValid = null ) => {
    const onValidCallback = onValid || onValidation;
    if (reset) {
      inputRef.current.setCustomValidity('');
      setMessage('');
      setError(false);
      onValidCallback(false, name);
      return;
    }
    if (inputRef.current.validity.valueMissing) {
      const message = `${label} is required.`;
      setMessage(message);
      setError(!!message);
      onValidCallback(!inputRef.current.validity.valueMissing, name);
      return;
    }

    if (inputRef.current.validity.patternMismatch) {
      setMessage(validation.message);
      setError(!!validation.message);
      onValidCallback(!inputRef.current.validity.patternMismatch, name);
      return;
    }

    if (!maxLengthInvalid && evt) {
      const value = mask ? cleanMask(evt, mask) : evt.target.value;
      const regex = new RegExp(getPattern());
      !regex.test(value) ? inputRef.current.setCustomValidity(validation.message) : inputRef.current.setCustomValidity('');
    }

    if (!inputRef.current.validity.valid) {
      let message = '';
      if (maxLengthInvalid) {
        const max = validation.maxLength;

        message = `Only ${ max } character${ max > 1 ? 's' : '' } allowed.`;
      } else {
        message = validation.message;
      }

      setMessage(message);
      setError(!!message);
      onValidCallback(inputRef.current.validity.valid, name);
      return;
    }

    setMessage('');
    setError(false);
    onValidCallback(inputRef.current.validity.valid, name);
  };

  const removeValidation = () => {
    if (inputRef.current.required || inputRef.current.pattern) {
      inputRef.current.removeAttribute('required');
      inputRef.current.removeAttribute('pattern');
      inputRef.current.removeEventListener('blur', validateComponent);
      inputRef.current.removeEventListener(validation.validateOn || validation.activateOn, validateComponent);
      inputRef.current.removeEventListener('invalid', handleOnInvalid);
    }
  };

  useEffect(() => {
    if (inputRefReady) {
      validation && addValidation();
      !validation && removeValidation();
    }
  }, [validation, inputRefReady]);

  useEffect(() => {
    if (reset && inputRefReady) { //TODO: this variable is true on the creation, and stays that way, so this is never trigger, check the logic on the form reset, because this is useless as it is.
      validateComponent(
        null,
        reset,
        (valid, message) => {
          setMessage('');
          setError(false);
          onValidation(valid, name);
        }
      );
    }
  }, [reset, inputRefReady]);

  useEffect(() => {
    if (validation?.maxLength && inputRefReady) {
      const unMaskedValue = cleanMask(value, mask);
      const maxLengthInvalidLocal = unMaskedValue.length > validation.maxLength;
      setMaxLengthInvalid(maxLengthInvalidLocal);
      inputRef.current.setCustomValidity(maxLengthInvalidLocal || ''); //TODO: Research why this is receiving a bool, should be a string...
    }
  }, [value, inputRefReady]);

  useEffect(() => {
    if (input) {
      inputRef.current = input;
      setInputRefReady(true);
    }
  }, [input]);

  useEffect(() => {
    if (typeof errorMessage === 'string' && inputRefReady) {
      inputRef.current.setCustomValidity(errorMessage);
    }
  }, [errorMessage, inputRefReady]);

  return [error, message, setInput];
};

export default useValidation;
