import { useEffect, useState } from 'react';
import { arrayOf, bool, func, number, oneOfType, shape, string } from 'prop-types';
import AmountDifferenceFieldForm from 'shared/standardInput/textField/AmountDifferenceFieldForm';
import PreviousCurrentTextFieldForm from 'shared/standardInput/textField/PreviousCurrentTextFieldForm';
import MultipleRows from '../shared/multipleRows/MultipleRows';
import './otherValues.scss';

const TEXT = 'Text';
const AMOUNT = 'Amount';
const limit = 10;

const validations = {
  [TEXT]: {
    pattern: '^.{0,20}$',
    message: 'Text must not exceed 20 valid characters',
    validateOn: 'keyup'
  },
  [AMOUNT]: {
    pattern: '^.{0,20}$',
    message: 'Amount must not exceed 20 valid characters',
    validateOn: 'keyup'
  }
};

const addButtonLabelText = '+ Add additional value';
const errorAddButtonLabelText = 'The maximum number of values for Box 14 is 10';

const cleanOtherValues = oValues => oValues
  .filter(oValue => oValue[TEXT] || oValue[AMOUNT])
  .map(oValue => ({
    [TEXT]: oValue.Text,
    [AMOUNT]: oValue.Amount
  }));

const OtherValues = ({
  otherValues,
  formActions,
  readOnlyUser,
  isClone,
  runUIValidationAutomatically,
  taxEntityCode
}) => {
  const [localOtherValues, setLocalOtherValues] = useState(() => otherValues.length
    ? [...otherValues
      .map((oValues, id) => ({
        Id: id + 1,
        [TEXT]: oValues[TEXT],
        [AMOUNT]: oValues[AMOUNT]
      }))
    ]
    : []);

  useEffect(() => {
    if (shouldSave(otherValues, localOtherValues)){
      const completedRows = cleanOtherValues(localOtherValues);
      formActions.setFieldValue('OTHERVALUES', completedRows, isClone, taxEntityCode);
    }
  }, [localOtherValues, formActions, isClone]);

  const shouldSave = (otherValues, localOtherValues) => {
    if (otherValues.length !== localOtherValues.length) {
      return true;
    }

    return !otherValues.every(oValues =>
      localOtherValues.find(lOtherValues =>
        oValues[TEXT] === lOtherValues[TEXT]
        && oValues[AMOUNT] === lOtherValues[AMOUNT]
      )
    );
  };

  const handleAddOtherValues = () => {
    const newOtherValues = [
      ...localOtherValues,
      {
        Id: (localOtherValues.at(-1)?.Id || 0) + 1,
        [TEXT]: '',
        [AMOUNT]: ''
      }
    ];

    setLocalOtherValues(newOtherValues);
  };

  const handleDeleteOtherValues = id => setLocalOtherValues(prevState => prevState.filter(record => record.Id !== id));

  const handleChange = (key, value, id) =>
    setLocalOtherValues(prevState => prevState.map(
      otherValueInfo => otherValueInfo.Id === id
        ? { ...otherValueInfo, [key]: value }
        : otherValueInfo)
    );

  const isAtLimit = localOtherValues.length === limit;

  return (
    <div className='other-values__container'>
      <MultipleRows
        onAddRow={ handleAddOtherValues }
        showAddButton={ !readOnlyUser }
        addButtonProps={ {
          label: isAtLimit ? errorAddButtonLabelText : addButtonLabelText,
          disabled: isAtLimit
        } }
      >
        {
          localOtherValues.map(oValue => (
            <MultipleRows.Row
              key={ oValue.Id }
              onDeleteRow={ handleDeleteOtherValues }
              row={ oValue }
              showDeleteButton={ !readOnlyUser }
            >
              <PreviousCurrentTextFieldForm
                boxName='14'
                label='Other'
                name={ `${TEXT}-${oValue.Id}` }
                value={ oValue[TEXT] }
                onChange={ (_, value) => handleChange(TEXT, value, oValue.Id) }
                validation= { validations[TEXT] }
                readOnlyUser={ readOnlyUser }
                runUIValidationAutomatically={ runUIValidationAutomatically }
                customClasses={ {
                  tirTextfieldFormWrapper: 'other-values__previous-current-text-field-form-container'
                } }
                toUpper
              />
              <AmountDifferenceFieldForm
                name={ `${AMOUNT}-${oValue.Id}` }
                hideLabel
                value={ oValue[AMOUNT] }
                onChange={ (_, value) => handleChange(AMOUNT, value, oValue.Id) }
                validation= { validations[AMOUNT] }
                readOnlyUser={ readOnlyUser }
                runUIValidationAutomatically={ runUIValidationAutomatically }
                customClasses={ {
                  amountDifferentFields: 'other-values__amount-different-fields'
                } }
                mask='CURRENCY'
              />
            </MultipleRows.Row>
          ))
        }
      </MultipleRows>
    </div>
  );
};

OtherValues.propTypes = {
  otherValues: arrayOf(shape({
    [TEXT]: string,
    [AMOUNT]: oneOfType([string, number])
  })).isRequired,
  readOnlyUser: bool,
  runUIValidationAutomatically: bool,
  isClone: bool.isRequired,
  formActions: shape({
    setFieldValue: func.isRequired
  }).isRequired,
  taxEntityCode: string.isRequired
};

OtherValues.defaultProps = {
  readOnlyUser: false,
  runUIValidationAutomatically: false
};

export default OtherValues;