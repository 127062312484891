const generateFileNamesString = files => files.map(file => `fn=${file.name}`).join('&');

export const sizeLimit = 1073741824; // 1,073,741,824 bytes = 1024 Megabytes (1024 system)

export const fileManagerFolders = {
  allFiles: 'All files',
  transmittalFiles: 'Transmittal Files',
  reports: 'Reports',
  printFiles: 'Print Files',
  uploaded: 'Uploaded',
  timImages: 'TIM Images'
};

export const createFormData = (folder, files) => {
  let url = '';
  let fileNameSelected = '';
  const formData = new FormData();
  url += generateFileNamesString(files);
  files.forEach(file => {
    fileNameSelected = file.name;
    formData.append('file', file, file.name);
  });
  if (folder) {
    formData.append('path', folder);
  }

  return { url, fileNameSelected, formData };
};

export const createNewFile = file => {
  file.bytes = file.size;
  file.id = Math.floor(Math.random() * 10000);
  file.progress = {
    loading: false
  };
  return file;
};

export const shouldAddFile = (file, destFiles, folder) => {
  const fileAlreadyAdded = !destFiles.some(destFile => destFile.path === file.path);
  const fileExtension = file.type.split('/')[1];
  const isTiffFile = fileExtension === 'tiff' || fileExtension === 'tif';

  if (folder === fileManagerFolders.timImages) {
    return isTiffFile
      ? { shouldAddFile: fileAlreadyAdded, message: `${file.path} already added` }
      : { shouldAddFile: false, message: `${file.path} extension not allowed` };
  }

  if (isTiffFile) {
    return { shouldAddFile: false, message: `${file.path} can only be uploaded in the TIM Images folder` };
  }

  return { shouldAddFile: fileAlreadyAdded, message: `${file.path} already added` };
};

export const mediaTypes = {
  pdf: "application/pdf"
};