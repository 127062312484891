import types from './RecordsSummaryConstants';
import { get, post } from 'helpers/axios/axiosInterceptorsV1';
import { buildUrlsParams } from 'helpers/buildUrlParams';

const fetchRecordsByFormSummarySuccess = summary => ({ type: types.FETCHING_RECORDS_BY_FORM_SUMMARY_SUCCESS, summary });
const fetchRecordsByFormSuccess = records => ({ type: types.FETCH_RECORDS_BY_FORM_SUCCESS, records });

const fetchRecordsByFormSummary = (sortColumn, sortOrder) => async dispatch => {
  const qsParsed = buildUrlsParams(Object.assign({ sortBy: sortOrder + sortColumn }));
  const path = `tax-forms/summary?${qsParsed}`;

  dispatch({ type: types.FETCHING_RECORDS_BY_FORM_SUMMARY });

  try {
    const response = await get(path);
    dispatch(fetchRecordsByFormSummarySuccess(response.data));
  } catch (error) {
    dispatch({ type: types.FETCHING_RECORDS_BY_FORM_SUMMARY_FAILURE });
    throw (error);
  }
};

const fetchRecordsByForm = (fchar, itemsPerPage, pageNumber, searchText, sortColumn, sortOrder) => async dispatch => {
  const qsParsed = buildUrlsParams({ pageSize: itemsPerPage, pageOffset: pageNumber, sortBy: sortOrder + sortColumn });
  const path = `tax-forms/summary/${fchar}?${qsParsed}`;

  dispatch({ type: types.FETCHING_RECORDS_BY_FORM });

  try {
    const response = await post(path, { SearchText: searchText });
    dispatch(fetchRecordsByFormSuccess(response.data));
  } catch (error) {
    throw (error);
  }
};

export {
  fetchRecordsByFormSummary,
  fetchRecordsByForm
};
